<script setup lang="ts">
import type { UpdateOrCreateStorageUser } from '~/types'

defineProps<{
  payload: UpdateOrCreateStorageUser
  customerOptions: { label: string, value: string }[]
}>()

const emit = defineEmits<{ (e: 'submit', payload: UpdateOrCreateStorageUser): void }>()
</script>

<template>
  <FormKit
    v-slot="{ value }"
    :value="payload.data"
    type="form"
    :submit-label="payload.mode === 'create' ? 'Neuen Lagernutzer anlegen' : 'Lagernutzer speichern'"
    @submit="(value: typeof payload.data) => emit('submit', { mode: payload.mode, data: value })"
  >
    <TheDevOnlyNiceJson :value="value" />
    <div class="grid grid-cols-2 gap-x-2">
      <FormKitMultiSelect
        name="storageCustomerId"
        :is-multi-select-allowed="false"
        :multi-select-options="customerOptions"
        label="Kunde"
        validation="required"
      />
      <div />

      <FormKit
        name="name"
        type="text"
        label="Name"
        help="Name des Lagernutzers (Vor- und Nachname)"
        validation="required|length:3"
      />
      <FormKit
        name="email"
        type="text"
        label="E-Mail"
        help="Die E-Mail Adresse mit welcher der Lagernutzer sich im System anmelden wird."
        validation="required|email"
        :disabled="payload.mode === 'update'"
      />
    </div>
  </FormKit>
</template>
