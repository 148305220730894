<script setup lang="ts">
const { openLogisticsStorageLocationPopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const updateMachinery = useMutation({
  mutationFn: $trpc.machinery.updateStorageLocation.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Maschine konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['machines'] })
    closePopup()
    notification.success({ title: 'Die Maschine wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const updateMachineryAccessory = useMutation({
  mutationFn: $trpc.machineryAccessory.updateStorageLocation.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Das Anbaugerät konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['accessories'] })
    closePopup()
    notification.success({ title: 'Das Anbaugerät wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const updateItemSet = useMutation({
  mutationFn: $trpc.itemSet.updateStorageLocation.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Das Set konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['itemSet'] })
    closePopup()
    notification.success({ title: 'Das Set wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

function update({ storageLocation }: { storageLocation: string }) {
  if (!popupData.value) {
    return
  }

  if (popupData.value.model === 'machinery') {
    return updateMachinery.mutate({ storageLocation, id: popupData.value.id })
  }

  if (popupData.value.model === 'machineryAccessory') {
    return updateMachineryAccessory.mutate({ storageLocation, id: popupData.value.id })
  }

  if (popupData.value.model === 'itemSet') {
    return updateItemSet.mutate({ storageLocation, id: popupData.value.id })
  }
}
</script>

<template>
  <ThePopup v-if="popupData" :show="Boolean(popupData)" :title="`Einlagerung ${popupData.id}`" @close="closePopup">
    <FormKit
      type="form"
      :value="popupData"
      submit-label="Speichern"
      @submit="update"
    >
      <FormKit
        name="storageLocation"
        type="text"
        validation="required"
        label="Lagerplatz"
        placeholder="Crailsheim"
      />
    </FormKit>
  </ThePopup>
</template>
