<script setup lang="ts">
import { createInput } from '@formkit/vue'
import InternalDynamicList from './InternalDynamicList.vue'

const internalDynamicList = createInput(InternalDynamicList)
</script>

<template>
  <FormKit :type="internalDynamicList" class="!pr-0" />
</template>
