import type { FilePath, UploadedFile } from '~/types'

export type Size = 'Normal' | 'Small'

/**
 * Helper to abstract away migration from the old file-storage model to the new one.
 *
 * Added in https://github.com/sidestream-tech/hanselmann-os/pull/2359
 *
 * TODO: Remove this and other #2359-changes once we fully switched over to the new, more secure file approach.
 *
 * @param filePathLike The `string` or `FilePath: { path: string }` that points to the location of the file
 * @returns string Pointer to the location of the file
 */
export function getPathFromFile(filePathLike: string | FilePath) {
  return typeof filePathLike === 'string' ? filePathLike : filePathLike.path
}

export function isImageFilePath(filePath: string, extensionsToCheck = ['.jpg', '.jpeg', '.png', '.webp']) {
  const extension = getFileExtension(filePath)

  return extensionsToCheck.includes(extension)
}

export function getUploadedFile(file: string | FilePath): UploadedFile {
  const filekey = getPathFromFile(file)

  return {
    filekey,
    status: 'success',
    hasPreview: isImageFilePath(filekey),
  }
}

function getExternalVideoProviderAndId(src: string) {
  // https://github.com/vidstack/player/blob/e0b6f303fc133ef76e794815d1712d7b7a7ee61f/packages/vidstack/src/providers/youtube/utils.ts#L1
  const youtubeRegex = /(?:youtu\.be|youtube|youtube\.com|youtube-nocookie\.com)\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=)?((?:\w|-){11})/
  const youtubeId = src.match(youtubeRegex)?.[1]
  if (youtubeId) {
    return ['youtube', youtubeId] as const
  }

  // https://github.com/vidstack/player/blob/e0b6f303fc133ef76e794815d1712d7b7a7ee61f/packages/vidstack/src/providers/vimeo/utils.ts#L3
  const vimeoRegex = /(?:https:\/\/)?(?:player\.)?vimeo(?:\.com)?\/(?:video\/)?(\d+)(?:\?hash=(.*))?/
  const vimeoId = src.match(vimeoRegex)?.[1]
  if (vimeoId) {
    return ['vimeo', vimeoId] as const
  }

  return [undefined, undefined] as const
}

export function getVideoProvider(src: string | null) {
  if (!src) {
    return undefined
  }
  const [provider, _] = getExternalVideoProviderAndId(src)
  return provider
}

export function normalizeExternalVideoURL(src: string) {
  const [provider, id] = getExternalVideoProviderAndId(src)
  if (!provider) {
    return undefined
  }

  const prefix = provider === 'youtube' ? 'https://www.youtube.com/watch?v=' : 'https://vimeo.com/'
  return `${prefix}${id}`
}
