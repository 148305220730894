import { z } from 'zod'

const numberSchema = z.coerce.number()

export default (val: unknown) => {
  const result = numberSchema.safeParse(val)
  if (!result.success) {
    return undefined
  }

  return result.data
}
