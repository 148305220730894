<script setup lang="ts">
import type { UpdateMachineryOperationHours } from '~/types'

const props = withDefaults(defineProps<{ payload: UpdateMachineryOperationHours, isLoadingReceptionMachine?: boolean, isDetailsPage?: boolean }>(), { isDetailsPage: false })
const emit = defineEmits<{ (e: 'save', payload: UpdateMachineryOperationHours): void }>()

const { cloned } = useCloned(props.payload)

const operatingHours = ref(cloned.value.operatingHours)

watch(operatingHours, (h) => {
  cloned.value.operatingHours = h
})

const formkitPlugins = useFormkitPlugins()
</script>

<template>
  <FormKit v-model="cloned" type="form" :plugins="[formkitPlugins]" submit-label="Betriebsstunden speichern" @submit="emit('save', cloned)">
    <div class="flex flex-col items-start">
      <FormKit
        v-model="operatingHours"
        step="0.1"
        label="Bisherige Betriebsstunden"
        :default="1"
        type="number"
        number
        validation="required|min:0"
      />
    </div>
  </FormKit>
</template>
