<script setup lang="ts">
defineProps<{ show: boolean }>()
const emits = defineEmits<{ (e: 'close'): void }>()
const modelValue = defineModel()

function decodeQrCode(decoded: string) {
  modelValue.value = decoded.split('/').at(-1) ?? decoded

  closePopup()
}

function closePopup() {
  emits('close')
}
</script>

<template>
  <ThePopup
    v-if="show"
    :show="show"
    title="Scanne Geräte"
    @close="closePopup"
  >
    <TheQRCodeScanner @decode-qr-code="decodeQrCode" />
  </ThePopup>
</template>
