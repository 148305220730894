<script setup lang="ts">
import type { DataTableRowKey } from 'naive-ui'

const props = withDefaults(defineProps<{ compatibleMachineryTypeIds: string[], alreadyCheckedRowKeys: DataTableRowKey[] }>(), {
  alreadyCheckedRowKeys: () => [],
})
const emit = defineEmits<{ (e: 'connect', machineryId: string | null): void }>()

const { machinerySingleSelect: { columns, fulltextSearch } } = useTableColumnConfigs()

const { fulltextSearchValue, where } = useFilterData(fulltextSearch)

const { machinery: queryMachinery } = useQuery()

const machineryWhere = computed(() => ({
  ...where.value,
  typeId: {
    in: props.compatibleMachineryTypeIds,
  },
  status: 'approval',
}))
const { data: machinery, error: errorMachinery, isLoading: isLoadingMachinery } = queryMachinery.all(machineryWhere)

const checkedRowKeys = ref<DataTableRowKey[]>(props.alreadyCheckedRowKeys)

const selectedMachineryId = computed(() => checkedRowKeys.value.length > 0 ? checkedRowKeys.value[0] as string : null)
</script>

<template>
  <TheDataCard title="Verbundene Geräte" :error="errorMachinery">
    <TableFilters v-model="fulltextSearchValue" :placeholder="fulltextSearch.placeholder">
      <TableView
        v-if="machinery"
        :data="machinery"
        :columns="columns"
        :is-loading="isLoadingMachinery"
        :row-key="row => row.id"
        :checked-row-keys="checkedRowKeys"
        @update:checked-row-keys="keys => checkedRowKeys = keys"
      />
    </TableFilters>
    <div class="flex w-full gap-4 mt-4">
      <div class="w-1/2">
        <n-button type="primary" style="width: 100%" :disabled="checkedRowKeys.length === 0" @click="emit('connect', selectedMachineryId)">
          <template #icon>
            <Icon name="material-symbols:add-link" />
          </template>
          Anbaugerät mit ausgewählem Gerät verbinden
        </n-button>
      </div>
      <div class="w-1/2">
        <n-button type="primary" style="width: 100%" :disabled="alreadyCheckedRowKeys.length === 0" @click="emit('connect', null)">
          <template #icon>
            <Icon name="material-symbols:link-off-rounded" />
          </template>
          Verbindung mit Gerät aufheben
        </n-button>
      </div>
    </div>
  </TheDataCard>
</template>
