<script setup lang="ts">
import type { SelectOption } from 'naive-ui'
import accessoryFilterConditions from './accessoryFilterConditions'
import type { AccessoryFilterCondition, NumberTypeFields, StringTypeFields } from './accessoryFilterConditions'
import { accessoryFilterConditionsFieldsToGermanWithPrefix } from '~/translations'
import type { MachineryAccessoryCategory, MachineryAccessoryCategoryFilter } from '~/types'
import type { ShoppingTab } from '~/components/Calendar/Page.vue'

const props = defineProps<{
  selectedTab: ShoppingTab
  selectedMachineryAccessoryCategory: null | MachineryAccessoryCategory
  filtersRenderingKey: string
  accessoryFilterOptions: Partial<Record<keyof MachineryAccessoryCategoryFilter, SelectOption[]>>
}>()
const accessoryFilter = defineModel<MachineryAccessoryCategoryFilter>({ default: () => {} })

function getOptionsFromDBData(field: keyof MachineryAccessoryCategoryFilter) {
  return props.accessoryFilterOptions[field] ?? []
}

const supportedFilters = computed((): Partial<Record<keyof MachineryAccessoryCategoryFilter, AccessoryFilterCondition>> => {
  if (!props.selectedMachineryAccessoryCategory) {
    return {}
  }
  const currentFilters = accessoryFilterConditions[props.selectedMachineryAccessoryCategory]
  if (!currentFilters) {
    return {}
  }
  return Object.fromEntries(Object.entries(currentFilters).filter(([_, value]) => {
    if (!value.upsupportedTab) {
      return true
    }
    return !value.upsupportedTab.includes(props.selectedTab)
  }))
})
</script>

<template>
  <div
    v-if="selectedMachineryAccessoryCategory && accessoryFilterConditions[selectedMachineryAccessoryCategory]"
    :key="filtersRenderingKey"
    class="flex flex-wrap gap-2 mb-4"
  >
    <div v-for="(filterCondition, key) in (supportedFilters)" :key="key" class="w-[calc(50%-0.5rem)]">
      <p class="font-bold">
        {{ `${accessoryFilterConditionsFieldsToGermanWithPrefix(selectedMachineryAccessoryCategory, key)}` }}
      </p>
      <n-select v-if="filterCondition?.type === 'select'" v-model:value="accessoryFilter[key]" class="w-full" :options="filterCondition?.optionsFromDBDataConfigs ? getOptionsFromDBData(key) : filterCondition?.options" clearable />
      <n-input
        v-else-if="filterCondition?.type === 'text'"
        :value="accessoryFilter[key as StringTypeFields]"
        clearable
        @update:value="val => accessoryFilter[key as StringTypeFields] = val ? val : undefined"
      />
      <n-input-number v-else v-model:value="accessoryFilter[key as NumberTypeFields]" clearable />
    </div>
  </div>
</template>
