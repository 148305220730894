<script setup lang="ts">
import { startOfDay } from 'date-fns'
import type { ApiCalendarMachinery, ApiCalendarMachineryAccessory, ApiCustomerGetAll } from '~/types'

const props = defineProps<{
  position: ApiCalendarMachinery | ApiCalendarMachineryAccessory
  dateOfColumn: Date
  locationHistoryIndex: number
  customersById: Record<string, ApiCustomerGetAll>
}>()

const isLocationAvailable = computed(() => props.position.locationHistory && props.position.locationHistory[props.locationHistoryIndex])

const columns: OneDataColumn<ApiCalendarMachinery | ApiCalendarMachineryAccessory>[] = [
  {
    title: 'Kundenname',
    key: 'relatedOfferPositions',
    render: ({ relatedOfferPositions }) => {
      const customerId = relatedOfferPositions.find(p => [p.machineryId, p.machineryAccessoryId].includes(props.position.id))?.relatedOffer.customerId
      if (!customerId) {
        return 'N/A'
      }
      return props.customersById[customerId].name ?? 'N/A'
    },
    isVisible: ({ locationHistory }) => !!locationHistory && locationHistory[props.locationHistoryIndex]?.includes('Kunde'),
  },
  {
    title: 'Lieferort',
    key: 'relatedOfferPositions',
    render: ({ relatedOfferPositions }) => relatedOfferPositions.find(p => [p.machineryId, p.machineryAccessoryId].includes(props.position.id))?.relatedOffer.deliveryLocation,
    isVisible: ({ locationHistory }) => !!locationHistory && locationHistory[props.locationHistoryIndex]?.includes('Kunde'),
  },
  {
    title: 'Fahrende',
    key: 'relatedOfferPositions',
    render: ({ relatedOfferPositions }) => {
      const relatedDeliveryVehicles = relatedOfferPositions.find(p => [p.machineryId, p.machineryAccessoryId].includes(props.position.id))?.relatedOffer.logisticsTasks
        .filter(({ assignedDeliveryVehicle, createdAt }) => assignedDeliveryVehicle && startOfDay(createdAt) <= props.dateOfColumn)
        .map(({ assignedDeliveryVehicle }) => assignedDeliveryVehicle)

      return relatedDeliveryVehicles?.length && relatedDeliveryVehicles[relatedDeliveryVehicles.length - 1]?.driver?.name
    },
    isVisible: ({ locationHistory }) => !!locationHistory && locationHistory[props.locationHistoryIndex]?.includes('Fahrzeug'),
  },
  {
    title: 'Kfz-Kennzeichen',
    key: 'relatedOfferPositions',
    render: ({ relatedOfferPositions }) => {
      const relatedDeliveryVehicles = relatedOfferPositions.find(p => [p.machineryId, p.machineryAccessoryId].includes(props.position.id))?.relatedOffer.logisticsTasks
        .filter(({ assignedDeliveryVehicle, createdAt }) => assignedDeliveryVehicle && startOfDay(createdAt) <= props.dateOfColumn)
        .map(({ assignedDeliveryVehicle }) => assignedDeliveryVehicle)

      return relatedDeliveryVehicles?.length && relatedDeliveryVehicles[relatedDeliveryVehicles.length - 1]?.licensePlateNumber
    },
    isVisible: ({ locationHistory }) => !!locationHistory && locationHistory[props.locationHistoryIndex]?.includes('Fahrzeug'),
  },
  {
    title: 'Lagerplatz',
    key: 'relatedOfferPositions',
    render: ({ storageLocation }) => storageLocation,
    isVisible: ({ locationHistory }) => !!locationHistory && locationHistory[props.locationHistoryIndex]?.includes('Lager'),
  },
]
</script>

<template>
  <n-popover v-if="isLocationAvailable" trigger="hover">
    <template #trigger>
      <slot />
    </template>
    <TableOneDataColumn :config="{ columns, data: position }" />
  </n-popover>
</template>
