<script setup lang="ts">
import { createInput } from '@formkit/vue'
import DateTimeInput from './CreateInputBase/DateTime.vue'

const dateTimeInput = createInput(DateTimeInput)
</script>

<template>
  <FormKit :type="dateTimeInput" />
</template>
