import currency from 'currency.js'
import type { PositionToCalculatePrice } from '~/server/pdf-generation/helpers'
import { calulateTotalPositionPrice } from '~/server/pdf-generation/helpers'

type InvoiceToOfferPositionPositionToCalculatePrice = PositionToCalculatePrice & {
  discountRateForDay?: number | null
}

/**
 * TODO: Figure out and document specific semantic / purpose of this function in comparison to the already existing and used `calulateTotalPositionPrice`.
 */
export default (position: InvoiceToOfferPositionPositionToCalculatePrice) => {
  if (position.quantity === 1 && position.discountRateForDay !== null) {
    const pricePerUnit = currency(position.pricePerUnit ?? 0)
    const discountRate = 1 - (position.discountRate ?? 0)
    const discountedPricePerUnit = pricePerUnit.multiply(discountRate)
    const discountRateForDay = 1 - (position.discountRateForDay ?? 0)

    return discountedPricePerUnit.multiply(discountRateForDay)
  }

  return calulateTotalPositionPrice(position)
}
