<script setup lang="ts">
import { createInput } from '@formkit/vue'
import Autocomplete from './CreateInputBase/Autocomplete.vue'

const autocomplete = createInput(Autocomplete, { props: [
  'class',
  'update',
  'options',
  'placeholder',
  'isOptionCreationAllowed',
  'isClearable',
] })
</script>

<template>
  <FormKit :type="autocomplete" />
</template>
