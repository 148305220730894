<script setup lang="ts">
import { computeOfferTimeline } from '~/components/Logistics/computeStatus'

const props = defineProps<{ offerId: string }>()

const { offer: queryOffer } = useQuery()
const { data: offer } = queryOffer.byIdForStatusTimeline(props.offerId)

const timelineItems = computed(() => {
  if (!offer.value || offer.value.status === 'offer') {
    return undefined
  }

  return Object.values(computeOfferTimeline(offer.value))
})
</script>

<template>
  <TheStatusTimeline :timeline-items="timelineItems" />
</template>
