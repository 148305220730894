<script setup lang="ts">
import { machineryRubrics } from '~/server/schemas'
import type { ApiPartnerSalesGetMachineryReleasedForPartnerConditions, MachineryRubrics, PartnerSalesGetMachineryReleasedForPartnerConditionsInput } from '~/types'

const props = defineProps<{ machineryId?: string }>()

const { openInvitePartnerSales, openReleaseMachineryForPartnerSalesPopup } = useGlobalOpeners()

// Filters
const selectedMachineryRubric: Ref<MachineryRubrics | null> = ref(null)
const selectedMachineryTypeId: Ref<string | null> = ref(null)
const producerCompanyName: Ref<string | null> = ref(null)
const selectedPartnerUserId: Ref<string | null> = ref(null)
const showSpecialOffers: Ref<boolean> = ref(false)

function resetFilters() {
  producerCompanyName.value = null
  selectedMachineryRubric.value = null
  selectedMachineryTypeId.value = null
  selectedPartnerUserId.value = null
  showSpecialOffers.value = false
}

// Queries
const whereMachineryReleasedForPartnerConditions = computed((): PartnerSalesGetMachineryReleasedForPartnerConditionsInput => ({
  machineryId: props.machineryId,
  machinery: {
    machineryRubric: selectedMachineryRubric.value || undefined,
    typeId: selectedMachineryTypeId.value || undefined,
    producerCompanyName: producerCompanyName.value ? { contains: producerCompanyName.value, ...postgreSQLCaseInsensitiveSearch() } : undefined,
  },
  partnerUserId: selectedPartnerUserId.value || undefined,
  isSpecialOffer: showSpecialOffers.value || undefined,
  ...where.value,
}))

const { partnerSales, machineryType: queryMachineryType } = useQuery()
const { machineryReleasedForPartnerConditions: { columns, fulltextSearch, rowClassName } } = useTableColumnConfigs()
const { fulltextSearchValue, where } = useFilterData(fulltextSearch)
const { data: releasedMachineryConditions, isLoading } = partnerSales.getMachineryReleasedForPartnerConditions(whereMachineryReleasedForPartnerConditions)
const { data: partners } = partnerSales.getPartners()
const { data: machineryTypes } = queryMachineryType.all()

// Mutations
const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const unReleaseMachineriesForPartners = useMutation({
  mutationFn: $trpc.partnerSales.unReleaseMachineriesForPartners.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Freigabe für Maschine konnte nicht aufgehoben werden' }),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: [`partnerSales`] })
    notification.success({ title: `Freigabe wurde erfolgreich gelöscht`, duration: 4500 })
  },
})

const setIsBlocked = useMutation({
  mutationFn: $trpc.partnerSales.setIsBlockedMachineryForPartner.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Änderung konnte nicht vorgenommen werden' }),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: ['partnerSales'] })
    notification.success({ title: `Die ${data.isBlocked ? 'Sperre' : 'Freigabe'} wurde erfolgreich vorgenommen`, duration: 4500 })
  },
})

// Filter options
const partnerOptions = computed(() => partners.value?.map(({ id, name, email }) => ({ label: name ?? email, value: id })) ?? [])
const rubricOptions = useTranslatedSelectOptions('machinery.rubric', machineryRubrics.options)
const machineryTypeOptions = computed(() => machineryTypes.value?.map(type => ({
  label: type.name,
  value: type.id,
})))

// Table props
function actionButtons(row: ApiPartnerSalesGetMachineryReleasedForPartnerConditions): Action[] {
  const buttons: Action[] = []

  if (row.isBlocked) {
    buttons.push('unblock')
  } else {
    buttons.push('block')
  }

  buttons.push('update', 'delete')

  return buttons
}
</script>

<template>
  <TheDataCard>
    <template #header>
      <div class="flex flex-col md:flex-row gap-2 md:mb-5 text-lg font-semibold">
        <h2>Partner-Verkaufs Übersicht</h2>
        <div class="grow" />
        <n-button v-if="machineryId" @click="openReleaseMachineryForPartnerSalesPopup.open({ mode: 'create', machineryIds: [machineryId] })">
          Freigaben Verwalten
        </n-button>
        <n-button @click="openInvitePartnerSales.open(true)">
          Partner Einladen
        </n-button>
      </div>
    </template>

    <TableFilters v-model="fulltextSearchValue" :placeholder="fulltextSearch.placeholder">
      <template #search>
        <n-collapse>
          <n-collapse-item>
            <template #header>
              <span class="text-lg">
                Partner-Verkaufs Filter ausklappen
              </span>
            </template>
            <div class="grid grid-cols-1 md:grid-cols-3 grid-rows-1 gap-2 grid-flow-auto bg-gray-200 p-3 rounded-sm">
              <div class="flex flex-col">
                <p class="font-semibold">
                  Hersteller
                </p>
                <n-input
                  v-model:value="producerCompanyName"
                  :consistent-menu-width="false"
                  filterable
                  clearable
                />
              </div>
              <div>
                <p class="font-semibold">
                  Gerätetyp
                </p>
                <n-select
                  v-model:value="selectedMachineryTypeId"
                  :options="machineryTypeOptions"
                  :consistent-menu-width="false"
                  filterable
                  clearable
                />
              </div>
              <div>
                <p class="font-semibold">
                  Rubrik
                </p>
                <n-select
                  v-model:value="selectedMachineryRubric"
                  :options="rubricOptions"
                  :consistent-menu-width="false"
                  filterable
                  clearable
                />
              </div>
              <div>
                <p class="font-semibold">
                  Partner-Verkäufer-Name
                </p>
                <n-select
                  v-model:value="selectedPartnerUserId"
                  :options="partnerOptions"
                  :consistent-menu-width="false"
                  filterable
                  clearable
                />
              </div>
              <n-checkbox v-model:checked="showSpecialOffers" class="flex items-center">
                Nur Sonderangebote anzeigen
              </n-checkbox>
              <div class="flex items-end">
                <n-button class="!bg-white w-full md:w-auto" @click="resetFilters">
                  Alle zurücksetzen
                </n-button>
              </div>
            </div>
          </n-collapse-item>
        </n-collapse>
      </template>
      <TableView
        v-bind="{ rowClassName }"
        :data="releasedMachineryConditions"
        :columns="columns"
        :is-loading="isLoading"
        :action-buttons="actionButtons"
        @update="entity => openReleaseMachineryForPartnerSalesPopup.open({ mode: 'update', id: entity.id, machineryIds: [entity.machineryId] })"
        @delete="({ id }) => unReleaseMachineriesForPartners.mutate({ machineryReleasedForPartnerRelationId: id })"
        @block="({ id }) => setIsBlocked.mutate({ id, isBlocked: true })"
        @unblock="({ id }) => setIsBlocked.mutate({ id, isBlocked: false })"
      />
    </TableFilters>
  </TheDataCard>
</template>

<style scoped>
:deep(.SpecialOffer td) {
  @apply bg-orange-100 !important;
}
</style>
