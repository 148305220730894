<script setup lang="ts">
const { openInvitePartnerSales: { close, data } } = useGlobalOpeners()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const { partnerSales } = useQuery()
const { data: partners, isLoading } = partnerSales.getPartners()

const formkitEmailIsNotPartnerEmailRule = computed(() => {
  const availablePartnerOptions = partners.value
  if (!availablePartnerOptions || availablePartnerOptions.length === 0) {
    return ''
  }
  return `|not:${availablePartnerOptions.map(({ email }) => email).join(',')}`
})

const invitePartner = useMutation({
  mutationFn: $trpc.partnerSales.invitePartner.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Partner-Verkäufer konnte nicht eingeladen werden.' }),
  onSuccess: (partner) => {
    queryClient.invalidateQueries({ queryKey: ['partnerSales'] })
    notification.success({ title: `${partner.name} wurde erfolgreich eingeladen. Er kann sich nun anmelden`, duration: 4500 })
    close()
  },
})
</script>

<template>
  <ThePopup
    v-if="data"
    :show="data"
    title="Partner-Verkäufer Einladen"
    @close="close"
  >
    <TheDataCard :is-loading="isLoading">
      <FormKit
        v-slot="{ value }"
        type="form"
        submit-label="Partner-Verkäufer einladen"
        @submit="invitePartner.mutate"
      >
        <TheDevOnlyNiceJson :value="value" />
        <FormKit
          name="userEmail"
          type="text"
          label="E-Mail"
          help="Die E-Mail Adresse mit welcher der Partner-Verkäufer sich im System anmelden wird."
          :validation="`required|email${formkitEmailIsNotPartnerEmailRule}`"
        />
        <FormKit
          name="userName"
          type="text"
          label="Name"
          help="Name des Partner-Verkäufers (Vor- und Nachname)"
          validation="required|length:3"
        />
      </FormKit>
    </TheDataCard>
  </ThePopup>
</template>
