<script setup lang="ts">
import type { UpdateOrCreateStorageLoadCarrier } from '~/types'

const { openStorageLoadCarrierCreateOrUpdatePopup: { data: popupData, close: closePopup_ } } = useGlobalOpeners()

const { storageLoadCarrier: queryStorageLoadCarrier, storageCustomer } = useQuery()

const storageLoadCarrierId = computed(() => popupData.value?.mode === 'update'
  ? popupData.value.id
  : undefined)
const { data: storageLoadCarrier, isFetching } = queryStorageLoadCarrier.byId(storageLoadCarrierId)

const { data: customers } = storageCustomer.allExternalStorageCustomers()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const routerNameToSaveCreatedId = useRouteQueryAsObject<{ loadCarrierId: string }>('loadCarrierSelectInput')

function closePopup(createdId?: string) {
  if (createdId) {
    routerNameToSaveCreatedId.value = {
      loadCarrierId: createdId,
    }
  }
  closePopup_()
}

const update = useMutation({
  mutationFn: $trpc.externalStorage.storageLoadCarrier.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Ladungsträger kann nicht durchgeführt werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['storageLoadCarrier'] })
    closePopup()
    notification.success({ title: 'Der Ladungsträger wurde erfolgreich durchgeführt', duration: 4500 })
  },
})

const create = useMutation({
  mutationFn: $trpc.externalStorage.storageLoadCarrier.create.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Ladungsträger konnte nicht erstellt werden' }),
  onSuccess: async (loadContainer) => {
    await queryClient.invalidateQueries({ queryKey: ['storageLoadCarrier'] })
    if (popupData.value?.mode === 'create') {
      closePopup(loadContainer.id)
    } else {
      closePopup()
    }
    notification.success({ title: 'Der Ladungsträger wurde erfolgreich erstellt', duration: 4500 })
  },
})

const payload = ref<UpdateOrCreateStorageLoadCarrier | undefined>()
watch(storageLoadCarrier, (storageLoadCarrier) => {
  if (!popupData.value || payload.value) {
    return
  }

  if (popupData.value.mode === 'create') {
    payload.value = {
      mode: 'create',
      data: {
        name: '',
        customerId: '',
        widthInMm: 0,
        heightInMm: 0,
        lengthInMm: 0,
        loadingMeter: 0,
        maximumLiftingWeight: 0,
        weight: 0,
        photos: [],
        documents: [],
      },
    }
  }

  if (!storageLoadCarrier || isFetching.value) {
    return
  }

  payload.value = {
    mode: 'update',
    data: storageLoadCarrier,
  }
}, { immediate: true })

function submit(payload: UpdateOrCreateStorageLoadCarrier) {
  return payload.mode === 'create' ? create.mutate(payload.data) : update.mutate(payload.data)
}

const selectedCustomerId = computed(() => popupData.value?.mode === 'create' && popupData.value?.customerId ? popupData.value.customerId : undefined)
</script>

<template>
  <ThePopup v-if="payload && popupData" :show="true" :title="popupData.mode === 'create' ? 'Neuen Ladungsträger anlegen' : 'Ladungsträger bearbeiten'" width="1100px" @close="closePopup">
    <ExternalStorageAdministrationStorageLoadCarrierCreateOrEditForm :payload="payload" :customers="customers ?? []" :selected-customer-id @submit="submit" />
  </ThePopup>
</template>
