<script setup lang="ts">
import { createLogisticsTask, updateLogisticsTask } from '~/server/schemas'
import type { CreateLogisticsTask, ShoppingCartPosition } from '~/types'

const { openLogisticsTaskCreateOrEditPopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const { offer: queryOffer, logisticsTask: queryLogisticsTask } = useQuery()

const offerId = computed(() => popupData.value?.offerId)

const id = computed(() => {
  if (!popupData.value) {
    return
  }

  if (popupData.value.mode === 'update') {
    return popupData.value.id
  }
})

const { data: offer } = queryOffer.byId(offerId)
const { data: logisticsTask, isFetching } = queryLogisticsTask.byIdOrThrow(id)

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const update = useMutation({
  mutationFn: $trpc.logisticsTask.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Lieferung konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
    ])
    closePopup()
    notification.success({ title: 'Die Lieferung wurde erfolgreich aktualisiert', duration: 4500 })
  },
})
const create = useMutation({
  mutationFn: $trpc.logisticsTask.create.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Lieferung konnte nicht erstellt werden' }),
  onSuccess: async (logisticsTask) => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
    ])
    closePopup()
    notification.success({ title: `Die Lieferung wurde erfolgreich erstellt: ${logisticsTask.id}`, duration: 4500 })
  },
})

function save(data: Omit<CreateLogisticsTask, 'type' | 'status' | 'offerId'>) {
  if (popupData.value?.mode === 'create') {
    const payload = createLogisticsTask.parse({
      ...data,
      type: popupData.value.type,
      status: 'created',
      offerId: popupData.value.offerId,
    })
    create.mutate(payload)
  } else {
    if (!logisticsTask.value) {
      throw new Error('Logistics task not found')
    }

    const payload = updateLogisticsTask.parse({
      ...data,
      id: logisticsTask.value.id,
      type: logisticsTask.value.type,
      status: logisticsTask.value.status,
    })

    update.mutate(payload)
  }
}

watch(() => offer.value?.type, (offerType) => {
  if (!offerType) {
    return
  }

  // Creating separate logistics tasks is limited to special offers and service projects
  if (!['special', 'service-project'].includes(offerType)) {
    closePopup()
  }
})

const isReadOnly = computed(() => {
  const fullPaymentInvoiced = offer.value?.invoices.some(({ type, status }) => type === 'fullpayment' && status !== 'cancelled')
  const isCompleted = offer.value?.status === 'completed'
  const isCancelled = offer.value?.isCancelled

  return Boolean(fullPaymentInvoiced || isCompleted || isCancelled)
})

const positionsOptions = computed(() => {
  if (!offer.value) {
    return []
  }

  return offer.value.positions
    .filter(doesOfferPositionNeedDelivery)
    .filter(position => position.machineryAccessoryCategory !== 'trailer' && position.machineryAccessory?.category !== 'trailer')
    .map(position => ({
      label: position.title,
      value: position.id,
      disabled: position.shippedViaLogisticsTasks.length > 0 && !position.shippedViaLogisticsTasks.some(task => task.id === id.value),
    }))
})

const transformedOutboundTask = computed(() => {
  if (logisticsTask.value?.type === 'outbound') {
    const { positionsToShip, ...rest } = logisticsTask.value

    return {
      ...rest,
      positionsToShip: positionsToShip.map(p => p.id),
    }
  }
})

const existingTrailerPosition = computed((): ShoppingCartPosition | undefined => {
  const position = logisticsTask.value?.positionsToShip.find(position => position.machineryAccessory?.category === 'trailer' || position.machineryAccessoryCategory === 'trailer')
  if (position?.machineryAccessoryId) {
    return { type: 'machineryAccessory', machineryAccessoryId: position.machineryAccessoryId }
  } else if (position?.machineryAccessoryCategory) {
    return { type: 'machineryAccessoryCategory', machineryAccessoryCategory: 'trailer', filters: {} }
  }

  return undefined
})
</script>

<template>
  <ThePopup v-if="popupData && offer && !isFetching" :show="Boolean(offer)" title="Lieferung-Anlage" @close="closePopup">
    <LogisticsCreateOrEditAToBTaskForm
      v-if="popupData.type === 'a-to-b'"
      :logistics-task="logisticsTask"
      :is-read-only="isReadOnly"
      :inquiried-date="offer.requestedAt"
      @save="save"
    />
    <LogisticsCreateOrEditOutboundForm
      v-else-if="popupData.type === 'outbound'"
      :logistics-task="transformedOutboundTask"
      :is-read-only="isReadOnly"
      :offer="offer"
      :positions-options="positionsOptions"
      :existing-trailer-position="existingTrailerPosition"
      @save="save"
    />
  </ThePopup>
</template>
