<script setup lang="ts">
import type { UpdateOrCreateItemSet } from '~/types'

const { openSetCreateOrUpdateFromIssuancePopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const { itemSet: queryItemSet } = useQuery()

const itemSetId = computed(() => {
  if (popupData.value?.mode === 'update') {
    return popupData.value.id
  }
  return undefined
})
const { data: itemSet } = queryItemSet.byIdWithOngoingOfferPositions(itemSetId)

const { openLogisticsIssuancePopup } = useGlobalOpeners()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()
// TODO: update from issuance should update the created offerPosition
const update = useMutation({
  mutationFn: $trpc.itemSet.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Aktualisierung kann nicht durchgeführt werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['itemSet'] })
    closePopup()
    notification.success({ title: 'Die Aktualisierung wurde erfolgreich durchgeführt', duration: 4500 })
  },
})

const create = useMutation({
  mutationFn: $trpc.itemSet.createSetFromIssuance.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Das Set konnte nicht erstellt werden' }),
  onSuccess: async (offerPosition) => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['itemSet'] }),
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offerPosition'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
    ])

    openLogisticsIssuancePopup.open({ id: offerPosition.id, taskType: 'outbound', positionType: 'itemSet', machineryId: offerPosition.itemSetId ?? '' })
    closePopup()

    notification.success({ title: 'Das Set wurde erfolgreich erstellt', duration: 4500 })
  },
})

const payload = computed((): UpdateOrCreateItemSet | undefined => {
  if (!popupData.value) {
    return undefined
  }

  if (popupData.value.mode === 'create') {
    return {
      mode: 'create',
      data: {
        title: `Set_${popupData.value.offerId}_${popupData.value.setIndex}`,
        priceInEuro: 0,
        description: '',
        machineryAccessoryIds: [],
        photos: [],
        documents: [],
      },
    }
  }

  if (!itemSet.value) {
    return undefined
  }

  return {
    mode: 'update',
    data: {
      ...itemSet.value,
      machineryAccessoryIds: itemSet.value.machineryAccessories.map(({ id }) => id),
    },
  }
})

function submit(payload: UpdateOrCreateItemSet) {
  if (!popupData.value) {
    defaultLogger.error({
      msg: 'No popup data provided in components/Set/CreateOrEditFromIssuanceContainer.vue',
    })
    return
  }

  if (payload.mode === 'create') {
    create.mutate({
      ...payload.data,
      logisticsTaskId: popupData.value.logisticsTaskId,
    })
  } else {
    update.mutate(payload.data)
  }
}
</script>

<template>
  <ThePopup v-if="payload && popupData" :show="Boolean(payload)" :title="`${popupData.mode === 'create' ? 'Set-Anlage' : `Set ${popupData.id}`}`" width="1100px" @close="closePopup">
    <SetCreateOrEditForm
      :payload="payload"
      :is-currently-in-offer="false"
      :ongoing-offer-positions="itemSet?.relatedOfferPositions"
      :item-set-storage-location="itemSet?.storageLocation ?? undefined"
      :is-issuance-mode="true"
      @submit="submit"
    />
  </ThePopup>
</template>
