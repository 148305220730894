<script lang="ts" setup>
import type { UpdateOfferPositionNotReturnable } from '~/types'

const { openIssuanceUpdateNotReturnablePopup: { data: popupData, close: closePopup }, openLogisticsIssuancePopup } = useGlobalOpeners()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const id = computed(() => {
  if (!popupData.value) {
    return undefined
  }
  return popupData.value.id
})

const { offerPosition: offerPositionQuery } = useQuery()
const { data: offerPosition, isLoading: isLoadingOfferPosition } = offerPositionQuery.byId(id)

const payload = computed((): UpdateOfferPositionNotReturnable | undefined => {
  if (!offerPosition.value || isLoadingOfferPosition.value) {
    return undefined
  }

  return {
    id: offerPosition.value.id,
    isNotReturnable: offerPosition.value.isNotReturnable ?? false,
    notReturnableReason: offerPosition.value.notReturnableReason ?? '',
  }
})

const update = useMutation({
  mutationFn: $trpc.offerPosition.logisticsTour.updateNotReturnable.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Ausgebe-Status konnte nicht aktualisiert werden' }),
  onSuccess: async (position) => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['offerPosition'] }),
    ])

    if (!position.isNotReturnable && popupData.value?.shouldOpenReturnPopup) {
      let machineryId: string | null = null

      if (position.type === 'machinery' && position.machineryId) {
        machineryId = position.machineryId
      } else if (
        (position.type === 'machineryAccessory'
        || position.type === 'machineryAccessoryCategory')
        && position.machineryAccessoryId
      ) {
        machineryId = position.machineryAccessoryId
      } else if (position.type === 'itemSet' && position.itemSetId) {
        machineryId = position.itemSetId
      } else {
        notification.error({
          title: 'Unbekannter Typ/Kategorie',
          description: 'Der Typ oder die Kategorie des Lagertools konnte nicht bestimmt werden. Bitte kontaktieren Sie den Administrator.',
        })
        closePopup()
        return
      }

      if (position.type === 'machineryAccessoryCategory') {
        if (position.machineryAccessoryCategory) {
          openLogisticsIssuancePopup.open({
            id: position.id,
            taskType: 'inbound',
            positionType: position.type,
            accessoryCategory: position.machineryAccessoryCategory,
            machineryId,
          })
        }
      } else {
        openLogisticsIssuancePopup.open({
          id: position.id,
          taskType: 'inbound',
          positionType: position.type,
          machineryId,
        })
      }
    }

    closePopup()
    notification.success({
      title: 'Der Ausgebe-Status wurde erfolgreich aktualisiert',
      duration: 4500,
    })
  },
})
</script>

<template>
  <ThePopup
    v-if="payload && popupData && offerPosition"
    :show="Boolean(payload)"
    :title="`Aktualisieren den Rückgabestatus ${offerPosition.machineryId ?? offerPosition.machineryAccessoryId}`"
    width="900px"
    @close="closePopup"
  >
    <n-alert
      v-if="['machineryAccessory', 'machineryAccessoryCategory'].includes(offerPosition.type)"
      type="warning"
      class="mb-4"
    >
      Wenn Sie das Gerät als nicht rücknahmefähig kennzeichnen, werden alle permanenten Verknüpfungen aufgehoben.
    </n-alert>
    <LogisticsIssuanceUpdateNotReturnableForm :payload="payload" @save="update.mutate" />
  </ThePopup>
</template>
