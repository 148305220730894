import { joinURL } from 'ufo'

type DesiredURLSegments = 'onlyOrigin' | 'onlyOriginPath' | 'onlyOriginPathSearch' | 'onlyOriginPathSearchHash'
const urlGetters: Record<DesiredURLSegments, (url: URL) => string> = {
  onlyOrigin: url => url.origin,
  onlyOriginPath: url => joinURL(url.origin, url.pathname),
  onlyOriginPathSearch: url => joinURL(url.origin, url.pathname, url.search),
  onlyOriginPathSearchHash: url => joinURL(url.origin, url.pathname, url.search, url.hash),
}

/**
 * Get the full current URL.
 *
 * Return always contains origin (eg: `https://google.com:3000`). By default includes:
 * - pathname (eg.: `/a/b`),
 * - search (eg: `?a=b`),
 * - hash (eg: `#heading1`)
 *
 * These defaults can be turned off.
 */
export default (desiredURLSegments: DesiredURLSegments = 'onlyOriginPathSearchHash'): string => urlGetters[desiredURLSegments](useRequestURL())
