<script lang="ts" setup>
import currency from 'currency.js'
import { calculateTotals as calculateInvoiceTotals } from '~/server/pdf-generation/sections/invoice/total'

const {
  openInvoiceCommentPopup,
  openInvoicePartialPaymentPopup,
  openInvoiceConfirmPaymentPopup: { data: payload, close: closePopup },
} = useGlobalOpeners()

const { invoice: { columns } } = useTableColumnConfigs()

const { invoice: queryInvoice } = useQuery()
const { data: invoice } = queryInvoice.byId(computed(() => payload.value?.id))

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const markAsPaid = useMutation({
  mutationFn: $trpc.invoice.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Rechnungs-Status konnte nicht erfolgreich aktualisiert werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['invoice'] })
    closePopup()

    notification.success({ title: 'Die Rechnung wurde als bezahlt markiert', duration: 4500 })
  },
})

const totalPaidAmount = computed(() => {
  if (!invoice.value) {
    return 0
  }

  return invoice.value.payments.reduce((acc, payment) => {
    return acc.add(payment.amount)
  }, currency(0)).value
})

const leftAmountToPay = computed(() => {
  if (!invoice.value) {
    return 0
  }

  const totalAmountBrutto = calculateInvoiceTotals(invoice.value).brutto

  const amountToPay = totalAmountBrutto.subtract(totalPaidAmount.value)
  return amountToPay.value
})
</script>

<template>
  <ThePopup v-if="payload && invoice" width="1024px" :show="Boolean(payload && invoice)" :title="`Bestätigung der Bezahlung von ${invoice.type === 'creditNote' ? 'Rechnungskorrektur' : 'Rechnung'} ${invoice.invoiceId}`" @close="closePopup">
    <div v-if="!invoice.invoiceId">
      <p class="text-red-500">
        Das erzeugte Dokument ist keine offizielle Rechnung und kann deshalb nicht als abgerechnet markiert werden.
      </p>
    </div>
    <div v-else>
      <p class="mb-4">
        Bestätigen Sie die Zahlung der folgenden {{ invoice.type === 'creditNote' ? 'Rechnungskorrektur' : 'Rechnung' }}?
      </p>
      <TableView
        :is-loading="false"
        :data="[invoice]"
        :columns="columns"
        :is-paginated="false"
        :show-summary="false"
        class="mb-4"
      />
      <div class="flex gap-2">
        <n-button @click="openInvoiceCommentPopup.open({ id: invoice.id, invoiceId: invoice.invoiceId })">
          Zahlungs-Beleg hochladen
        </n-button>
        <n-popover>
          <template #trigger>
            <n-button type="primary" @click="markAsPaid.mutate({ id: invoice.id, status: 'paid', paidAmount: leftAmountToPay })">
              Vollständige Zahlung von {{ invoice.invoiceId }} durch Kunde&nbsp;<span class="truncate max-w-[65px]">{{ invoice.offer.customer.name }}</span>&nbsp;bestätigen
            </n-button>
          </template>
          Vollständige Zahlung von {{ invoice.invoiceId }} durch Kunde {{ invoice.offer.customer.name }} bestätigen
        </n-popover>
        <n-button @click="openInvoicePartialPaymentPopup.open({ id: invoice.id })">
          Teilweise Zahlung von {{ invoice.invoiceId }} bestätigen
        </n-button>
      </div>
    </div>
  </ThePopup>
</template>
