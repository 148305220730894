<script setup lang="ts">
import type { ApiCalendarMachineryAccessory, MachineryAccessoryStatus } from '~/types'
import { machineryStatusToGerman } from '~/translations'

defineProps<{ machineryAccessory: ApiCalendarMachineryAccessory }>()

const columns: OneDataColumn<ApiCalendarMachineryAccessory>[] = [
  {
    title: 'Status',
    key: 'status',
    render: ({ status, isDefective }) => isDefective ? 'Defekt' : machineryStatusToGerman[status as MachineryAccessoryStatus],
    isVisible: ({ isDefective }) => isDefective,
  },
  {
    title: 'Defektstartdatum',
    key: 'dateDefectStarted',
    render: ({ dateDefectStarted }) => dateDefectStarted ? useDateAsString(dateDefectStarted) : 'N/A',
    isVisible: ({ isDefective }) => isDefective,
  },
]
</script>

<template>
  <n-popover trigger="hover">
    <template #trigger>
      <slot />
    </template>
    <TableOneDataColumn :config="{ columns, data: machineryAccessory }" />
    <span v-if="machineryAccessory.isDefective" class="text-blue-400 pt-2">
      <Icon name="material-symbols:edit-outline-rounded" />
      <nuxt-link :to="`/service/repair-storage-tool/${machineryAccessory.id}`">
        Servicebericht anzeigen
      </nuxt-link>
    </span>
  </n-popover>
</template>
