<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps<{ error: NuxtError }>()

/**
 * Try reloading app automatically, `reloadNuxtApp` only runs once per minute (see TTL: https://github.com/nuxt/nuxt/blob/d439da919914ff0e1de7bb48e7a41a0db7362409/packages/nuxt/src/app/composables/chunk.ts#L5-L9)
 *
 * This is an attempt to avoid chunk-errors from lazy components not updating, as this is not handled nicely by Nuxt v <= 3.12, see https://github.com/sidestream-tech/hanselmann-os/issues/2084#issuecomment-2213637131 for more.
 */
if (import.meta.client && props.error?.statusCode === 500) {
  reloadNuxtApp({ ttl: 60_000 })
}

const router = useRouter()

const canUserGoBack = computed(() => {
  if (import.meta.server) {
    return false
  }

  // history length is `1` if tab is directly, newly opened (see https://developer.mozilla.org/en-US/docs/Web/API/History/length). So we need at least 2 elements to go back
  const historyLength = window?.history.length ?? 0
  return historyLength > 1
})
</script>

<template>
  <ThePage :title="$t('errorPage.title', { errorCode: error?.statusCode ?? 'N/A' })" icon-name="material-symbols:construction-rounded">
    <p class="text-xl">
      {{ $t('errorPage.description') }}
    </p>
    <div class="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-2">
      <n-button type="primary" @click="navigateTo('/')">
        {{ $t('errorPage.buttons.startPage') }}
      </n-button>
      <n-button v-if="canUserGoBack" type="primary" @click="router.back()">
        {{ $t('errorPage.buttons.previousPage') }}
      </n-button>
    </div>

    <n-divider />
    <n-collapse>
      <n-collapse-item>
        <template #header>
          <p>{{ $t('errorPage.moreTechnicalInfo') }}</p>
        </template>
        <pre wrap>
{{ JSON.stringify(error, null, 2) }}
        </pre>
      </n-collapse-item>
    </n-collapse>
  </ThePage>
</template>
