<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'

const routeQuery = useRouteQuery('lang')

const i18n = useI18n()
const locale = ref(typeof routeQuery.value === 'string' ? routeQuery.value : i18n.locale.value)

const langs = computed(() => i18n.locales.value
  .filter(lang => lang.code !== 'de_marketing_environment')
  .map(lang => ({ label: lang.name, value: lang.code })))

// Set the query parameter to match locale
watch(locale, (newLocale) => {
  if (i18n.locale.value !== newLocale && i18n.locales.value.some(l => l.code === newLocale)) {
    i18n.setLocale(newLocale)
    routeQuery.value = newLocale
  }
}, { immediate: true })
</script>

<template>
  <n-select v-model:value="locale" :options="langs" class="w-32 m-2" />
</template>
