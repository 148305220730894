<script setup lang="ts">
import type { CreateQuickSale } from '~/types'

defineProps<{ payload: CreateQuickSale }>()
const emit = defineEmits<{ (e: 'save', payload: CreateQuickSale): void }>()
</script>

<template>
  <FormKit :value="payload" type="form" submit-label="Erstellen" @submit="(data: CreateQuickSale) => emit('save', data)">
    <FormKit
      name="price"
      type="number"
      number="float"
      step="0.01"
      label="Preis"
    />
    <FormKit
      name="description"
      type="text"
      label="Beschreibung"
      validation="required"
    />
    <FormKit
      name="additionalInformation"
      type="textarea"
      label="Zusatzinformationen auf dem Angebot"
    />
    <FormKit
      name="includeVAT"
      type="checkbox"
      label="Mehrwertsteuer von 19% auf dem Angebot mit ausweisen"
    />
  </FormKit>
</template>
