<script setup lang="ts">
import type { ApiMachineryGetById } from '~/types'

defineProps<{ payload: ApiMachineryGetById }>()
const emit = defineEmits<{ (e: 'save', data: ApiMachineryGetById): void }>()
</script>

<template>
  <FormKit
    type="form"
    :value="payload"
    submit-label="Speichern"
    @submit="emit('save', $event)"
  >
    <FormKit
      type="textarea"
      name="marketingMachineryAccessoryDescription"
      label="Anbaugeräte"
    />

    <FormKit
      type="textarea"
      name="marketingSpecialEquipmentDescription"
      label="Sonderausstattung"
    />
  </FormKit>
</template>
