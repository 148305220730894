<script setup lang="ts">
const { t: $t } = useI18n()

import type { ApiPublicMachineryAccessoryGetById, MachineryAccessoryCategory } from '~/types'

const props = withDefaults(
  defineProps<{
    machineryAccessoryId: string
    forPartner?: boolean
  }>(),
  {
    forPartner: false,
  },
)

const { setLocale, locale } = useI18n()

if (props.forPartner) {
  setLocale('de')
}

const { machineryAccessory: queryMachineryAccessory } = useQuery()
const { data: machineryAccessory, isLoading: isLoadingMachineryAccessory, error: errorMachineryAccessory } = queryMachineryAccessory.public.byId(ref(props.machineryAccessoryId))

const { machineryAccessoryPublicFieldsByCategory: showMachineryAccessoryPublicFieldsByCategory } = useFormOptionalConditions()

const machineryAccessoryPublicCreationInformation: OneDataColumn<ApiPublicMachineryAccessoryGetById>[] = [
  {
    title: $t('publicDetailsPage.machineryAccessory.creationInformationColumn.category'),
    key: 'category',
    render: ({ category }) => $t(`machineryAccessory.category.${category}`),
  },
  {
    title: $t('publicDetailsPage.machineryAccessory.creationInformationColumn.producer'),
    key: 'producerCompanyName',
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.producerCompanyName.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('publicDetailsPage.machineryAccessory.creationInformationColumn.type'),
    key: 'typeName',
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.typeName.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('publicDetailsPage.machineryAccessory.creationInformationColumn.yearBuilt'),
    key: 'yearBuilt',
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.yearBuilt.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('publicDetailsPage.machineryAccessory.creationInformationColumn.liftingWeight'),
    key: 'liftingWeight',
    render: ({ liftingWeight }) => liftingWeight?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.liftingWeight.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('publicDetailsPage.machineryAccessory.creationInformationColumn.length'),
    key: 'lengthInMillimeters',
    render: ({ lengthInMillimeters }) => lengthInMillimeters?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.lengthInMillimeters.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('publicDetailsPage.machineryAccessory.creationInformationColumn.width'),
    key: 'widthInMillimeters',
    render: ({ widthInMillimeters }) => widthInMillimeters?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.widthInMillimeters.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('publicDetailsPage.machineryAccessory.creationInformationColumn.height'),
    key: 'heightInMillimeters',
    render: ({ heightInMillimeters }) => heightInMillimeters?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.heightInMillimeters.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('publicDetailsPage.machineryAccessory.creationInformationColumn.distanceInnerEdgeToInnerEdge'),
    key: 'distanceInnerEdgeToInnerEdge',
    render: ({ distanceInnerEdgeToInnerEdge }) => distanceInnerEdgeToInnerEdge?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.distanceInnerEdgeToInnerEdge.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('publicDetailsPage.machineryAccessory.creationInformationColumn.distanceOuterEdgeToOuterEdge'),
    key: 'distanceOuterEdgeToOuterEdge',
    render: ({ distanceOuterEdgeToOuterEdge }) => distanceOuterEdgeToOuterEdge?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.distanceOuterEdgeToOuterEdge.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('publicDetailsPage.machineryAccessory.creationInformationColumn.retractionLugsWidth'),
    key: 'retractionLugsWidth',
    render: ({ retractionLugsWidth }) => retractionLugsWidth?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.retractionLugsWidth.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('publicDetailsPage.machineryAccessory.creationInformationColumn.retractionLugsHeight'),
    key: 'retractionLugsHeight',
    render: ({ retractionLugsHeight }) => retractionLugsHeight?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.retractionLugsHeight.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('publicDetailsPage.machineryAccessory.creationInformationColumn.retractionLugsDistanceInnerEdgeToInnerEdge'),
    key: 'retractionLugsDistanceInnerEdgeToInnerEdge',
    render: ({ retractionLugsDistanceInnerEdgeToInnerEdge }) => retractionLugsDistanceInnerEdgeToInnerEdge?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.retractionLugsDistanceInnerEdgeToInnerEdge.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('publicDetailsPage.machineryAccessory.creationInformationColumn.retractionLugsDistanceOuterEdgeToOuterEdge'),
    key: 'retractionLugsDistanceOuterEdgeToOuterEdge',
    render: ({ retractionLugsDistanceOuterEdgeToOuterEdge }) => retractionLugsDistanceOuterEdgeToOuterEdge?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.retractionLugsDistanceOuterEdgeToOuterEdge.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('publicDetailsPage.machineryAccessory.creationInformationColumn.lengthLeverLink'),
    key: 'lengthLeverLink',
    render: ({ lengthLeverLink }) => lengthLeverLink?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.lengthLeverLink.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('publicDetailsPage.machineryAccessory.creationInformationColumn.widthLeverLink'),
    key: 'widthLeverLink',
    render: ({ widthLeverLink }) => widthLeverLink?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.widthLeverLink.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('publicDetailsPage.machineryAccessory.creationInformationColumn.powerLeverLink'),
    key: 'powerLeverLink',
    render: ({ powerLeverLink }) => powerLeverLink?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.powerLeverLink.includes(category as MachineryAccessoryCategory),
  },
]
</script>

<template>
  <CookieConsent :locale />
  <div>
    <div v-if="!forPartner" class="flex max-sm:flex-col sm:items-center sm:mb-2 relative">
      <TheDataCard class="mb-2">
        <TheLogo />
      </TheDataCard>
      <TheLanguageSelector class="sm:absolute sm:left-4 max-sm:m-2" />
    </div>
    <div v-if="!machineryAccessory && !isLoadingMachineryAccessory">
      <TheDataCard>
        Dieses Anbaugerät existiert nicht oder wurde noch nicht bestätigt.
      </TheDataCard>
    </div>
    <div v-else class="flex flex-col w-full gap-y-2">
      <TheDataCard :title="$t('publicDetailsPage.machineryAccessory.creationInformation')" :is-loading="isLoadingMachineryAccessory" :error="errorMachineryAccessory">
        <TableOneDataColumn :config="{ data: machineryAccessory, columns: machineryAccessoryPublicCreationInformation }" />
        <FileView :label="$t('publicDetailsPage.machineryAccessory.photos')" :files="machineryAccessory?.photos ?? []" collapsed-by-default="if-empty" />
      </TheDataCard>
    </div>
  </div>
</template>
