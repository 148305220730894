<script setup lang="ts">
defineProps<{ payload: { offerPositionId: string, repurchasePrice: null, repurchasedAt: null } }>()

const emit = defineEmits<{ save: [data: DataToEmit] }>()
interface DataToEmit {
  offerPositionId: string
  repurchasePrice: number
  repurchasedAt: Date
}
</script>

<template>
  <FormKit
    type="form"
    :value="payload"
    submit-label="Speichern"
    @submit="(data: DataToEmit) => emit('save', data)"
  >
    <FormKit
      name="repurchasePrice"
      type="number"
      number="float"
      step="0.01"
      validation="required|min:0"
      label="Rückkaufspreis (EUR)"
    />
    <FormKitDate
      name="repurchasedAt"
      validation="required"
      label="Rückkaufsdatum"
    />
  </FormKit>
</template>
