<script setup lang="ts">
const { t: $t } = useI18n()

import { eachDayOfInterval, isSameDay } from 'date-fns'
import type { RentalPauseUpsert } from '~/types'

defineEmits<{ (e: 'upsert'): RentalPauseUpsert }>()

const { openRentalPausePopup: { data: popupData, close: closePopup } } = useGlobalOpeners()
const { rentalPause, offer } = useQuery()
const { data: offerRentalPausesData } = rentalPause.allByOfferId(popupData.value?.offerId)
const { data: offerData } = offer.byId(popupData.value?.offerId)

const rentalPauseData = computed(() => popupData.value?.id ? offerRentalPausesData.value?.find(rp => rp.id === popupData.value?.id) : undefined)
const relatedRentalPauses = computed(() => popupData.value?.id
  ? offerRentalPausesData.value?.filter(rp => rp.id !== popupData.value?.id) ?? []
  : offerRentalPausesData.value ?? [],
)
const relatedPauseDates = computed(() => relatedRentalPauses.value.flatMap(rp => eachDayOfInterval({ start: rp.startDate, end: rp.endDate })))

const validPeriod = computed(() => {
  if (!offerData.value) {
    return null
  }

  return {
    start: offerData.value?.obligationStartsAt,
    end: offerData.value?.obligationActuallyEndedAt || offerData.value?.obligationEndsAt,
  }
})

const validDates = computed(() => {
  if (!validPeriod.value) {
    return []
  }

  return eachDayOfInterval({ start: validPeriod.value.start, end: validPeriod.value.end ?? validPeriod.value.start })
    .filter(value => !relatedPauseDates.value.some(d => isSameDay(d, value)))
})

const payload = computed<RentalPauseUpsert | undefined>(() => {
  if (!validPeriod.value || !popupData.value?.offerId) {
    return undefined
  }
  if (rentalPauseData.value) {
    return {
      id: rentalPauseData.value.id,
      offerId: rentalPauseData.value.offerId,
      startDate: rentalPauseData.value.startDate,
      endDate: rentalPauseData.value.endDate,
    }
  }
  return {
    offerId: popupData.value.offerId,
    startDate: validDates.value[0] ?? validPeriod.value.start,
    endDate: validDates.value[0] ?? validPeriod.value.start,
  }
})

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const upsert = useMutation({
  mutationFn: $trpc.rentalPause.upsert.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('query.error', { entity: $t('offer.rentalPause.entity') }) }),
  onSuccess: async (res) => {
    await Promise.all([
      queryClient.resetQueries({ queryKey: ['rentalPause'] }),
      queryClient.invalidateQueries({ queryKey: ['offer', res.offerId] }),
    ])

    closePopup()
    notification.success({ title: $t('query.success', { entity: $t('offer.rentalPause.entity') }), duration: 4500 })
  },
})
</script>

<template>
  <ThePopup
    v-if="validPeriod && payload"
    :title="$t(`offer.rentalPause.page.${payload.id ? 'edit' : 'create'}`)"
    show
    @close="closePopup"
  >
    <RentalPauseCreateOrEditForm
      :payload="payload"
      :valid-dates="validDates"
      :related-rental-pauses="relatedRentalPauses"
      @save="upsert.mutate"
    />
  </ThePopup>
</template>
