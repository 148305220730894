/**
 * Format a number into a string in a specific locale and with a specific decimal places count.
 *
 * @param n Number to format into a string
 * @param locale Locale to use for formatting (eg: impacts whether `,` or `.` is used for decimal)
 * @param fractionDigits How many decimal places to show. Can either be a `number` to enforce an exact number of digits or a object to specify an upper bound of digits.
 * @returns A string representing the number
 *
 * NOTE:
 * - If you pass in `{ maximumFractionDigits: number }` it means that less than `maximumFractionDigits` places may be returned.
 * - Per default, exactly two places are returned
 */
export default function (n: number, locale: Intl.LocalesArgument = 'de-DE', fractionDigits: number | { maximumFractionDigits: number } = 2): string {
  const formatterConfig = typeof fractionDigits === 'number'
    ? { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }
    : fractionDigits

  // `Intl.NumberFormat` should be more performant here, as it may decide to cache lookup results, see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
  const formatter = new Intl.NumberFormat(locale, formatterConfig)

  return formatter.format(n)
}
