<script setup lang="ts">
const { t: $t } = useI18n()

import countries from 'i18n-iso-countries/langs/de.json'
import type { OtherInvoiceRecipientUpsert } from '~/types'
import { customerLanguages, paymentConditions, taxRates } from '~/server/schemas'
import { customerFieldsToGerman } from '~/translations'

defineProps<{ payload: OtherInvoiceRecipientUpsert }>()

defineEmits<{
  (e: 'upsert', payload: OtherInvoiceRecipientUpsert): void
}>()

const taxRateOptions = taxRates.options.map(rate => ({
  label: `${(Number(rate) * 100).toFixed(0)}%`,
  value: rate,
}))

const countryOptions = Object.entries(countries.countries).map((country) => {
  if (typeof country[1] === 'string') {
    return {
      label: country[1],
      value: country[0],
    }
  } else {
    // if there are multiple names exist for one country, we use the first one
    return {
      label: country[1][0],
      value: country[0],
    }
  }
})

const paymentConditionOptions = paymentConditions.options.map(condition => ({
  label: $t(`paymentConditions.${condition}`),
  value: condition,
}))

const languageOptions = customerLanguages.options.map(language => ({
  label: $t(`customerLanguages.${language}`),
  value: language,
}))
</script>

<template>
  <FormKit
    type="form"
    :value="payload"
    submit-label="Abweichender Rechnungsempfänger speichern"
    @submit="$emit('upsert', $event)"
  >
    <FormKit
      label="Kunde"
      type="text"
      name="customerId"
      disabled
    />
    <div class="flex space-x-2">
      <FormKit
        label="Name"
        type="text"
        name="name"
        required
      />
      <FormKit
        name="nameTwo"
        type="text"
        label="Name 2"
        placeholder="ACME Inc."
      />
    </div>

    <div class="flex space-x-2">
      <FormKit
        name="nameThree"
        type="text"
        label="Name 3"
        placeholder="Musterfirma GmbH"
      />
      <FormKit
        name="address"
        type="text"
        label="Straße und Ort"
        placeholder="Spitalseestraße 21, 74564 Crailsheim"
        required
      />
    </div>

    <div class="flex space-x-2">
      <FormKit
        name="email"
        type="email"
        :validation="[['required'], ['email']]"
        label="Email"
        placeholder="max@musterman.de"
      />
      <FormKit
        name="telephone"
        type="text"
        label="Telefon"
        placeholder="079 123 45 67"
        required
      />
    </div>

    <div class="flex space-x-2">
      <FormKit
        name="telefax"
        type="tel"
        label="Telefax"
        placeholder="+49 123456966966"
      />
      <FormKit
        name="iban"
        type="text"
        :label="customerFieldsToGerman.iban"
        placeholder="DE89370400440532013000"
        required
      />
    </div>

    <div class="flex space-x-2">
      <FormKit
        name="taxId"
        type="text"
        :label="customerFieldsToGerman.taxId"
        placeholder="22 00 0 001 00001"
        required
      />
      <FormKit
        name="vatID"
        type="text"
        :label="customerFieldsToGerman.vatID"
        placeholder="DE89370400440532013000"
        required
      />
    </div>

    <div class="flex space-x-2">
      <FormKit
        name="taxRate"
        type="select"
        :options="taxRateOptions"
        label="Steuersatz"
        required
      />
      <FormKit
        name="paymentCondition"
        type="select"
        :options="paymentConditionOptions"
        validation="required"
        label="Zahlungskonditionen"
        required
      />
    </div>

    <div class="flex space-x-2">
      <FormKit
        name="language"
        type="select"
        :options="languageOptions"
        validation="required"
        label="Sprache"
        placeholder="Bitte auswählen"
        required
      />
      <FormKitMultiSelect
        name="country"
        label="Land"
        :multi-select-options="countryOptions"
        :is-multi-select-allowed="false"
        placeholder="Deutschland"
        required
      />
    </div>
  </FormKit>
</template>
