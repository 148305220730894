<script setup lang="ts">
import { nextTick } from 'vue'
import draggable from 'vuedraggable'
import { reset } from '@formkit/core'
import type { FormKitNode } from '@formkit/core'
import { v4 as uuid } from 'uuid'
import { normalizeExternalVideoURL } from '../File/common'

const props = defineProps<{
  context: { value: string[], node: { input: (v: string[]) => unknown } } & Record<string, any>
}>()

const container = ref<Element | null>(null)

const list = ref(props.context.value.map(value => ({ id: uuid(), value })))
const listValues = computed(() => list.value.map(entry => entry.value))

watchEffect(() => props.context.node.input(listValues.value))

function deleteFromList(id: string) {
  list.value = list.value.filter(entry => entry.id !== id)
}

function updateInList(id: string, value: string) {
  const entry = list.value.find(entry => entry.id === id)
  if (entry) {
    entry.value = value
  }
}

const placeholder = computed(() => `Füge einen ${list.value.length > 0 ? 'weiteren ' : ''}YouTube oder Vimeo Link hinzu`)

function onNewURLInput(value?: string) {
  if (!value) {
    return
  }
  list.value.push({ id: uuid(), value })
  reset('newURLInputField')
  nextTick(() => {
    const input = container.value?.querySelector('li:last-of-type input') as HTMLInputElement | undefined
    input?.focus()
  })
}

const validationMessage = 'Bitte geben Sie einen gültigen YouTube oder Vimeo Link an.'
function isExternalVideoURLValid(node: FormKitNode) {
  return typeof node.value === 'string' && !!normalizeExternalVideoURL(node.value)
}
</script>

<template>
  <div ref="container" class="mb-4">
    <draggable :list item-key="id" tag="ul" handle=".handle">
      <template #item="{ element: { value, id } }">
        <li class="mb-2">
          <div class="flex items-center w-full">
            <FormKit
              type="text"
              :value
              :outer-class="{ 'mb-4': false }"
              :inner-class="{ 'max-w-md': false, 'relative': true }"
              :validation-rules="{ validExternalVideoURL: isExternalVideoURLValid }"
              validation="required|validExternalVideoURL"
              validation-label="YouTube oder Vimeo Link"
              :validation-messages="{ validExternalVideoURL: validationMessage }"
              @input="updateInList(id, $event ?? '')"
            >
              <template #prefixIcon>
                <Icon name="material-symbols:link" class="text-2xl ml-3" />
              </template>
              <template #suffixIcon>
                <div class="handle cursor-pointer w-11 h-11 flex items-center justify-center mr-1" title="Verschiebe URL">
                  <Icon name="material-symbols:expand" class="text-2xl opacity-70" />
                </div>
              </template>
            </FormKit>

            <n-button
              quaternary
              type="error"
              size="large"
              title="Lösche URL"
              class="w-10 h-10 mt-0.5 mx-2 self-start"
              @click="() => deleteFromList(id)"
            >
              <template #icon>
                <Icon name="material-symbols:delete-outline-rounded" class="text-2xl" />
              </template>
            </n-button>
          </div>
        </li>
      </template>
    </draggable>
    <FormKit
      id="newURLInputField"
      ignore
      type="text"
      :placeholder
      autocomplete="off"
      :outer-class="{ 'md:pr-12': true, 'opacity-70': true }"
      :inner-class="{ 'max-w-md': false, 'md:mr-2': true, 'h-11': true }"
      @input="onNewURLInput"
    >
      <template #prefixIcon>
        <Icon name="material-symbols:add-link-rounded" class="text-2xl ml-3" />
      </template>
    </FormKit>
  </div>
</template>

<style scoped>
.sortable-ghost {
  opacity: 0.4;
}
</style>
