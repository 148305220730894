<script lang="ts" setup>
import { onKeyStroke } from '@vueuse/core'
import 'vidstack/bundle'
import { getVideoProvider } from './common'

const props = defineProps<{ src: string | null, disableCookieConset: boolean }>()

const emit = defineEmits<{ close: [] }>()

const { cookiesEnabledIds, isModalActive } = useCookieControl()

const externalVideoProvider = computed(() => getVideoProvider(props.src))
const cookieConsentGiven = computed((): boolean => {
  if (props.disableCookieConset) {
    return true
  }

  const cookiesEnabledIdsValue = cookiesEnabledIds.value

  if (!cookiesEnabledIdsValue?.includes('functional')) {
    return false
  }

  return !externalVideoProvider.value || cookiesEnabledIdsValue?.includes(externalVideoProvider.value)
})

onKeyStroke('Escape', () => emit('close'))
</script>

<template>
  <transition>
    <ClientOnly v-if="src">
      <div class="fixed isolate top-0 left-0 w-full h-full bg-red z-50 flex justify-center items-center bg-black bg-opacity-50" @click="emit('close')">
        <div class="absolute flex items-center justify-center w-[80%] max-h-[80%] m-auto" @click.stop="() => {}">
          <p class="absolute bg-white w-fit px-6 py-4 rounded-lg">
            <template v-if="!cookieConsentGiven">
              <span class="text-lg">
                Um dieses Video abzuspielen, akzeptiere bitte die
                <template v-if="externalVideoProvider">funktionalen Cookies.</template>
                <template v-else>{{ externalVideoProvider === 'youtube' ? 'YouTube' : 'Vimeo' }} Cookie Richtlinien.</template>
              </span>
              <div class="flex justify-end mt-3">
                <n-button secondary type="info" @click="isModalActive = true">
                  Cookies verwalten
                </n-button>
                <n-button secondary type="error" class="ml-2" @click="$emit('close')">
                  Schließen
                </n-button>
              </div>
            </template>
            <template v-else>
              Dieses Video kann leider nicht abgespielt werden.
              <n-button quaternary type="error" class="ml-2" @click="$emit('close')">
                Schließen
              </n-button>
            </template>
          </p>
          <media-player v-if="cookieConsentGiven" :src autoplay storage="vidstack-setting-local-storage">
            <media-provider />
            <media-video-layout />
          </media-player>
        </div>
        <button class="absolute top-0 right-0 w-10 h-10 rounded-bl-full bg-black bg-opacity-50 hover:bg-opacity-80" @click="emit('close')">
          <Icon name="material-symbols:fullscreen-exit-rounded" size="20" class="ml-2 mb-2 text-gray-200" />
        </button>
      </div>
    </ClientOnly>
  </transition>
</template>
