<script setup lang="ts">
import type { LogisticsTaskAToBStatus, UpdateLogisticsTask } from '~/types'

const { openEditTaskAToBStatusPopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const { logisticsTask: queryLogisticsTask } = useQuery()
const { data: logisticsTask } = queryLogisticsTask.byIdOrThrow(computed(() => popupData.value?.id))

const updateAToBTask = useMutation({
  mutationFn: $trpc.logisticsTask.updateStatus.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Lieferung konnte nicht bearbeitet werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
    ])

    closePopup()
    notification.success({ title: 'Die Lieferung wurde erfolgreich bearbeitet', duration: 4500 })
  },
})

const hasFinalInvoiceCreated = computed(() => {
  if (!logisticsTask.value) {
    return false
  }

  return logisticsTask.value.offer?.invoices.some(invoice => invoice.type === 'fullPayment' && invoice.status !== 'cancelled')
})

function updateStatus({ isCompleted }: { isCompleted: boolean }) {
  if (isCompleted === null || !logisticsTask.value) {
    return
  }

  let status: LogisticsTaskAToBStatus = 'created'
  if (isCompleted) {
    status = 'completed'
  } else if (logisticsTask.value.deliveredAt) {
    status = 'delivered'
  }

  updateAToBTask.mutate({
    ...logisticsTask.value,
    status,
  } as UpdateLogisticsTask)
}
</script>

<template>
  <ThePopup v-if="logisticsTask && popupData" :show="Boolean(popupData)" :title="`Spezialfahrt für Auftrag ${logisticsTask.offer?.id ?? 'N/A'}`" @close="closePopup">
    <n-alert v-if="hasFinalInvoiceCreated" type="warning">
      Der Status kann nach der Erstellung einer Schlussrechnung nicht mehr aktualisiert werden.
    </n-alert>
    <FormKit
      v-slot="{ value }"
      type="form"
      :disabled="hasFinalInvoiceCreated"
      submit-label="Spezialfahrt speichern"
      @submit="updateStatus"
    >
      <FormKit
        :value="logisticsTask.status === 'completed'"
        type="checkbox"
        name="isCompleted"
        label="Fahrt abgeschlossen?"
      />
      
    </FormKit>
  </ThePopup>
</template>
