import { endOfDay, startOfDay } from 'date-fns'

/**
 * Return a prisma range[0] <= date <= range[1] date-filter. The filter is serialized to string, so meant to be passed into a tRPC-call.
 *
 * By default `range[0]` is transformed to `startOfDay(range[0])` and `range[1]` is transformed to `endOfDay(range[1])`.
 */
export default (range: [number, number], options: { transformStartToStartOfDay?: boolean } = {}) => {
  const rangeFrom = new Date(range[0])
  const rangeTo = new Date(range[1])

  const { transformStartToStartOfDay = true } = options

  return {
    gte: transformStartToStartOfDay ? startOfDay(rangeFrom).toISOString() : rangeFrom.toISOString(),
    lte: endOfDay(rangeTo).toISOString(),
  }
}
