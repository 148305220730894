<script setup lang="ts">
const { openInviteFreelancerSales: { close, data } } = useGlobalOpeners()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const { freelancerSales } = useQuery()
const { data: freelancers, isLoading } = freelancerSales.getFreelancers()

const formkitEmailIsNotFreelancerEmailRule = computed(() => {
  const availableFreelancerOptions = freelancers.value
  if (!availableFreelancerOptions || availableFreelancerOptions.length === 0) {
    return ''
  }
  return `|not:${availableFreelancerOptions.map(({ email }) => email).join(',')}`
})

const inviteFreelancer = useMutation({
  mutationFn: $trpc.freelancerSales.inviteFreelancer.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Freelancer konnte nicht eingeladen werden.' }),
  onSuccess: async (freelancer) => {
    await queryClient.invalidateQueries({ queryKey: ['freelancerSales'] })
    notification.success({ title: `${freelancer.name} wurde erfolgreich eingeladen. Er kann sich nun anmelden`, duration: 4500 })
    close()
  },
})
</script>

<template>
  <ThePopup
    v-if="data"
    :show="data"
    title="Freelancer Einladen"
    @close="close"
  >
    <TheDataCard :is-loading="isLoading">
      <FormKit
        v-slot="{ value }"
        type="form"
        submit-label="Freelancer einladen"
        @submit="inviteFreelancer.mutate"
      >
        <TheDevOnlyNiceJson :value="value" />
        <FormKit
          name="userEmail"
          type="text"
          label="E-Mail"
          help="Die E-Mail Adresse mit welcher der Freelancer sich im System anmelden wird."
          :validation="`required|email${formkitEmailIsNotFreelancerEmailRule}`"
        />
        <FormKit
          name="userName"
          type="text"
          label="Name"
          help="Name des Freelancers (Vor- und Nachname)"
          validation="required|length:3"
        />
      </FormKit>
    </TheDataCard>
  </ThePopup>
</template>
