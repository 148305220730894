<script setup lang="ts">
defineProps<{ actionButtonLabel?: string }>()

const emit = defineEmits<{
  (e: 'confirm'): void
  (e: 'close'): void
}>()
</script>

<template>
  <n-modal
    show
    preset="dialog"
    :title="$t('global.confirm.title')"
    :positive-text="actionButtonLabel ?? $t('global.action.delete')"
    :negative-text="$t('global.action.cancel')"
    @positive-click="emit('confirm')"
    @negative-click="emit('close')"
    @update-show="emit('close')"
  >
    <slot />
  </n-modal>
</template>
