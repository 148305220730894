import type { RentalDay } from '@prisma/client'
import { add, isBefore } from 'date-fns'
import { isHoliday } from 'feiertagejs'
import { createId } from '@paralleldrive/cuid2'

const convertLocalTimeToUTCTime = (localDate: Date) => new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000)

function generateDatesInIntervalByDay(startDate: Date, endDate: Date) {
  const dates = []
  let currentDate = new Date(startDate)

  // For this check to work correctly in server
  // we need to save `obligationStartsAt`, `obligationEndsAt`, `obligationActuallyEndedAt` as startofDay in local timezone
  // we convert time to UTC time to get same result from server & frontend
  while (isBefore(currentDate, endDate) || getAbsoluteDateDifferenceInHours(convertLocalTimeToUTCTime(endDate), convertLocalTimeToUTCTime(currentDate)) <= 12) {
    dates.push(new Date(currentDate))
    currentDate = add(currentDate, { days: 1 })
  }

  return dates
}

/**
 * Generate the _additional_ rental days that occured after a span of existing rental days.
 *
 * E.g.: If today is the `2024-01-20` and the passed rental days span `2024-01-01` until `2024-01-10`, the rental days from `2024-01-11` until `2024-01-20` are returned.
 *
 * The returned rental days are fully compatible with the `RentalDay`-database model.
 */
export default (rentalDays: { date: Date, discountRate: number }[], extendedDate?: Date | null, offerId?: string, weekendDiscoundDisabled = false): RentalDay[] => {
  if (rentalDays.length === 0) {
    return []
  }

  const startOfTodayInTz = startOfDayInTz(new Date())

  const sortedRentalDays = [...rentalDays].sort((a, b) => sortNullishDates(a.date, b.date))
  const obligationEndsAt = sortedRentalDays[sortedRentalDays.length - 1].date
  const obligationEndsAtHasPassed = obligationEndsAt.getTime() < startOfTodayInTz.getTime() && !extendedDate
  const obligationHasExtended = extendedDate && extendedDate.getTime() > obligationEndsAt.getTime()

  if (!(obligationEndsAtHasPassed || obligationHasExtended)) {
    return []
  }

  const rentalEndDate = extendedDate ?? startOfTodayInTz
  const startToExtendedDate = addDaysDstSafe(obligationEndsAt, 1)

  return generateDatesInIntervalByDay(startToExtendedDate, rentalEndDate).map((date) => {
    const dayInGermanTimezone = useDateAsString(date, 'EEEE')
    const isWeekend = !weekendDiscoundDisabled && (dayInGermanTimezone === 'Samstag' || dayInGermanTimezone === 'Sonntag')
    return {
      date,
      discountRate: isWeekend || isHoliday(date, 'BUND') ? 1 : 0,
      id: createId(),
      createdAt: new Date(),
      offerId: offerId ?? createId(),
      rentalPauseId: null,
      updatedByEmail: null,
    }
  })
}
