export default () => {
  const { username } = useAuthorization()

  const customKeyInfix = ref<string | undefined>(undefined)
  const key = computed(() => ({ prefix: username.value, key: customKeyInfix.value }))

  const clearFormStorage = () => {
    const { prefix, key: customKeyInfix } = unref(key)
    const keyFormat = [prefix, customKeyInfix].join('-')

    Object.keys(localStorage).forEach((k) => {
      if (k.startsWith(keyFormat)) {
        localStorage.removeItem(k)
      }
    })
  }

  return {
    key,
    customKeyInfix,
    clearFormStorage,
  }
}
