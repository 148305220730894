<script setup lang="ts">
const props = defineProps<{
  value: number
  update: (v: number) => unknown
  label: string
  quantity?: number
}>()

const rate = ref(props.value)

function storePercentageStringAsNumber(input: string) {
  const nums = input.replaceAll(',', '.').trim()
  props.update(Number(nums) / 100)
  return Number(nums) / 100
}

function displayNumberAsPercentageString(value: number | null) {
  if (!value) {
    return '0'
  }
  return formatNumberToString(value * 100, 'de', { maximumFractionDigits: 2 })
}
</script>

<template>
  <label>
    <p class="font-bold pb-2">
      {{ label }}
      <ThePartialQuantityPopover
        v-if="quantity"
        :quantity="quantity"
        :discount-rate="value"
        @update-discount-rate="value => rate = value"
      />
    </p>

    <n-input-number
      v-model:value="rate"
      class="w-full"
      :controls="false"
      :parse="storePercentageStringAsNumber"
      :format="displayNumberAsPercentageString"
      :show-button="false"
      :min="0"
      :max="1"
      :default-value="value"
      v-bind="$attrs"
    />
  </label>
</template>
