<script setup lang="ts">
import { createInput } from '@formkit/vue'
import SignaturePopup from './CreateInputBase/SignaturePopup.vue'

const signaturePopup = createInput(SignaturePopup, { props: ['disablePositionsOverview', 'title', 'buttonText', 'disableByPass', 'onSave'] })
</script>

<template>
  <FormKit :type="signaturePopup" />
</template>
