<script setup lang="ts">
import type { DataTableRowKey } from 'naive-ui'
import type { Prisma } from '@prisma/client'
import type { MachineryAccessoryCategory, MachineryCategories } from '~/types'

const props = withDefaults(defineProps<{ selectType?: 'multiple' | 'single', machineryId: string, machineryCategory: MachineryCategories, compatibleMachineryTypeId: string, alreadyCheckedRowKeys: DataTableRowKey[], accessoryCategoriesToShow?: MachineryAccessoryCategory[], machineryAccessoryWhere?: Prisma.MachineryAccessoryWhereInput }>(), {
  alreadyCheckedRowKeys: () => [],
  selectType: 'multiple',
  accessoryCategoriesToShow: () => [],
  machineryAccessoryWhere: () => ({}),
})
const emit = defineEmits<{ (e: 'connect', machineryAccessoryIds: string[]): void }>()

const { machineryAccessorySelect } = useTableColumnConfigs()
const tableConfig = machineryAccessorySelect({ machineryId: props.machineryId, selectType: props.selectType, isSetSelectable: false })
const { fulltextSearchValue, where } = useFilterData(tableConfig.value.fulltextSearch)
const { shopping } = useQuery()

const machineryId = computed(() => props.machineryId)

const connectableAccessoryCategories: Readonly<Partial<Record<MachineryCategories, MachineryAccessoryCategory[]>>> = {
  forklift: ['fork', 'forkAdjuster', 'sideShifter', 'forkCarriage', 'charger'],
}

const accessoryWhere = computed(() => ({
  ...where.value,
  ...props.machineryAccessoryWhere,
  machineryAccessoryBundleId: null,
}))

const { data, error, isLoading } = shopping.getCompatibleAccessories(
  machineryId,
  computed(() => props.accessoryCategoriesToShow.length > 0 ? props.accessoryCategoriesToShow : connectableAccessoryCategories[props.machineryCategory]),
  accessoryWhere,
  true,
)

const forkCarriageKey = computed(() => data.value?.find(m => m.category === 'forkCarriage'))

const checkedRowKeys = ref<DataTableRowKey[]>(props.alreadyCheckedRowKeys)

// https://github.com/sidestream-tech/hanselmann-os/issues/2138
const showOnlySelectedItems = ref(false)
const filteredData = computed(() => (
  showOnlySelectedItems.value
    ? data.value?.filter(it => checkedRowKeys.value.includes(it.id))
    : data.value
))
</script>

<template>
  <TheDataCard title="Verbundene Anbaugeräte" :error="error">
    <label class="flex justify-end gap-2 mb-2">
      <span class="font-semibold">
        Nur verbundene Anbaugeräte anzeigen
      </span>
      <n-switch v-model:value="showOnlySelectedItems" />
    </label>

    <TableFilters v-model="fulltextSearchValue" :placeholder="tableConfig.fulltextSearch.placeholder">
      <TableView
        v-if="filteredData"
        :data="filteredData"
        :columns="tableConfig.columns"
        :is-loading="isLoading"
        :row-key="row => row.id"
        :checked-row-keys="checkedRowKeys"
        @update:checked-row-keys="keys => checkedRowKeys = keys"
      />
    </TableFilters>
    <n-button type="primary" @click="emit('connect', checkedRowKeys as string[])">
      <template #icon>
        <Icon name="material-symbols:add-link" />
      </template>
      Maschine mit {{ checkedRowKeys.length }} ausgewählten Anbaugeräten verbinden
    </n-button>
    <p v-if="forkCarriageKey" class="text-gray-600 font-semibold my-3">
      Veränderungen an Gabelträger Verbindungen müssen zunächst abgespeichert werden um die Kompatibilität der Maschine neu bestimmen zu können.
    </p>
  </TheDataCard>
</template>
