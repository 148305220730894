<script setup lang="ts">
import { createInput } from '@formkit/vue'
import MultipleSelect from './CreateInputBase/MultipleSelect.vue'

const multipleSelect = createInput(MultipleSelect, { props: [
  'class',
  'update',
  'multiSelectOptions',
  'placeholder',
  'isMultiSelectAllowed',
  'isOptionCreationAllowed',
  'isClearable',
  'hasSelectAll',
] })
</script>

<template>
  <FormKit :type="multipleSelect" />
</template>
