<script setup lang="ts">
const props = defineProps<{ payload: ComponentData, mode: 'upload' | 'edit' }>()
const emit = defineEmits<{ (e: 'save', payload: ComponentData): void }>()

type TransportFiles = { path: string }[]
interface ComponentData {
  id: string
  status: string
  transportFiles: TransportFiles
}

const sumbitLabel = computed(() => props.mode === 'upload' ? 'Speichern und als "Übergeben" markieren' : 'Speichern')

function submit({ transportFiles }: { transportFiles: TransportFiles }) {
  const base = { id: props.payload.id, transportFiles }
  if (props.mode === 'upload') {
    emit('save', { ...base, status: 'delivery' })
    return
  }

  emit('save', { ...base, status: props.payload.status })
}
</script>

<template>
  <FormKit :value="{ transportFiles: payload.transportFiles }" type="form" :submit-label="sumbitLabel" @submit="submit">
    <FormKitFileDropzone
      name="transportFiles"
      label="Bitte lade die Transportpapiere hoch"
      validation="required"
      wrap-path-in-object
      validation-visibility="submit"
      :validation-messages="{
        required: 'Bitte laden Sie mindestens eine Datei hoch',
      }"
    />
  </FormKit>
</template>
