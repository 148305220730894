<script setup lang="ts">
import type { FreelancerData } from '~/types'

const props = defineProps<{
  freelancers: FreelancerData[]
  title: string
  selected: string[]
}>()

const emit = defineEmits<{
  (e: 'submit', selected: string[]): void
  (e: 'close'): void
}>()

const selectedRef = ref(props.selected)

const checkboxOptions = computed(() => props.freelancers.map(freelancer => ({
  value: freelancer.id,
  label: freelancer.name ?? freelancer.email,
})))
</script>

<template>
  <ThePopup show :title="title" @close="emit('close')">
    <FormKit type="form" @submit="emit('submit', $event.selected)">
      <FormKit
        v-model="selectedRef"
        name="selected"
        type="checkbox"
        label="Freelancer"
        :options="checkboxOptions"
      />
    </FormKit>
  </ThePopup>
</template>
