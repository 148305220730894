/**
 * Utilities in this file are there to make date-fns a bit more timezone aware than it usually is.
 *
 * So far the utilities here tackle the following problem classes:
 * - Getting an absolute point of time in another timezone (`startOfDayInTz`, `endOfDayInTz`)
 * - Adding durations to timestamps in a daylight-savings aware manner, as days can be longer / shorter than 24 hours during daylight savings time (`addDaysDstSafe`)
 *
 * We should try to avoid these helpers where possible, as they mess with time in complex ways, potentially causing unforseeable problems.
 */
import { addDays, addMilliseconds, endOfDay, startOfDay } from 'date-fns'
import { fromZonedTime, getTimezoneOffset, toZonedTime } from 'date-fns-tz'

/**
 * Helper to get the startOfDay internal UTC-date-representation in any timezone.
 *
 * E.g.: You are in UTC+0 and want to know: "What's start of day in Germany for 05.10.?" Answer: "04.10. 22:00" in UTC+2.
 *
 * Note that this is only needed when you are in timezone A but want to think in timezone B. E.g., when:
 * - the server in UTC time wants to run time-sensitive checks for the user in Germany (UTC+2), but cannot get the time sent over from the user.
 *
 * A specific case where this happens is our task-generation system, that e.g., needs to check if German deliveries are overdue.
 *
 * Adapted from https://github.com/marnusw/date-fns-tz/issues/67#issuecomment-927900990
 */
export function startOfDayInTz(utcDate: Date, timezone: string = 'Europe/Berlin') {
  const inputZoned = toZonedTime(utcDate, timezone)

  const dayStartZoned = startOfDay(inputZoned)

  return fromZonedTime(dayStartZoned, timezone)
}

/**
 * Helper to get the endOfDay internal UTC-date-representation in any timezone.
 *
 * Note that this is only needed when you are in timezone A but want to think in timezone B. E.g., when:
 * - the server in UTC time wants to run time-sensitive checks for the user in Germany (UTC+2), but cannot get the time sent over from the user.
 *
 * A specific case where this happens is our task-generation system, that e.g., needs to check if German deliveries are overdue.
 */
export function endOfDayInTz(utcDate: Date, timezone: string = 'Europe/Berlin') {
  const inputZoned = toZonedTime(utcDate, timezone)

  const dayStartZoned = endOfDay(inputZoned)

  return fromZonedTime(dayStartZoned, timezone)
}

/**
 * Add `amount` days to a passed date in a daylight-save manner.
 *
 * So if the day has `25` or `23` hours, because it is a daylight-savings-switch day, this helper will add `23` or `25` hours instead of the typical 24 hours.
 *
 * This method also correctly handles negative `amount`s, so it can be used as a safe substitute to `subDays` calls as well.
 *
 * `date-fns` sadly does not handle this case at all. Adapted from an open issue related to this topic: https://github.com/date-fns/date-fns/issues/571#issuecomment-1693396557
 */
export function addDaysDstSafe(date: Date | number, amount: number, timezone = 'Europe/Berlin') {
  const startDate = typeof date === 'number' ? new Date(date) : date
  const endDate = addDays(date, amount)

  const additionalMillisecondsForDay = getTimezoneOffset(timezone, startDate) - getTimezoneOffset(timezone, endDate)
  return addMilliseconds(
    endDate,
    additionalMillisecondsForDay,
  )
}
