<script setup lang="ts">
import type { SelectOption } from 'naive-ui'

type Event = string | (string | number | undefined)[]
const props = defineProps<{
  context: {
    value: string[]
    node: { input: (e: Event) => unknown }
    update?: (e: Event) => unknown
    multiSelectOptions: SelectOption[]
    placeholder?: string
    isMultiSelectAllowed?: boolean
    isOptionCreationAllowed?: boolean
    isClearable?: boolean
    hasSelectAll?: boolean
    class: string
  }
}>()

function update(event: Event) {
  if (props.context.update) {
    props.context.update(event)
  } else {
    props.context.node.input(event)
  }
}

const selectAll = () => update(props.context.multiSelectOptions.map(option => option.value))
</script>

<template>
  <div>
    <n-select
      :class="context.class"
      :value="context.value ?? null"
      :options="context.multiSelectOptions"
      :placeholder="context.placeholder ?? 'Wählen Sie aus der Liste'"
      filterable
      :multiple="context.isMultiSelectAllowed ?? true"
      :consistent-menu-width="false"
      :tag="context.isOptionCreationAllowed"
      size="large"
      :clearable="context.isClearable"
      @update:value="update"
    >
      <template v-if="context.hasSelectAll" #action>
        <n-button style="width: 100%" @click="selectAll">
          Alle auswählen
        </n-button>
      </template>
    </n-select>
  </div>
</template>
