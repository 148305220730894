<script setup lang="ts">
import type { ApiMachineryGetById, MachineryAggregatedPricingDecisionData } from '~/types'
import type { ExternalSalesMarketplaceId } from '~/prisma/enums'
import { machineryFieldsToCompare } from '~/server/schemas'
import { machineryFieldsToCompareLabels } from '~/translations'

const props = withDefaults(defineProps<{ payload: { machineryId: string }, isDetailsPage?: boolean }>(), { isDetailsPage: false })
const emit = defineEmits<{ (e: 'save', payload: ApiMachineryGetById): void }>()

const { useremail } = useAuthorization()

const { machinery: queryMachinery, receptionMachinery: queryReceptionMachinery, externalSalesMarketplace: queryExternalSalesMarketplace, internalCostPosition: queryInternalCostPosition, reporting: queryReporting } = useQuery()

// Load and prepare machinery to confirm data
const { data: machinery } = queryMachinery.byId(computed(() => props.payload.machineryId))

// Load and prepare receptionMachinery to compare data
const { data: receptionMachinery, isLoading: isLoadingReceptionMachinery } = queryReceptionMachinery.byMachineryId(computed(() => props.payload.machineryId))

const submitLabel = 'Speichern'.concat(props.isDetailsPage ? '' : ' und als "Bestätigt" markieren')

const moveToDetailsPage = () => navigateTo(`/machinery/${props.payload.machineryId}`)

const { data: externalSalesMarketplaces } = queryExternalSalesMarketplace.getAll()
const externalMarketplaceOptions = computed(() => {
  const marketplaceEntries = externalSalesMarketplaces.value?.map((marketplace) => {
    const releasedMachineryCount = marketplace._count.machineryToExport
    const remainingMachinery = marketplace.maxExportedMachineryAmount === null ? null : marketplace.maxExportedMachineryAmount - releasedMachineryCount

    return [
      marketplace.marketplaceId,
      {
        remainingMachinery,
        isIncludedInRemaining: !!machinery.value?.releasedForSaleOnExternalMarketplaces.find(({ marketplaceId }) => marketplaceId === marketplace.marketplaceId),
      },
    ]
  })

  return Object.fromEntries(marketplaceEntries ?? [])
},
)

const differentFields = computed(() => {
  const machineryToConfirmValue = machinery.value
  if (!machineryToConfirmValue) {
    return []
  }

  const receptionMachineryValue = receptionMachinery.value
  if (!receptionMachineryValue) {
    return []
  }

  return machineryFieldsToCompare.options.filter((field) => {
    if (field === 'serialNumber') {
      return machineryToConfirmValue.serialNumber !== receptionMachineryValue.chassisNumber
    }

    return machineryToConfirmValue[field] !== receptionMachineryValue[field]
  })
})

const { openReleaseMachineryForFreelancerSalesPopup } = useGlobalOpeners()

const { data: internalCostPositionForMachineryId } = queryInternalCostPosition.all(ref({ machineryId: props.payload.machineryId }))
const { data: machineryRevenueChart } = queryReporting.getChartRevenueMachinery([0, Date.now().valueOf()], props.payload.machineryId)
const aggregatedPricingDecisionData = computed ((): MachineryAggregatedPricingDecisionData => {
  return {
    pricePurchaseEuros: machinery.value?.pricePurchaseEuros,
    operatingHours: receptionMachinery.value?.operatingHours ?? undefined,
    internalCostPositionsPriceSum: internalCostPositionForMachineryId.value?.reduce((sum, costPosition) => sum + costPosition.price, 0),
    totalMachineryRevenue: machineryRevenueChart.value?.additionalData?.totalRevenue,
  }
})

type FormInput = {
  isReleasedForRent: boolean
  isReleasedForSale: boolean
  isReleasedForOnlineSale: true
  releasedForSaleOnExternalMarketplaces: ExternalSalesMarketplaceId[]
  endCustomerSellingPrice: number | null
  dealerSellingPrice: number | null
} | {
  isReleasedForRent: boolean
  isReleasedForSale: boolean
  isReleasedForOnlineSale: false
}

const formData = ref<FormInput>({
  isReleasedForRent: false,
  isReleasedForSale: false,
  isReleasedForOnlineSale: false,
})
watchEffect (() => {
  formData.value = {
    isReleasedForRent: machinery.value?.isReleasedForRent ?? false,
    isReleasedForSale: machinery.value?.isReleasedForSale ?? false,
    ...(machinery.value && machinery.value?.isReleasedForOnlineSale
      ? {
          isReleasedForOnlineSale: true,
          releasedForSaleOnExternalMarketplaces: machinery.value.releasedForSaleOnExternalMarketplaces.map(marketplace => marketplace.marketplaceId),
          endCustomerSellingPrice: machinery.value.endCustomerSellingPrice,
          dealerSellingPrice: machinery.value.dealerSellingPrice,
        }
      : {
          isReleasedForOnlineSale: false,
        }),
  }
})

function submitMachinery(value: FormInput) {
  if (!machinery.value) {
    return
  }

  const submittedValue: ApiMachineryGetById = {
    ...machinery.value,
    ...value,
    status: 'approval',
    releasedStateUpdatedAt: new Date(),
    releasedStateUpdatedByEmail: useremail.value,
    releasedForSaleOnExternalMarketplaces: value.isReleasedForOnlineSale
      ? value.releasedForSaleOnExternalMarketplaces.map(marketplaceId => (
        {
          marketplaceId,
          // this value is not setting anything, but we need it to satisfy ApiMachineryGetById
          exportHistory: [],
        }),
      )
      : [],
  }

  emit('save', submittedValue)
}
</script>

<template>
  <FormKit
    v-if="machinery && receptionMachinery"
    v-slot="{ value: formValue }"
    v-model="formData"
    type="form"
    :submit-label="submitLabel"
    :disabled="machinery.isDefective"
    @submit="submitMachinery"
  >
    <TheDevOnlyNiceJson :machinery :reception-machinery :form-value />
    <n-alert v-if="machinery.isDefective" title="Das Geräte ist zurzeit Defekt." type="error" class="mb-3">
      Bitte reparieren sie den Defekt oder schieben ihn auf.
    </n-alert>
    <n-alert
      v-if="typeof aggregatedPricingDecisionData.operatingHours !== 'number' && !machinery.isDefective && !machinery.soldAt"
      :title="$t('machinery.onlineSaleApproval.operatingHoursNotSet.infoPopup.title')"
      type="info"
      class="mb-3"
    >
      {{ $t('machinery.onlineSaleApproval.operatingHoursNotSet.infoPopup.text') }}
    </n-alert>
    <div v-if="!isDetailsPage" class="flex justify-between mb-1">
      <div>
        Alle erhobenen Parameter
      </div>
      <n-button quaternary @click="moveToDetailsPage">
        <template #icon>
          <Icon name="material-symbols:expand-content-rounded" />
        </template>
      </n-button>
    </div>
    <div v-if="!isDetailsPage" class="mb-4">
      <div v-if="receptionMachinery">
        <span v-if="differentFields.length === 0" class="text-blue-500">Geräteinformationen von Anlage und Aufnahme stimmen überein</span>
        <ul v-else class="px-3 list-disc text-red-500">
          <li v-for="field in differentFields" :key="field">
            <span v-if="field === 'serialNumber'">
              Serien Nr. (Anlage): {{ machinery[field] ?? 'N/A' }} - Fahrgestell Nr. (Aufnahme): {{ receptionMachinery.chassisNumber ?? 'N/A' }}
            </span>
            <span v-else>
              {{ machineryFieldsToCompareLabels[field] }} (Anlage): {{ machinery[field] ?? 'N/A' }} - {{ machineryFieldsToCompareLabels[field] }} (Aufnahme): {{ receptionMachinery[field] ?? 'N/A' }}
            </span>
          </li>
        </ul>
      </div>
      <span v-else-if="isLoadingReceptionMachinery">Lade Abgleich von Anlage und Aufnahme Geräteinformationen...</span>
      <span v-else class="text-red-500">Keine Geräteinformationen von der Aufnahme gefunden</span>
    </div>
    <div class="flex flex-col gap-2 mb-2">
      <div class="flex flex-col">
        <FormKit
          name="isReleasedForRent"
          type="checkbox"
          :label="$t('machinery.isReleasedForRentCheckbox.label')"
        />
        <FormKit
          name="isReleasedForSale"
          type="checkbox"
          :label="$t('machinery.isReleasedForSaleCheckbox.label')"
        />
        <OnlineSalesReleaseMachineryFormInput :value="formValue as FormInput" :marketplace-options="externalMarketplaceOptions ?? []" :aggregated-pricing-decision-data :sold-at="machinery.soldAt" />
      </div>
      <n-button
        @click="openReleaseMachineryForFreelancerSalesPopup.open({ mode: 'create', machineryIds: [machinery.id] })"
      >
        Freelancer Freigabe verwalten
      </n-button>
    </div>
  </FormKit>
</template>
