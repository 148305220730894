<script setup lang="ts">
import type { ApiCalendarMachinery, MachineryStatus } from '~/types'
import { machineryStatusToGerman } from '~/translations'

defineProps<{ machinery: ApiCalendarMachinery }>()

const columns: OneDataColumn<ApiCalendarMachinery>[] = [
  {
    title: 'Status',
    key: 'status',
    render: ({ status, isDefective }) => isDefective ? 'Defekt' : machineryStatusToGerman[status as MachineryStatus],
    isVisible: ({ isDefective }) => isDefective,
  },
  {
    title: 'Defektstartdatum',
    key: 'defects',
    render: ({ defects }) => {
      const startedAt = defects.sort((a, b) => sortNullishDates(a.createdAt, b.createdAt))[0]?.createdAt
      return startedAt ? useDateAsString(startedAt) : ' '
    },
    isVisible: ({ isDefective }) => isDefective,
  },
  {
    title: 'Freigabe für Vermietung',
    key: 'isReleasedForRent',
    render: ({ isReleasedForRent }) => isReleasedForRent ? 'Ja' : 'Nein',
    isVisible: ({ isReleasedForRent }) => !isReleasedForRent,

  },
]
</script>

<template>
  <n-popover trigger="hover">
    <template #trigger>
      <slot />
    </template>
    <TableOneDataColumn :config="{ columns, data: machinery }" />
    <span v-if="machinery.isDefective" class="text-blue-400 pt-2">
      <Icon name="material-symbols:edit-outline-rounded" />
      <nuxt-link :to="`/service/repair-machinery/${machinery.id}`">
        Servicebericht anzeigen
      </nuxt-link>
    </span>
    <span v-if="!machinery.isReleasedForRent" class="text-blue-400 pt-2">
      <Icon name="material-symbols:edit-outline-rounded" />
      <nuxt-link :to="`/machinery/${machinery.id}`">
        Gerätedetailseite aufrufen
      </nuxt-link>
    </span>
  </n-popover>
</template>
