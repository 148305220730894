<script setup lang="ts">
import type { ApiLogisticsTaskGetById } from '~/types'

const props = defineProps<{
  logisticsTask: ApiLogisticsTaskGetById
  viewOnly?: boolean
}>()

const emits = defineEmits<{ (e: 'save', payload: ApiLogisticsTaskGetById): void }>()

const { cloned } = useMightyCloned(props.logisticsTask)

function submit() {
  emits('save', cloned.value)
}

const formkitPlugins = useFormkitPlugins()
</script>

<template>
  <template v-if="viewOnly">
    <div
      v-for="position of logisticsTask.positionsToShip"
      :key="position.id"
    >
      <div class="flex items-center gap-2">
        <Icon v-if="position.isCollected" name="material-symbols:check-box-outline-rounded" class="text-green-500" />
        <Icon v-else name="material-symbols:check-box-outline-blank" />
        <span>{{ useMakeLabelForPosition(position) }}</span>
      </div>
    </div>
  </template>

  <FormKit
    v-else
    type="form"
    :plugins="[formkitPlugins]"
    submit-label="Speichern"
    @submit="submit"
  >
    <FormKit
      v-model="cloned.positionsToShip"
      type="list"
    >
      <FormKit
        v-for="(position, index) of cloned.positionsToShip"
        :key="index"
        type="group"
      >
        <FormKit
          id="isCollected"
          type="checkbox"
          :label="useMakeLabelForPosition(position)"
        />
      </FormKit>
    </FormKit>
  </FormKit>
</template>
