<script lang="ts" setup>
import type { ApiLogisticsTaskGetByIdPositionsToShip } from '~/types'

const props = defineProps<{
  isOutbound: boolean
  position: ApiLogisticsTaskGetByIdPositionsToShip | ApiLogisticsTaskGetByIdPositionsToShip['itemSetMember'][number]
  itemSetId?: string
  isPositionCollected: boolean
  isIssuanceDisabled?: boolean
  showDeleteButton?: boolean
}>()

const emits = defineEmits<{
  (e: 'openIssuancePopup', type: 'scanQR' | 'typeId'): void
  (e: 'typeId'): void
  (e: 'updateIsIssued'): void
  (e: 'updateIsReturned'): void
  (e: 'deletePosition'): void
}>()

const isSpecialPosition = props.position.type === 'special'

const { openIssuanceUpdateNotReturnablePopup, openSetCreateOrUpdatePopup } = useGlobalOpeners()
</script>

<template>
  <div>
    <n-checkbox
      v-if="isOutbound"
      :checked="position.isIssued"
      :disabled="!isSpecialPosition && !position.isIssued"
      @update:checked="emits('updateIsIssued')"
    >
      Ausgegeben
    </n-checkbox>
    <n-checkbox
      v-else
      :checked="position.isReturned"
      :disabled="(!isSpecialPosition && !position.isReturned) || Boolean(position.isNotReturnable)"
      @update:checked="emits('updateIsReturned')"
    >
      Zurückgegeben
    </n-checkbox>
  </div>

  <div v-if="!isOutbound && !isPositionCollected" class="text-xs text-zinc-500">
    Position kann erst nach Abholung zurückgegeben werden
  </div>
  <div v-else>
    <div class="flex flex-wrap gap-x-3 gap-y-2 mb-2">
      <n-button
        v-if="!isSpecialPosition"
        class="w-60"
        :disabled="Boolean(isOutbound ? position.isIssued : position.isReturned) || isIssuanceDisabled"
        @click="emits('openIssuancePopup', 'scanQR')"
      >
        Gerät scannen
      </n-button>

      <n-button
        v-if="!isSpecialPosition"
        class="w-40"
        :disabled="Boolean(isOutbound ? position.isIssued : position.isReturned) || isIssuanceDisabled"
        @click="emits('openIssuancePopup', 'typeId')"
      >
        Manuell Id eingeben
      </n-button>

      <n-button
        v-if="isOutbound && itemSetId"
        :disabled="position.isIssued"
        @click="openSetCreateOrUpdatePopup.open({ mode: 'update', id: itemSetId })"
      >
        Set anpassen
      </n-button>

      <n-button
        v-if="showDeleteButton"
        :disabled="position.isLoaded"
        @click="emits('deletePosition')"
      >
        <template #icon>
          <Icon name="material-symbols:delete-outline-rounded" />
        </template>
      </n-button>
    </div>
    <n-button
      v-if="!isOutbound"
      size="small"
      class="w-[300px] text-xs"
      :disabled="(position.isReturned && !position.isNotReturnable) || position.type === 'itemSet'"
      @click="openIssuanceUpdateNotReturnablePopup.open({ id: position.id })"
    >
      {{ `Als ${position.isNotReturnable ? '' : 'nicht '}rücknahmefähig kennzeichnen` }}
    </n-button>
  </div>
</template>
