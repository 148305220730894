<script setup lang="ts">
import type { ApiMachineryAccessoryGetById, FilePath } from '~/types'

interface UVVData {
  id: string
  lastUVVDate: Date | null
  documentUVVFiles: FilePath[]
}

defineProps<{ payload: ApiMachineryAccessoryGetById }>()
const emit = defineEmits<{ (e: 'save', data: UVVData): void }>()

function submitUVVForm(data: UVVData) {
  emit('save', data)
}
</script>

<template>
  <FormKit
    type="form"
    :value="payload"
    submit-label="Speichern"
    @submit="submitUVVForm"
  >
    <FormKitDate
      name="lastUVVDate"
      label="Letztes UVV-Datum"
    />
    <FormKitFileDropzone wrap-path-in-object name="documentUVVFiles" label="Dokumente" />
  </FormKit>
</template>
