<script setup lang="ts">
import 'viewerjs/dist/viewer.css'
import * as viewer from 'v-viewer'

import type { Size } from './common'

import FilePreview from './Preview.vue'
import type { UploadedFile } from '~/types'

withDefaults(defineProps<{
  allowDelete?: boolean
  files: UploadedFile[]
  size?: Size
}>(), {
  allowDelete: false,
  size: 'Normal',
})

const emit = defineEmits<{
  (e: 'delete', src: string): void
}>()

const isAuthenticated = computed(() => useAuth().status.value === 'authenticated')

const vViewer = viewer.directive({
  defaultOptions: {
    fullscreen: false,
  },
})

const pdfSource = ref<string | null>(null)
const videoSource = ref<string | null>(null)
</script>

<template>
  <FileVideoPlayerPopup
    v-if="videoSource"
    :src="videoSource"
    :disable-cookie-conset="isAuthenticated"
    @close="videoSource = null"
  />
  <LazyFilePdfEmbedPopup
    v-if="pdfSource"
    :source="pdfSource"
    @close="pdfSource = null"
  />

  <div v-viewer class="images clearfix">
    <div class="flex flex-wrap gap-1">
      <FilePreview
        v-for="file in files"
        :key="file.filekey"
        :allow-delete="allowDelete"
        :file="file"
        :size="size"
        :disable-cookie-conset="isAuthenticated"
        @show-pdf="pdfSource = $event"
        @show-video="videoSource = $event"
        @delete="emit('delete', $event)"
      />
    </div>
  </div>
</template>
