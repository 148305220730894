<script setup lang="ts">
import type { ApiCalendarMachineryAccessory, MachineryAccessoryCategory } from '~/types'
import { machineryAccessoryCategoryToGerman } from '~/translations'

defineProps<{ machineryAccessory: ApiCalendarMachineryAccessory }>()

const columns: OneDataColumn<ApiCalendarMachineryAccessory>[] = [
  {
    title: 'Category',
    key: 'category',
    render: ({ category }) => machineryAccessoryCategoryToGerman[category as MachineryAccessoryCategory],
  },
  {
    title: 'Baujahr',
    key: 'yearBuilt',
  },
]

const { openPositionsDetailsPopup } = useGlobalOpeners()
</script>

<template>
  <n-popover trigger="hover">
    <template #trigger>
      <slot />
    </template>
    <TableOneDataColumn :config="{ columns, data: machineryAccessory }" />
    <div class="pt-2">
      <n-button @click="openPositionsDetailsPopup.open({ id: machineryAccessory.id, type: 'machineryAccessory' })">
        <template #icon>
          <Icon name="material-symbols:edit-outline-rounded" />
        </template>
        Detailseite aufrufen
      </n-button>
    </div>
  </n-popover>
</template>
