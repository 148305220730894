<script lang="ts" setup>
import { startOfDay, startOfToday } from 'date-fns'
import type { CreateInternalReservation } from '~/types'

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const { openInternalReservationCreateOrUpdatePopup: { data: popupData, close: closePopup } } = useGlobalOpeners()
const id = computed(() => popupData.value && popupData.value.mode === 'update'
  ? popupData.value.id
  : undefined)

const { internalReservation: internalReservationQuery } = useQuery()
const { data: internalReservation, isLoading: isLoadingInternalReservation, isFetching: isFetchingInternalReservation } = internalReservationQuery.byId(id)

const update = useMutation({
  mutationFn: $trpc.internalReservation.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die interne Reservierung konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['internalReservations'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
    ])
    closePopup()
    notification.success({ title: 'Die interne Reservierung wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const create = useMutation({
  mutationFn: $trpc.internalReservation.create.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die interne Reservierung konnte nicht erstellt werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['internalReservations'] })
    queryClient.invalidateQueries({ queryKey: ['offer'] })
    closePopup()
    notification.success({ title: 'Die interne Reservierung wurde erfolgreich erstellt', duration: 4500 })
  },
})

function saveCreateOrEdit(payload: CreateInternalReservation) {
  if (!popupData.value) {
    return
  }

  if (popupData.value.mode === 'create') {
    create.mutate(payload)
  } else {
    update.mutate({
      ...payload,
      id: popupData.value.id,
    })
  }
}

const modeInGerman = computed(() => {
  if (!popupData.value) {
    return ''
  }
  return popupData.value.mode === 'create' ? 'erstellen' : 'bearbeiten'
})

const title = computed(() => {
  const titleComponents = ['Interne Reservierung']
  if (popupData.value && popupData.value.mode === 'update') {
    titleComponents.push(popupData.value.id)
  }
  titleComponents.push(modeInGerman.value)

  return titleComponents.join(' ')
})

const show = computed(() => {
  if (!popupData.value || !internalReservationForPrefilling.value) {
    return false
  }
  if (popupData.value.mode === 'update') {
    return !!internalReservation.value
  }
  return true
})

const internalReservationForPrefilling: Ref<CreateInternalReservation | undefined> = ref()

watch([popupData, internalReservation], ([newPopupData, newInternalReservation]) => {
  if (!newPopupData || internalReservationForPrefilling.value) {
    return
  }
  const today = startOfToday()
  if (newPopupData.mode === 'create') {
    internalReservationForPrefilling.value = {
      machineryId: newPopupData.machineryId,
      reservationStartsAt: today,
      reservationEndsAt: addDaysDstSafe(today, 1),
    }
  } else if (newInternalReservation && !isFetchingInternalReservation.value) {
    internalReservationForPrefilling.value = { ...newInternalReservation }
  }
}, { immediate: true })

function getDateValidation(validationField: 'date_before' | 'date_after', dateToCompare?: Date) {
  if (!dateToCompare) {
    return ''
  }
  const stringDateToCompare = useDateAsString(dateToCompare, 'yyyy-MM-dd')
  return `|${validationField}:${stringDateToCompare}`
}

const reservationStartsAtValidation = computed(() => {
  const validation = 'required'
  if (!internalReservationForPrefilling.value?.reservationEndsAt) {
    return validation
  }
  return `${validation}|${getDateValidation('date_before', addDaysDstSafe(internalReservationForPrefilling.value.reservationEndsAt, 1))}`
})

const reservationEndsAtValidation = computed(() => {
  const validation = 'required'
  if (!internalReservationForPrefilling.value?.reservationStartsAt) {
    return validation
  }
  return `${validation}|${getDateValidation('date_after', addDaysDstSafe(internalReservationForPrefilling.value.reservationStartsAt, -1))}`
})
</script>

<template>
  <ThePopup
    v-if="show"
    :is-loading="isLoadingInternalReservation"
    :show="show"
    :title="title"
    @close="closePopup"
  >
    <FormKit
      v-model="internalReservationForPrefilling"
      type="form"
      :submit-label="`Interne Reservierung ${modeInGerman}`"
      @submit="saveCreateOrEdit"
    >
      <TheDevOnlyNiceJson :value="internalReservationForPrefilling" />
      <FormKit
        type="text"
        name="machineryId"
        label="Geräte-ID"
        :disabled="true"
      />

      <FormKitDate
        name="reservationStartsAt"
        label="Startdatum der Reservierung"
        :transform-date="startOfDay"
        validation-visibility="live"
        :validation="reservationStartsAtValidation"
        :validation-messages="{ date_before: 'Startdatum kann nicht später als Enddatum sein.' }"
      />
      <FormKitDate
        name="reservationEndsAt"
        label="Enddatum der Reservierung"
        :transform-date="startOfDay"
        validation-visibility="live"
        :validation="reservationEndsAtValidation"
        :validation-messages="{ date_after: 'Enddatum kann nicht vor dem Startdatum liegen.' }"
      />
      <FormKit
        type="text"
        name="reservationDescription"
        label="Beschreibung"
      />
    </FormKit>
  </ThePopup>
</template>
