<script setup lang="ts">
import { startOfDay } from 'date-fns'
import type { ApiOfferGetAll } from '~/types'

const props = defineProps<{ offer: ApiOfferGetAll, show: boolean }>()
const emit = defineEmits<{ (e: 'close'): void }>()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const isAttachedAccessoryHidden = computed(() => props.offer.positions.some(position => position.isAttachedAccessoryHidden))

const update = useMutation({
  mutationFn: $trpc.offer.markAsConfirmed.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Projekt konnte nicht abgeschlossen werden' }),
  onSuccess: async (offer) => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['offer', offer.id ?? null] }),
    ])

    emit('close')
    notification.success({ title: 'Projekt wurde erfolgreich abgeschlossen', duration: 4500 })
  },
})

const deliveryAtValidationString = computed(() => {
  const validationSegments = ['required']

  const startOfObligationStartsAtDay = startOfDay(props.offer.obligationStartsAt)
  const oneDayAfterObligationStartsAt = addDaysDstSafe(startOfObligationStartsAtDay, 1)
  const obligationStartsAtPlusOneDay = useDateAsString(oneDayAfterObligationStartsAt, 'yyyy-MM-dd\'T\'HH:mm')
  validationSegments.push(`date_before:${obligationStartsAtPlusOneDay}`)
  return validationSegments.join('|')
})
</script>

<template>
  <ThePopup v-if="show" :show="show" :title="`Auftrag ${offer.id} für Lieferung freigeben`" @close="emit('close')">
    <n-alert v-if="isAttachedAccessoryHidden" type="error" :show-icon="false" class="mb-2">
      {{ $t('offer.position.isAttachedAccessoryHidden') }}
    </n-alert>
    <FormKit
      v-slot="{ value }"
      type="form"
      submit-label="Speichern"
      :disabled="isAttachedAccessoryHidden"
      @submit="update.mutate"
    >
      <FormKit
        name="id"
        :value="offer.id"
        type="text"
        label="Verkaufs-ID"
        validation="required"
        :disabled="true"
      />
      <FormKitDateTime
        name="deliveryAt"
        :value="useDateAsString(offer.deliveryAt ?? new Date(), 'yyyy-MM-dd\'T\'HH:mm')"
        label="geplantes Anlieferdatum und Zeit"
        :validation="deliveryAtValidationString"
        validation-visibility="live"
        :validation-messages="{
          date_before: 'Anlieferdatum kann nicht später als das geplante Abwicklungsdatum sein.',
        }"
      />
      <FormKit
        name="doesCustomerDoTask"
        :value="offer.doesCustomerDoTask"
        label="Der Kunde holt die Ware selbst ab"
        type="checkbox"
      />
      <FormKit
        v-if="value?.doesCustomerDoTask"
        name="customerLicensePlate"
        type="text"
        label="Kunden Kfz-Kennzeichen"
      />
      <TheDevOnlyNiceJson :value="value" />
    </FormKit>
  </ThePopup>
</template>
