import { defu } from 'defu'
import type { ApexOptions } from 'apexcharts'

export interface ReportingChartConfiguration {
  id?: string
  type?: Exclude<ApexOptions['chart'], undefined>['type']
  series: ApexAxisChartSeries
  options?: ApexOptions
  enrichOptionsWith?: EnrichOptions
  additionalData?: Record<string, any>
}

export interface EnrichOptions {
  addXAxisDateFormatting?: boolean
  addYAxisFixesDecimalPointFormatting?: boolean
  showYAxisAsEuro?: boolean
}

function addXAxisDateFormatting(config: ApexOptions) {
  const formattingConfig: ApexOptions = {
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
    },
  }

  return defu(formattingConfig, config)
}

function addYAxisFixesDecimalPointFormatting(config: ApexOptions, decimalPoints: number = 0) {
  const formattingConfig: ApexOptions = {
    yaxis: {
      labels: {
        formatter: (value: number) => value?.toFixed(decimalPoints),
      },
    },
  }

  return defu(formattingConfig, config)
}

function showYAxisAsEuro(config: ApexOptions) {
  const formattingConfig: ApexOptions = {
    yaxis: {
      labels: {
        formatter: (value: number) => useDisplayNumberAsCurrency(value ?? 0),
      },
    },
  }

  return defu(formattingConfig, config)
}

/**
 * Enrich the passed apex options with non-serializable extra configuration.
 *
 * This is useful to add further configuration to chart-options that the backend sends which cannot be de-/serialized (such as functions)
 */
export function enrichApexOptions(options: ApexOptions, enrichWith: EnrichOptions) {
  let enrichedOptions = options

  if (enrichWith.addXAxisDateFormatting) {
    enrichedOptions = addXAxisDateFormatting(enrichedOptions)
  }

  if (enrichWith.addYAxisFixesDecimalPointFormatting) {
    enrichedOptions = addYAxisFixesDecimalPointFormatting(enrichedOptions)
  }

  if (enrichWith.showYAxisAsEuro) {
    enrichedOptions = showYAxisAsEuro(enrichedOptions)
  }

  return enrichedOptions
}
