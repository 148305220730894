<script setup lang="ts">
import type { ApiLogisticsTaskGetById } from '~/types'

const props = defineProps<{ logisticsTask: ApiLogisticsTaskGetById }>()

const isEditingDisabled = computed(() => props.logisticsTask.status !== 'collected')

const hasPositionTerminated = computed(() => {
  return props.logisticsTask.offer?.obligationActuallyEndedAt
    || props.logisticsTask.positionsToShip.some((position) => {
      return position.terminatedDate
    })
})

const { openDriverCollectionChecklistPopup } = useGlobalOpeners()
</script>

<template>
  <TheDataCard>
    <template #header>
      <div class="CardHeader">
        <h2>Abholungs-Checklist</h2>
        <n-button v-if="hasPositionTerminated && !isEditingDisabled" @click="openDriverCollectionChecklistPopup.open({ id: logisticsTask?.id ?? '' })">
          <template #icon>
            <Icon name="material-symbols:edit-outline-rounded" />
          </template>
          Ändern
        </n-button>
      </div>
    </template>

    <template v-if="hasPositionTerminated">
      <div v-if="logisticsTask.status === 'delivered'" class="flex justify-between">
        <span>Leer</span>
        <n-button @click="openDriverCollectionChecklistPopup.open({ id: logisticsTask?.id ?? '' })">
          Die Checkliste ausfüllen
        </n-button>
      </div>
      <DriverCollectionChecklistForm v-else :logistics-task="logisticsTask" :view-only="true" />
    </template>
    <div v-else class="text-center mt-5">
      Dieser Auftrag wurde noch nicht abgemeldet.
    </div>
  </TheDataCard>
</template>

<style scoped>
.CardHeader {
  @apply  w-full flex justify-between mb-5
}
.CardHeader h2 {
  @apply text-lg font-semibold
}
</style>
