<script setup lang="ts">
import currency from 'currency.js'

const props = defineProps<{
  quantity: number
  discountRate: number
}>()

const emits = defineEmits<{
  updateDiscountRate: [value: number]
}>()

const precision = { precision: 4 }

const partialQuantity = ref<number | undefined>(currency(props.quantity).multiply(currency(1, precision).subtract(props.discountRate)).value)
const showPopover = ref(false)
const popoverRef = ref<HTMLElement | null>()

function handleOutsideClick(e: MouseEvent) {
  if (popoverRef.value?.contains(e.target as Node)) {
    return
  }

  showPopover.value = false
}

function setPercentageInput() {
  const partialQuantityToQuantityRatio = currency(partialQuantity.value ?? props.quantity, precision).divide(props.quantity)
  const discountRate = currency(1, precision).subtract(partialQuantityToQuantityRatio).value

  emits('updateDiscountRate', discountRate)

  showPopover.value = false
}
</script>

<template>
  <span ref="popoverRef">
    <n-popover v-if="quantity" :show="showPopover" trigger="manual" @clickoutside="handleOutsideClick">
      <template #trigger>
        <Icon name="material-symbols:electric-bolt-outline-rounded" class="underline decoration-dotted hover:cursor-pointer" @click="showPopover = !showPopover" />
      </template>

      <FormKit type="form" submit-label="Anpassen" @submit="setPercentageInput">
        <FormKit
          v-model="partialQuantity"
          type="number"
          number
          min="0"
          :max="quantity"
          step="0.01"
          label="Teilmenge"
        />
      </Formkit>
    </n-popover>
  </span>
</template>
