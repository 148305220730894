import { de } from 'date-fns/locale'
import { formatInTimeZone } from 'date-fns-tz/formatInTimeZone'

const DEFAULT_DATE_FORMAT = 'dd.MM.yy HH:mm'
export default (date: string | number | Date, format_?: string) => formatInTimeZone(
  date,
  'Europe/Berlin',
  format_ || DEFAULT_DATE_FORMAT,
  { locale: de },
)
