<script setup lang="ts">
import type { ShoppingTab } from '~/components/Calendar/Page.vue'
import type { MachineryAccessoryCategory, OfferStatus, ShoppingCartPosition } from '~/types'
import { machineryAccessoryCategoryToGerman } from '~/translations'

const props = defineProps<{
  payload: {
    machineryAccessoryId: string
    machineryAccessoryCategory: MachineryAccessoryCategory
    selectedCompatiblePositions: ShoppingCartPosition[]
  }
  selectedAccessoryIdsWithoutCompatibleAccessoryId: string[]
  selectedMachineryId: string | undefined
  selectedMachineryCategory: string | undefined
  offerStartDay: Date
  offerEndDay?: Date
  offer: { status: OfferStatus, id?: string }
  disabledAccessoryIds: string[]
  isAccessoryOnlyOffer: boolean
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'updateCompatibleMachineryAccessories', selectedAccessoryPositions: ShoppingCartPosition[], compatibleMachineryAccessoryId: string): void
}>()

const { openPositionsDetailsPopup } = useGlobalOpeners()

const selectedCompatiblePositions = ref<ShoppingCartPosition[]>(props.payload.selectedCompatiblePositions)

function removeCompatiblePosition(index: number) {
  selectedCompatiblePositions.value.splice(index, 1)
}

const selectedTab = ref<ShoppingTab>('incompatible')
const selectedMachineryAccessoryCategory = ref<MachineryAccessoryCategory | null>(props.payload.machineryAccessoryCategory)

const { machineryAccessory } = useQuery()

const machineryAccessoryWhere = computed(() => {
  const allMachineryAccessoryIdsInCart = selectedCompatiblePositions.value
    .filter(p => p.type === 'machineryAccessory')
    .map(p => p.machineryAccessoryId)

  return {
    id: {
      in: allMachineryAccessoryIdsInCart,
    },
  }
})

const { data: detailsForMachineryAccessoriesInCart } = machineryAccessory.all(machineryAccessoryWhere)

const disabledAccessoryIds = props.disabledAccessoryIds.concat(props.selectedAccessoryIdsWithoutCompatibleAccessoryId)
</script>

<template>
  <ThePopup show width="1500px" @close="emit('close')">
    <div class="flex gap-4 mb-4">
      <TheDataCard :title="`Kompatible Lagertools für ${machineryAccessoryCategoryToGerman[props.payload.machineryAccessoryCategory]} ${payload.machineryAccessoryId} hinzufügen`" class="w-2/3">
        <ShoppingAccessoryOverview
          v-model="selectedCompatiblePositions"
          :show-attachable-tab="selectedMachineryCategory === 'forklift'"
          :machinery-id="selectedMachineryId"
          :offer-start-day="props.offerStartDay"
          :offer-end-day="props.offerEndDay"
          :selected-tab="selectedTab"
          :selected-machinery-accessory-category="selectedMachineryAccessoryCategory"
          :is-fork-edit-mode="false"
          :is-accessory-only-offer="isAccessoryOnlyOffer"
          :offer="offer"
          :disabled-accessory-ids="disabledAccessoryIds"
          :compatible-accessory-id="payload.machineryAccessoryId"
          @select-tab="tab => selectedTab = tab"
          @select-machinery-accessory-category="category => selectedMachineryAccessoryCategory = category"
        />
      </TheDataCard>

      <TheDataCard title="Ausgewählte Lagertools">
        <div v-if="!selectedCompatiblePositions.length">
          Keine Lagertools ausgewählt
        </div>
        <template v-else>
          <div v-for="(accessory, index) in selectedCompatiblePositions" :key="index">
            <div>
              <template v-if="accessory.type === 'machineryAccessory'">
                {{ index + 1 }}. {{ machineryAccessoryCategoryToGerman[detailsForMachineryAccessoriesInCart?.find(a => a.id === accessory.machineryAccessoryId)?.category as MachineryAccessoryCategory] ?? 'Lädt...' }}
                <span
                  class="text-blue-400 underline hover:cursor-pointer"
                  @click="openPositionsDetailsPopup.open({ type: 'machineryAccessory', id: accessory.machineryAccessoryId })"
                >({{ accessory.machineryAccessoryId }})</span>
              </template>

              <template v-else>
                {{ index + 1 }}. {{ machineryAccessoryCategoryToGerman[accessory.machineryAccessoryCategory] }}
              </template>

              <span
                class="ml-2 underline hover:cursor-pointer"
                @click="removeCompatiblePosition(index)"
              >(Löschen)</span>
            </div>
          </div>
        </template>
      </TheDataCard>
    </div>

    <n-button
      type="primary"
      @click="emit('updateCompatibleMachineryAccessories', selectedCompatiblePositions, payload.machineryAccessoryId)"
    >
      Speichern
    </n-button>
    <n-button
      type="primary"
      class="ml-2"
      @click="emit('close')"
    >
      Abbrechen
    </n-button>
  </ThePopup>
</template>
