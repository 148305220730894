import { add, differenceInCalendarDays } from 'date-fns'

/**
 * Calculate the duration of a rental, given its `obligationStartsAt` and `obligationEndsAt` dates.
 */
export default (interval: { obligationStartsAt: Date, obligationEndsAt: Date | null }): number | undefined => {
  const { obligationStartsAt, obligationEndsAt } = interval

  return obligationEndsAt ? differenceInCalendarDays(add(obligationEndsAt, { days: 1 }), obligationStartsAt) : undefined
}
