<script lang="ts" setup>
import ForkliftInternational from 'assets/icons/externalSalesMarketplaces/Forklift.webp'
import MachineryZone from 'assets/icons/externalSalesMarketplaces/MachineryZone.webp'
import Maschinensucher from 'assets/icons/externalSalesMarketplaces/Maschinensucher.webp'
import Mascus from 'assets/icons/externalSalesMarketplaces/Mascus.webp'
import Supralift from 'assets/icons/externalSalesMarketplaces/Supralift.webp'

import type { ExternalSalesMarketplaceId } from '~/prisma/enums'

defineProps<{
  marketplaceId: ExternalSalesMarketplaceId
}>()

const icons: { [id in ExternalSalesMarketplaceId]: string } = {
  ForkliftInternational,
  MachineryZone,
  Maschinensucher,
  Mascus,
  Supralift,
}
</script>

<template>
  <img :src="icons[marketplaceId]" class="h-6 aspect-square object-contain rounded" v-bind="$attrs">
</template>
