<script lang="ts" setup>
import type { ApiReceptionMachineryGetById, MachineryCategories, MachineryDrives } from '~/types'

const props = defineProps<{
  receptionMachinery: ApiReceptionMachineryGetById
}>()

const { receptionMachineryVehicle, receptionMachineryPlatform, receptionMachineryMast, receptionMachineryDimension, receptionMachineryTires, receptionMachineryMotor, receptionMachineryBattery, receptionMachinerySteeringType, receptionMachinerySpecialEquipment, receptionMachineryExtraInfo } = useOneDataColumnConfigs()
const { machineryHeadingsByMachineCategory: showMachineryHeadingsByMachineCategory, machineryFieldsByMachineCategory: showMachineryFieldsByMachineCategory } = useFormOptionalConditions()

const category = computed(() => props.receptionMachinery.category as MachineryCategories)
const drive = computed(() => props.receptionMachinery.drive?.name as MachineryDrives || undefined)
</script>

<template>
  <div class="space-y-4">
    <div>
      <h2 class="Heading">
        Fahrzeugdaten
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachineryVehicle }" />
    </div>
    <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'platform', category)">
      <h2 class="Heading">
        Plattform
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachineryPlatform }" />
    </div>
    <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'mastdaten', category)">
      <h2 class="Heading">
        Mastdaten / Maße
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachineryMast }" />
    </div>
    <div>
      <h2 class="Heading">
        Sonderausstattung
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachinerySpecialEquipment }" />
    </div>
    <div>
      <h2 class="Heading">
        Abmessung
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachineryDimension }" />
    </div>
    <div>
      <h2 class="Heading">
        Bereifung
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachineryTires }" />
    </div>
    <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'antrieb', category)">
      <h2 class="Heading">
        Antrieb
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachineryMotor }" />
    </div>
    <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'batteriedaten', category)">
      <h2 class="Heading">
        Batteriedaten
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachineryBattery }" />
    </div>
    <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'lenkung', category)">
      <h2 class="Heading">
        Lenkung
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachinerySteeringType }" />
    </div>
    <div>
      <h2 class="Heading">
        Weitere Infos
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachineryExtraInfo }" />
    </div>
    <div>
      <h2 class="Heading">
        Fotos
      </h2>
      <FileView
        label="Grunddaten"
        :files="receptionMachinery.basicDataPhotos"
        collapsed-by-default
      />
      <FileView
        label="Allgemeine Fotos"
        :files="receptionMachinery.generalPhotos"
        collapsed-by-default
      />

      <FileView
        v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'cabinInsidePhotos', category)"
        label="Kabinen Fotos - innen"
        :files="receptionMachinery.cabinInsidePhotos"
        collapsed-by-default
      />

      <FileView
        v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'cabinOutsidePhotos', category)"
        label="Kabinen Fotos - außen"
        :files="receptionMachinery.cabinOutsidePhotos"
        collapsed-by-default
      />

      <FileView
        v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'tiresPhotos', category)"
        label="Reifen Fotos"
        :files="receptionMachinery.tire?.photos ?? []"
        collapsed-by-default
      />

      <FileView
        v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'batteryPhotos', category, drive)"
        label="Batterie / Ladegerät Fotos"
        :files="receptionMachinery.battery?.photos ?? []"
        collapsed-by-default
      />

      <FileView
        v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'motorPhotos', category, drive)"
        label="Motor Fotos"
        :files="receptionMachinery.motor?.photos ?? []"
        collapsed-by-default
      />

      <FileView
        label="Videos"
        :files="receptionMachinery.videos"
        collapsed-by-default
      />
    </div>
  </div>
</template>

<style scoped>
.Heading {
  @apply text-lg font-semibold pb-1;
}
</style>
