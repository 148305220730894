/* eslint-disable ts/method-signature-style */
import { z } from 'zod'

/**
 * Force an operation like `{ a: 0 } & { b: 1 }` to be computed so that it displays `{ a: 0; b: 1 }`.
 *
 * Also works for some non-intersections, e.g. `keyof SomeObj` => `"a" | "b" | ...`
 */
export type show<t> = { [k in keyof t]: t[k] } & unknown

export type merge<base, merged> = show<Omit<base, keyof merged> & merged>

type optionalShapeKeyOf<schema extends z.ZodRawShape> = { [k in keyof schema]: schema[k] extends z.ZodOptional<z.ZodTypeAny> ?
  k : never
}[keyof schema]

type requiredShapeKeyOf<schema extends z.ZodRawShape> = Exclude<keyof schema, optionalShapeKeyOf<schema>>

type inferZodShapeOut<schema extends z.ZodRawShape> = show<{
  [k in requiredShapeKeyOf<schema>]: z.infer<schema[k]>;
} & { [k in optionalShapeKeyOf<schema>]?: z.infer<schema[k]> }>

type inferZodShapeIn<schema extends z.ZodRawShape> = show<{
  [k in requiredShapeKeyOf<schema>]: z.input<schema[k]>;
} & { [k in optionalShapeKeyOf<schema>]?: z.input<schema[k]> }>

export function zObject<shape extends z.AnyZodObject>(
  schema: shape,
): ZObject<z.infer<shape>, z.input<shape>>
export function zObject<shape extends z.ZodRawShape>(
  schema: shape,
): ZObject<
  inferZodShapeOut<shape>,
  inferZodShapeIn<shape>
>
/**
 * Create an alternative, optimized type definition for Zod objects that maps
 * over inferred In/Out types directly.
 *
 * Chained methods parallel those available on the builtin ZodObject and return
 * ZObject instances, allowing familiar chaining patterns for Zod schemas.
 */
export function zObject(shape: z.AnyZodObject | z.ZodRawShape): object {
  return shape instanceof z.ZodObject ? shape : z.object(shape)
}

export type AnyZObject = ZObject<any, any>

/**
 * An alternative, optimized type definition for Zod objects that maps over
 * inferred In/Out types directly.
 *
 * The methods defined here should mirror those defined on the builtin
 * ZodObject, but instead return a new ZObject instance to ensure the type
 * optimizations can propagate.
 */

export interface ZObject<Out extends object = any, In extends object = Out>
  extends z.ZodType<Out, z.ZodAnyDef, In> {
  infer: Out
  inferIn: In
  get shape(): {
    [k in keyof Out]: z.ZodType<Out[k], z.ZodAnyDef, In[k & keyof In]>;
  }

  pick<keys extends keyof Out>(
    keys: {
      [k in keys]: true;
    },
  ): ZObject<Pick<Out, keys>, Pick<In, keys & keyof In>>

  omit<keys extends keyof Out>(
    keys: {
      [k in keys]: true;
    },
  ): ZObject<Omit<Out, keys>, Omit<In, keys>>

  partial<keys extends keyof Out>(
    keys: {
      [k in keys]: true;
    },
  ): ZObject<
    show<
      Omit<Out, keys> & {
        [k in keys]?: Out[k];
      }
    >,
    show<
      Omit<Out, keys> & {
        [k in keys]?: Out[k];
      }
    >
  >
  partial(): ZObject<Partial<Out>, Partial<In>>

  required<keys extends keyof Out>(
    keys: {
      [k in keys]: true;
    },
  ): ZObject<
    show<
      {
        [k in keys]-?: Out[k];
      } & Out
    >,
    show<
      {
        [k in keys]-?: In[k & keyof In];
      } & In
    >
  >
  required(): ZObject<Required<Out>, Required<In>>

  keyof(): z.ZodType<keyof Out>

  merge<right extends AnyZObject>(
    right: right,
  ): ZObject<merge<Out, right['infer']>, merge<In, right['inferIn']>>
  merge<right extends z.AnyZodObject>(
    right: right,
  ): ZObject<merge<Out, z.infer<right>>, merge<In, z.input<right>>>

  extend<shape extends z.ZodRawShape>(
    shape: shape,
  ): ZObject<
    merge<Out, inferZodShapeOut<shape>>,
    merge<In, inferZodShapeIn<shape>>
  >

  strict(...args: Parameters<z.AnyZodObject['strict']>): this
  strip(): this
  passthrough(): this
}

export declare abstract class ZDiscriminatedUnion<Out> extends z.ZodType<
  Out
> {
  get discriminator(): string
  get options(): ZObject[]
  /**
   * The constructor of the discriminated union schema. Its behaviour is very similar to that of the normal z.union() constructor.
   * However, it only allows a union of objects, all of which need to share a discriminator property. This property must
   * have a different value for each object in the union.
   * @param discriminator the name of the discriminator property
   * @param types an array of object schemas
   * @param params
   */
  static create<
    discriminator extends string,
    shapes extends readonly { [k in discriminator]: unknown }[],
  >(
    discriminator: discriminator,
    types: { [i in keyof shapes]: ZObject<shapes[i]> },
    params?: z.RawCreateParams,
  ): ZDiscriminatedUnion<shapes[number]>
}

export const zDiscriminatedUnion: (typeof ZDiscriminatedUnion)['create'] = z.discriminatedUnion as never
