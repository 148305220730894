<script lang="ts" setup>
const { t: $t } = useI18n()

import type { ApiInboundLogisticsTaskById } from '~/types'

defineProps<{
  payload: {
    cuid: string
    deliverySlips: ApiInboundLogisticsTaskById['deliverySlips']
  }
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const updateDeliverySlips = useMutation({
  mutationFn: $trpc.externalStorage.inboundLogisticsTask.updateDeliverySlips.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('query.error_plural', { entity: $t('externalStorage.inboundLogisticsTask.deliverySlips.entity') }) }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['externalStoragePosition'] })

    notification.success({ title: $t('query.success_plural', { entity: $t('externalStorage.inboundLogisticsTask.deliverySlips.entity') }), duration: 4500 })

    emit('close')
  },
})
</script>

<template>
  <ThePopup
    :show="true"
    :title="$t('externalStorage.inboundLogisticsTask.deliverySlips.edit')"
    @close="emit('close')"
  >
    <FormKit
      type="form"
      :value="payload"
      :submit-label="$t('button.save')"
      @submit="updateDeliverySlips.mutate"
    >
      <FormKitFileDropzone
        name="deliverySlips"
        :label="$t('externalStorage.inboundLogisticsTask.deliverySlips.label')"
        wrap-path-in-object
      />
    </FormKit>
  </ThePopup>
</template>
