<script setup lang="ts">
import type { ApiMachineryAccessoryGetById, UpdateMachineryAccessoryData } from '~/types'

defineProps<{ payload: ApiMachineryAccessoryGetById }>()
const emit = defineEmits<{ (e: 'submit', data: UpdateMachineryAccessoryData): void }>()

function submitPurchasePriceData(data: ApiMachineryAccessoryGetById) {
  const update: UpdateMachineryAccessoryData = {
    accessory: data,
    machineryTypes: data.compatibleMachineryTypes.map(type => type.id) ?? [],
  } as UpdateMachineryAccessoryData

  emit ('submit', update)
}
</script>

<template>
  <FormKit
    type="form"
    :value="payload"
    submit-label="Speichern"
    @submit="submitPurchasePriceData"
  >
    <FormKit
      id="pricePurchaseEuros"
      name="pricePurchaseEuros"
      type="number"
      number
      step="0.01"
      label="Einkaufspreis (EUR)"
      placeholder="20"
    />
    <FormKitFileDropzone
      id="purchaseDocumentFiles"
      name="purchaseDocumentFiles"
      label="Zusatzdokumente"
      wrap-path-in-object
    />
  </FormKit>
</template>
