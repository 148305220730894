<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'
import type { StorageProductUpsert } from '~/server/trpc/routers/externalStorage/storageProduct'

const { data: popupData, close: closePopup } = useGlobalOpeners().openStorageProductCreateOrUpdatePopup

const { storageProject, storageProduct } = useQuery()
const useGetById = storageProduct.byId
const useGetAll = storageProject.allByCustomer

// Projects data (we need for select)
const customerId = computed(() => popupData.value?.customerId)
const { data: projects } = useGetAll(customerId)
const isProjectSelectionDisabled = computed(() => popupData.value?.projectId !== undefined)

// Product data
const productId = computed(() => popupData.value?.id)
const { data: product } = useGetById(productId)

const isCreateMode = computed(() => !productId.value)
const payload = computed<StorageProductUpsert | undefined>(() => {
  if (!popupData.value || !projects.value) {
    return
  }

  if (isCreateMode.value) {
    return {
      articleNumber: '',
      name: '',
      projectId: popupData.value.projectId ?? projects.value[0].id,
      exampleDocuments: [],
      examplePhotos: [],
      isPackagingUnit: false,
    } satisfies StorageProductUpsert
  }

  // Update mode
  return (product.value || undefined) satisfies StorageProductUpsert | undefined
})

const upsertedStorageProductId = useRouteQuery('upsertedStorageProductId')

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const upsertMutation = useMutation({
  mutationFn: $trpc.externalStorage.storageProduct.upsert.mutate,
  onError: makeTrpcErrorToast(notification, {
    description: `Der Artikelstamm konnte nicht ${isCreateMode.value ? 'erstellt' : 'aktualisiert'} werden`,
  }),
  onSuccess: async (storageProduct) => {
    if (popupData.value?.isUpsertedIdRequired) {
      upsertedStorageProductId.value = storageProduct.id
    }

    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['externalStorageProduct'] }),
      queryClient.invalidateQueries({ queryKey: ['externalStoragePosition'] }),
    ])

    closePopup()
    notification.success({
      title: `Der Artikelstamm wurde erfolgreich ${isCreateMode.value ? 'erstellt' : 'aktualisiert'}`,
      duration: 4500,
    })
  },
})

const popupTitle = computed(() => (isCreateMode.value
  ? 'Neuen Lagerartikelstamm anlegen'
  : 'Lagerartikelstamm bearbeiten'
))

function onSubmit(payload: StorageProductUpsert) {
  upsertMutation.mutate(payload)
}
</script>

<template>
  <ThePopup
    v-if="payload"
    :show="true"
    :title="popupTitle"
    @close="closePopup"
  >
    <ExternalStorageStorageProductCreateOrEditForm
      :payload="payload"
      :projects="projects ?? []"
      :is-project-selection-disabled="isProjectSelectionDisabled"
      @submit="onSubmit"
    />
  </ThePopup>
</template>
