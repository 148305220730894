import type { ApiLogisticsTaskGetById, MachineryAccessoryCategory } from '~/types'
import { machineryAccessoryCategoryToGerman } from '~/translations'

type Position = ApiLogisticsTaskGetById['positionsToShip'][number]
function makeLabelForPosition(position: Position) {
  const { machinery, machineryAccessory, itemSet } = position
  if (machinery) {
    return `${machinery.id}, ${machinery.producerCompanyName}, ${machinery.type.name}`
  }

  if (machineryAccessory) {
    return `${machineryAccessory.id}, ${machineryAccessoryCategoryToGerman[machineryAccessory.category as MachineryAccessoryCategory]}`
  }

  if (itemSet) {
    return `${itemSet.id}, ${itemSet.title}`
  }

  if (position.type === 'special') {
    return position.title
  }

  return `Unbekannte Position (${position.type} - ${position.id})`
}

export default (position: Position) => makeLabelForPosition(position)
