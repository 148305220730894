<script setup lang="ts">
const props = defineProps<{
  id: string
}>()

const emit = defineEmits(['close'])

const { freelancerSales } = useQuery()
const allFreelancers = freelancerSales.getFreelancers().data
const freelancersOfferIsSharedWith = freelancerSales.getFreelancersOfferIsSharedWith(props.id)

const selected = computed(() => (freelancersOfferIsSharedWith.data.value || []).map(it => it.id))

const { $trpc, queryClient, makeTrpcErrorToast, useMutation } = useMutationHelpers()
const notification = useNotification()
const shareOfferWithFreelancers = useMutation({
  mutationFn: $trpc.freelancerSales.shareOfferWithFreelancers.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Freelancer Teilen konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['freelancerSales'] }),
    ])
    notification.success({ title: 'Freelancer Teilen wurde erfolgreich aktualisiert', duration: 4500 })
    emit('close')
  },
})

function onFreelancersChanged(newIds: string[]) {
  shareOfferWithFreelancers.mutate({
    offerId: props.id,
    freelancers: newIds,
  })
}
</script>

<template>
  <ShareWithFreelancersFormPopup
    v-if="allFreelancers && !freelancersOfferIsSharedWith.isFetching.value"
    :freelancers="allFreelancers"
    :selected="selected"
    title="Angebot teilen"
    @close="emit('close')"
    @submit="onFreelancersChanged"
  />
</template>
