<script setup lang="ts">
const { t: $t } = useI18n()

import { StandardWorkingDays } from '~/types'
import { userOfferMembershipRoleSchema } from '~/server/schemas'

const { isRole } = useAuthorization()

const { openUserOfferMembershipCreateOrEditPopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const { cloned } = useCloned(popupData.value?.data)

const { userOfferMembership: queryUserOfferMembership } = useQuery()
const { data: users, isLoading: isLoadingUsers } = queryUserOfferMembership.getUsersUserCanAddToOffer(computed(() => popupData.value?.data.offerId))

const userOptions = computed(() => {
  if (isLoadingUsers.value || !users.value) {
    return []
  }

  return users.value.map(user => ({
    label: `${user.name ?? user.email} (${$t(`userRoles.${user.role}`) ?? 'N/A'})`,
    value: user.email,
  }))
})

const roleOptions = userOfferMembershipRoleSchema.options.map(role => ({ label: $t(`userRolesOfferMembership.${role}`), value: role }))
const workingDaysOptions = Object.values(StandardWorkingDays).filter(val => typeof val === 'number').map(value => ({ label: $t(`standardWorkingDays.${value}`), value }))

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const create = useMutation({
  mutationFn: $trpc.userOfferMembership.create.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Person konnte dem Projekt nicht hinzugefügt werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['userOfferMembership'] }),
      queryClient.invalidateQueries({ queryKey: ['task'] }),
    ])
    notification.success({ title: 'Das Projekt-Mitglied wurde erfolgreich erstellt', duration: 4500 })
    closePopup()
  },
})

const update = useMutation({
  mutationFn: $trpc.userOfferMembership.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Mitglied konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['userOfferMembership'] })
    notification.success({ title: 'Das Projekt-Mitglied wurde erfolgreich aktualisiert', duration: 4500 })
    closePopup()
  },
})

function save() {
  if (!popupData.value || !cloned.value) {
    return
  }

  if (popupData.value.mode === 'create') {
    create.mutate(cloned.value)
  } else {
    update.mutate({
      ...cloned.value,
      offerId: popupData.value.data.offerId,
      userEmail: popupData.value.data.userEmail,
    })
  }
}

const formkitPlugins = useFormkitPlugins()
</script>

<template>
  <ThePopup
    v-if="popupData && cloned && !isLoadingUsers"
    :show="Boolean(popupData)"
    :is-spinning="update.isPending.value || create.isPending.value"
    :title="`Team-Mitglied ${popupData.mode === 'create' ? 'hinzufügen' : 'bearbeiten'}`"
    @close="closePopup"
  >
    <FormKit
      v-slot="{ value }"
      v-model="cloned"
      type="form"
      :plugins="[formkitPlugins]"
      :disabled="!isRole(['department-lead', 'rental-employee', 'site-manager'])"
      submit-label="Speichern"
      :actions="false"
      @submit="save"
    >
      <div v-if="popupData.mode === 'create'">
        <FormKit
          id="existingOrNew"
          name="existingOrNew"
          type="select"
          :options="{ existing: 'Existierender Nutzer', new: 'Neuer Nutzer' }"
        />
        <div v-if="value?.existingOrNew === 'existing'">
          <FormKitMultiSelect
            v-if="userOptions.length"
            id="userEmail"
            label="Nutzer"
            :multi-select-options="userOptions"
            :is-multi-select-allowed="false"
            validation="required"
          />
          <p v-else class="font-bold my-2">
            Es gibt keinen existierenden Nutzer der dem Projekt hinzugefügt werden könnte
          </p>
        </div>

        <FormKit
          v-else
          id="userEmail"
          name="userEmail"
          type="email"
          label="E-Mail"
          validation="required"
        />
      </div>

      <div v-else class="mb-6">
        Du bearbeitest die Rechte für {{ popupData.data.userEmail }}.
      </div>

      <FormKit
        id="role"
        type="select"
        label="Rolle im Projekt"
        :options="roleOptions"
      />
      <div class="flex gap-x-2 items-start">
        <FormKitMultipleCheckbox
          id="standardWorkingDays"
          label="Standard Arbeitstage im Projekt"
          :multi-select-options="workingDaysOptions"
          :select-all-switch="true"
        />
      </div>

      <FormKit
        type="submit"
        label="Speichern"
        :disabled="!value?.userEmail"
      />
      <TheDevOnlyNiceJson :value="value" />
    </FormKit>
  </ThePopup>
</template>
