<script lang = 'ts' setup>
import type { InputHTMLAttributes } from 'vue'
import type { MachineryAggregatedPricingDecisionData } from '~/types'
import type { ExternalSalesMarketplaceId } from '~/prisma/enums'
import ExternalSalesMarketplaceIcon from '~/components/ExternalSalesMarketplace/MarketplaceIcon.vue'

const props = defineProps<{
  value: FormInput
  marketplaceOptions: {
    [marketplaceId in ExternalSalesMarketplaceId]?: {
      remainingMachinery: number | null
      isIncludedInRemaining?: boolean
    }
  }
  soldAt?: Date | null
  aggregatedPricingDecisionData?: MachineryAggregatedPricingDecisionData
}>()

type FormInput = {
  isReleasedForOnlineSale: true
  releasedForSaleOnExternalMarketplaces: ExternalSalesMarketplaceId[]
  endCustomerSellingPrice: number | null
  dealerSellingPrice: number | null
} | {
  isReleasedForOnlineSale: false
  releasedForSaleOnExternalMarketplaces?: undefined
  endCustomerSellingPrice?: undefined
  dealerSellingPrice?: undefined
}

const isReleasedForOnlineSale = ref<boolean>(props.value.isReleasedForOnlineSale)
watchEffect(() => isReleasedForOnlineSale.value = props.value.isReleasedForOnlineSale)
const selectedExternalMarketplaces = ref<ExternalSalesMarketplaceId[]>([])
watchEffect(() => {
  if (!isReleasedForOnlineSale.value) {
    selectedExternalMarketplaces.value = []
  }
})

const formKitMarketplaceOptions = computed(() => Object.entries(props.marketplaceOptions).map(([marketplaceId, { remainingMachinery, isIncludedInRemaining }]) => {
  const isCurrentlySelected = !!selectedExternalMarketplaces.value.includes(marketplaceId as ExternalSalesMarketplaceId)
  let displayedRemainingMachinery
  if (typeof remainingMachinery === 'number' && isIncludedInRemaining && !isCurrentlySelected) {
    displayedRemainingMachinery = remainingMachinery + 1
  } else if (typeof remainingMachinery === 'number' && !isIncludedInRemaining && isCurrentlySelected) {
    displayedRemainingMachinery = remainingMachinery - 1
  } else {
    displayedRemainingMachinery = remainingMachinery
  }

  return {
    value: marketplaceId,
    remainingMachinery: displayedRemainingMachinery,
    attrs: {
      disabled: displayedRemainingMachinery !== null && !isCurrentlySelected ? displayedRemainingMachinery <= 0 : undefined,
    },
  }
}))

const { machineryAggregatedPricingDecisionData: pricingDecisionDataColumnConfig } = useOneDataColumnConfigs()

interface MarketplaceOptionProps {
  value: ExternalSalesMarketplaceId
  remainingMachinery: number | null
  attrs: InputHTMLAttributes
}
</script>

<template>
  <n-tooltip trigger="hover" placement="top-start" :disabled="!soldAt">
    <template #trigger>
      <div>
        <FormKit
          v-model="isReleasedForOnlineSale"
          :value="true"
          :name="'isReleasedForOnlineSale' satisfies keyof FormInput"
          type="checkbox"
          :label="$t('machinery.isReleasedForOnlineSaleCheckbox.label')"
          :disabled="soldAt || typeof aggregatedPricingDecisionData?.operatingHours !== 'number'"
          :outer-class="soldAt ? 'opacity-50' : ''"
        />
      </div>
    </template>
    {{ $t('machinery.isReleasedForOnlineSaleCheckbox.disabledTooltip') }}
  </n-tooltip>
  <div v-if="isReleasedForOnlineSale">
    <FormKit
      v-model="selectedExternalMarketplaces"
      :name="'releasedForSaleOnExternalMarketplaces' satisfies keyof FormInput"
      type="checkbox"
      :label="$t('machinery.releasedForSaleOnExternalMarketplaces.label')"
      :options="formKitMarketplaceOptions"
      :help="$t('machinery.releaseForSaleOnExternalMarketplaces.help')"
    >
      <template #label="{ option }: {option: MarketplaceOptionProps}">
        <div class="flex items-center">
          <div class="flex items-center" :class="{ 'opacity-50': option.attrs.disabled }">
            <ExternalSalesMarketplaceIcon :marketplace-id="option.value" class="mx-2" />
            {{ $t(`externalSalesMarketplace.id.${option.value}`) }}
          </div>
          <span v-if="typeof option.remainingMachinery === 'number'" class="ml-2 text-hos-gray">
            {{ $t('externalSalesMarketplace.maxExportedMachineryAmount.remaining', { value: option.remainingMachinery }) }}
          </span>
        </div>
      </template>
    </FormKit>
    <FormKit
      :name="'endCustomerSellingPrice' satisfies keyof FormInput"
      type="number"
      step="0.01"
      min="1"
      :label="$t('onlineSales.endCustomerSellingPriceWithCurrency.label', { currency: 'EUR' })"
      placeholder="40000"
      validation="required"
      number="float"
      :help="$t('onlineSales.endCustomerSellingPrice.help')"
    />

    <FormKit
      :name="'dealerSellingPrice' satisfies keyof FormInput"
      type="number"
      step="0.01"
      min="1"
      :label="$t('onlineSales.dealerSellingPriceWithCurrency.label', { currency: 'EUR' })"
      placeholder="40000"
      validation="required"
      number="float"
      :help="$t('onlineSales.dealerSellingPrice.help')"
    />
    <div v-if="aggregatedPricingDecisionData" class="w-fit mb-4">
      <TheDataCard>
        <template #header>
          <h2 class="mb-4 font-semibold">
            {{ $t('onlineSales.aggregatedPricingDecisionData.title') }}
          </h2>
        </template>
        <TableOneDataColumn :config="{ data: aggregatedPricingDecisionData, columns: pricingDecisionDataColumnConfig }" />
      </TheDataCard>
    </div>
  </div>
</template>
