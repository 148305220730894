<script setup lang="ts">
import type { ApiMachineryGetById, SpecialPermissions } from '~/types'

export interface MachineryAccessoryInspectionDatePayload {
  id: string
  technicalInspectionDate?: Date | null
  securityInspectionDate?: Date | null
  specialPermissions: SpecialPermissions
}

const props = withDefaults(defineProps<{
  payload: ApiMachineryGetById | MachineryAccessoryInspectionDatePayload
  isMachineryAccessory?: boolean
}>(), {
  isMachineryAccessory: false,
})
const emit = defineEmits<{
  (e: 'saveMachinery', data: ApiMachineryGetById): void
  (e: 'saveMachineryAccessory', data: MachineryAccessoryInspectionDatePayload): void
}>()

const { useremail } = useAuthorization()

const { cloned: specialPermissions } = useCloned(props.payload.specialPermissions)

function submitForm(data: ApiMachineryGetById | MachineryAccessoryInspectionDatePayload) {
  // Machinery accessory update uses specific updateInspectionDate router that receives specific inspection date fields,
  // while machinery update uses the general update router that receives all fields.
  // Both require different types, which is why the data is asserted to the respective parameter types.

  if (props.isMachineryAccessory) {
    const machineryAccessoryData = {
      ...data,
      specialPermissions: specialPermissions.value,
      // Metadata for machinery accessory inspection date is updated directly in the router
    } as MachineryAccessoryInspectionDatePayload

    emit('saveMachineryAccessory', machineryAccessoryData)
  } else {
    const machineryData = {
      ...data,
      specialPermissions: specialPermissions.value,
      // As data is updated using the general update router, some data must be manually set from the client
      inspectionDateUpdatedAt: new Date(),
      inspectionDateUpdatedByEmail: useremail.value,
    } as ApiMachineryGetById

    emit('saveMachinery', machineryData)
  }
}
</script>

<template>
  <FormKit
    type="form"
    :value="payload"
    submit-label="Speichern"
    @submit="submitForm"
  >
    <FormKitDate
      name="technicalInspectionDate"
      label="Nächste TÜV Prüfung fällig am"
    />

    <FormKitDate
      name="securityInspectionDate"
      label="Nächste Sicherheitsprüfung fällig am"
    />

    <FormKitDate
      v-if="!isMachineryAccessory"
      name="speedometerInspectionDate"
      label="Nächste Tacho Prüfung fällig am"
    />

    <MachinerySpecialPermissionInput
      v-model="specialPermissions"
    />
  </FormKit>
</template>
