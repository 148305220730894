<script setup lang="ts">
const { t: $t } = useI18n()

const props = defineProps<{
  id: string
  error: Error | null
  isLoading: boolean
}>()

// Data
const { rentalPricing } = useQuery()
const { data: machineryPrice } = rentalPricing.machineryBasePriceByMachineryId(computed(() => props.id))

// Mutations
const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()
const deleteOne = useMutation({
  mutationFn: $trpc.shop.rentalPricing.deleteBasePrice.mutate,
  onError: makeTrpcErrorToast(notification, {
    description: $t('rentalBasePrice.delete.error'),
  }),
  async onSuccess() {
    await queryClient.invalidateQueries({ queryKey: ['rentalPricing'] })

    deletePricePopupPayload.value = undefined
    notification.success({
      title: $t('rentalBasePrice.delete.success'),
      duration: 4500,
    })
  },
})

// Popups
const basePriceCreateOrEditPopupPayload = ref<{ id?: string } | undefined>()
const deletePricePopupPayload = ref<string | undefined>()
</script>

<template>
  <TheDataCard :error :is-loading>
    <template #header>
      <div class="CardHeader">
        <h2>{{ $t("machinery.detailsPage.rentalPricing.title") }}</h2>
        <n-button v-if="!machineryPrice" @click="basePriceCreateOrEditPopupPayload = {}">
          <template #icon>
            <Icon name="material-symbols:add-circle-outline" />
          </template>{{ $t("button.create") }}
        </n-button>
      </div>
    </template>

    <RentalBasePriceOverviewMachineryTable
      :search-value="props.id"
      @update="basePriceCreateOrEditPopupPayload = { id: $event }"
      @delete="deletePricePopupPayload = $event"
    />
  </TheDataCard>

  <!-- Popups -->
  <RentalBasePriceCreateOrEditPopup
    v-if="basePriceCreateOrEditPopupPayload"
    :id="basePriceCreateOrEditPopupPayload.id"
    :machinery-id="props.id"
    type="machinery"
    @close="basePriceCreateOrEditPopupPayload = undefined"
  />

  <TheConfirmPopup v-if="deletePricePopupPayload" @confirm="deleteOne.mutate(deletePricePopupPayload)" @close="deletePricePopupPayload = undefined">
    Der Basispreis wird gelöscht.
  </TheConfirmPopup>
</template>

<style scoped>
.CardHeader {
  @apply  w-full flex justify-between mb-5 items-center md:items-start
}
.CardHeader h2 {
  @apply text-lg font-semibold
}
</style>
