<script setup lang="ts">
import type { ApiCalendarMachinery, MachineryDrives } from '~/types'
import { machineryDrivesToGerman } from '~/translations'

defineProps<{ machinery: ApiCalendarMachinery }>()

const columns: OneDataColumn<ApiCalendarMachinery>[] = [
  {
    title: 'Antriebsart',
    key: 'driveId',
    render: ({ drive }) => machineryDrivesToGerman[drive.name as MachineryDrives],
  },
  {
    title: 'Baujahr',
    key: 'yearBuilt',
    render: ({ receptionMachinery }) => receptionMachinery?.yearBuilt ?? 'N/A',
  },
  {
    title: 'Eigengewicht (kg)',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.weight?.toLocaleString('de-DE') ?? 'N/A',
  },
  {
    title: 'Tragkraft (kg)',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.liftingWeight?.toLocaleString('de-DE') ?? 'N/A',
  },
  {
    title: 'Hubhöhe (mm)',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.liftingHeightInMillimeters?.toLocaleString('de-DE') ?? 'N/A',
    isVisible: ({ receptionMachinery }) => receptionMachinery?.category !== 'platform',
  },
  {
    title: 'Arbeitshöhe (mm)',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.workHeightInMillimeters?.toLocaleString('de-DE') ?? 'N/A',
    isVisible: ({ receptionMachinery }) => receptionMachinery?.category === 'platform',
  },
  {
    title: 'Bauhöhe (mm)',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => (receptionMachinery?.category === 'forklift' ? receptionMachinery?.mastOverallHeightInMillimeters?.toLocaleString('de-DE') : receptionMachinery?.overallHeightInMillimeters?.toLocaleString('de-DE')) ?? 'N/A',
  },
  {
    title: 'Baubreite (mm)',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.overallWidthInMillimeters?.toLocaleString('de-DE') ?? 'N/A',
  },
  {
    title: 'Gesamtlänge (mm)',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.totalLengthInMillimeters?.toLocaleString('de-DE') ?? 'N/A',
  },
  {
    title: 'Klima',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => {
      const relation = receptionMachinery?.receptionMachinerySpecialEquipmentRelation
      if (!relation || !('receptionMachinerySpecialEquipments' in relation)) {
        return 'N/A'
      }

      const specialEquipments = relation.receptionMachinerySpecialEquipments
      if (specialEquipments.length === 0) {
        return 'N/A'
      }

      return specialEquipments.map(specialEquipment => specialEquipment.typeName).includes('Klima') ? 'Ja' : 'Nein'
    },
  },
  {
    title: 'Lagerplatz',
    key: 'storageLocation',
    rowProps: reactive({ style: { color: 'rgb(239, 68, 68)' } }),
  },
  {
    title: 'Notiz',
    key: 'comment',
  },
]

const { openPositionsDetailsPopup } = useGlobalOpeners()
</script>

<template>
  <n-popover trigger="hover">
    <template #trigger>
      <slot />
    </template>
    <TableOneDataColumn :config="{ columns, data: machinery }" />
    <div class="pt-2">
      <n-button @click="openPositionsDetailsPopup.open({ id: machinery.id, type: 'machinery' })">
        <template #icon>
          <Icon name="material-symbols:edit-outline-rounded" />
        </template>
        Detailseite aufrufen
      </n-button>
    </div>
    <template v-if="machinery.attachedMachineryAccessories.length">
      <n-divider class="!my-2" />
      <h2 class="font-bold">
        {{ $t('machinery.calendarPopover.attachedAccessories.header') }}
      </h2>
      <template v-for="accessory in machinery.attachedMachineryAccessories" :key="accessory.id">
        <h3 class="font-semibold">
          {{ $t(`machineryAccessory.category.${accessory.category}`) }}
        </h3>
        <p v-if="accessory.category === 'fork'" class="text-sm pl-2">
          {{ $t('machinery.calendarPopover.attachedAccessories.lengthInMm', { length: accessory.lengthInMillimeters }) }}<br>
          {{ $t('machinery.calendarPopover.attachedAccessories.widthInMm', { width: accessory.widthInMillimeters }) }}<br>
          {{ $t('machinery.calendarPopover.attachedAccessories.heightInMm', { height: accessory.heightInMillimeters }) }}
        </p>
      </template>
    </template>
  </n-popover>
</template>
