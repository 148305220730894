import currency from 'currency.js'
import { isBefore } from 'date-fns'
import options from './options'
import type { PDFDocumentType } from './type'
import type { OfferType } from '~/types'

// FUTURE TODO: use PDFKit `doc.save()` and `doc.restore()` instead of calling
// `reset()` at the beginning and end of each section function
export function reset<TData>(doc: PDFDocumentType<TData>) {
  doc
    .fontSize(options.fontSize.base)
    .fillColor(options.fontColor.base)
    .lineWidth(1)
    .strokeColor(options.fontColor.base)
    .strokeOpacity(1)
    .lineGap(0)

  return doc
}

export function maxWidth(doc: PDFDocumentType<any>, lines: string[], options: PDFKit.Mixins.TextOptions = {}) {
  if (lines.length === 0) {
    return 0
  }
  return Math.max(...lines.map(l => doc.widthOfString(l, options)))
}

export interface PositionToCalculatePrice {
  title: string
  type: string
  pricePerUnit: number | null
  discountRate: number | null
  quantity: number
  terminatedDate?: Date | null
}

export function calulateTotalPositionPrice(position: PositionToCalculatePrice | null | undefined, rentalDays?: { discountRate: number, date: Date }[], offerType?: OfferType, shouldGetExtendedRentalDays = true, showSingleUnitPrice?: boolean) {
  if (!position || position.quantity === 0) {
    return currency(0)
  }

  const pricePerUnit = currency(position.pricePerUnit ?? 0)
  let quantity = position.quantity
  let rentalDaysForPosition = rentalDays

  if (showSingleUnitPrice) {
    quantity = 1
  } else if (rentalDaysForPosition && shouldGetExtendedRentalDays && !isOfferPositionQuantityUpdatable(position.type, offerType)) {
    const extendedRentalDays = getExtendedOfferRentalDays(rentalDaysForPosition, position.terminatedDate)
    rentalDaysForPosition = rentalDaysForPosition
      .concat(extendedRentalDays)
      .filter(({ date }) => position.terminatedDate && (isBefore(date, position.terminatedDate) || getAbsoluteDateDifferenceInHours(date, position.terminatedDate) <= 12))

    quantity = rentalDaysForPosition.length
  }

  const discountRate = 1 - (position.discountRate ?? 0)
  const discountedPricePerUnit = pricePerUnit.multiply(discountRate)

  let totalDiscountPriceForRentalDays
  if (rentalDaysForPosition && ['machineryAccessory', 'machineryAccessoryCategory', 'machinery', 'itemSet'].includes(position.type) && !showSingleUnitPrice) {
    totalDiscountPriceForRentalDays = rentalDaysForPosition.reduce((total, { discountRate }) =>
      total.add(discountedPricePerUnit.multiply(discountRate ?? 0)), currency(0))
  } else if (rentalDaysForPosition && position.type === 'insurance' && !showSingleUnitPrice) {
    totalDiscountPriceForRentalDays = rentalDaysForPosition.reduce((total, { discountRate }) =>
      total.add(discountedPricePerUnit.multiply(discountRate === 1 ? 1 : 0)), currency(0))
  }

  const totalPositionPrice = discountedPricePerUnit.multiply(quantity).subtract(totalDiscountPriceForRentalDays ?? 0)

  // Custom positions can be negative
  const isCustomPosition = position.type === 'invoice' || position.type === 'creditNote'

  if (!isCustomPosition && totalPositionPrice.value < 0) {
    pdfLogger.error({
      'msg': 'The total price cannot be a negative amount',
      'position.title': position.title,
      'position.type': position.type,
      'position.pricePerUnit': position.pricePerUnit,
      'position.discountRate': position.discountRate,
      'position.quantity': position.quantity,
      'totalPositionPrice.value': totalPositionPrice.value,
    })
    throw new Error('The total price cannot be a negative amount.')
  }

  return totalPositionPrice
}

export function formatPercentage(percentageBetween0And1: number | string | null | undefined, locale = 'de') {
  const percentage = percentageBetween0And1 ? Number(percentageBetween0And1) : 0
  return `${formatNumberToString(percentage * 100, locale)}%`
}

function createUnitFormatter(unit: string) {
  return (value: string | number) => `${value} ${unit}`
}

export const kgUnitFormatter = createUnitFormatter('kg')
export const mmUnitFormatter = createUnitFormatter('mm')

export function extractInnerEntities(positions: PositionToCalculatePrice[]) {
  const machineryRelation = positions.find(p => p.type === 'machinery')
  const accessoryRelations = positions.filter(p => ['machineryAccessory', 'machineryAccessoryCategory'].includes(p.type))
  const itemSetRelations = positions.filter(p => p.type === 'itemSet')

  const automatedPositionsRelations = positions.filter(p => p.type === 'generatedByAutomation')
  const extraPositionRelations = positions.filter((p) => {
    if (automatedPositionsRelations.map(({ title }) => title).includes(p.title)) {
      return false
    }

    return ['extraPosition', 'logisticsTask', 'manualPosition'].includes(p.type)
  })
  const commentPositionRelations = positions.filter(p => p.type === 'comment')
  const insurancePositionRelation = positions.find(p => p.type === 'insurance')

  return { machineryRelation, accessoryRelations, itemSetRelations, extraPositionRelations, commentPositionRelations, insurancePositionRelation, automatedPositionsRelations }
}

export function drawHorizontalLine<T>(doc: PDFDocumentType<T>, moveDown = 1) {
  doc
    .moveDown(moveDown)
    .moveTo(options.margin.left, doc.y)
    .lineTo(doc.page.width - options.margin.right, doc.y)
    .stroke()
}

export function formatDetails(i18n: I18n, value: null | undefined | number | string | boolean): string | null {
  if (value === null || value === undefined) {
    return null
  }

  if (typeof value === 'boolean') {
    return i18n.t(`pdf.common.${Boolean(value).toString()}`)
  }

  return value.toString()
}
