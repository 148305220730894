import { z } from 'zod'

const sentryVariablesSchema = z.object({
  dsn: z.string().url(),
  environment: z.enum(['development', 'production']),
  sampleRate: z.coerce.number(),
})

export default (): { doInitialize: true, sentryConfig: z.infer<typeof sentryVariablesSchema> } | { doInitialize: false, errorMessage: string } => {
  const { public: publicRuntime } = useRuntimeConfig()
  const sentryConfig = sentryVariablesSchema.safeParse(publicRuntime.sentry)

  if (!sentryConfig.success || sentryConfig.data.environment === 'development') {
    return { doInitialize: false, errorMessage: 'glitchtip node: NUXT_PUBLIC_SENTRY_DSN not set OR mode development, skipping initialization' }
  }

  return {
    doInitialize: true,
    sentryConfig: sentryConfig.data,
  }
}
