/**
 * Generates i18n translated select input options based on the provided translation prefix and options.
 *
 * @param {string} translationPrefix - The prefix for the translation keys.
 * @param {string[]} options - An array of option values to be translated.
 * @returns {Array<{ label: string, value: string }>} An array of objects containing translated labels and their corresponding values.
 *
 * @example
 * const options = useTranslatedSelectOptions('example.options', ['option1', 'option2']);
 * // options:
 * // [
 * //   { label: i18n.t('example.options.option1'), value: 'option1' },
 * //   { label: i18n.t('example.options.option2'), value: 'option2' }
 * // ]
 */
export default function useTranslatedSelectOptions(translationPrefix: string, options: string[]) {
  const i18n = useI18n()
  return options.map(key => ({
    label: i18n.t(`${translationPrefix}.${key}`),
    value: key,
  }))
}
