import { customerModuleRoles } from '~/authorization'

export default defineNuxtRouteMiddleware((to) => {
  if (to.path.startsWith('/auth')) {
    return
  }

  const { canAccessPage, role } = useAuthorization()

  const isCustomerModuleUser = customerModuleRoles.includes(role.value)
  // Redirect customer-users to the other landing page
  if (isCustomerModuleUser && to.path === '/') {
    return navigateTo('/my-hos')
  }

  if (!canAccessPage(to.path)) {
    if (!to.path.startsWith('/my-hos') && isCustomerModuleUser) {
      return navigateTo('/my-hos')
    }
    return navigateTo('/')
  }
})
