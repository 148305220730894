<script setup lang="ts">
const props = defineProps<{ show: boolean, type: 'scanQR' | 'typeId', offerId: string, logisticsTaskId: string }>()
const emits = defineEmits<{ (e: 'close'): void }>()

const decodedId = ref('')

const { openLogisticsIssuancePopup } = useGlobalOpeners()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const createOfferPosition = useMutation({
  mutationFn: $trpc.offerPosition.logisticsTour.issuance.createPositionFromIssuance.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Position konnte nicht erstellt werden' }),
  onSuccess: async (offerPosition) => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offerPosition'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
    ])

    openLogisticsIssuancePopup.open({ id: offerPosition.id, taskType: 'outbound', positionType: offerPosition.type as 'machineryAccessory' | 'itemSet', machineryId: decodedId.value })
    closePopup()

    notification.success({ title: 'Die Position wurde erfolgreich erstellt', duration: 4500 })
  },
})

function decodeQrCode(decoded: string) {
  decodedId.value = decoded.split('/').at(-1) ?? decoded

  let offerPosition
  if (decodedId.value[0] === 'a') {
    offerPosition = { type: 'machineryAccessory' as const, machineryAccessoryId: decodedId.value }
  } else if (decodedId.value[0] === 's') {
    offerPosition = { type: 'itemSet' as const, itemSetId: decodedId.value }
  } else {
    notification.error({ title: 'Nicht unterstützter Positionstyp', duration: 4500 })
    closePopup()
    return
  }

  createOfferPosition.mutate({
    logisticsTaskId: props.logisticsTaskId,
    offerPosition,
  })

  closePopup()
}

function closePopup() {
  emits('close')
}

const formkitPlugins = useFormkitPlugins()
interface QRCodeFormData {
  id: string
}
</script>

<template>
  <ThePopup
    v-if="show"
    :show="show"
    title="Scanne Geräte"
    @close="closePopup"
  >
    <TheQRCodeScanner v-if="type === 'scanQR'" @decode-qr-code="decodeQrCode" />
    <div v-else class="flex flex-col gap-4">
      <FormKit
        type="form"
        :plugins="[formkitPlugins]"
        @submit="(value: QRCodeFormData) => decodeQrCode(value.id)"
      >
        <FormKit
          id="id"
          type="text"
          validation="required"
          label="Geräte ID"
          placeholder="a00012"
        />
        <template #actions>
          <n-button type="primary" attr-type="submit">
            Speichern
          </n-button>
        </template>
      </FormKit>
    </div>
  </ThePopup>
</template>
