<script setup lang="ts">
const doesQueryExist = computed(() => Object.keys(useRoute().query).length > 0)

const {
  openCustomerPopup,
  openDeliveryVehiclePopup,
  openLogisticsTaskInformationPopup,
  openOfferTerminationPopup,
  openDriverLoadingChecklistPopup,
  openDriverCollectionChecklistPopup,
  openLogisticsIssuancePopup,
  openLogisticsTaskDocumentUploadPopup,
  openLogisticsDateUpdateComment,
  openPositionsDetailsPopup,
  openCommentCreateOrUpdatePopup,
  openMachineryCreateOrEditPopup,
  openReceptionProtocol,
  openReceptionProtocolHistory,
  openAccessoryCreateOrEditPopup,
  openLogisticsStorageLocationPopup,
  openLogisticsStoreMachineryScanPopup,
  openOfferPrintPopup,
  openOfferNextStepsPopup,
  openLogisticsIssuanceEnterMachineryId,
  openLogisticsIssuanceMachineryList,
  openLogisticsReturnScanMachinery,
  openInvoicingPopup,
  openInvoiceCommentPopup,
  openInvoiceConfirmPaymentPopup,
  openInvoicePositionsPopup,
  openCreateCreditNotePopup,
  openInvoicePartialPaymentPopup,
  openInvoiceNextStepsPopup,
  openInternalCostCreateOrEditPopup,
  openInternalCostPopup,
  openDefectCreateOrUpdatePopup,
  openSetCreateOrUpdatePopup,
  openSetCreateOrUpdateFromIssuancePopup,
  openSpecialPositionsCreateOrEditPopup,
  openIssuanceUpdateNotReturnablePopup,
  openLogisticsTaskCreateOrEditPopup,
  openEditTaskAToBStatusPopup,
  openDefectRepairPopup,
  /**
   * TODO: remove this file when we don't need it anymore
   * @temporary
   * This is for allow user to edit the termination day of the offers
   * that are terminated before the partial termination implemented
   * see https://github.com/sidestream-tech/hanselmann-os/issues/1526 for more information
   */
  openOfferRevertTerminationPopup,
  openUserOfferMembershipCreateOrEditPopup,
  openOfferCompleteProjectPopup,
  openReleaseMachineryForFreelancerSalesPopup,
  openInviteFreelancerSales,
  openReleaseMachineryForPartnerSalesPopup,
  openInvitePartnerSales,
  openReleaseMachineryForOnlineSalesPopup,
  openInternalReservationCreateOrUpdatePopup,
  openStorageLoadCarrierCreateOrUpdatePopup,
  openStorageProductCreateOrUpdatePopup,
  openStorageUserCreateOrUpdatePopup,
  openPositionTemplateBundleCreateOrUpdatePopup,
  openStoragePositionApprovePopup,
  openStoragePartialPositionCreateOrEditPopup,
  openInboundLogisticsTaskTransportationDocumentPopup,
  openStoragePositionPdfPopup,
  openInboundLogisticsTaskPrintDeliverySlipPopup,
  openOtherInvoiceRecipientCreateOrEditPopup,
  openRentalPausePopup,
  openGroupedPhotoPopup,
} = useGlobalOpeners()
</script>

<template>
  <!--
    NOTE: Instead of directly using `popupOpener.data.value` we used to specify computeds like `const showPopup = computed(() => popupOpener.data.value)`. This
      is not required anymore (and never was). Instead, we can use it in the template as `popupOpener.data.value`. The reason it didn't work before is a limi-
      tation of the vue-template `.value`-addition algorithm: It only looks at the left-most value of a Member Expression `a.b.c` to determine whether it needs to
      add a `.value`. So e.g., it would transform `a.b.c` to `a.value.b.c` if `a` was a Ref, but it would not transform `a.b.c` to `a.b.c.value` if
      `c` was a Ref.

      Thus, `popupOpener.data` (with `.data` being a `ref`) will not be transformed to `popupOpener.data.value`. This leads to `v-if="popupOpener.data"` always
      evaluating to `true` (a ref-object is truthy, i.e.: `true === Boolean(ref())`) and running the script setup of the child component. Manually appending the
      `.value` does the trick of resolving this.

      This was first pointed out by @phoenix-ru here: https://github.com/sidestream-tech/hanselmann-os/pull/2237#issuecomment-1961467792.
   -->
  <LogisticsTerminationContainer v-if="openOfferTerminationPopup.data.value" />

  <CustomerCreateOrEditContainer v-if="openCustomerPopup.data.value" />
  <MachineryTypeCreationCreateOrEditContainer v-if="doesQueryExist" />
  <DeliveryVehicleCreateOrEditContainer v-if="openDeliveryVehiclePopup.data.value" />
  <LogisticsInformationContainer v-if="openLogisticsTaskInformationPopup.data.value" />
  <DriverLoadingChecklistContainer v-if="openDriverLoadingChecklistPopup.data.value" />
  <DriverCollectionChecklistContainer v-if="openDriverCollectionChecklistPopup.data.value" />
  <LogisticsIssuanceContainer v-if="openLogisticsIssuancePopup.data.value" />
  <DriverDocumentUploadContainer v-if="openLogisticsTaskDocumentUploadPopup.data.value" />
  <LogisticsPlanningDateUpdateCommentContainer v-if="openLogisticsDateUpdateComment.data.value" />
  <CalendarPositionDetailsPopup v-if="openPositionsDetailsPopup.data.value" />

  <CommentCreateOrEditContainer v-if="openCommentCreateOrUpdatePopup.data.value" />

  <MachineryCreateOrEditContainer v-if="openMachineryCreateOrEditPopup.data.value" />
  <MachineryCreationReceptionProtocolContainer v-if="openReceptionProtocol.data.value" />
  <MachineryCreationReceptionProtocolHistoryContainer v-if="openReceptionProtocolHistory.data.value" />
  <MachineryAccessoryCreationCreateOrEditContainer v-if="openAccessoryCreateOrEditPopup.data.value" />

  <LogisticsStoreScanMachineryContainer v-if="openLogisticsStoreMachineryScanPopup.data.value" />
  <LogisticsStoreUpdateStorageLocation v-if="openLogisticsStorageLocationPopup.data.value" />

  <!-- offer related popups -->
  <OfferPrintContainer v-if="openOfferPrintPopup.data.value" />
  <OfferPopupNextSteps v-if="openOfferNextStepsPopup.data.value" />
  <OfferPopupCompleteProject v-if="openOfferCompleteProjectPopup.data.value" />

  <!-- issuance qr code scan -->
  <LogisticsIssuanceEnterMachineryIdPopup v-if="openLogisticsIssuanceEnterMachineryId.data.value" />
  <LogisticsIssuanceMachineryListContainer v-if="openLogisticsIssuanceMachineryList.data.value" />
  <LogisticsReturnScanMachineryContainer v-if="openLogisticsReturnScanMachinery.data.value" />

  <!-- invoice related popups -->
  <InvoiceCreationPopup v-if="openInvoicingPopup.data.value" />
  <InvoiceCommentPopup v-if="openInvoiceCommentPopup.data.value" />
  <InvoiceConfirmPaymentPopup v-if="openInvoiceConfirmPaymentPopup.data.value" />
  <InvoicePositionsPopup v-if="openInvoicePositionsPopup.data.value" />
  <InvoiceCreateCreditNotePopup v-if="openCreateCreditNotePopup.data.value" />
  <InvoicePartialPaymentPopup v-if="openInvoicePartialPaymentPopup.data.value" />
  <InvoiceCreationPopupNextSteps v-if="openInvoiceNextStepsPopup.data.value" />

  <InternalCostCreateOrEditContainer v-if="openInternalCostCreateOrEditPopup.data.value" />
  <InternalCostPopup v-if="openInternalCostPopup.data.value" />

  <DefectCreateOrEditContainer v-if="openDefectCreateOrUpdatePopup.data.value" />
  <DefectRepairContainer v-if="openDefectRepairPopup.data.value" />

  <!-- set related popups -->
  <SetCreateOrEditContainer v-if="openSetCreateOrUpdatePopup.data.value" />
  <SetCreateOrEditFromIssuanceContainer v-if="openSetCreateOrUpdateFromIssuancePopup.data.value" />

  <SpecialPositionCreateOrEditContainer v-if="openSpecialPositionsCreateOrEditPopup.data.value" />

  <LogisticsIssuanceUpdateNotReturnableContainer v-if="openIssuanceUpdateNotReturnablePopup.data.value" />
  <LogisticsCreateOrEditContainer v-if="openLogisticsTaskCreateOrEditPopup.data.value" />
  <LogisticsEditTaskAToBStatusPopup v-if="openEditTaskAToBStatusPopup.data.value" />
  <LogisticsTerminationRevertContainerForNotPartialTerminatedOffer v-if="openOfferRevertTerminationPopup.data.value" />

  <UserOfferMembershipCreateOrEditPopup v-if="openUserOfferMembershipCreateOrEditPopup.data.value" />

  <FreelancerSalesReleaseMachineryPopup v-if="openReleaseMachineryForFreelancerSalesPopup.data.value" />
  <FreelancerSalesInvitePopup v-if="openInviteFreelancerSales.data.value" />

  <PartnerSalesReleaseMachineryPopup v-if="openReleaseMachineryForPartnerSalesPopup.data.value" />
  <PartnerSalesInvitePopup v-if="openInvitePartnerSales.data.value" />

  <OnlineSalesReleaseMachineryPopup v-if="openReleaseMachineryForOnlineSalesPopup.data.value" />

  <InternalReservationCreateOrEditPopup v-if="openInternalReservationCreateOrUpdatePopup.data.value" />

  <ExternalStorageAdministrationStorageLoadCarrierCreateOrEditContainer v-if="openStorageLoadCarrierCreateOrUpdatePopup.data.value" />
  <ExternalStorageAdministrationStorageUserCreateOrEditContainer v-if="openStorageUserCreateOrUpdatePopup.data.value" />

  <ExternalStorageStorageProductCreateOrEditContainer v-if="openStorageProductCreateOrUpdatePopup.data.value" />

  <PositionTemplateBundleCreateOrEditContainer v-if="openPositionTemplateBundleCreateOrUpdatePopup.data.value" />

  <ExternalStorageStoragePositionApprovePopup v-if="openStoragePositionApprovePopup.data.value" />
  <ExternalStoragePartialStoragePositionCreateOrEditContainer v-if="openStoragePartialPositionCreateOrEditPopup.data.value" />

  <ExternalStorageInboundLogisticsTaskStorePopupTransportationDocuments v-if="openInboundLogisticsTaskTransportationDocumentPopup.data.value" />

  <ExternalStorageInboundLogisticsTaskStorePopupPrintDeliverySlip v-if="openInboundLogisticsTaskPrintDeliverySlipPopup.data.value" />

  <ExternalStorageStoragePositionPopupPdf v-if="openStoragePositionPdfPopup.data.value" />

  <CustomerOtherInvoiceRecipientCreateOrEditContainer v-if="openOtherInvoiceRecipientCreateOrEditPopup.data.value" />

  <RentalPauseCreateOrEditPopup v-if="openRentalPausePopup.data.value" />

  <GroupedPhotoCreateOrEditPopup v-if="openGroupedPhotoPopup.data.value" />
</template>
