<script setup lang="ts">
import type { ApiLogisticsTaskGetById } from '~/types'

const props = defineProps<{ logisticsTask: ApiLogisticsTaskGetById }>()

const { openDriverLoadingChecklistPopup } = useGlobalOpeners()
const outboundTour = props.logisticsTask.type === 'outbound'
const isPlanned = Boolean(props.logisticsTask.deliveryAt && (props.logisticsTask.assignedDeliveryVehicleId || props.logisticsTask.doesCustomerDoTask || props.logisticsTask.doesFitterDoTask || props.logisticsTask.doesTransportationCompanyDoTask))

// TODO(dispo refactoring): Do we want to enfore something different to see if something was issued?
const isIssued = Boolean(props.logisticsTask.positionsToShip.some(p => p.issuedAt))

const isEditingDisabled = computed(() => props.logisticsTask.status !== 'loaded')
</script>

<template>
  <TheDataCard>
    <template #header>
      <div class="CardHeader">
        <h2>Beladen-Checklist</h2>
        <n-button
          v-if="outboundTour && !isEditingDisabled"
          @click="openDriverLoadingChecklistPopup.open({ id: logisticsTask.id })"
        >
          <template #icon>
            <Icon name="material-symbols:edit-outline-rounded" />
          </template>
          Ändern
        </n-button>
      </div>
    </template>

    <template v-if="outboundTour && isPlanned && isIssued">
      <div v-if="'created' === logisticsTask.status" class="flex justify-between">
        <span>Leer</span>
        <n-button @click="openDriverLoadingChecklistPopup.open({ id: logisticsTask.id })">
          Die Checkliste ausfüllen
        </n-button>
      </div>
      <DriverLoadingChecklistForm v-else :logistics-task="logisticsTask" :view-only="true" />
    </template>
    <div v-else class="text-center mt-5">
      Dieser Auftrag ist noch nicht geplant und ausgegeben worden.
    </div>
  </TheDataCard>
</template>

<style scoped>
.CardHeader {
  @apply  w-full flex justify-between mb-5
}
.CardHeader h2 {
  @apply text-lg font-semibold
}
</style>
