<script setup lang="ts">
import { createInput } from '@formkit/vue'
import DateInput from './CreateInputBase/Date.vue'

const dateInput = createInput(DateInput, { props: ['transformDate'] })
</script>

<template>
  <FormKit :type="dateInput" />
</template>
