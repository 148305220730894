<script setup lang="ts">
const { openLogisticsReturnScanMachinery: { data: popupData, close: closePopup_ } } = useGlobalOpeners()

const { offerPosition } = useQuery()
const { data: offerPositionsToReturn } = offerPosition.all(computed(() => ({
  OR: [{ type: 'machinery' }, { type: 'machineryAccessory' }, { type: 'machineryAccessoryCategory' }],
  isReturned: false,
  shippedViaLogisticsTasks: { some: { type: 'inbound', status: 'collected' } },
})))

const offerPositions = computed(() => {
  return offerPositionsToReturn.value?.reduce((acc, p) => {
    if (p.machineryId || p.machineryAccessoryId) {
      const key = (p.machineryId ?? p.machineryAccessoryId) as string
      acc[key] = p.id
    }
    return acc
  }, {} as Record<string, string>) ?? {}
})

const scannedMachinery = ref('')

function openLogisticsIssuance(scannedMachineryId: string) {
  if (!popupData.value) {
    return
  }

  const positionType = scannedMachineryId[0] === 'a' ? 'machineryAccessory' : 'machinery'
  openLogisticsIssuancePopup.open({ id: offerPositions.value[scannedMachineryId], taskType: 'inbound', positionType, machineryId: scannedMachineryId })

  closePopup()
}

const errorText = ref<string | undefined>(undefined)
function decodeQrCode(decoded: string) {
  const machineryId = decoded.split('/').at(-1) ?? decoded
  if (!popupData.value) {
    return
  }

  if (Object.keys(offerPositions.value).includes(machineryId)) {
    openLogisticsIssuance(machineryId)
  } else {
    errorText.value = 'Maschine ist nicht in der Rückgabeliste'
  }
}

function closePopup() {
  errorText.value = undefined
  closePopup_()
}

const { openLogisticsIssuancePopup } = useGlobalOpeners()
</script>

<template>
  <ThePopup
    v-if="popupData"
    :show="Boolean(popupData)"
    title="Scanne Rücknahmen Geräte"
    @close="closePopup"
  >
    {{ scannedMachinery }}
    <div v-if="errorText">
      <span class="font-bold text-red-500">{{ errorText }}</span>
    </div>
    <TheQRCodeScanner @decode-qr-code="decodeQrCode" />
  </ThePopup>
</template>
