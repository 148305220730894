import type { UnwrapRef } from 'vue'

export default <T extends Record<string, any>>() => {
  const payload = ref<T | null>(null)

  const open = (data: T) => {
    payload.value = data as UnwrapRef<T>
  }

  const close = () => {
    payload.value = null
  }

  return {
    payload,
    open,
    close,
  }
}
