<script setup lang="ts">
import type { ApiLogisticsTaskGetById } from '~/types'

interface Payload {
  task: ApiLogisticsTaskGetById
}
defineProps<Payload>()

const emit = defineEmits<{ (e: 'save', payload: Payload): void }>()

const isCustomerNotReachable = ref(false)

function submit(task: ApiLogisticsTaskGetById) {
  if (isCustomerNotReachable.value) {
    task.deliveryPerson = null
    task.deliveryCustomerSignature = null
  }
  emit('save', { task })
}
</script>

<template>
  <FormKit
    type="form"
    :value="task"
    :submit-label="$t('button.save')"
    @submit="submit"
  >
    <FormKit
      name="deliveryPerson"
      type="text"
      :label="$t('logisticsTask.deliveryPerson.label')"
      :validation="isCustomerNotReachable ? '' : 'required'"
      :disabled="isCustomerNotReachable"
    />

    <FormKitSignaturePopup
      name="deliveryCustomerSignature"
      :label="$t('logisticsTask.deliveryCustomerSignature.label')"
      :disable-by-pass="true"
      :disable-positions-overview="true"
      :default="undefined"
      :validation="isCustomerNotReachable ? '' : 'required'"
      :disabled="isCustomerNotReachable"
    />

    <n-checkbox v-model:checked="isCustomerNotReachable" class="mb-3">
      {{ $t('logisticsTask.customerNotReachable.label') }}
    </n-checkbox>

    <FormKit
      name="deliveryNotes"
      type="textarea"
      label="Bemerkungen"
    />

    <FormKitFileDropzone
      name="deliveryPhotos"
      wrap-path-in-object
      label="Fotos"
    />
  </FormKit>
</template>
