<script setup lang="ts">
const { t: $t } = useI18n()

const props = defineProps<{
  searchValue?: string
}>()

const emit = defineEmits<{
  (e: 'update', id: string): void
  (e: 'delete', id: string): void
}>()

// Pagination
const { serverSidePaginationTableProps, serverSidePagination, watchForPageCount } = useTablePagination({ pageCount: 1 })

// Search
const { fulltextSearchValue, where } = useFilterData({ filters: ['/machinery/id'] })
const placeholder = computed(() => $t('rentalBasePrice.overview.machinery.search.placeholder'))
fulltextSearchValue.value = props.searchValue ?? ''

// Data
const { rentalPricing } = useQuery()
const { data, isLoading, suspense } = rentalPricing.allMachineryBasePrices(serverSidePagination, where)

type RentalBasePrice = NonNullable<typeof data['value']>['data'][number]
type MachineryRentalBasePrice = Omit<RentalBasePrice, 'machinery'> & { machinery: NonNullable<RentalBasePrice['machinery']> }

const machineryBasePrices = computed<MachineryRentalBasePrice[]>(() => (data.value?.data ?? []) as MachineryRentalBasePrice[])

// Table configs
const machineryColumns = computed<TableConfig<MachineryRentalBasePrice>['columns']>(() => [
  {
    title: 'Id',
    key: 'id',
    render: row => row.machinery.id,
  },
  {
    title: 'Kategorie',
    key: 'category',
    render: row => $t(`machinery.category.${row.machinery.category}`),
  },
  {
    title: 'Preis in EUR',
    key: 'valueInEuros',
  },
])

function actionButtons(): Action[] {
  return ['showDetails', 'update', 'delete']
}

// Misc
watchForPageCount(computed(() => data.value?.totalCount ?? 0))
onServerPrefetch(suspense)
</script>

<template>
  <TableFilters v-model="fulltextSearchValue" :placeholder :disabled="!!searchValue">
    <TableView
      v-bind="serverSidePaginationTableProps"
      :is-loading="isLoading"
      :data="machineryBasePrices"
      :columns="machineryColumns"
      :action-buttons="actionButtons"
      class="mt-6"
      @show-details="navigateTo(`/rental/pricing/machinery/${$event.machinery.id}`)"
      @update="emit('update', $event.id)"
      @delete="emit('delete', $event.id)"
    />
  </TableFilters>
</template>
