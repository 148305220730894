<script lang="ts" setup>
import type { DeliveryVehicleUpsert } from '~/server/trpc/routers/deliveryVehicle'
import { DeliveryVehicleStatus } from '~/types'

const { openDeliveryVehiclePopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const id = computed(() => popupData.value?.mode === 'update' ? popupData.value.id : undefined)

const { deliveryVehicle: vehicleQueries, user: userQueries } = useQuery()
const { data: vehicle } = vehicleQueries.byIdOrThrow(id)
const { data: drivers } = userQueries.drivers()

const payload = ref<DeliveryVehicleUpsert | undefined>()
watch([popupData, vehicle], () => {
  if (payload.value || !popupData.value) {
    return
  }

  if (popupData.value.mode === 'create') {
    payload.value = {
      status: DeliveryVehicleStatus.Active,
      licensePlateNumber: '',
      model: '',
      height: 0,
      breadth: 0,
      maxPartialLoadWeight: 0,
      maxSingleLoadWeight: 0,
      technicalInspectionDate: new Date(),
      securityInspectionDate: new Date(),
      photos: [],
      isExternalStorageVehicle: false,
    }
    return
  }

  payload.value = vehicle.value || undefined
}, { immediate: true })

const verbInNotifications = computed(() => popupData.value?.mode === 'create' ? 'erstellt' : 'aktualisiert')
const upsert = useMutation({
  mutationFn: $trpc.deliveryVehicle.upsert.mutate,
  onError: makeTrpcErrorToast(notification, { description: `Das Lieferfahrzeug konnte nicht ${verbInNotifications.value} werden` }),
  async onSuccess() {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['deliveryVehicles'] }),
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
    ])
    closePopup()
    notification.success({ title: `Das Lieferfahrzeug wurde erfolgreich ${verbInNotifications.value}`, duration: 4500 })
  },
})
</script>

<template>
  <ThePopup v-if="payload" :show="true" title="Fahrzeug-Anlage" @close="closePopup">
    <DeliveryVehicleCreateOrEditForm :payload="payload" :drivers="drivers ?? []" @save="upsert.mutate" />
  </ThePopup>
</template>
