<script setup lang="ts">
/**
 * TODO: remove this file when we don't need it anymore
 * @temporary
 * This is for allow user to edit the termination day of the offers
 * that are terminated before the partial termination implemented
 * see https://github.com/sidestream-tech/hanselmann-os/issues/1526 for more information
 */
import type { ApiInputOfferRevertEndedObligation } from '~/types'

const props = defineProps<{ payload: ApiInputOfferRevertEndedObligation }>()
const emit = defineEmits<{ (e: 'save', payload: ApiInputOfferRevertEndedObligation): void }>()
const { cloned } = useMightyCloned(props.payload)
const formkitPlugins = useFormkitPlugins()
</script>

<template>
  <FormKit v-model="cloned" type="form" :plugins="[formkitPlugins]" submit-label="Abmeldung rückgängig machen" @submit="emit('save', cloned)">
    <CommentList :id="payload.id" type="termination" class="mb-5" hide-list-title />
  </FormKit>
</template>
