<script setup lang="ts">
import type { DataTableRowKey } from 'naive-ui'
import type { ApiShoppingGetAllAccessories, OfferStatus, OfferType } from '~/types'

const props = defineProps<{
  additionalAccessoryFilters?: Record<string, any>
  show: boolean
  offerStartDay: Date
  offerEndDay?: Date
  offer: { status: OfferStatus, id?: string, type: OfferType }
  machineryId: string | undefined
  selectedAccessoryIds: string[]
  disabledAccessoryIds: string[]
}>()
const emits = defineEmits<{ (e: 'save', ids: string[]): void, (e: 'close'): void }>()

const checkedAccessoryIds = ref(props.selectedAccessoryIds)
const { serverSidePaginationTableProps, serverSidePagination, watchForPageCount, watchForReset } = useTablePagination({ pageCount: 1, pageSize: 10 })

const { shoppingAccessoryIdTable } = useTableColumnConfigs()
const { fulltextSearch, columns, rowProps } = shoppingAccessoryIdTable(
  props.offer,
  props.disabledAccessoryIds,
)

const { fulltextSearchValue, where } = useFilterData(fulltextSearch)

const accessoriesWhere = computed(() => ({
  ...where.value,
  ...props.additionalAccessoryFilters,
}))

const { shopping } = useQuery()
const { data: accessoriesData, isLoading: isLoadingAccessories } = shopping.allAccessories(
  computed(() => props.show),
  computed(() => props.offerStartDay),
  computed(() => props.offerEndDay),
  accessoriesWhere,
  serverSidePagination,
)
const accessories = computed(() => accessoriesData.value?.accessories ?? [])
watchForPageCount(computed(() => accessoriesData.value?.totalCount ?? 0))
watchForReset(where)

const notification = useNotification()

function addBundledMachineryAccessories(rows: ApiShoppingGetAllAccessories[]) {
  const bundleAccessoryIds = rows.flatMap(row => row.machineryAccessoryBundle?.machineryAccessories
    .filter(({ id }) => !checkedAccessoryIds.value.includes(id))
    .map(({ id }) => id) ?? [])

  if (bundleAccessoryIds.length > 0) {
    checkedAccessoryIds.value = checkedAccessoryIds.value.concat(...bundleAccessoryIds)
    notification.success({ title: 'Lagertool Bündel wurde erfolgreich hinzugefügt', duration: 4500 })
  }
}

function removeBundledMachineryAccessories(row?: ApiShoppingGetAllAccessories, checkedRowKeys?: DataTableRowKey[]) {
  if (!row || !checkedRowKeys) {
    const accessoryIdsToRemove = accessories.value
      .flatMap(accessory => accessory.machineryAccessoryBundle?.machineryAccessories.map(({ id }) => id))
      .concat(accessories.value.map(({ id }) => id))

    checkedAccessoryIds.value = checkedAccessoryIds.value.filter(id => !accessoryIdsToRemove.includes(id))
    notification.success({ title: 'Lagertool Bündel wurde erfolgreich entfernt', duration: 4500 })
    return
  }

  if (!row.machineryAccessoryBundle) {
    return
  }

  const bundleAccessoryIds = row.machineryAccessoryBundle.machineryAccessories.map(({ id }) => id)

  if (bundleAccessoryIds.some(id => !checkedRowKeys.includes(id))) {
    checkedAccessoryIds.value = checkedAccessoryIds.value.filter(id => !bundleAccessoryIds.includes(id))
  }
}

function setSelectedAccessoryIds(ids: DataTableRowKey[], rows: ApiShoppingGetAllAccessories[], meta: { row?: ApiShoppingGetAllAccessories, action: 'check' | 'uncheck' | 'checkAll' | 'uncheckAll' }) {
  checkedAccessoryIds.value = ids as string[]

  if (meta.action === 'check' || meta.action === 'checkAll') {
    addBundledMachineryAccessories(rows)
  } else if (meta.action === 'uncheck' && meta.row) {
    removeBundledMachineryAccessories(meta.row, ids)
  } else if (meta.action === 'uncheckAll') {
    removeBundledMachineryAccessories()
  }
}
</script>

<template>
  <ThePopup :show="show" title="Spezifisches Anbaugerät hinzufügen" @close="emits('close')">
    <div class="flex flex-col gap-4">
      <TableFilters v-model="fulltextSearchValue" :placeholder="fulltextSearch.placeholder">
        <TableView
          v-bind="serverSidePaginationTableProps"
          :data="accessories"
          :columns="columns"
          :is-loading="isLoadingAccessories"
          :single-line="false"
          :row-key="row => row.id"
          :checked-row-keys="checkedAccessoryIds"
          :row-props="rowProps"
          @update:checked-row-keys="setSelectedAccessoryIds"
        />
      </TableFilters>

      <n-button class="w-full" type="primary" @click="emits('save', checkedAccessoryIds)">
        Speichern
      </n-button>
    </div>
  </ThePopup>
</template>
