/**
 * We used to have a sqlite-local development setup, and switched to postgres-local here: https://github.com/sidestream-tech/hanselmann-os/pull/3356
 *
 * To allow allow for easy backwards compatability we keep this utiltity for now (see https://github.com/sidestream-tech/hanselmann-os/pull/3356#discussion_r1761440525), in case we need to roll back to sqlite for local development.
 *
 * Once we are happy with how postgres serves us for local development, we can remove this utility.
 */
export default function (): { mode: 'insensitive' } {
  return { mode: 'insensitive' }
}
