import { generateClasses } from '@formkit/themes'
import { de } from '@formkit/i18n'
import { genesisIcons } from '@formkit/icons'
import { defineFormKitConfig } from '@formkit/vue'
import genesis from '@formkit/themes/tailwindcss/genesis'

// Customize tailwind genesis theme
const customGenesis = { ...genesis }
customGenesis.global.outer = `${genesis.global.outer} w-full`
customGenesis.global.inner = `${genesis.global.inner} bg-white`

export default defineFormKitConfig({
  // i18n
  locales: { de },
  locale: 'de',
  // Styling
  icons: { ...genesisIcons },
  config: {
    classes: generateClasses(customGenesis),
  },
})
