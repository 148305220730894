<script setup lang="ts">
import { createInput } from '@formkit/vue'
import FileDropzone from '~/components/File/Dropzone.vue'

const fileDropzone = createInput(FileDropzone)
</script>

<template>
  <FormKit :type="fileDropzone" />
</template>
