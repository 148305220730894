<script setup lang="ts">
import type { StorageProductUpsert } from '~/server/trpc/routers/externalStorage/storageProduct'
import type { ApiStorageProjectMinimal } from '~/types'

const props = defineProps<{
  payload: StorageProductUpsert
  projects: ApiStorageProjectMinimal[]
  isProjectSelectionDisabled?: boolean
}>()

const emit = defineEmits<{
  (e: 'submit', payload: StorageProductUpsert): void
}>()

const projectOptions = computed(() => props.projects.map(it => ({
  label: it.name,
  value: it.id,
})))

const isPackagingUnit = ref(false)
</script>

<template>
  <FormKit
    type="form"
    :value="payload"
    @submit="emit('submit', $event)"
  >
    <div class="grid grid-cols-2 gap-x-2">
      <FormKit
        name="projectId"
        label="Projekt"
        type="select"
        :options="projectOptions"
        :disabled="isProjectSelectionDisabled"
        required
      />
      <div />

      <FormKit
        name="name"
        label="Bezeichnung"
        placeholder="AIZP AEG"
        validation="required"
      />
      <FormKit
        name="articleNumber"
        label="Artikel Nr."
        placeholder="CVE.1110123213"
        validation="required"
      />

      <FormKit
        type="number"
        number="float"
        name="valueInEuro"
        label="Warenwert (EUR)"
        step="0.01"
        placeholder="10000"
      />
      <div />

      <FormKit
        v-model="isPackagingUnit"
        type="checkbox"
        name="isPackagingUnit"
        label="Verpackungseinheit"
      />
      <div />

      <FormKit
        v-if="isPackagingUnit"
        type="number"
        number="integer"
        name="packagingLotSize"
        label="Stückzahl in Verpackungseinheit"
        placeholder="800"
        validation="required"
      />

      <FormKitFileDropzone
        name="examplePhotos"
        wrap-path-in-object
        label="Musterfotos"
        outer-class="col-span-2"
      />

      <FormKitFileDropzone
        name="exampleDocuments"
        wrap-path-in-object
        label="Musterdokumente"
        outer-class="col-span-2"
      />
    </div>
  </FormKit>
</template>
