<script setup lang="ts">
import { normalizeExternalVideoURL } from './common'
import type { UploadedFile } from '~/types'

defineProps<{
  file: UploadedFile
  allowDelete: boolean
  alt: string
  iconSize: string
  isPdf: boolean
  isVideo: boolean
  isExternalVideo: boolean
}>()

defineEmits<{
  delete: []
}>()

// Generated via https://blurha.sh/ with an image of a forklift provided by HOS
const FALLBACK_BLURHASH = 'LkHCG[D%x]WV?wRjs:WB-;xuV@of'
</script>

<template>
  <div class="FileContainer group relative">
    <UnLazyImage
      v-if="file.hasPreview"
      :blurhash="FALLBACK_BLURHASH"
      :src="String(file.filekey)"
      :alt
      class="object-cover cursor-pointer h-full w-full"
    />
    <Icon v-else-if="isPdf" name="material-symbols:description-outline-rounded" :size="iconSize" class="DocumentIcon text-red-700 rounded" />
    <Icon v-else-if="isVideo" name="material-symbols:video-file-outline-rounded" :size="iconSize" class="DocumentIcon text-green-700 rounded" />
    <Icon v-else name="material-symbols:note-outline-rounded" :size="iconSize" class="DocumentIcon text-blue-700 rounded" />

    <div class="hidden group-hover:flex absolute right-[8px] top-[8px] gap-1" @click.stop>
      <nuxt-link :href="isExternalVideo ? normalizeExternalVideoURL(file.filekey) : file.filekey" target="_blank" rel="noopener">
        <n-button small color="#8a2be2">
          <template #icon>
            <Icon v-if="isExternalVideo" name="material-symbols:open-in-new-rounded" />
            <Icon v-else name="material-symbols:download" />
          </template>
        </n-button>
      </nuxt-link>
      <n-button v-if="allowDelete" small color="red" @click="$emit('delete')">
        <template #icon>
          <Icon name="material-symbols:close" />
        </template>
      </n-button>
    </div>
  </div>
</template>

<style scoped>
.FileContainer {
  @apply bg-gray-200 w-full aspect-square flex justify-center items-center rounded overflow-hidden
}
</style>
