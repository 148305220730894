import { endOfToday } from 'date-fns'

export default (isSold: boolean) => {
  return isSold
    ? {
        AND: {
          soldAt: { lte: endOfToday() },
        },
      }
    : {
        AND: {
          OR: [
            { soldAt: { gt: endOfToday() } },
            { soldAt: null },
          ],
        },
      }
}
