<script setup lang="ts">
import type { UpdateOrCreatePositionTemplateBundle } from '~/types'

const { openPositionTemplateBundleCreateOrUpdatePopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const positionTemplateBundleId = computed(() => popupData.value?.mode === 'update' ? popupData.value.id : undefined)

const { positionTemplateBundle: queryPositionTemplateBundle } = useQuery()
const { data: positionTemplateBundle, isFetching: isFetchingPositionTemplateBundle } = queryPositionTemplateBundle.byId(positionTemplateBundleId)

const payload = computed((): UpdateOrCreatePositionTemplateBundle | undefined => {
  if (!popupData.value) {
    return undefined
  }

  if (popupData.value.mode === 'create') {
    return {
      mode: 'create',
      data: {
        title: '',
        templateToBundleRelations: [],
        offerType: popupData.value.offerType,
      },
    }
  }

  if (!positionTemplateBundle.value || isFetchingPositionTemplateBundle.value) {
    return undefined
  }

  const data = {
    ...positionTemplateBundle.value,
    templateToBundleRelations: positionTemplateBundle.value.templateToBundleRelations.toSorted((a, b) => a.order - b.order),
  }

  return { mode: 'update', data }
})

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const create = useMutation({
  mutationFn: $trpc.positionTemplateBundle.create.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Positionsvorlage konnte nicht erstellt werden' }),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['positionTemplateBundle'] })
    closePopup()
    notification.success({ title: 'Die Positionsvorlage wurde erfolgreich erstellt', duration: 4500 })
  },
})

const update = useMutation({
  mutationFn: $trpc.positionTemplateBundle.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Positionsvorlage konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['positionTemplateBundle'] })
    closePopup()
    notification.success({ title: 'Die Positionsvorlage wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

function submit(payload: UpdateOrCreatePositionTemplateBundle) {
  if (payload.mode === 'create') {
    create.mutate(payload.data)
  } else {
    update.mutate(payload.data)
  }
}
</script>

<template>
  <ThePopup
    v-if="popupData && payload"
    :show="true"
    :title="`Positionsvorlage ${popupData.mode === 'create' ? 'erstellen' : 'bearbeiten'}`"
    @close="closePopup"
  >
    <PositionTemplateBundleCreateOrEditForm :payload="payload" @submit="submit" />
  </ThePopup>
</template>
