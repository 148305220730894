<script setup lang="ts">
import type { UpdateOrCreateItemSet } from '~/types'

const { openSetCreateOrUpdatePopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const { itemSet: queryItemSet } = useQuery()

const itemSetId = computed(() => {
  if (popupData.value?.mode === 'update') {
    return popupData.value.id
  }
  return undefined
})
const { data: itemSet } = queryItemSet.byIdWithOngoingOfferPositions(itemSetId)

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()
const update = useMutation({
  mutationFn: $trpc.itemSet.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Aktualisierung kann nicht durchgeführt werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['itemSet'] }),
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
    ])
    closePopup()
    notification.success({ title: 'Die Aktualisierung wurde erfolgreich durchgeführt', duration: 4500 })
  },
})

const create = useMutation({
  mutationFn: $trpc.itemSet.create.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Das Set konnte nicht erstellt werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['itemSet'] })
    closePopup()
    notification.success({ title: 'Das Set wurde erfolgreich erstellt', duration: 4500 })
  },
})

const isCurrentlyInOffer = computed(() => itemSet.value?.relatedOfferPositions.some(offerPosition => offerPosition.issuedAt && !offerPosition.returnedAt) ?? false)

const payload = computed((): UpdateOrCreateItemSet | undefined => {
  if (!popupData.value) {
    return undefined
  }

  if (popupData.value.mode === 'create') {
    return {
      mode: 'create',
      data: {
        title: '',
        priceInEuro: 0,
        description: '',
        machineryAccessoryIds: [],
        photos: [],
        documents: [],
      },
    }
  }

  if (!itemSet.value) {
    return undefined
  }

  return {
    mode: 'update',
    data: {
      ...itemSet.value,
      machineryAccessoryIds: itemSet.value.machineryAccessories.map(({ id }) => id),
    },
  }
})

function submit(payload: UpdateOrCreateItemSet) {
  if (payload.mode === 'create') {
    create.mutate(payload.data)
  } else {
    update.mutate(payload.data)
  }
}
</script>

<template>
  <ThePopup v-if="payload && popupData" :show="Boolean(payload)" :title="`${popupData.mode === 'create' ? 'Set-Anlage' : `Set ${popupData.id}`}`" width="1100px" @close="closePopup">
    <SetCreateOrEditForm :payload="payload" :is-currently-in-offer="isCurrentlyInOffer" :ongoing-offer-positions="itemSet?.relatedOfferPositions" :item-set-storage-location="itemSet?.storageLocation ?? undefined" @submit="submit" />
  </ThePopup>
</template>
