<script setup lang="ts">
import type { DataTableRowKey } from 'naive-ui'
import type { OfferType } from '~/types'

type ClosePopupFunction = () => void
const props = withDefaults(defineProps<{ offerType: OfferType, alreadyCheckedRowKeys: string[], closeTemplatePositionPopup: ClosePopupFunction, showPopup: boolean, isBundle?: boolean }>(), { alreadyCheckedRowKeys: () => [] })

const emit = defineEmits<{
  submit: [selectedPositionTemplateIds: string[]]
  submitToBundle: [templates: { id: string, title: string }[]]
}>()

const { positionTemplatesMultiSelect: { columns, fulltextSearch } } = useTableColumnConfigs()

const { fulltextSearchValue, where: fulltextSearchWhere } = useFilterData(fulltextSearch)

const checkedRowKeys = ref<DataTableRowKey[]>(props.alreadyCheckedRowKeys as DataTableRowKey[])

const positionTemplateWhere = computed(() => {
  if (props.offerType === 'sale') {
    return {
      ...fulltextSearchWhere.value,
      canUseSale: true,
    }
  }

  if (props.offerType === 'service-project') {
    return {
      ...fulltextSearchWhere.value,
      canUseServiceProject: true,
    }
  }

  return {
    ...fulltextSearchWhere.value,
    canUseRental: true,
  }
})

const checkedPositionTemplateWhere = computed(() => {
  return {
    id: { in: [...checkedRowKeys.value] },
  }
})

const { positionTemplate } = useQuery()
const { data: positionTemplates, error, isFetching } = positionTemplate.all(positionTemplateWhere, supportedLocaleOptions)
const { data: checkedPositionTemplates } = positionTemplate.all(checkedPositionTemplateWhere, supportedLocaleOptions)

function submit() {
  const templateIds = checkedRowKeys.value as string[]
  const templates = checkedPositionTemplates.value
    ?.map(template => ({ id: template.id, title: template.titles.find(title => title.locale === 'de')?.title ?? template.id })) ?? []

  if (props.isBundle) {
    emit('submitToBundle', templates)
  } else {
    emit('submit', templateIds)
  }
}
</script>

<template>
  <ThePopup v-if="showPopup" :show="showPopup" title="" width="950px" @close="closeTemplatePositionPopup">
    <TheDataCard title="Zusatzpositionen" :error="error">
      <TableFilters v-model="fulltextSearchValue" :placeholder="fulltextSearch.placeholder">
        <TableView
          :data="positionTemplates"
          :columns="columns"
          :is-loading="isFetching"
          :row-key="row => row.id"
          :checked-row-keys="checkedRowKeys"
          @update:checked-row-keys="keys => checkedRowKeys = keys"
        />
      </TableFilters>
      <n-button type="primary" @click="submit">
        <template #icon>
          <Icon name="material-symbols:add-link" />
        </template>
        {{ checkedRowKeys.length }} ausgewählte Positionen hinzufügen
      </n-button>
    </TheDataCard>
  </ThePopup>
</template>
