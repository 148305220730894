<script setup lang="ts">
import { createInput } from '@formkit/vue'
import TheMultipleCheckbox from './CreateInputBase/MultipleCheckbox.vue'

const multipleCheckbox = createInput(TheMultipleCheckbox, { props: ['multiSelectOptions', 'selectAllSwitch'] })
</script>

<template>
  <FormKit :type="multipleCheckbox" />
</template>
