<script setup lang="ts">
import type { UpdateOfferPositionNotReturnable } from '~/types'

const props = defineProps<{ payload: UpdateOfferPositionNotReturnable }>()
const emit = defineEmits<{ (e: 'save', payload: UpdateOfferPositionNotReturnable): void }>()

const { cloned } = useCloned(props.payload)

watch(() => cloned.value.isNotReturnable, (isNotReturnable) => {
  if (!isNotReturnable) {
    cloned.value.notReturnableReason = undefined
  }
})

const formkitPlugins = useFormkitPlugins()
</script>

<template>
  <FormKit
    v-model="cloned"
    type="form"
    :plugins="[formkitPlugins]"
    submit-label="Speichern"
    @submit="emit('save', cloned)"
  >
    <FormKit
      id="isNotReturnable"
      name="photos"
      type="checkbox"
      label="ist nicht rücknahmefähig"
    />
    <FormKit
      v-if="cloned.isNotReturnable"
      id="notReturnableReason"
      type="text"
      validation="required"
      label="Erklärung warum das Gerät nicht rückgabefähig ist"
      placeholder="Motor springt nicht an"
    />
  </FormKit>
</template>
