<script setup lang="ts">
const { t: $t } = useI18n()

import type { CreateBasePrices, UpdateBasePrice } from '~/server/trpc/routers/shop/rentalPricing'
import type { RentalPricingDataType } from '~/types'

const props = defineProps<{
  id?: string
  type: RentalPricingDataType
  // machineryId to create base price for a specific machinery
  // this is required when creating a base price for a specific machinery
  machineryId?: string
}>()

const emit = defineEmits(['close'])

// Data
const { rentalPricing } = useQuery()
const { data: existingBasePriceData } = rentalPricing.basePriceById(computed(() => props.id))
const isMachineryType = computed(() => props.type === 'machineryType')
const isCreateMode = computed(() => !props.id)
const title = computed(() => $t(`rentalBasePrice.${isMachineryType.value ? 'machineryType' : 'machinery'}.${isCreateMode.value ? 'create' : 'edit'}.title`))

// We can create prices based on existing prices (which would look like editing)
const payload = ref()
watch(existingBasePriceData, (newData) => {
  // Payload already created or no data when it should be
  if (payload.value || (props.id && !newData)) {
    return
  }

  // Editing a single BasePrice
  if (newData) {
    payload.value = {
      id: newData.id,
      valueInEuros: newData.valueInEuros,
    }
    return
  }

  const machineryTypes = isMachineryType.value ? [] : undefined
  const machineries = isMachineryType.value ? undefined : [] as string[]
  if (machineries && props.machineryId) {
    machineries.push(props.machineryId)
  }

  // Batch-creating new BasePrices
  payload.value = {
    effectiveFrom: new Date(),
    machineryTypes,
    machineries,
  }
}, { immediate: true })

// Mutations
const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()
const createBasePrices = useMutation({
  mutationFn: $trpc.shop.rentalPricing.createBasePrices.mutate,
  onError: makeTrpcErrorToast(notification, {
    description: `Basispreis(e) konnte(n) nicht erstellt werden`,
  }),
  async onSuccess(createResult) {
    await queryClient.invalidateQueries({ queryKey: ['rentalPricing'] })

    closePopup()
    notification.success({
      title: `${createResult.newPrices} neue Basispreise wurden erfolgreich erstellt`,
      duration: 4500,
    })
  },
})
const updateBasePrice = useMutation({
  mutationFn: $trpc.shop.rentalPricing.updateBasePrice.mutate,
  onError: makeTrpcErrorToast(notification, {
    description: `Der Basispreis konnte nicht bearbeitet werden`,
  }),
  async onSuccess() {
    await queryClient.invalidateQueries({ queryKey: ['rentalPricing'] })

    closePopup()
    notification.success({
      title: `Der Basispreis wurde erfolgreich erstellt`,
      duration: 4500,
    })
  },
})

function submit(payload: CreateBasePrices | UpdateBasePrice) {
  if ('id' in payload) {
    updateBasePrice.mutate(payload)
  } else {
    createBasePrices.mutate(payload)
  }
}
function closePopup() {
  emit('close')
}
</script>

<template>
  <ThePopup
    v-if="payload"
    show
    :title
    @close="closePopup"
  >
    <FormKit type="form" :value="payload" @submit="submit">
      <!--
        At the moment a BasePrice can be created in batch only for MachineryType
        https://github.com/sidestream-tech/hanselmann-os/pull/3640#pullrequestreview-2418901656
      -->
      <RentalMachineryTypeMultiSelectionInput
        v-if="isMachineryType && isCreateMode"
        v-model="payload.machineryTypes"
        :disallow-duplicate-base-price-selection="true"
      />
      <!-- If machinery id was passed we only allow to create base price for the specific machinery -->
      <!-- <RentalMachineryMultiSelectionInput
        v-else-if="!machineryId"
        v-model="payload.machineries"
      /> -->

      <FormKit
        name="valueInEuros"
        type="number"
        label="Basispreis in EUR"
        min="0.01"
        step="0.01"
        number="float"
        validation="required"
      />
    </FormKit>
  </ThePopup>
</template>
