<script setup lang="ts">
import { offerUpdateSchema } from '~/server/schemas'

const { openOfferCompleteProjectPopup: { data: payload, close: closePopup } } = useGlobalOpeners()
const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const router = useRouter()
const { offer: queryOffer } = useQuery()
const { data: offer, isLoading } = queryOffer.byId(computed(() => payload.value?.offerId))

let offerData: Ref<typeof offer.value> | undefined

watch(offer, (newOffer) => {
  if (newOffer && !offerData) {
    offerData = useMightyCloned(newOffer).cloned
  }
}, {
  immediate: true,
})

// Form
const formkitPlugins = useFormkitPlugins()

const isCustomerAbsent = ref(false)
const customerSignatureValidation = computed(() => {
  return isCustomerAbsent.value ? '' : 'required'
})

function saveForm() {
  if (!offerData || !offerData.value) {
    return
  }

  update.mutate(offerUpdateSchema.parse(offerData.value))
}

function closePage() {
  return router.push({ path: '/service-project/completed' })
}

// Mutation
const update = useMutation({
  mutationFn: $trpc.offer.completeServiceProject.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Projekt konnte nicht abgeschlossen werden' }),
  onSuccess: async (offer) => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['offer', offer.id ?? null] }),
    ])

    closePage()
    notification.success({ title: 'Projekt wurde erfolgreich abgeschlossen', duration: 4500 })
  },
})
</script>

<template>
  <ThePopup v-if="payload" :show="Boolean(payload)" title="Abschlussreport erstellen" @close="closePopup">
    <div v-if="isLoading || !offerData">
      Daten werden für den Projekt abschluss geladen...
    </div>
    <div v-else>
      <FormKit
        v-model="offerData"
        type="form"
        submit-label="Speichern"
        :plugins="[formkitPlugins]"
        @submit="saveForm"
      >
        <FormKitDate
          id="obligationActuallyEndedAt"
          label="Datum Projektabschluss"
          validation="required"
        />
        <FormKitRadioWithReset
          id="wasFullyCompleted"
          label="Ist das Projekt vollständig abgeschlossen?"
          validation="required"
        />
        <FormKit
          id="wasFullyCompletedComment"
          label="Bemerkung"
          type="text"
          placeholder="Verladung der Ware durch Hallendach mit drei Schwerlastzügen"
        />
        <FormKitRadioWithReset
          id="hadExtraExpenses"
          label="Sind ungeplante Aufwendungen entstanden?"
          validation="required"
        />
        <FormKit
          id="hadExtraExpensesComment"
          label="Bemerkung"
          type="text"
          placeholder="Verladung der Ware durch Hallendach mit drei Schwerlastzügen"
        />
        <FormKitRadioWithReset
          id="hadCustomerDelays"
          label="Gab es zu dem Projekt kundenseitige Verzögerungen?"
          validation="required"
        />
        <FormKit
          id="hadCustomerDelaysComment"
          label="Bemerkung"
          type="text"
          placeholder="Verladung der Ware durch Hallendach mit drei Schwerlastzügen"
        />
        <FormKitRadioWithReset
          id="hadExternalDelays"
          label="Gab es zu dem Projekt externe Verzögerungen?"
          validation="required"
        />
        <FormKit
          id="hadExternalDelaysComment"
          label="Bemerkung"
          type="text"
          placeholder="Verladung der Ware durch Hallendach mit drei Schwerlastzügen"
        />
        <FormKitRadioWithReset
          id="hadExtraMaterials"
          label="Wurde zusätzliches Material / Maschinen benötigt?"
          validation="required"
        />
        <FormKit
          id="hadExtraMaterialsComment"
          label="Bemerkung"
          type="text"
          placeholder="Verladung der Ware durch Hallendach mit drei Schwerlastzügen"
        />
        <FormKitRadioWithReset
          id="hadExtraWorkDone"
          label="Wurde zusätzliche Arbeit durchgeführt?"
          validation="required"
        />
        <FormKit
          id="hadExtraWorkDoneComment"
          label="Bemerkung"
          type="text"
          placeholder="Verladung der Ware durch Hallendach mit drei Schwerlastzügen"
        />
        <FormKitRadioWithReset
          id="hadDefects"
          label="Gab es Reklamationen / Beschädigungen?"
          validation="required"
        />
        <FormKit
          id="hadDefectsComment"
          label="Bemerkung"
          type="text"
          placeholder="Verladung der Ware durch Hallendach mit drei Schwerlastzügen"
        />
        <FormKit
          id="completionComment"
          label="Sonstiger Kommentar"
          type="textarea"
          placeholder="Verladung der Ware durch Hallendach mit drei Schwerlastzügen"
        />
        <FormKitFileDropzone
          id="completionPhotos"
          accept="image/png,image/jpg,image/jpeg"
          label="Fotos"
          wrap-path-in-object
        />

        <n-divider />

        <div class="flex flex-col gap-2 my-2">
          <FormKit
            v-model="isCustomerAbsent"
            label="Kunde nicht anwesend"
            type="checkbox"
          />
          <div class="flex gap-5">
            <FormKit
              id="customerName"
              label="Kunden Name"
              type="text"
            />
            <FormKitSignaturePopup
              id="customerSignature"
              label="Kunden Signatur"
              title="Ich bestätige, dass das Projekt abgeschlossen würde."
              :validation="customerSignatureValidation"
              :default="undefined"
              :disable-by-pass="true"
              :disable-positions-overview="true"
            />
          </div>
          <div class="flex gap-5">
            <FormKit
              id="projectLeadName"
              label="Projektleiter Name"
              type="text"
            />
            <FormKitSignaturePopup
              id="projectLeadSignature"
              label="Projektleiter Signatur"
              title="Ich bestätige, dass das Projekt abgeschlossen würde."
              button-text="Unterschrift des Projektleiters"
              validation="required"
              :default="undefined"
              :disable-by-pass="true"
              :disable-positions-overview="true"
            />
          </div>
        </div>
      </FormKit>
      <TheDevOnlyNiceJson :value="offerData" />
    </div>
  </ThePopup>
</template>
