<script setup lang="ts">
import { NEllipsis } from 'naive-ui'

const props = defineProps<{ text: string }>()
</script>

<template>
  <div>
    <n-ellipsis :tooltip="false" class="text-sm pl-1">
      {{ props.text }}
    </n-ellipsis>
  </div>
</template>
