<script setup lang="ts">
import type { DataTableRowKey } from 'naive-ui'

const props = withDefaults(defineProps<{ alreadyCheckedRowKeys: DataTableRowKey[], selectType?: 'multiple' | 'single' }>(), {
  alreadyCheckedRowKeys: () => [],
  selectType: 'multiple',
})
const emit = defineEmits<{ (e: 'makeCompatible', machineryAccessoryIds: string[]): void }>()

const { machineryAccessorySelect } = useTableColumnConfigs()
const tableConfig = machineryAccessorySelect({ selectType: props.selectType, isSetSelectable: true })

const { fulltextSearchValue, where: fulltextSearchWhere } = useFilterData(tableConfig.value.fulltextSearch)

const { machineryAccessory } = useQuery()
const { data: accessories, error, isLoading } = machineryAccessory.all(fulltextSearchWhere)

const checkedRowKeys = ref<DataTableRowKey[]>(props.alreadyCheckedRowKeys)
</script>

<template>
  <TheDataCard title="Kompatible Anbaugeräte" :error="error">
    <TableFilters v-model="fulltextSearchValue" :placeholder="tableConfig.fulltextSearch.placeholder">
      <TableView
        v-if="accessories"
        :data="accessories"
        :columns="tableConfig.columns"
        :is-loading="isLoading"
        :row-key="row => row.id"
        :checked-row-keys="checkedRowKeys"
        :row-props="tableConfig.rowProps"
        @update:checked-row-keys="keys => checkedRowKeys = keys"
      />
    </TableFilters>
    <n-button type="primary" class="mt-4" @click="emit('makeCompatible', checkedRowKeys as string[])">
      <template #icon>
        <Icon name="material-symbols:add-link" />
      </template>
      Maschine mit {{ checkedRowKeys.length }} ausgewählten Anbaugeräten kompatibel machen
    </n-button>
  </TheDataCard>
</template>
