<script lang="ts" setup>
import { joinURL } from 'ufo'

const { data: popupData, close } = useGlobalOpeners().openInboundLogisticsTaskPrintDeliverySlipPopup

const { data: inboundLogisticsTask } = useQuery().inboundLogisticsTask.byId(computed(() => popupData.value?.id))
function navigateToDeliveryPDF() {
  if (inboundLogisticsTask.value) {
    window.open(joinURL('/api/pdf/inboundLogisticsTask/delivery', inboundLogisticsTask.value.cuid), '_blank')
  }
}
</script>

<template>
  <ThePopup
    v-if="popupData"
    :show="true"
    :title="`Lieferschein ${popupData.id}`"
    width="900px"
    @close="close"
  >
    <div v-if="inboundLogisticsTask" class="flex flex-col gap-y-4">
      <n-button class="w-72" @click="navigateToDeliveryPDF">
        Lieferschein erstellen und drucken
      </n-button>
      <FileView label="Lieferscheine (extern)" :files="inboundLogisticsTask.deliverySlips" />
    </div>
  </ThePopup>
</template>
