<script setup lang="ts">
const { openLogisticsStoreMachineryScanPopup: { data: popupData, close: closePopup_ } } = useGlobalOpeners()

const errorText = ref<string | undefined>(undefined)
const enteredId = ref('')

function openUpdateStorageLocationPopup(id: string) {
  if (!popupData.value) {
    return
  }

  openLogisticsStorageLocationPopup.open({ id, model: popupData.value.model })

  closePopup()
}

function decodeQrCode(decoded: string) {
  const id = decoded.split('/').at(-1) ?? decoded

  if (!popupData.value) {
    return
  }
  if (id !== popupData.value.id) {
    errorText.value = `Gescannte ID ${id} stimmt nicht überein`
    return
  }
  return openUpdateStorageLocationPopup(id)
}

function closePopup() {
  closePopup_()
  errorText.value = undefined
}

const { openLogisticsStorageLocationPopup } = useGlobalOpeners()
const isQRScanType = computed(() => popupData.value?.type === 'scanQR')
</script>

<template>
  <ThePopup
    v-if="popupData"
    :show="Boolean(popupData)"
    :title="isQRScanType ? `Scanne ${popupData.id}` : 'Geben Sie die ID an'"
    @close="closePopup"
  >
    <div v-if="errorText">
      <span class="font-bold text-red-500">{{ errorText }}</span>
    </div>
    <TheQRCodeScanner v-if="isQRScanType" @decode-qr-code="decodeQrCode" />
    <div v-else class="flex flex-col gap-4">
      <n-input v-model:value="enteredId" class="mb-2" placeholder="a00006" />
      <n-button type="primary" @click="decodeQrCode(enteredId)">
        Speichern
      </n-button>
    </div>
  </ThePopup>
</template>
