<script setup lang="ts">
import { endOfDay, startOfDay } from 'date-fns'
import type { FormKitOptionsPropWithGroups } from '@formkit/inputs'
import type { ApiLogisticsTaskGetById, CreateLogisticsTask, ShoppingCartPosition } from '~/types'

type TransformedLogisticsTask = Omit<ApiLogisticsTaskGetById, 'positionsToShip'> & { positionsToShip: string[] | undefined }

const props = defineProps<{
  logisticsTask?: TransformedLogisticsTask | null
  isReadOnly: boolean
  offer: {
    obligationStartsAt: Date
    obligationEndsAt: Date | null
  }
  positionsOptions: FormKitOptionsPropWithGroups
  existingTrailerPosition?: ShoppingCartPosition
}>()

const emits = defineEmits<{ (e: 'save', data: CreateLogisticsTask): void }>()

const showTrailerOptions = ref(Boolean(props.existingTrailerPosition))
const cartPositions = ref<ShoppingCartPosition[]>(props.existingTrailerPosition ? [props.existingTrailerPosition] : [])

const offerStartDateAsString = computed(() => useDateAsString(startOfDay(props.offer.obligationStartsAt), 'yyyy-MM-dd\'T\'HH:mm'))
const offerEndDateAsString = computed(() => props.offer.obligationEndsAt ? useDateAsString(endOfDay(props.offer.obligationEndsAt), 'yyyy-MM-dd\'T\'HH:mm') : '')

const plannedDeliveryAtAsString = ref(props.logisticsTask?.plannedDeliveryAt ? useDateAsString(startOfDay(props.logisticsTask.plannedDeliveryAt), 'yyyy-MM-dd\'T\'HH:mm') : offerStartDateAsString)

function save(data: CreateLogisticsTask) {
  const dataWithTrailer = {
    ...data,
    trailerPosition: showTrailerOptions.value ? cartPositions.value[0] : undefined,
  }
  emits('save', dataWithTrailer)
}

const formkitPlugins = useFormkitPlugins()
</script>

<template>
  <n-alert v-if="isReadOnly" type="warning">
    Diese Logistik ist nicht mehr bearbeitbar.
  </n-alert>

  <FormKit
    v-slot="{ value }"
    type="form"
    submit-label="Lieferung speichern"
    :disabled="isReadOnly"
    :value="logisticsTask ?? undefined"
    :plugins="[formkitPlugins]"
    @submit="save"
  >
    <FormKitDateTime
      id="plannedDeliveryAt"
      v-model="plannedDeliveryAtAsString"
      label="Anlieferdatum und Zeit"
      :validation="`required|date_before:${offerEndDateAsString}`"
      validation-visibility="live"
      :validation-messages="{
        date_before: `Anlieferdatum kann nicht später als Enddatum sein.`,
      }"
    />
    <FormKit
      v-model="showTrailerOptions"
      type="checkbox"
      label="Lieferung mit Trailer"
    />
    <div v-if="showTrailerOptions">
      <ShoppingAccessoryDetailsByCategory
        v-model="cartPositions"
        selected-tab="incompatible"
        compatability="incompatible"
        add-category-button-text="Trailer bei der Ausgabe bestimmen"
        selected-machinery-accessory-category="trailer"
        select-type="single"
        :machinery-id="undefined"
        :show-selection="true"
        :is-accessory-only-offer="true"
        :offer-start-day="plannedDeliveryAtAsString ? new Date(plannedDeliveryAtAsString) : new Date(offerStartDateAsString)"
        :offer-end-day="offerEndDateAsString ? new Date(offerEndDateAsString) : undefined"
        :offer="{ status: 'order' }"
        :disabled-accessory-ids="[]"
      />
    </div>
    <FormKit
      id="doesFitterDoTask"
      :value="false"
      type="checkbox"
      label="Durchgeführt von Monteur"
    />
    <FormKit
      id="doAllowPositionAdditionDuringIssuance"
      :value="false"
      type="checkbox"
      label="Bei der Ausgabe weitere Lagertool Geräte hinzufügen"
    />
    <FormKit
      v-if="value?.doAllowPositionAdditionDuringIssuance"
      id="positionAdditionDuringIssuanceComment"
      value=""
      type="text"
      label="Informationen zu den zusätzlichen Geräten"
      :validation="`${value?.doAllowPositionAdditionDuringIssuance ? 'required' : ''}`"
    />
    <FormKitMultipleCheckbox
      id="positionsToShip"
      :multi-select-options="positionsOptions"
      label="Positionen"
      :validation="`${value?.doAllowPositionAdditionDuringIssuance ? '' : 'required|length_min:1'}`"
    />
    <TheDevOnlyNiceJson :cart-positions="cartPositions" :value="value" />
  </FormKit>
</template>
