/**
 * Adapted from:
 * - https://github.com/nuxt/nuxt/pull/19086#issuecomment-1553385289 (idea approved by @danielroe one comment below)
 * - https://github.com/nuxt/nuxt/blob/f209158352b09d1986aa320e29ff36353b91c358/packages/nuxt/src/app/plugins/chunk-reload.client.ts
 *
 */
export default defineNuxtPlugin({
  name: 'sidestream:chunk-reload',
  setup(nuxtApp) {
    nuxtApp.hook('app:chunkError', () => {
      reloadNuxtApp()
    })
  },
})
