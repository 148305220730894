import currency from 'currency.js'
import options from '../../options'
import type { PDFDocumentType } from '../../type'
import { maxWidth, reset } from '../../helpers'
import type { Invoice } from '../../getInvoice'
import header from '../header'

type InvoiceToCalculateTotals = Pick<Invoice, 'totalAmount' | 'includeVAT'> & {
  offer: {
    customer: { taxRate: string | null }
    otherInvoiceRecipient: { taxRate: string | null } | null
  }
}

/* ------ DYNAMIC DATA ------ */
export function calculateTotals(invoice: InvoiceToCalculateTotals) {
  const invoiceRecipient = invoice.offer.otherInvoiceRecipient ?? invoice.offer.customer

  const netto = currency(invoice.totalAmount)
  const vat = invoice.includeVAT ? netto.multiply(Number(invoiceRecipient.taxRate)) : netto.multiply(0)
  const brutto = netto.add(vat)

  return { netto, brutto, vat }
}

function getData(invoice: Invoice, i18n: I18n) {
  const { paymentCondition } = invoice.offer
  const invoiceRecipient = invoice.offer.otherInvoiceRecipient ?? invoice.offer.customer
  const { netto: totalNetto, brutto: totalBrutto, vat } = calculateTotals(invoice)

  return {
    paymentCondition: {
      label: `${i18n.t('pdf.invoice.paymentTerms')}: `,
      value: i18n.t(`paymentConditions.${paymentCondition}`),
    },
    paymentLines: [
      { label: i18n.t('pdf.invoice.netTotal'), middle: '€', value: formatNumberToString(totalNetto.value, i18n.locale) },
      { label: `+ ${i18n.t('pdf.invoice.vat')} ${vat.value ? formatNumberToString(Number(invoiceRecipient.taxRate) * 100, i18n.locale) : formatNumberToString(vat.value, i18n.locale)}`, middle: '%', value: formatNumberToString(vat.value, i18n.locale) },
      { label: i18n.t('pdf.invoice.grossTotal'), middle: '€', value: formatNumberToString(totalBrutto.value, i18n.locale) },
    ],
  }
}
/* -------------------------- */

/* ------ VARIABLE OPTIONS ------ */
const indent = 30
const preValueGap = 20
const textFont = options.fontFamily.base
const textBruttoBetragFont = options.fontFamily.bold
/* ------------------------------ */

export default function (doc: PDFDocumentType<Invoice>, i18n: I18n, extraPaddingBelow = 0) {
  const { paymentCondition, paymentLines } = getData(doc.data, i18n)

  /* ------ INLINE OPTIONS ------ */
  reset(doc)
    .font(textFont)
  /* ---------------------------- */

  /* --------- RENDER CODE ------ */
  // Payment Lines
  const labelWidth = Math.max(maxWidth(doc, [paymentLines[0].label, paymentLines[2].label]), doc.widthOfString(paymentLines[1].label) + indent)
  const valueWidth = maxWidth(doc, paymentLines.map(({ value }) => value))
  const middleWidth = maxWidth(doc, paymentLines.map(({ middle }) => middle))
  const leftX = doc.page.width - options.margin.right - labelWidth - doc.widthOfString(' ') - middleWidth - preValueGap - valueWidth
  const midX = leftX + labelWidth + doc.widthOfString(' ')
  const rightX = midX + middleWidth + preValueGap + valueWidth
  const extraHeight = 10

  if (doc.y + extraHeight + doc.currentLineHeight(true) * paymentLines.length > doc.footerStartY + doc.currentLineHeight(true)) {
    doc.addPage()
    header(doc)
  } else {
    doc.y = doc.footerStartY - doc.currentLineHeight(true) - extraPaddingBelow
  }

  doc
    .text(paymentLines[0].label, leftX, doc.y, { lineBreak: false })
    .text(paymentLines[0].middle, midX, doc.y, { lineBreak: false })
    .text(paymentLines[0].value, rightX - doc.widthOfString(paymentLines[0].value))

  doc
    .text(paymentLines[1].label, leftX + indent, doc.y, { lineBreak: false })
    .text(paymentLines[1].middle, midX, doc.y, { lineBreak: false })
    .text(paymentLines[1].value, rightX - doc.widthOfString(paymentLines[1].value))

  doc
    .font(textBruttoBetragFont)
    .text(paymentLines[2].label, leftX, doc.y, { lineBreak: false })
    .text(paymentLines[2].middle, midX, doc.y, { lineBreak: false })
    .text(paymentLines[2].value, rightX - doc.widthOfString(paymentLines[2].value))
    .font(textFont)

  // Payment Condition
  const paymentLabelWidth = maxWidth(doc, [paymentCondition.label])
  const paymentLabelX = options.margin.right
  const paymentValueX = paymentLabelX + paymentLabelWidth + doc.widthOfString(' ')

  doc.moveUp(1)

  doc
    .text(paymentCondition.label, paymentLabelX, doc.y, { lineBreak: false })
    .text(paymentCondition.value, paymentValueX)

  return reset(doc)
  /* ---------------------------- */
}
