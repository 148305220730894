<script setup lang="ts">
import { createInput } from '@formkit/vue'
import DateInput from './CreateInputBase/DateNaiveUi.vue'

const dateInput = createInput(DateInput, {
  props: ['transformDate', 'isDateDisabled', 'isTimeDisabled', 'format', 'timePickerProps', 'inputType', 'size', 'disabled'],
})
</script>

<template>
  <FormKit :type="dateInput" />
</template>
