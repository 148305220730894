<script lang="ts" setup>
import { groupInvoiceOfferPositionsByIdWeekly } from './utils'
import { invoiceStatusToGerman } from '~/translations'
import type { InvoiceStatus } from '~/types'

const { openInvoicePositionsPopup: { data: payload, close: closeInvoicePopup } } = useGlobalOpeners()
const invoiceId = computed(() => payload.value?.invoiceId)

const { invoice: queryInvoice } = useQuery()

const { data: invoice, isLoading: isLoadingInvoice } = queryInvoice.byId(invoiceId)

const { invoiceOfferPositionRow: { columns } } = useTableColumnConfigs()
const invoiceOfferPositionRowWithoutSelection = columns.slice(1)

const invoicePositionsRow = computed(() => groupInvoiceOfferPositionsByIdWeekly(invoice.value))
</script>

<template>
  <ThePopup
    v-if="payload && invoicePositionsRow && invoice"
    width="1024px"
    :show="Boolean(payload && invoicePositionsRow)"
    :title="`Rechnungen ${invoice.invoiceId ?? 'N/A'} (${invoiceStatusToGerman[invoice.status as InvoiceStatus]})`"
    @close="closeInvoicePopup"
  >
    <span v-if="invoice.type === 'downpayment'"><b>Anzahlungsrechnung:</b> {{ invoice.totalAmount }} EUR</span>
    <TableView
      v-else-if="invoicePositionsRow"
      class="mt-4"
      :data="invoicePositionsRow"
      :columns="invoiceOfferPositionRowWithoutSelection"
      :is-loading="isLoadingInvoice"
      :is-paginated="false"
      :row-key="row => row.id"
    />
  </ThePopup>
</template>
