import { can } from '~/authorization'
import type { UserRole } from '~/authorization'

export default () => {
  const { data } = useAuth()
  const role = computed(() => getRoleFromSession(data.value))
  const canAccessPage = (path: string) => can(role.value, path)

  /**
   * Check whether the current user is any of the passed role(s). Always returns `true` if the user is `admin`, unless `isAdminEveryRole` is set to `false`.
   */
  const isRole = (roles: readonly UserRole[] | UserRole = 'admin', isAdminEveryRole = true) => {
    const currentUserRole = role.value

    // Admin has access to every page
    if (currentUserRole === 'admin' && isAdminEveryRole) {
      return true
    }

    // If no role is specified: no access
    if (!roles) {
      return false
    }

    if (typeof roles === 'string') {
      return currentUserRole === roles
    }

    return roles.includes(currentUserRole)
  }

  const useremail = computed(() => data.value?.user?.email ?? 'N/A')
  const username = computed(() => data.value?.user?.name ?? 'N/A')

  return { canAccessPage, isRole, role, username, useremail }
}
