<script setup lang="ts">
const props = defineProps<{ offerId: string }>()

const { offer } = useQuery()
const { data, isLoading } = offer.byIdOnlyPositions(props.offerId)
const orderedPositions = computed(() => data.value?.positions.filter(p => p.groupInOffer === 0).sort(compareOfferPositionsByGroupAndIndex))
</script>

<template>
  <TheDataCard :is-loading="isLoading">
    <template #header>
      <div class="CardHeader">
        <h2>Positionsübersicht</h2>
      </div>
    </template>
    <template #summary>
      <p class="mb-2">
        Dieser Auftrag hat folgende Positionen:
      </p>
    </template>
    <div v-if="data">
      <ul v-if="orderedPositions && orderedPositions.length > 0" class="list-decimal ml-4">
        <li v-for="position of orderedPositions" :key="position.id">
          {{ position.title }}
        </li>
      </ul>
      <p v-else>
        Mit diesem Auftrag sind keine Positionen verknüpft.
      </p>
    </div>
  </TheDataCard>
</template>

<style scoped>
.CardHeader h2 {
  @apply text-lg font-semibold
}
</style>
