<script setup lang="ts">
import QrScanner from 'qr-scanner'

const emit = defineEmits<{ (e: 'decodeQrCode', data: string): boolean, (e: 'startScanning'): void }>()

const isLoading = ref(true)

const scanner = ref()

let qrScanner: QrScanner
const hasFlashLight = ref(false)
function decoded({ data }: { data: string }) {
  emit('decodeQrCode', data)
}

watchEffect(async () => {
  if (scanner.value) {
    qrScanner = new QrScanner(
      scanner.value,
      decoded,
      {
        highlightScanRegion: true,
        highlightCodeOutline: true,
        returnDetailedScanResult: true,
      },
    )
    qrScanner.start()
    hasFlashLight.value = await qrScanner.hasFlash()
    isLoading.value = false
  }
})

onUnmounted(() => {
  qrScanner.destroy()
})
</script>

<template>
  <div class="w-full h-full">
    <client-only placeholder="loading...">
      <div v-if="isLoading">
        Loading...
      </div>
      <video ref="scanner" width="400" height="400" />
      <n-button v-if="hasFlashLight" @click="qrScanner.toggleFlash()">
        Flash
      </n-button>
    </client-only>
  </div>
</template>
