<script lang="ts" setup>
import type { InvoiceToOfferPositionRelation } from '~/server/trpc/routers/invoice'
import type { GeneratedByAutomation, OfferType } from '~/types'
import { unitsToGerman } from '~/translations'

const props = defineProps<{ row: InvoiceToOfferPositionRelation, insuranceId: string | undefined, offerType?: OfferType }>()

const emit = defineEmits<{ (e: 'close'): void }>()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const updateManualPosition = useMutation({
  mutationFn: $trpc.invoice.updateManualPosition.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Position konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['invoice'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['offerPosition'] }),
    ])

    notification.success({ title: 'Die Position wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const updatePricePerUnit = useMutation({
  mutationFn: $trpc.invoice.updatePositionPricePerUnit.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Position konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['invoice'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['offerPosition'] }),
    ])

    notification.success({ title: 'Die Position wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const updateDiscountRateForDay = useMutation({
  mutationFn: $trpc.invoice.updateDiscountRateForDay.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Position konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['invoice'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['offerPosition'] }),
    ])

    notification.success({ title: 'Die Position wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const updateQuantity = useMutation({
  mutationFn: $trpc.invoice.updateGeneratedPositionQuantity.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Position konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['invoice'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['offerPosition'] }),
    ])

    notification.success({ title: 'Die Position wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const discountRateRef = ref(props.row.discountRateForDay ?? props.row.discountRate)

function submitUpdatePosition(data: any) {
  const { id, offerId, type, generatedByAutomation, invoicedDate } = props.row

  if (type === 'manualPosition') {
    updateManualPosition.mutate({
      id,
      pricePerUnit: data.pricePerUnit,
      unit: data.unit,
      quantity: data.quantity,
      discountRate: discountRateRef.value,
    })
  } else if (type === 'generatedByAutomation') {
    updateQuantity.mutate({
      offerId,
      generatedByAutomation: generatedByAutomation as GeneratedByAutomation,
      quantity: data.quantity,
    })
  } else if (invoicedDate) {
    updateDiscountRateForDay.mutate({ id, offerId, invoicedDate, discountRate: discountRateRef.value })
  } else {
    updatePricePerUnit.mutate({
      id,
      pricePerUnit: data.pricePerUnit,
      // Update insurance position if machinery price changes
      insuranceId: type === 'machinery' ? props.insuranceId : undefined,
    })
  }

  emit('close')
}

const formkitPlugins = useFormkitPlugins()
</script>

<template>
  <FormKit
    type="form"
    :plugins="[formkitPlugins]"
    submit-label="Speichern"
    @submit="submitUpdatePosition"
  >
    <div v-if="row.type === 'manualPosition'" class="grid grid-cols-2 gap-2">
      <FormKit
        id="pricePerUnit"
        :value="row.pricePerUnit"
        type="number"
        number
        label="E-Preis (EUR)"
        step=".01"
        min="0"
      />
      <FormKit
        id="unit"
        :value="row.unit"
        type="select"
        :options="Object.entries(unitsToGerman).map(([value, label]) => ({ value, label }))"
        label="Einheit"
      />
      <FormKit
        id="quantity"
        :value="row.quantity"
        type="number"
        number
        label="Menge"
        min="0"
      />
      <ThePercentageInput
        class="mb-4"
        label="Rabatt %"
        :value="discountRateRef"
        :update="(discountRate: number) => discountRateRef = discountRate"
        :quantity="offerType === 'special' ? row.quantity : undefined"
        validation="required"
      />
    </div>

    <div v-else>
      <FormKit
        v-if="row.type === 'generatedByAutomation'"
        id="quantity"
        :value="row.quantity"
        type="number"
        number
        label="Menge"
        min="0"
      />
      <ThePercentageInput
        v-else-if="row.invoicedDate"
        class="mb-4"
        label="Rabatt %"
        :value="discountRateRef"
        :update="(discountRate: number) => discountRateRef = discountRate"
        validation="required"
      />
      <FormKit
        v-else
        id="pricePerUnit"
        :value="row.pricePerUnit"
        type="number"
        number
        label="E-Preis (EUR)"
        min="0"
        step="0.01"
      />
    </div>
  </FormKit>
</template>
