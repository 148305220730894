import type { ApiInvoiceGetById, InvoiceOfferPositionRow } from '~/types'

export function groupInvoiceOfferPositionsByIdWeekly(invoice?: ApiInvoiceGetById | null): InvoiceOfferPositionRow[] {
  if (!invoice) {
    return []
  }

  if (invoice.offer.type === 'sale') {
    return invoice.offerPositions
  }

  const machineryPositions = invoice.offerPositions.find(position => position.offerPosition.type === 'machinery')
  const rows: InvoiceOfferPositionRow[] = []
  const unitPositionsByPositionId = {} as Record<string, [InvoiceOfferPositionRow[]]>
  const sortedOfferPositions = [...invoice.offerPositions].sort((a, b) => compareOfferPositionsByGroupAndIndex(a.offerPosition, b.offerPosition))

  sortedOfferPositions.forEach((position) => {
    if (position.offerPosition.type === 'insurance') {
      rows.push({
        ...position,
        invoicedDate: machineryPositions?.invoicedDate ?? position.offerPosition.relatedOffer.obligationStartsAt,
      })
    } else if (position.offerPosition.unit !== 'day' || position.offerPosition.type === 'extraDays') {
      rows.push(position)
    } else {
      const positionId = position.offerPosition.id
      if (!unitPositionsByPositionId[positionId]) {
        rows.push(position)
        unitPositionsByPositionId[positionId] = [[position]]
      } else if (unitPositionsByPositionId[positionId][unitPositionsByPositionId[positionId].length - 1].length < 7) {
        unitPositionsByPositionId[positionId][unitPositionsByPositionId[positionId].length - 1].push(position)
      } else {
        unitPositionsByPositionId[positionId].push([position])
      }
    }
  })

  return rows.map((row) => {
    if (!shouldOfferPositionBeGroupedByDayOnInvoice(row.offerPosition)) {
      return row
    }

    return {
      ...row,
      id: `${row.id}-grandparent`,
      quantity: unitPositionsByPositionId[row.offerPosition.id].reduce((acc, positions) => acc + positions.length, 0),
      children: unitPositionsByPositionId[row.offerPosition.id].map(positions => ({
        ...positions[0],
        id: `${positions[0].id}-parent`,
        quantity: positions.length,
        children: positions,
      })),
    }
  })
}
