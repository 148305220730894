import type { ZodIssue } from 'zod'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { trpcCustomErrorMessagesToGerman } from '~/translations'

interface OptionsToastMaker {
  title?: string
  description?: string
}

function formatTrpcErrorErrorMessage(error: Error | null): string {
  if (!error || !error.message) {
    return 'unbekannt'
  }

  const translatedError: string | undefined = (trpcCustomErrorMessagesToGerman as Record<string, string>)[error.message]
  if (translatedError) {
    return translatedError
  }

  try {
    // Note: trpc returns zod errors as stringified json
    const zodErrors = JSON.parse(error.message)
    return zodErrors.map((zodError: ZodIssue) => {
      return `${zodError.message} '/${zodError.path.join('/')}'`
    }).join(', ')
  } catch {
    return error.message
  }
}

function makeTrpcErrorToast(notification: ReturnType<typeof useNotification>, options: OptionsToastMaker) {
  return (error: Error | null) => {
    const { description = 'Die Daten-Anpassung konnte leider nicht vorgenommen werden.' } = options
    notification.error({
      content: options.title ?? 'Operation Fehlgeschlagen',
      description: `${description} ${error ? `(Fehler: ${formatTrpcErrorErrorMessage(error)})` : ''}`,
      duration: 1000 * 5,
    })
  }
}

export default () => {
  const { $trpc } = useNuxtApp()
  const queryClient = useQueryClient()

  return {
    $trpc,
    queryClient,
    useMutation,
    makeTrpcErrorToast,
  }
}
