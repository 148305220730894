import { joinURL } from 'ufo'
import type { ApiOfferGetAll, ApiOfferGetById } from '~/types'

const prefixWithIhr = {
  Angebot: 'Ihr Angebot',
  Auftrag: 'Ihren Auftrag',
}

function generateOfferEmailBody(offer: ApiOfferGetAll | ApiOfferGetById, name: string) {
  // Axiom: there can be only one offer position with machinery in it
  const offerMachinery = offer.positions.filter(pos => !!pos.machinery).map(pos => pos.machinery)
  if (offerMachinery.length > 1) {
    throw new Error('There can be only one offer or none position with machinery in it')
  }

  const currentURLOrigin = useFullCurrentUrl('onlyOrigin')
  const machineryOfferDetailPageUrl = offerMachinery.length === 1 && offerMachinery[0]
    ? joinURL(currentURLOrigin, '/machinery/', offerMachinery[0].id)
    : undefined

  // Don't show machinery if it's a `service-project`-offer, as there can be many machineries inside a service-project (and the customer does not end up using them themselves)
  const linesWithMachineryUrl = machineryOfferDetailPageUrl && offer.type !== 'service-project'
    ? ['Schauen sie sich gerne mehr Details zur Maschine über folgenden Link an:', `${machineryOfferDetailPageUrl}`, '']
    : []

  const offerOrOrder = offer.status === 'offer' ? 'Angebot' : 'Auftrag'

  const offerURL = urlWithLocale(joinURL(currentURLOrigin, '/api/pdf/offer/details/', offer.cuid), getLocale(offer.customer?.language))
  const lines = [
    `Liebe/er ${offer.contactPerson?.name},`,
    `Vielen Dank für Ihr Interesse. Sie können ${prefixWithIhr[offerOrOrder]} über nachfolgenden Link einsehen:`,
    offerURL,
    ...linesWithMachineryUrl,
    '',
    'Liebe Grüße,',
    name,
  ]

  // Join all the lines and add a new line at the end of each
  return lines.join('%0D%0A')
}

/**
 * Generate a `mailto` for a offer to send to customer.
 *
 * Mailto opens a pre-filled mail inside the default mail-app. See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#linking_to_an_email_address
 */
export default function (offer: ApiOfferGetAll | ApiOfferGetById, name: string) {
  const offerOrOrder = offer.status === 'offer' ? 'Angebot' : 'Auftrag'
  const subject = `Ihr ${offerOrOrder} bei Hanselmann`
  const body = generateOfferEmailBody(offer, name)

  return `mailto:${offer.contactPerson?.email}?subject=${subject}&body=${body}`
}
