import { browserTracingIntegration, captureException, init, withScope } from '@sentry/vue'

const logger = defaultLogger.withTag('SENTRY')

export default defineNuxtPlugin({
  enforce: 'pre',
  setup(nuxt) {
    const sentryInitializationData = shouldSentryBeInitialized()
    if (!sentryInitializationData.doInitialize) {
      logger.info(sentryInitializationData.errorMessage)
      return
    }

    const { sentryConfig } = sentryInitializationData

    const router = useRouter()

    init({
      app: nuxt.vueApp,
      dsn: sentryConfig.dsn,
      environment: sentryConfig.environment,
      integrations: [
        browserTracingIntegration({ router }),
      ],
      tracesSampleRate: sentryConfig.sampleRate,
      tracePropagationTargets: ['localhost', 'https://app.hanselmann.de'],
    })

    nuxt.vueApp.config.errorHandler = (err, context) => {
      withScope((scope) => {
        scope.setExtra('context', context)
        captureException(err)
      })
    }

    nuxt.hook('app:error', (err) => {
      captureException(err)
    })
  },
})
