import type { ConsolaOptions, ConsolaReporter } from 'consola'
import { createConsola } from 'consola/core'
import { consola } from 'consola'
import isProduction from './isProduction'

/**
 * This is to automatically set a server reporter for all loggers derived from `defaultLogger`
 */
let serverReporter: ConsolaReporter | undefined
/** Overwrite server reporter for all loggers */
export function setServerReporter(reporter: ConsolaReporter) {
  serverReporter = reporter
}

const options: Partial<ConsolaOptions> = {
  // TRACE in production, NORMAL in development
  // https://github.com/unjs/consola?tab=readme-ov-file#log-level
  level: isProduction ? 5 : 3,
  // Node `inspect` options
  // https://nodejs.org/api/util.html#utilinspectobject-options
  formatOptions: {
    compact: Infinity,
    breakLength: isProduction ? Infinity : 80,
    colors: !isProduction,
    date: true,
  },
  // This is needed for server plugin to work
  reporters: import.meta.server
    ? [{
        log(logObj, ctx) {
          // Try using a custom reporter
          if (serverReporter) {
            return serverReporter.log(logObj, ctx)
          }

          // Fallback to consola
          const defaultReporter = consola.options.reporters[0]
          if (defaultReporter) {
            return defaultReporter.log(logObj, ctx)
          }
        },
      }]
    : undefined,
  // Reporter needs access to `stdout` and `stderr`
  stdout: import.meta.server ? process.stdout : undefined,
  stderr: import.meta.server ? process.stderr : undefined,
}

const versionTag = process.env.SIDESTREAM_TAG ?? process.env.SIDESTREAM_COMMIT_SHA

/**
 * Generic logger. Use it to create more specialised loggers.
 *
 * Custom reporter is registered in `server/plugins/logger.ts` because it needs Node-specific APIs
 */
export const defaultLogger = versionTag
  ? createConsola(options).withTag(versionTag)
  : createConsola(options)

/** Logger for all-things authentication */
export const authLogger = defaultLogger.withTag('AUTH')

/** Logger for PDF generation functionality */
export const pdfLogger = defaultLogger.withTag('PDF')

/** Logger for XLSX (MS Excel) generation functionality */
export const xlsxLogger = defaultLogger.withTag('XLSX')

/** Logger for userTask related functionality */
export const userTaskLogger = defaultLogger.withTag('USER-TASK')

/** Logger for ExternalStorage module */
export const externalStorageLogger = defaultLogger.withTag('EXTERNAL-STORAGE')
