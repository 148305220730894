<script setup lang="ts">
import { FormKitMessages } from '@formkit/vue'
import type { UpdateOrCreateComment } from '~/types'

defineProps<{ payload: UpdateOrCreateComment, onSave: (payload: UpdateOrCreateComment) => Promise<unknown> }>()

const formIsEmptyTextNode = ref()
const isFormEmpty = (v: UpdateOrCreateComment['data']) => !v.text?.length && !v.files?.length
</script>

<template>
  <FormKit
    v-slot="{ value }"
    :value="payload.data"
    type="form"
    :submit-label="$t('comment.button.save')"
    @submit="(data: UpdateOrCreateComment['data']) => onSave({ mode: payload.mode, data } as UpdateOrCreateComment)"
  >
    <FormKit
      name="text"
      type="textarea"
      :label="$t('comment.text.label')"
      outer-class="CommentInput"
    />
    <FormKitFileDropzone
      name="files"
      :label="$t('comment.files.label')"
      wrap-path-in-object
    />
    <FormKitMessages :node="formIsEmptyTextNode?.node" />
    <FormKit
      ref="formIsEmptyTextNode"
      type="hidden"
      :validation="isFormEmpty(value) ? 'required' : ''"
      :validation-messages="{
        required: $t('comment.validation.empty.message'),
      }"
    />
    <TheDevOnlyNiceJson :comment="value" />
  </FormKit>
</template>

<style scoped>
.CommentInput :deep(.formkit-inner) {
  @apply !max-w-full;
}

:deep(textarea.formkit-input) {
  @apply min-h-20 p-2;
}
</style>
