<script setup lang="ts">
import { joinURL, withQuery } from 'ufo'
import { basename } from 'pathe'
import type { ApiInboundLogisticsTaskById, ApiStoragePositionById } from '~/types'

type PartialStoragePosition = ApiStoragePositionById['partialStoragePositions'][number]
interface PartialStoragePositionWithDelivery extends PartialStoragePosition {
  outboundLogisticsTaskDelivery: NonNullable<PartialStoragePosition['outboundLogisticsTaskDelivery']>
}

const { openStoragePositionPdfPopup: { data: popupData, close: closePopup } } = useGlobalOpeners()
const id = computed(() => popupData.value?.id)

const { storagePosition: queryStoragePosition } = useQuery()
const { data: storagePosition, isLoading: isLoadingOffer } = queryStoragePosition.byId(id)
const inboundLogisticsTask = computed(() => storagePosition.value?.storagePositionSet.inboundLogisticsTask)
const partialStoragePositionsWithOutboundLogisticsTaskDelivery = computed(() => storagePosition.value?.partialStoragePositions
  .filter((p): p is PartialStoragePositionWithDelivery => p.outboundLogisticsTaskDelivery !== null) ?? [])

function navigateToInboundDeliveryNotePDF(taskId: string) {
  window.open(joinURL('/api/pdf/inboundLogisticsTask/delivery', taskId), '_blank')
}

function openPdf(pdfPath: string) {
  window.open(withQuery(pdfPath, { inline: false }), '_blank')
}

const { payload: deliverySlipsPayload, open: openDeliverySlipsPopup, close: closeDeliverySlipsPopup } = usePopup<{ cuid: string, deliverySlips: ApiInboundLogisticsTaskById['deliverySlips'] }>()
</script>

<template>
  <ExternalStorageStoragePositionPopupDeliverySlips
    v-if="deliverySlipsPayload"
    :payload="deliverySlipsPayload"
    @close="closeDeliverySlipsPopup"
  />
  <ThePopup v-if="popupData && storagePosition" :is-loading="isLoadingOffer" :show="true" :title="`Dokumenten-Übersicht (${popupData.id})`" @close="closePopup">
    <div class="CustomTable">
      <div class="Row">
        <div class="Label">
          {{ $t('logisticsTask.deliveryNote.label_inbound') }}
        </div>
        <div v-if="inboundLogisticsTask" class="flex flex-col gap-4">
          <n-button
            class="w-40"
            @click="navigateToInboundDeliveryNotePDF(inboundLogisticsTask.cuid)"
          >
            {{ $t('button.loadAsPdf') }}
          </n-button>
          <div class="flex gap-2">
            <FileView class="w-full pt-2" :label="$t('externalStorage.inboundLogisticsTask.deliverySlips.label_external')" :files="inboundLogisticsTask.deliverySlips" />
            <n-button @click="openDeliverySlipsPopup({ cuid: inboundLogisticsTask.cuid, deliverySlips: inboundLogisticsTask.deliverySlips })">
              {{ $t('button.update') }}
            </n-button>
          </div>
        </div>
        <span v-else class="text-gray-500">{{ $t('logisticsTask.deliveryNote.deliveryNotCreated') }}</span>
      </div>

      <div class="Row">
        <div class="Label">
          {{ $t('logisticsTask.deliveryNote.label_outbound') }}
        </div>
        <div v-if="partialStoragePositionsWithOutboundLogisticsTaskDelivery.length" class="space-y-5">
          <div
            v-for="partialStoragePosition in partialStoragePositionsWithOutboundLogisticsTaskDelivery"
            :key="partialStoragePosition.id"
            class="flex flex-col gap-2"
          >
            <n-button
              class="w-60"
              @click="openPdf(partialStoragePosition.outboundLogisticsTaskDelivery.deliveryPdfPath)"
            >
              {{ $t('button.loadAsPdfWithDate', { date: useDateAsString(partialStoragePosition.deliveryAt, 'dd.MM.yyyy') }) }}
            </n-button>

            <!-- Show deliveryProofPdfs -->
            <template v-if="partialStoragePosition.outboundLogisticsTaskDelivery.deliveryProofPdfs.length">
              <n-collapse>
                <n-collapse-item>
                  <template #header>
                    <h2 class="font-semibold">
                      {{ $t('outboundLogisticsTaskDelivery.deliveryProofPdf.label') }} ({{ partialStoragePosition.outboundLogisticsTaskDelivery.deliveryProofPdfs.length }})
                    </h2>
                  </template>
                  <div class="grid grid-cols-2 gap-1">
                    <NPopover
                      v-for="deliveryProofPdf in partialStoragePosition.outboundLogisticsTaskDelivery.deliveryProofPdfs"
                      :key="deliveryProofPdf.path"
                      trigger="hover"
                    >
                      <template #trigger>
                        <n-button
                          class="w-[220px]"
                          @click="openPdf(deliveryProofPdf.path)"
                        >
                          <span class="truncate">{{ basename(deliveryProofPdf.path) }}</span>
                        </n-button>
                      </template>
                      {{ basename(deliveryProofPdf.path) }}
                    </NPopover>
                  </div>
                </n-collapse-item>
              </n-collapse>
            </template>
          </div>
        </div>
        <span v-else class="text-gray-500">{{ $t('logisticsTask.deliveryNote.deliveryNotCreated') }}</span>
      </div>
    </div>
  </ThePopup>
</template>

<style scoped>
  .CustomTable {
    @apply flex flex-col gap-y-2
  }
  .CustomTable .Row {
    @apply flex flex-row
  }
  .CustomTable .Label {
    @apply flex w-[140px] font-semibold shrink-0 mr-2
  }
  .Row + .Row {
    @apply border-t border-gray-200 pt-2
  }
</style>
