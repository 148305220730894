<script setup lang="ts">
import { computeLogisticsTaskTimeline } from '~/components/Logistics/computeStatus'

const props = defineProps<{ logisticsTaskId: string }>()

const { logisticsTask: queryLogisticsTask } = useQuery()
const { data: logisticsTask } = queryLogisticsTask.byIdForStatusTimeline(props.logisticsTaskId)

const timelineItems = computed(() => {
  if (!logisticsTask.value) {
    return undefined
  }

  return Object.values(computeLogisticsTaskTimeline(logisticsTask.value))
})
</script>

<template>
  <TheStatusTimeline :timeline-items="timelineItems" />
</template>
