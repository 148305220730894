<script setup lang="ts">
import { joinURL, withQuery } from 'ufo'
import type { ApiMachineryAccessoryGetAllForOverview, ApiMachineryAccessoryGetById } from '~/types'

// accessory prop should be passed when type is machineryAccessory
const props = defineProps<{
  id: string
  type: 'machinery' | 'accessory' | 'itemSet' | 'storagePosition' | 'inboundLogisticsTask'
  accessory?: ApiMachineryAccessoryGetById | ApiMachineryAccessoryGetAllForOverview
}>()

function openExternalPDF(format: 'A5' | 'A6' | 'small', isColored?: boolean) {
  const url = withQuery(joinURL('/api/pdf/qr/', props.type, props.id), { format, isColored })

  return window.open(url, '_blank')
}
</script>

<template>
  <div>
    <div class="flex flex-col gap-2 w-full">
      <div class="CustomTable my-4">
        <div class="OuterRow">
          <span class="Label">A5</span>
          <div class="InnerRow">
            <n-button
              @click="() => openExternalPDF('A5')"
            >
              Als PDF laden
            </n-button>
            <n-button
              @click="() => openExternalPDF('A5', true)"
            >
              Als PDF laden (Mit Farbe)
            </n-button>
          </div>
        </div>
        <div class="OuterRow">
          <span class="Label">A6</span>
          <div class="InnerRow">
            <n-button
              @click="() => openExternalPDF('A6')"
            >
              Als PDF laden
            </n-button>
            <n-button
              @click="() => openExternalPDF('A6', true)"
            >
              Als PDF laden (Mit Farbe)
            </n-button>
          </div>
        </div>
        <div v-if="!['storagePosition', 'inboundLogisticsTask'].includes(type)" class="OuterRow">
          <span class="Label">Klein</span>
          <div class="InnerRow">
            <n-button
              @click="() => openExternalPDF('small')"
            >
              Als PDF laden
            </n-button>
            <n-button
              @click="() => openExternalPDF('small', true)"
            >
              Als PDF laden (Mit Farbe)
            </n-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .CustomTable {
    @apply flex flex-col gap-y-2
  }
  .CustomTable .OuterRow {
    @apply flex flex-row
  }
  .CustomTable .InnerRow {
    @apply flex sm:flex-row flex-col gap-2
  }
  .CustomTable .Label {
    @apply inline-block sm:w-[140px] w-[45px] font-semibold
  }
</style>
