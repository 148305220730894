<script setup lang="ts">
import type { ApiInputLogisticsUpdateStatus, ApiInputOfferPositionUpdateStatus, ApiLogisticsTaskGetById, LogisticsTaskRoundtripStatus, LogisticsTaskType } from '~/types'
import { updateLogisticsTaskStatusSchema } from '~/server/schemas'

const { openDriverLoadingChecklistPopup: { data: popupData, close: closeChecklistPopup } } = useGlobalOpeners()

const { logisticsTask: queryLogisticsTask } = useQuery()

const logisticsTaskId = computed(() => popupData.value?.id)
const { data: logisticsTask } = queryLogisticsTask.byIdOrThrow(logisticsTaskId)

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()
const update = useMutation({
  mutationFn: async ({ positions, task }: { positions: ApiInputOfferPositionUpdateStatus, task: ApiInputLogisticsUpdateStatus }) => {
    await $trpc.logisticsTask.updateStatus.mutate(task)
    return $trpc.offerPosition.updateStatus.mutate(positions)
  },
  onError: makeTrpcErrorToast(notification, { description: 'Die Beladen-Checklist konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
    ])
    closeChecklistPopup()
    notification.success({ title: 'Die Beladen-Checklist wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const { useremail } = useAuthorization()
function updateIsLoaded(task: ApiLogisticsTaskGetById) {
  const positions = task.positionsToShip.map(p => ({ id: p.id, isLoaded: p.isLoaded }))
  const taskPayload = { id: task.id, type: task.type as LogisticsTaskType, status: 'loaded' as LogisticsTaskRoundtripStatus, loadedAt: new Date(), loadedByEmail: useremail.value }
  return update.mutate({
    positions,
    task: updateLogisticsTaskStatusSchema.parse(taskPayload),
  })
}
</script>

<template>
  <ThePopup v-if="popupData && logisticsTask" :show="Boolean(logisticsTask)" title="Beladen-Checklist" @close="closeChecklistPopup">
    <DriverLoadingChecklistForm
      :logistics-task="logisticsTask"
      @save="updateIsLoaded"
    />
  </ThePopup>
</template>
