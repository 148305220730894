<script setup lang="ts">
withDefaults(defineProps<{
  show: boolean
  title?: string
  width?: string
  isLoading?: boolean // skeleton loader when content should not be rendered yet
  isSpinning?: boolean // spinner overlay when interaction should be disabled
}>(), {
  title: undefined,
  width: '700px',
  isLoading: false,
  isSpinning: false,
})

const emit = defineEmits<{ (e: 'close'): void }>()
</script>

<template>
  <n-modal
    :mask-closable="false"
    preset="card"
    :show="show"
    :title="title"
    :style="`width: ${width}`"
    @esc="emit('close')"
    @close="emit('close')"
  >
    <div v-if="isLoading">
      <n-skeleton height="30vh" :sharp="false" />
    </div>
    <n-spin v-else :show="isSpinning">
      <slot />
    </n-spin>
  </n-modal>
</template>
