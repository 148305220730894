interface OfferPositionWithIndex {
  indexInOffer: number
  groupInOffer: number
  groupType?: string
  type: string
}

/**
 * Compare two offer-positions in the following order:
 * 1. by `groupType === 'invoiceRelated'` (positions of `invoiceRelated` group type will be placed last)
 * 2. by `groupInOffer`
 * 3. by `indexInOffer`
 * 4. by `type === 'extraDays'` (`extraDays` type will be sorted after the original position)
 */
export default function (a: OfferPositionWithIndex, b: OfferPositionWithIndex) {
  return Number(a.groupType === 'invoiceRelated') - Number(b.groupType === 'invoiceRelated')
    || a.groupInOffer - b.groupInOffer
    || a.indexInOffer - b.indexInOffer
    || Number(a.type === 'extraDays') - Number(b.type === 'extraDays')
}
