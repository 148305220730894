<script setup lang="ts">
const { openStoragePositionApprovePopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const { data: storagePosition } = useQuery().storagePosition.byId(computed(() => popupData.value?.id))

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()
const approve = useMutation({
  mutationFn: $trpc.externalStorage.storagePosition.approve.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Lagerposition konnte nicht bestätigt werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['externalStoragePosition'] })
    closePopup()
    notification.success({ title: 'Die Lagerposition wurde erfolgreich bestätigt', duration: 4500 })
  },
})
</script>

<template>
  <ThePopup v-if="popupData && storagePosition" :show="true" :title="`Bestätigen ${popupData.id}`" @close="closePopup">
    <n-alert
      v-if="storagePosition && storagePosition.announcedQuantity !== storagePosition.quantity"
      type="warning"
      class="mb-5"
    >
      Achtung, diese Lagerposition wurde in einer abweichenden Menge angeliefert
    </n-alert>

    <CommentList :id="popupData.id" type="storagePosition" class="mb-5" />
    <FormKit type="form" submit-label="Als bestätigt markieren" @submit="approve.mutate">
      <FormKit type="hidden" name="id" :value="popupData.id" />
    </FormKit>
  </ThePopup>
</template>
