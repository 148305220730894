<script setup lang="ts">
import type { LogisticsTourDirections, MachineryAccessoryCategory, MachineryCategories, MachineryDrives, UpsertLogisticsDetails } from '~/types'

const { openLogisticsIssuancePopup: { data: popupData, close: closePopup }, openLogisticsIssuanceMachineryList } = useGlobalOpeners()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const { offerPosition: queryOfferPosition, calendar: queryCalendar } = useQuery()
const id = computed(() => popupData.value?.id ?? undefined)
const { data: offerPosition, isLoading: isLoadingOfferPosition } = queryOfferPosition.byId(id)

const shippedViaLogisticsTask = computed(() => offerPosition.value?.shippedViaLogisticsTasks?.find(t => t.type === popupData.value?.taskType))
const relatedOffer = computed(() => {
  if (!offerPosition.value || popupData.value?.positionType || !shippedViaLogisticsTask.value) {
    return
  }

  return {
    ...shippedViaLogisticsTask.value?.offer,
    offerType: shippedViaLogisticsTask.value?.offer?.type,
    machineryTypeId: shippedViaLogisticsTask.value?.positionsToShip.find(p => p.machineryId)?.machinery?.typeId ?? undefined,
  }
})

const { data: machineryAccessories } = queryCalendar.machineryAccessoryGroupedByCategory(relatedOffer as CalendarMachineryAccessoryGroupedByCategoryArgs)

const isOutbound = computed(() => popupData.value?.taskType === 'outbound')
const upsert = useMutation({
  mutationFn: $trpc.offerPosition.logisticsTour.issuance.upsertLogisticsDetails.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Aktualisierung kann nicht durchgeführt werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offerPosition'] }),
    ])
    closePopup()
    if (shippedViaLogisticsTask.value) {
      openLogisticsIssuanceMachineryList.open({ id: shippedViaLogisticsTask.value.id, taskType: shippedViaLogisticsTask.value.type as LogisticsTourDirections })
    }
    notification.success({ title: 'Die Aktualisierung wurde erfolgreich durchgeführt', duration: 4500 })
  },
})

const machineryPosition = computed(() => shippedViaLogisticsTask.value?.positionsToShip.find(p => p.type === 'machinery'))

const { useremail } = useAuthorization()
const payload = computed((): UpsertLogisticsDetails | undefined => {
  if (!popupData.value || !offerPosition.value || isLoadingOfferPosition.value) {
    return undefined
  }

  const receptionMachinery = offerPosition.value.machinery?.receptionMachinery
  const taskType = popupData.value.taskType
  const defaultPayload = {
    taskType,
    id: offerPosition.value.id,
    comment: offerPosition.value[`${taskType}Comment`],
    generalPhotos: offerPosition.value[`${taskType}GeneralPhotos`],
    innerCabinPhotos: offerPosition.value[`${taskType}InnerCabinPhotos`],
    outerCabinPhotos: offerPosition.value[`${taskType}OuterCabinPhotos`],
    tiresPhotos: offerPosition.value[`${taskType}TiresPhotos`],
    enginePhotos: offerPosition.value[`${taskType}EnginePhotos`],
    isIssued: true,
    issuedAt: offerPosition.value.issuedAt ?? new Date(),
    issuedByEmail: offerPosition.value.issuedByEmail ?? useremail.value,
    // fill in this data only if the offerPosition is for inbound logisticsTask
    isReturned: !isOutbound.value,
    returnedAt: isOutbound.value ? undefined : offerPosition.value.returnedAt ?? new Date(),
    returnedByEmail: isOutbound.value ? undefined : offerPosition.value.returnedByEmail ?? useremail.value,
    receptionMachinery,
  }

  if (popupData.value.positionType === 'machinery') {
    return {
      ...defaultPayload,
      logisticsMachineryDetails: offerPosition.value[`${taskType}LogisticsMachineryDetails`] ?? {},
      logisticsFunctionalDetails: offerPosition.value[`${taskType}LogisticsFunctionalDetails`] ?? {},
      logisticsVisualDetails: offerPosition.value[`${taskType}LogisticsVisualDetails`] ?? {},
      logisticsConditionDetails: offerPosition.value[`${taskType}LogisticsConditionDetails`] ?? {},
      logisticsAccessoryDetails: undefined,
    } as UpsertLogisticsDetails
  }

  const machineryCategoryFromPopupData = popupData.value.positionType === 'machineryAccessoryCategory'
    ? popupData.value.accessoryCategory
    : undefined

  const accessoryDetailsToUpdate = {
    itemSetId: popupData.value.positionType === 'itemSet' ? popupData.value.machineryId : null,
    machineryAccessoryId: ['machineryAccessory', 'machineryAccessoryCategory'].includes(popupData.value.positionType) ? popupData.value.machineryId : null,
    machineryAccessoryCategory: offerPosition.value.machineryAccessoryCategory ?? machineryCategoryFromPopupData,
  }

  return {
    ...defaultPayload,
    logisticsMachineryDetails: undefined,
    logisticsFunctionalDetails: undefined,
    logisticsVisualDetails: undefined,
    logisticsConditionDetails: undefined,
    logisticsAccessoryDetails: {
      ...offerPosition.value[`${taskType}LogisticsAccessoryDetails`],
      ...accessoryDetailsToUpdate,
    },
    storageLocation: offerPosition.value.machineryAccessory?.storageLocation ?? null,
  }
})

const { issuancePopupInformation } = useOneDataColumnConfigs()

const machineryIdToUpdate = computed(() => {
  if (!popupData.value?.positionType) {
    return ''
  }

  return popupData.value.machineryId
})

const accessoryCategoryToUpdate = computed(() => {
  if (!popupData.value?.positionType) {
    return
  }

  return popupData.value?.positionType === 'machineryAccessoryCategory' ? popupData.value.accessoryCategory : undefined
})

const protocolName = computed(() => popupData.value?.taskType === 'outbound' ? 'Ausgabeprotokoll' : 'Rücknahmeprotokoll')

const popupTitle = computed(() => {
  if (!popupData.value) {
    return protocolName.value
  }

  return `${protocolName.value} ${machineryIdToUpdate.value ?? popupData.value.id}`
})
</script>

<template>
  <ThePopup v-if="payload && popupData" :show="Boolean(payload)" :title="popupTitle" @close="closePopup">
    <TableOneDataColumn v-if="offerPosition" :config="{ columns: issuancePopupInformation, data: offerPosition }" />
    <LogisticsIssuanceForm
      :payload="payload"
      :offer-id="offerPosition?.offerId"
      :machinery-id="machineryPosition?.machineryId"
      :category="machineryPosition?.machinery?.category as MachineryCategories || undefined"
      :drive="machineryPosition?.machinery?.drive.name as MachineryDrives || undefined"
      :submit-label="`${protocolName} speichern`"
      :logistics-task-type="popupData.taskType"
      :available-machinery-accessories="machineryAccessories ?? []"
      :position-type-to-update="popupData.positionType"
      :machinery-id-to-update="machineryIdToUpdate"
      :accessory-category-to-update="(accessoryCategoryToUpdate as MachineryAccessoryCategory | undefined)"
      :is-attached-to-machinery="Boolean(offerPosition?.machineryAccessory && offerPosition.machineryAccessory.attachedMachineryId)"
      :is-in-set="Boolean(offerPosition?.machineryAccessory && offerPosition.itemSetId)"
      @save="upsert.mutate"
    />
  </ThePopup>
</template>
