export default (target: Ref<HTMLElement | null>) => {
  const isQueryEnabled = ref(false)

  const { stop } = useIntersectionObserver(target, ([{ isIntersecting }]) => {
    if (isIntersecting) {
      isQueryEnabled.value = true
      stop()
    }
  })

  return { isQueryEnabled }
}
