/**
 * Generate a `mailto` for a logistics task.
 *
 * Mailto opens a pre-filled mail inside the default mail-app. See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#linking_to_an_email_address
 */
export default function (task: { id: string }, link: string) {
  const subject = `Übergabe von ${task.id}`

  const lines = [
    'Guten Tag,',
    `Die Geräte aus der Lieferung ${task.id} würden an den folgenden Ort abgeliefert:`,
    '',
    encodeURIComponent(link),
    '',
    'Liebe Grüße,',
  ]

  const body = lines.join('%0D%0A')
  return `mailto:?subject=${subject}&body=${body}`
}
