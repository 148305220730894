import { defu } from 'defu'
import { generateColorThemes } from '@bg-dev/nuxt-naiveui/utils'

const colorThemes = generateColorThemes({ primary: '#1E3A8A' })

export default defineAppConfig({
  // See https://nuxt-naiveui.bg.tn/components/naive-config#naiveconfig
  naiveui: {
    themeConfig: defu({
      shared: {
        common: {
          borderRadius: '0.4rem',
        },
        Tooltip: {
          color: 'white',
          textColor: 'black',
        },
        DataTable: {
          thColor: '#f3f4f6',
          thTextColor: '#000',
          thFontWeight: '600',
          thIconColor: '#000',
          tdColorHover: '#f3f4f6',
          loadingSize: '40px',
          opacityLoading: '50%',
          borderColor: '#e5e7eb',
        },
      },
    }, colorThemes),
  },
})
