<script setup lang="ts">
import draggable from 'vuedraggable'
import { FormKitMessages } from '@formkit/vue'
import type { UpdateOrCreatePositionTemplateBundle } from '~/types'

const props = defineProps<{
  payload: UpdateOrCreatePositionTemplateBundle
}>()

const emit = defineEmits<{ submit: [payload: UpdateOrCreatePositionTemplateBundle] }>()

const title = ref(props.payload.data.title)
const templates = ref(props.payload.data.templateToBundleRelations.map(relation => relation.template))
const checkIfTemplatesEmpty = ref()

function deletePositionTemplate(id: string) {
  templates.value = templates.value.filter(template => template.id !== id)
}

const { payload: positionTemplatePayload, open: openPositionTemplatePopup, close: closePositionTemplatePopup } = usePopup<{ templateIds: string[] }>()

function submitPositionTemplatePopup(data: { id: string, title: string }[]) {
  templates.value = data
  closePositionTemplatePopup()
}

function submitForm() {
  const data = {
    title: title.value,
    templateToBundleRelations: templates.value.map((template, index) => ({
      order: index,
      template,
    })),
    offerType: props.payload.data.offerType,
  }

  if (props.payload.mode === 'create') {
    emit('submit', { mode: 'create', data })
  } else {
    emit('submit', { mode: 'update', data: { ...data, id: props.payload.data.id } })
  }
}
</script>

<template>
  <OfferCreateOrEditPopupSelectExtraPosition
    v-if="!!positionTemplatePayload"
    :offer-type="payload.data.offerType"
    :already-checked-row-keys="positionTemplatePayload.templateIds"
    :show-popup="!!positionTemplatePayload"
    :close-template-position-popup="closePositionTemplatePopup"
    :is-bundle="true"
    @submit-to-bundle="submitPositionTemplatePopup"
  />

  <FormKit
    type="form"
    submit-label="Positionsvorlage speichern"
    @submit="submitForm"
  >
    <FormKit
      v-model="title"
      type="text"
      label="Titel"
      validation="required"
    />

    <n-button
      class="mb-4"
      @click="openPositionTemplatePopup({ templateIds: templates.map(template => template.id) })"
    >
      + Zusatzpositionen hinzufügen
    </n-button>

    <draggable :list="templates" item-key="id" tag="ul" handle=".handle">
      <template #item="{ element, index }">
        <div class="flex items-center gap-x-4">
          <n-tooltip trigger="hover">
            <template #trigger>
              <div class="grow">
                <FormKit :value="element.title" :label="`${index + 1}. Zusatzposition`" :disabled="true" />
              </div>
            </template>
            {{ element.title }}
          </n-tooltip>

          <n-button class="handle">
            <Icon name="material-symbols:expand" class="text-2xl" />
          </n-button>

          <n-button @click="deletePositionTemplate(element.id)">
            <Icon name="material-symbols:delete-outline-rounded" class="text-2xl" />
          </n-button>
        </div>
      </template>
    </draggable>

    <FormKitMessages :node="checkIfTemplatesEmpty?.node" />
    <FormKit
      ref="checkIfTemplatesEmpty"
      type="hidden"
      :validation="templates.length ? '' : 'required'"
      :validation-messages="{ required: 'Die Positionsvorlage darf nicht leer sein' }"
    />
  </FormKit>
</template>
