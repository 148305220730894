import type { FormKitNode } from '@formkit/core'

// Adapted from examples here: https://formkit.com/essentials/examples#plugins
function applyDefaults(node: FormKitNode) {
  // Automatically set the `name` property of the form node according to its id
  if (node.props.id) {
    node.name = node.props.id
  }

  // Automatically add a `*` after all required labels
  if (node.props.label && node.props.validation?.includes('required')) {
    node.props.label = `${node.props.label} *`
  }

  // Add / remove `*` after label if validation rules change
  node.on('prop', ({ payload, origin }) => {
    if (payload.prop !== 'validation') {
      return
    }

    const label = origin.props.label
    if (!label) {
      return
    }

    const validation = payload.value
    if (validation.includes('required') && !label.endsWith(' *')) {
      origin.props.label = `${label} *`
      return
    }

    if (!validation.includes('required') && label.endsWith(' *')) {
      origin.props.label = label.slice(0, label.length - 2)
    }
  })

  // Convert numbers to actual numbers if and only if:
  if (
    // (a) the input type is `number`
    node.props.type === 'number'
    // (b) the `cast to number`-feature of formkit is not used (see https://formkit.com/inputs/number#cast-to-number). This was introduced much _after_ we started to use formkit
    && !Object.keys(node.props).includes('number')
  ) {
    node.hook.input((value: string | number | null, next) => {
      /**
       * When value was typed in and removed from number input, this will be an empty string.
       * in this case zod would complain about getting string type instead of number type,
       * so we return null instead (more details about the issue can be found from https://github.com/sidestream-tech/hanselmann-os/issues/661)
       */
      if (typeof value === 'string' && value.length === 0) {
        return next(null)
      }

      if (!value) {
        return next(value)
      }

      return next(Number(value))
    })
  }
}

export default () => applyDefaults
