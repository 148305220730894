<script setup lang="ts">
import { z } from 'zod'

const props = defineProps<{
  context: {
    value: string
    node: { input: (v: null | Date) => unknown }
    transformDate?: ((date: number | Date) => Date)
    isDateDisabled?: ((current: number) => boolean)
    isTimeDisabled?: ((current: number) => { isHourDisabled?: (hour: number) => boolean, isMinuteDisabled?: (minute: number) => boolean })
    format?: string
    timePickerProps?: {
      format: string
    }
    inputType?: 'date' | 'datetime'
    size: 'small' | 'medium' | 'large'
    disabled?: boolean
  } & Record<string, any>
}>()

function handleInput(value: number | null) {
  if (value === null) {
    props.context.node.input(null)
    return
  }

  const valueToUpdate = z.coerce.date().safeParse(value).success ? new Date(value) : null
  const transformer = props.context.transformDate
  if (transformer && valueToUpdate) {
    const transformed = transformer(valueToUpdate)
    props.context.node.input(transformed)
  } else {
    props.context.node.input(valueToUpdate)
  }
}

const value = computed(() => {
  if (props.context.value) {
    return new Date(props.context.value).getTime()
  }
  return null
})
</script>

<template>
  <n-date-picker
    :value="value"
    :size="context.size ?? 'large'"
    :type="context.inputType ?? 'date'"
    :format="context.format"
    :time-picker-props="context.timePickerProps"
    :is-date-disabled="context.isDateDisabled"
    :is-time-disabled="context.isTimeDisabled"
    clearable
    :disabled="context.disabled"
    @update:value="handleInput"
  />
</template>
