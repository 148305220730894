<script setup lang="ts">
import type { PartnerSalesReleaseMachineryForPartnerBase } from '~/types'

const { openReleaseMachineryForPartnerSalesPopup: { data, close } } = useGlobalOpeners()

const releaseConditionId = computed(() => (
  data.value?.mode === 'update'
    ? data.value.id
    : undefined
))
const prefilledValue = computed(() => {
  const data = partnerMachineryRelation.data.value
  if (!data) {
    return
  }

  return {
    ...data,
    partnerUserIds: [data.partnerUserId],
    // Fixes FormKit pre-filling issue
    ts: Date.now(),
  }
})

const { partnerSales } = useQuery()
const partners = partnerSales.getPartners()
const partnerMachineryRelation = partnerSales.getReleaseConditionById(releaseConditionId)

const isLoading = computed(() => partners.isLoading.value && partnerMachineryRelation.isLoading.value)

const partnerOptions = computed(() => {
  const availablePartnerOptions = partners.data.value
  if (!availablePartnerOptions) {
    return {}
  }

  return Object.fromEntries(availablePartnerOptions.map(({ id, name, email }) => [id, name ?? email]))
})

const { $trpc, queryClient, makeTrpcErrorToast, useMutation } = useMutationHelpers()
const notification = useNotification()
const releaseMachineriesForPartners = useMutation({
  mutationFn: $trpc.partnerSales.releaseMachineriesForPartners.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Freigaben für die Maschinen konnten nur teilweise angewendet werden.' }),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['partnerSales'] })
    notification.success({ title: `Gewünschte Freigaben wurden erfolgreich erstellt`, duration: 4500 })
    close()
  },
})

const updateMachineryReleasedForPartnerCondition = useMutation({
  mutationFn: $trpc.partnerSales.updateMachineryReleasedForPartnerCondition.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Freigaben für die Maschinen konnten nur teilweise angewendet werden.' }),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['partnerSales'] })
    notification.success({ title: 'Gewünschte Freigaben wurden erfolgreich erstellt', duration: 4500 })
    close()
  },
})

type SubmitFormData = PartnerSalesReleaseMachineryForPartnerBase & { partnerUserIds: string[] }
function onFormSubmit(value: SubmitFormData) {
  const popupData = data.value
  if (!popupData) {
    return
  }

  if (popupData.mode === 'create') {
    releaseMachineriesForPartners.mutate({ ...value, machineryIds: popupData.machineryIds })
  } else {
    updateMachineryReleasedForPartnerCondition.mutate({
      ...value,
      id: popupData.id,
    })
  }
}
</script>

<template>
  <ThePopup
    v-if="data"
    :show="true"
    :title="$t('machinery.releaseMachineryPopup.title')"
    @close="close"
  >
    <TheDataCard :is-loading="isLoading">
      <n-collapse class="mb-4">
        <n-collapse-item :title="$t('machinery.releaseMachineryPopup.dataCard.title')">
          <ul class="ml-8 list-disc grid grid-rows-1 grid-cols-5 grid-flow-auto">
            <li v-for="machineryId in data.machineryIds" :key="machineryId">
              {{ machineryId }}
            </li>
          </ul>
        </n-collapse-item>
      </n-collapse>
      <FormKit
        v-slot="{ value }"
        :key="prefilledValue?.ts"
        type="form"
        submit-label="Neue Konditionen speichern"
        :value="prefilledValue"
        @submit="onFormSubmit"
      >
        <TheDevOnlyNiceJson :value="value" />
        <FormKit
          name="partnerUserIds"
          type="checkbox"
          label="Partner"
          :options="partnerOptions"
          :disabled="data.mode !== 'create'"
          decorator-icon="happy"
          help="Wählen Sie die Partner-Verkäufer aus, für die diese Freigabe gelten soll. Bereits bestehende Konditionen werden überschrieben."
          validation="required|min:1"
        />
        <FormKit
          name="pricePartnerHasToPurchaseFor"
          type="number"
          step="0.01"
          min="0"
          label="Partner-Verkäufer EK-Preis"
          placeholder="40000"
          validation="required"
          number="float"
          help="Geben Sie an, zu welchem Preis der Partner-Verkäufer Ihnen die Maschine abkaufen muss."
        />
        <FormKit
          name="isSpecialOffer"
          type="checkbox"
          label="Als Sonderangebot markieren"
        />
        <FormKit
          name="note"
          type="text"
          label="Notiz an den Partner-Verkäufer"
        />
      </FormKit>
    </TheDataCard>
  </ThePopup>
</template>
