<script setup lang="ts">
const props = defineProps<{
  projectId: string
  selectedProjectAddressId?: string | null
}>()
const emit = defineEmits<{
  'update:value': [value: string | null]
  'requireAlternativeAddress': [value: boolean]
}>()

const { storageProjectAddress } = useQuery()
const useGetAllAlternativeAddressOptions = storageProjectAddress.allAlternativeAddressOptions
const { data: addressOptions } = useGetAllAlternativeAddressOptions(props.projectId)

const selectAlternativeAddress = ref(!!props.selectedProjectAddressId)
watch(selectAlternativeAddress, (newVal) => {
  if (!newVal) {
    emit('update:value', null)
  }

  emit('requireAlternativeAddress', newVal)
})

const {
  payload: addressPayload,
  open: openAddressPopup,
  close: closeAddressPopup,
} = usePopup<{ projectId: string }>()
</script>

<template>
  <ExternalStorageAdministrationStorageProjectAddressPopup
    v-if="addressPayload"
    v-bind="addressPayload"
    @close="closeAddressPopup"
  />

  <div class="flex flex-col gap-y-2">
    <p>
      Es wird automatisch die Standard Adresse hinterlegt.
      Bei abweichender Adresse bitte hier hinterlegen.
    </p>

    <n-checkbox v-model:checked="selectAlternativeAddress">
      Abweichende Adresse nutzen
    </n-checkbox>

    <n-select
      v-if="selectAlternativeAddress"
      :options="addressOptions ?? []"
      :default-value="selectedProjectAddressId ?? undefined"
      size="large"
      filterable
      @update:value="emit('update:value', $event)"
    >
      <template #action>
        <n-button style="width: 100%" @click="openAddressPopup({ projectId })">
          Lieferadressen verwalten
        </n-button>
      </template>
    </n-select>
  </div>
</template>
