<script setup lang="ts">
const { t: $t } = useI18n()

import { FormKitMessages } from '@formkit/vue'
import { createLocalStoragePlugin } from '@formkit/addons'
import type { LocalStorageOptions } from '@formkit/addons'
import { machineryInsuranceDeductible, machineryPaymentConditions, machineryPurchasing, machineryRubrics } from '~/server/schemas'
import type { ApiCustomerGetAll, ApiMachineryDriveGetAll, ApiMachineryTypeGetAll, MachineryCategories, UpdateOrCreateMachinery } from '~/types'

const props = defineProps<{
  payload: UpdateOrCreateMachinery
  machineryTypes: ApiMachineryTypeGetAll[]
  createdTypeId: string | undefined
  machineryDrives: ApiMachineryDriveGetAll[]
  localStorageKey: LocalStorageOptions
  customers?: ApiCustomerGetAll[]
  onSave: (payload: UpdateOrCreateMachinery) => Promise<unknown>
}>()
const emit = defineEmits<{ (e: 'openMachineryTypePopup', category: MachineryCategories): void }>()
const { cloned } = useCloned(props.payload)
const { machineryFieldsByMachineType: showMachineryFieldsByMachineType } = useFormOptionalConditions()

const category = computed(() => props.payload.data.category)
const isForeignMachinery = computed(() => props.payload.data.isForeignMachinery)

watch(() => props.createdTypeId, (value) => {
  if (!value) {
    return
  }
  nextTick(() => {
    cloned.value.data.typeId = value
  })
})

const currentPaymentCondition = ref<string>('')
const specialPaymentCondition = ref<string>('')
onMounted(() => {
  currentPaymentCondition.value = cloned.value.data.paymentCondition
  if (!machineryPaymentConditions.safeParse(currentPaymentCondition.value).success) {
    currentPaymentCondition.value = 'Sonstiges'
    specialPaymentCondition.value = cloned.value.data.paymentCondition
  }
})

function updateCurrentPaymentCondition(selectedOption: string) {
  currentPaymentCondition.value = selectedOption
  if (selectedOption === 'Sonstiges') {
    cloned.value.data.paymentCondition = ''
  }
}

const machineryTypeOptions = computed(() => props.machineryTypes.filter(type => type.category === props.payload.data.category).map(type => ({
  label: `${type.name}`,
  value: type.id,
})))

const machineryPaymentConditionsOptions = computed(() => machineryPaymentConditions.options.map(option => ({
  label: option,
  value: option,
})))

const machineryInsuranceDeductibleOptions = machineryInsuranceDeductible.options.map(option => ({
  label: `${option} EUR`,
  value: option,
}))

const machineryDrivesOptions = computed(() => props.machineryDrives.map(drive => ({
  label: $t(`machinery.drive.${drive.name}`),
  value: drive.id,
})))

const customerOptions = computed(() => props.customers?.map(customer => ({
  label: customer.name,
  value: customer.id,
})) ?? [])

const machineryType = ref()

function save() {
  if (currentPaymentCondition.value === 'Sonstiges') {
    cloned.value.data.paymentCondition = specialPaymentCondition.value
  } else {
    cloned.value.data.paymentCondition = currentPaymentCondition.value
  }

  if (!cloned.value.data.driveId) {
    const drive = props.machineryDrives.find(drive => drive.name === 'noDrive')

    // If it's empty, it will just throw an error like it normally would.
    cloned.value.data.driveId = drive?.id ?? ''
  }

  return props.onSave(cloned.value)
}

const formkitPlugins = useFormkitPlugins()
</script>

<template>
  <FormKit
    v-slot="{ value }"
    v-model="cloned.data"
    name="MachineryCreationCreateOrEditForm"
    type="form"
    :plugins="[formkitPlugins, createLocalStoragePlugin(localStorageKey)]"
    use-local-storage
    :submit-label="$t('button.save')"
    @submit="save"
  >
    <TheDevOnlyNiceJson :form-value="value" />
    <FormKit
      v-if="cloned.mode === 'create' && !isForeignMachinery"
      id="id"
      type="number"
      validation="min:0|max:5999"
      :label="$t('common.field.id.name')"
      :placeholder="$t('common.field.id.placeholder')"
      :help="$t('machinery.field.id.help')"
    />
    <FormKitMultiSelect
      v-if="isForeignMachinery"
      id="foreignMachineryCustomerId"
      :is-multi-select-allowed="false"
      :multi-select-options="customerOptions"
      :label="$t('common.field.customer.name')"
      validation="required"
    />
    <div class="flex flex-col md:flex-row md:space-x-2">
      <FormKit
        id="deliveryCompanyName"
        type="text"
        validation="required"
        :label="$t('machinery.field.deliveryCompanyName.name')"
        :placeholder="$t('machinery.field.deliveryCompanyName.placeholder')"
      />
      <FormKit
        id="producerCompanyName"
        type="text"
        validation="required"
        :label="$t('machinery.field.producerCompanyName.name')"
        :placeholder="$t('machinery.field.producerCompanyName.placeholder')"
      />
    </div>
    <div class="flex flex-col md:flex-row gap-2">
      <div class="w-full">
        <FormKit type="group">
          <label>
            <span class="font-bold">{{ $t('common.field.type.name') }} *</span>
            <n-select
              :value="cloned.data.typeId ? cloned.data.typeId : null"
              :options="machineryTypeOptions"
              filterable
              size="large"
              :placeholder="$t('machinery.field.type.placeholder')"
              @update:value="value => { cloned.data.typeId = value }"
            >
              <template #action>
                <n-button style="width: 100%" @click="emit('openMachineryTypePopup', cloned.data.category)">
                  {{ $t('machinery.creation.button.createNewMachineryType') }}
                </n-button>
              </template>
            </n-select>
            <FormKitMessages :node="machineryType?.node" />
          </label>
          <FormKit
            ref="machineryType"
            v-model="cloned.data.typeId"
            :label="$t('common.field.type.name')"
            name="typeId"
            type="hidden"
            validation="required"
          />
        </FormKit>
      </div>
      <FormKit
        id="machineryRubric"
        type="select"
        :options="useTranslatedSelectOptions('machinery.rubric', machineryRubrics.options)"
        validation="required"
        :label="$t('machinery.field.rubric.name')"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        id="serialNumber"
        type="text"
        :label="$t('machinery.field.serialNumber.name')"
        :placeholder="$t('machinery.field.serialNumber.placeholder')"
      />
      <FormKit
        id="yearBuilt"
        type="number"
        validation="required"
        :label="$t('machinery.field.yearBuilt.name')"
        :placeholder="$t('machinery.field.yearBuilt.placeholder')"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        id="driveId"
        :value="cloned.data.driveId ? cloned.data.driveId : undefined"
        type="select"
        :options="machineryDrivesOptions"
        validation="required"
        :label="$t('machinery.field.drive.name')"
        :placeholder="$t('machinery.field.drive.placeholder')"
        class="WidthHalfMinusGap"
      />
      <FormKit
        v-if="showMachineryFieldsByMachineType.liftingHeightInMillimeters.includes(category)"
        id="liftingHeightInMillimeters"
        type="number"
        step="0.01"
        :label="$t('machinery.field.liftingHeight.name')"
        :placeholder="$t('machinery.field.liftingHeight.placeholder')"
      />
    </div>

    <FormKit
      v-if="showMachineryFieldsByMachineType.licensePlateNumber.includes(category)"
      id="licensePlateNumber"
      type="text"
      :label="$t('common.field.licensePlateNumber.name')"
      :placeholder="$t('common.field.licensePlateNumber.placeholder')"
      class="WidthHalfMinusGap"
    />

    <div class="flex space-x-2">
      <div v-if="showMachineryFieldsByMachineType.liftingWeight.includes(category)" class="w-full md:WidthHalfMinusGap">
        <FormKit
          id="liftingWeight"
          type="number"
          step="0.01"
          validation="required"
          :label="$t('machinery.field.liftingWeight.name')"
          :placeholder="$t('machinery.field.liftingWeight.placeholder')"
        />
      </div>
      <div v-if="showMachineryFieldsByMachineType.forkLengthInMillimeters.includes(category)" class="w-full md:WidthHalfMinusGap">
        <FormKit
          id="forkLengthInMillimeters"
          type="number"
          step="0.01"
          :label="$t('machinery.field.forkLength.name')"
          :placeholder="$t('machinery.field.forkLength.placeholder')"
        />
      </div>
    </div>

    <div class="flex space-x-2">
      <FormKit
        v-if="payload.mode === 'create'"
        id="storageLocation"
        type="text"
        validation="required"
        :label="$t('machinery.field.storageLocation.name')"
        :placeholder="$t('machinery.field.storageLocation.placeholder')"
      />

      <FormKit
        id="pricePurchaseEuros"
        type="number"
        step="0.01"
        validation="required"
        min="0"
        :label="$t('machinery.field.pricePurchaseEuros.name')"
        :placeholder="$t('machinery.field.pricePurchaseEuros.placeholder')"
      />
    </div>

    <div class="flex space-x-2">
      <div v-if="showMachineryFieldsByMachineType.workHeightInMillimeters.includes(category)" class="w-full md:WidthHalfMinusGap">
        <FormKit
          id="workHeightInMillimeters"
          type="number"
          step="0.01"
          :label="$t('machinery.field.workHeight.name')"
          :placeholder="$t('machinery.field.workHeight.placeholder')"
        />
      </div>
      <div class="w-full md:WidthHalfMinusGap">
        <FormKitDate
          id="deliveryDate"
          :label="$t('machinery.field.deliveryDate.name')"
        />
      </div>
    </div>

    <div class="flex gap-x-2">
      <FormKitMultiSelect
        v-model="currentPaymentCondition"
        :multi-select-options="machineryPaymentConditionsOptions"
        :is-multi-select-allowed="false"
        validation="required"
        :label="$t('common.field.paymentCondition.name')"
        :update="updateCurrentPaymentCondition"
      />
      <FormKit
        v-if="currentPaymentCondition === 'Sonstiges'"
        v-model="specialPaymentCondition"
        type="text"
        validation="required"
        :label="$t('machinery.creation.label.specialPaymentCondition')"
      />
    </div>

    <div class="flex space-x-2 items-end md:items-start">
      <FormKit
        id="purchasing"
        type="select"
        :options="machineryPurchasing.options"
        :label="$t('machinery.field.purchasing.name')"
        :placeholder="machineryPurchasing.options[0]"
      />
      <FormKit
        id="additionalDeliveryCost"
        type="number"
        step="0.01"
        :label="$t('machinery.field.additionalDeliveryCost.name')"
        :placeholder="$t('machinery.field.additionalDeliveryCost.placeholder')"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        id="insuranceDeductible"
        type="select"
        :options="machineryInsuranceDeductibleOptions"
        :label="$t('machinery.field.insuranceDeductible.name')"
        :placeholder="$t('machinery.field.insuranceDeductible.placeholder')"
      />
    </div>
    <FormKitFileDropzone
      id="documentFiles"
      name="documentFiles"
      :label="$t('common.field.documentFiles.name')"
      wrap-path-in-object
    />
    <FormKit
      id="comment"
      type="text"
      :label="$t('machinery.field.comment.name')"
      :placeholder="$t('machinery.field.comment.placeholder')"
    />
  </FormKit>
</template>

<style scoped>
  .WidthHalfMinusGap {
    width: calc(50% - 0.5rem)
  }

  :deep(.formkit-actions) button {
    @apply w-full md:w-auto justify-center md:justify-start;
  }
</style>
