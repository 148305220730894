import { z } from 'zod'
import { idSchema, pathInObjectFilesSchema } from './common'
import { machineryFems, machineryFemsAndFive, machineryFemsAndNone, machineryTireColors } from './machineryAndMachineryAccessoryCommon'
import type { ZObject } from '~/server/schemas/zObject'
import { zDiscriminatedUnion, zObject } from '~/server/schemas/zObject'

export const machineryAccessoryCategories = ['hookShort', 'hookLong', 'clipOnHook', 'latticeMastWinch', 'winch', 'wheels', 'workBasket', 'clipOnBasket', 'charger', 'weights', 'rollers', 'stands', 'forkAdjuster', 'sideShifter', 'forkCarriage', 'ramps', 'jib', 'heavyCasters', 'shovel', 'loadDistributionPlates', 'ballast', 'forkExtension', 'fork', 'craneArm', 'rotaryDevice', 'coilDorn', 'abusBogie', 'miscellaneous', 'heavyDutyLifter', 'basePlate', 'trailer', 'storageSpace', 'internalOperatingEquipment'] as const
export const machineryAccessoryCategorySchema = z.enum(machineryAccessoryCategories)
export const machineryAccessoryStatusWithSold = z.enum(['creation', 'marking', 'approval', 'sold'])
export const machineryAccessoryStatusSchema = z.enum(['creation', 'marking', 'approval'])
export const machineryAccessoryConditions = ['neu', 'gut', 'gebraucht', 'defekt'] as const
export const machineryAccessoryConditionSchema = z.enum(machineryAccessoryConditions)
export const machineryAccessoryTrailerTypes = z.enum(['mega', 'standard', 'meusburger', 'lowloader', 'flatbed', 'box'])

/** Base for every accessory */
const machineryAccessoryBase = zObject({
  storageLocation: z.string().nullish(),
  photos: pathInObjectFilesSchema,
  condition: machineryAccessoryConditionSchema,
  comment: z.string().default(''),
  transportLengthInMillimeters: z.number().nullish(),
  transportWidthInMillimeters: z.number().nullish(),
  transportHeightInMillimeters: z.number().nullish(),
  productCode: z.string().nullish(),
  producerCompanyName: z.string().nullish(),
  typeName: z.string().nullish(),
  serialNumber: z.string().nullish(),
  lengthInMillimeters: z.number().nonnegative().nullish(),
  widthInMillimeters: z.number().nonnegative().nullish(),
  heightInMillimeters: z.number().nonnegative().nullish(),
})

const priceInEuros = z.number().nonnegative().nullish()
const pricePurchaseEuros = z.number().nonnegative().nullish()

const fem = machineryFems
const forkFem = machineryFemsAndFive
const rotaryDeviceFem = machineryFemsAndNone

const yearBuilt = z.number().int().min(1900).max(2030).nullish()

const liftingWeight = z.number().nonnegative().nullish()

const weightInKilograms = z.number().nonnegative().nullish()

const neck = z.enum(['A', 'B'])

const tireType = z.string().nullish()

const wheelSize = z.string()

const compatibleMachineryProducer = z.string()

const volt = z.string()

const color = machineryTireColors

const forkLength = z.number().nonnegative()
const forkWidth = z.number().nonnegative()
const forkHeight = z.number().nonnegative()

const distanceInnerEdgeToInnerEdge = z.number().nonnegative()
const distanceOuterEdgeToOuterEdge = z.number().nonnegative()

const cubicMeters = z.number().nonnegative()

const retractionLugsWidth = z.number().nonnegative()
const retractionLugsHeight = z.number().nonnegative()

const retractionLugsDistanceInnerEdgeToInnerEdge = z.number().nonnegative()
const retractionLugsDistanceOuterEdgeToOuterEdge = z.number().nonnegative()

const description = z.string().default('')

const documentFiles = pathInObjectFilesSchema
const purchaseDocumentFiles = pathInObjectFilesSchema

const innerLength = z.number().nonnegative()
const innerWidth = z.number().nonnegative()

const liftingHeight = z.number().nonnegative()
const componentParts = z.string()
const drive = z.string()
const lengthLeverLink = z.number().nonnegative()
const widthLeverLink = z.number().nonnegative()
const powerLeverLink = z.number().nonnegative()

const deliveryCompanyName = z.string()

const trailerType = machineryAccessoryTrailerTypes

const actualPayloadInKilograms = z.number().int().min(0).nullish()

const licensePlateNumber = z.string()
const firstRegistrationDate = z.coerce.date().nullable()
const technicalInspectionDate = z.coerce.date().nullable()
const securityInspectionDate = z.coerce.date().nullable()
const vehicleRegistrationPhoto = pathInObjectFilesSchema

export const hookShort = machineryAccessoryBase.merge(zObject({
  category: z.literal('hookShort'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
}))

export const hookLong = machineryAccessoryBase.merge(zObject({
  category: z.literal('hookLong'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
}))

export const clipOnHook = machineryAccessoryBase.merge(zObject({
  category: z.literal('clipOnHook'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
  retractionLugsWidth,
  retractionLugsHeight,
  retractionLugsDistanceInnerEdgeToInnerEdge,
  retractionLugsDistanceOuterEdgeToOuterEdge,
}))

export const latticeMastWinch = machineryAccessoryBase.merge(zObject({
  category: z.literal('latticeMastWinch'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
}))

export const winch = machineryAccessoryBase.merge(zObject({
  category: z.literal('winch'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
}))

export const wheels = machineryAccessoryBase.merge(zObject({
  category: z.literal('wheels'),
  priceInEuros,
  weightInKilograms,
  documentFiles,
  tireType,
  color,
  wheelSize,
  compatibleMachineryProducer,
}))

export const workBasket = machineryAccessoryBase.merge(zObject({
  category: z.literal('workBasket'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
}))

export const clipOnBasket = machineryAccessoryBase.merge(zObject({
  category: z.literal('clipOnBasket'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
  retractionLugsWidth,
  retractionLugsHeight,
  retractionLugsDistanceInnerEdgeToInnerEdge,
  retractionLugsDistanceOuterEdgeToOuterEdge,
}))

export const charger = machineryAccessoryBase.merge(zObject({
  category: z.literal('charger'),
  priceInEuros,
  yearBuilt,
  documentFiles,
  weightInKilograms,
  volt,
}))

export const weights = machineryAccessoryBase.merge(zObject({
  category: z.literal('weights'),
  priceInEuros,
  documentFiles,
  weightInKilograms,
}))

export const rollers = machineryAccessoryBase.merge(zObject({
  category: z.literal('rollers'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
}))

export const stands = machineryAccessoryBase.merge(zObject({
  category: z.literal('stands'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
}))

export const forkAdjuster = machineryAccessoryBase.merge(zObject({
  category: z.literal('forkAdjuster'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
  forkLength,
  forkWidth,
  forkHeight,
  distanceInnerEdgeToInnerEdge,
  distanceOuterEdgeToOuterEdge,
  cubicMeters,
  fem,
}))

export const sideShifter = machineryAccessoryBase.merge(zObject({
  category: z.literal('sideShifter'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
  distanceInnerEdgeToInnerEdge,
  distanceOuterEdgeToOuterEdge,
  cubicMeters,
  fem,
}))

export const forkCarriage = machineryAccessoryBase.merge(zObject({
  category: z.literal('forkCarriage'),
  priceInEuros,
  yearBuilt,
  documentFiles,
  liftingWeight,
  fem,
}))

export const ramps = machineryAccessoryBase.merge(zObject({
  category: z.literal('ramps'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
}))

export const jib = machineryAccessoryBase.merge(zObject({
  category: z.literal('jib'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
}))

export const heavyCasters = machineryAccessoryBase.merge(zObject({
  category: z.literal('heavyCasters'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
}))

export const shovel = machineryAccessoryBase.merge(zObject({
  category: z.literal('shovel'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
  cubicMeters,
}))

export const loadDistributionPlates = machineryAccessoryBase.merge(zObject({
  category: z.literal('loadDistributionPlates'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
}))

export const ballast = machineryAccessoryBase.merge(zObject({
  category: z.literal('ballast'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
}))

export const forkExtension = machineryAccessoryBase.merge(zObject({
  category: z.literal('forkExtension'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
  retractionLugsWidth,
  retractionLugsHeight,
}))

export const fork = machineryAccessoryBase.merge(zObject({
  category: z.literal('fork'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
  fem: forkFem,
  neck,
}))

export const craneArm = machineryAccessoryBase.merge(zObject({
  category: z.literal('craneArm'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
}))

export const rotaryDevice = machineryAccessoryBase.merge(zObject({
  category: z.literal('rotaryDevice'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
  fem: rotaryDeviceFem,
}))

export const coilDorn = machineryAccessoryBase.merge(zObject({
  category: z.literal('coilDorn'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
}))

export const abusBogie = machineryAccessoryBase.merge(zObject({
  category: z.literal('abusBogie'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
  fem,
}))

export const miscellaneous = machineryAccessoryBase.merge(zObject({
  category: z.literal('miscellaneous'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  weightInKilograms,
  description,
}))

export const heavyDutyLifter = machineryAccessoryBase.merge(zObject({
  category: z.literal('heavyDutyLifter'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  weightInKilograms,
  description,
  liftingHeight,
  componentParts,
  drive,
  lengthLeverLink,
  widthLeverLink,
  powerLeverLink,
}))

export const basePlate = machineryAccessoryBase.merge(zObject({
  category: z.literal('basePlate'),
  priceInEuros,
  yearBuilt,
  liftingWeight,
  documentFiles,
  weightInKilograms,
  innerLength,
  innerWidth,
}))

export const trailer = machineryAccessoryBase.merge(zObject({
  category: z.literal('trailer'),
  priceInEuros,
  yearBuilt,
  description,
  weightInKilograms,
  deliveryCompanyName,
  trailerType,
  licensePlateNumber,
  firstRegistrationDate,
  technicalInspectionDate,
  securityInspectionDate,
  vehicleRegistrationPhoto,
  actualPayloadInKilograms,
}))

export const storageSpace = machineryAccessoryBase.merge(zObject({
  category: z.literal('storageSpace'),
  description,
}))

export const internalOperatingEquipment = machineryAccessoryBase.merge(zObject({
  category: z.literal('internalOperatingEquipment'),
  pricePurchaseEuros,
  imeiOne: z.string().length(15).nullish(),
  imeiTwo: z.string().length(15).nullish(),
  componentParts,
  purchaseDocumentFiles,
}))

export const individualCreateInnerMachineAccessorySchemas = [
  hookShort,
  hookLong,
  clipOnHook,
  latticeMastWinch,
  winch,
  wheels,
  workBasket,
  clipOnBasket,
  charger,
  weights,
  rollers,
  stands,
  forkAdjuster,
  sideShifter,
  forkCarriage,
  ramps,
  jib,
  heavyCasters,
  shovel,
  loadDistributionPlates,
  ballast,
  forkExtension,
  fork,
  craneArm,
  rotaryDevice,
  coilDorn,
  abusBogie,
  miscellaneous,
  heavyDutyLifter,
  basePlate,
  trailer,
  storageSpace,
  internalOperatingEquipment,
] as const

type IndividualCreateInnerMachineAccessorySchemas = typeof individualCreateInnerMachineAccessorySchemas

export const createInnerMachineryAccessory = zDiscriminatedUnion('category', individualCreateInnerMachineAccessorySchemas)

type IndividualUpdateInnerMachineAccesorySchemas<
  createSchemas extends readonly ZObject[] = IndividualCreateInnerMachineAccessorySchemas,
> = {
  [i in keyof createSchemas]: ZObject<
    createSchemas[i]['infer'] & {
      id: string
      status: 'creation' | 'marking' | 'approval'
    }
  >;
}

const withUpdateFields = zObject({
  id: idSchema,
  status: machineryAccessoryStatusSchema,
})
const individualUpdateInnerMachineAccessorySchemas: IndividualUpdateInnerMachineAccesorySchemas = individualCreateInnerMachineAccessorySchemas.map(schema =>
  withUpdateFields.merge(schema),
) as never

const updateInnerMachineryAccessory = zDiscriminatedUnion('category', individualUpdateInnerMachineAccessorySchemas)

const machineryTypes = z.array(z.string().min(1))
export const createMachineryAccessory = zObject({ accessory: createInnerMachineryAccessory, machineryTypes })
export const updateMachineryAccessory = zObject({ accessory: updateInnerMachineryAccessory, machineryTypes })

export const updateOrCreateMachineryAccessorySchema = zDiscriminatedUnion('mode', [
  zObject({
    mode: z.literal('create'),
    data: createMachineryAccessory,
  }),
  zObject({
    mode: z.literal('update'),
    data: updateMachineryAccessory,
  }),
])
