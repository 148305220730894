<script setup lang="ts">
import currency from 'currency.js'
import { createId } from '@paralleldrive/cuid2'
import { groupInvoiceOfferPositionsByIdWeekly } from './utils'
import type { CreateCreditNoteCustomPosition, CreateInvoice, CreateInvoiceCustomOrCreditNotePositionType } from '~/types'

const { openCreateCreditNotePopup: { data: popupData, close: closePopup_ }, openInvoiceNextStepsPopup } = useGlobalOpeners()

function closePopup() {
  resetFormData()
  closePopup_()
}

const checkedPositionKeys = ref<string[]>([])

const { invoice: queryInvoice } = useQuery()

const invoiceId = computed(() => popupData.value?.id)

const { data: invoice, isLoading: isLoadingInvoice } = queryInvoice.byId(invoiceId)

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const create = useMutation({
  mutationFn: $trpc.invoice.create.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Rechnungskorrektur konnte nicht erstellt werden' }),
  onSuccess: async (invoice) => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['comment'] }),
      queryClient.invalidateQueries({ queryKey: ['invoice'] }),
      queryClient.invalidateQueries({ queryKey: ['positions'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
    ])

    closePopup()
    openInvoiceNextStepsPopup.open({ invoiceId: invoice.id })

    notification.success({ title: 'Die Rechnungskorrektur wurde erfolgreich erstellt', duration: 4500 })
  },
})

const payload = ref<Extract<CreateInvoice, { type: 'creditNote' }> | null>(null)

function resetFormData() {
  payload.value = null
  checkedPositionKeys.value = []
}

watch(invoice, (newInvoice) => {
  if (!newInvoice && !popupData.value) {
    resetFormData()
    return
  }

  if (!newInvoice) {
    return
  }

  if (payload.value) {
    payload.value.offerId = newInvoice.offer.id
    payload.value.creditNoteForInvoiceId = newInvoice.id
  } else {
    payload.value = {
      type: 'creditNote',
      offerPositions: [],
      customPositions: [],
      includeVAT: true,
      offerId: newInvoice.offer.id,
      status: 'created',
      totalAmount: 0,
      creditNoteForInvoiceId: newInvoice.id,
      invoicedAt: new Date(),
    }
  }
})

const invoicePositionsRow = computed(() => {
  return groupInvoiceOfferPositionsByIdWeekly(invoice.value)
})

const unrenderFormKitItemsToOverWrite = ref(false)
function deletePosition(idx: number) {
  if (!payload.value) {
    return
  }
  const newPositions = payload.value.customPositions.slice()
  newPositions.splice(idx, 1)

  unrenderFormKitItemsToOverWrite.value = true
  nextTick(() => {
    if (!payload.value) {
      return
    }
    payload.value.customPositions = newPositions
    unrenderFormKitItemsToOverWrite.value = false
  })
}

function createCustomPosition(type: CreateInvoiceCustomOrCreditNotePositionType) {
  if (!payload.value || type === 'invoice') {
    return
  }

  const customPositions = payload.value.customPositions

  const position: CreateCreditNoteCustomPosition = {
    id: createId(),
    title: '',
    quantity: 1,
    pricePerUnit: 0,
    unit: 'flatRate',
    discountRate: 0,
    type,
    indexInOffer: customPositions.length,
    groupInOffer: 0,
    groupType: 'invoiceRelated',
    showPositionInOfferPdf: false,
    includePositionToInvoice: true,
  }

  customPositions.push(position)
}

function createInvoiceToCreate() {
  if (!payload.value || !invoice.value) {
    return
  }

  const childrenCheckedPositionIds = checkedPositionKeys.value.filter(key => !key.includes('-'))
  const totalAmountOfSelectedOfferPositions = invoice.value.offerPositions.filter(position => childrenCheckedPositionIds.includes(position.id)).reduce(
    (acc, position) => acc.add(calculateInvoiceToOfferPositionPrice({
      title: position.offerPosition.title,
      type: position.offerPosition.type,
      pricePerUnit: position.pricePerUnit,
      discountRate: position.discountRate,
      quantity: position.quantity,
      discountRateForDay: position.discountRateForDay,
    })),
    currency(0),
  )

  const totalAmountOfCreatedCustomPositions = payload.value.customPositions.reduce(
    (acc, position) => acc.add(currency(position.pricePerUnit).multiply(position.quantity))
    , currency(0),
  )

  const offerPositions = childrenCheckedPositionIds.map(id => ({ id }))
  const totalAmount = totalAmountOfSelectedOfferPositions.add(totalAmountOfCreatedCustomPositions.value).value

  return {
    ...payload.value,
    offerPositions,
    // Credit should be shown as a negative amount
    totalAmount: totalAmount * -1,
  }
}

function submit() {
  const creditNoteToCreate = createInvoiceToCreate()
  if (creditNoteToCreate) {
    create.mutate({ invoice: creditNoteToCreate })
  }
}

const { invoiceOfferPositionRow: { columns } } = useTableColumnConfigs()

const isInvoiceFilledIn = computed(() => {
  return checkedPositionKeys.value.length > 0 || (payload.value?.customPositions && payload.value.customPositions.length > 0)
})

/** Get customer language */
const customerLocale = computed(() => getLocale(invoice.value?.offer.customer.language))

/** PREVIEW PDF */
const previewPdfUrl = computed(() => urlWithLocale('/api/pdf/invoice/preview', customerLocale.value))
const { fetch: createPreview, isLoading: isLoadingPreviewPdf } = useOpenDocumentInNewTab(previewPdfUrl, () => createInvoiceToCreate())

const formkitPlugins = useFormkitPlugins()
</script>

<template>
  <ThePopup v-if="popupData && payload && invoice" class="Popup" width="1024px" :show="Boolean(popupData)" :title="`Rechnungskorrektur zur Rechnung ${invoice.invoiceId} erstellen`" @close="closePopup">
    <div v-if="!invoice.invoiceId">
      <p class="text-red-500">
        Das erzeugte Dokument ist keine offizielle Rechnung und kann deshalb nicht als abgerechnet markiert werden.
      </p>
    </div>
    <div>
      <FormKit
        v-if="payload"
        v-model="payload"
        type="form"
        :plugins="[formkitPlugins]"
        submit-label="Rechnungskorrektur erstellen"
        @submit="submit"
      >
        <div class="mb-5 w-full flex flex-col gap-3">
          <FormKitDate
            id="invoicedAt"
            label="Rechnungsdatum"
            validation="required"
          />

          <p>
            Rechnungspositionen auswählen, die gutgeschrieben werden sollen:
          </p>

          <span class="font-bold">Positiondaten</span>

          <TableView
            :data="invoicePositionsRow"
            :columns="columns"
            :is-loading="isLoadingInvoice"
            :single-line="false"
            :row-key="row => row.id"
            :checked-row-keys="checkedPositionKeys"
            :show-summary="false"
            :is-paginated="false"
            @update:checked-row-keys="keys => checkedPositionKeys = keys as string[]"
          />

          <InvoiceCreationPositionInput
            v-if="!unrenderFormKitItemsToOverWrite"
            v-model="payload.customPositions"
            type="creditPosition"
            class="mt-6"
            :allow-negative-value="true"
            @delete="deletePosition"
            @create-custom-position="createCustomPosition"
          />

          <n-button :style="{ width: '250px' }" :disabled="!isInvoiceFilledIn" :loading="isLoadingPreviewPdf" @click="createPreview">
            Vorschau anzeigen
          </n-button>
        </div>
      </FormKit>
    </div>
  </ThePopup>
</template>
