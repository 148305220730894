<script setup lang="ts">
import { isAfter, isBefore, isSameDay, min } from 'date-fns'
import type { ApiRentalPauseGetAll, RentalPauseUpsert } from '~/types'

const props = defineProps<{
  payload: RentalPauseUpsert
  relatedRentalPauses: ApiRentalPauseGetAll[]
  validDates: Date[]
}>()
defineEmits<{ (e: 'save', payload: RentalPauseUpsert): void }>()

const { cloned } = useCloned(props.payload)

const nextRelatedStartDate = computed(() => min(props.relatedRentalPauses.map(rp => rp.startDate).filter(d => isAfter(d, cloned.value.startDate)) ?? []) ?? cloned.value.startDate)
const validEndDates = computed(() => props.validDates.filter(value => !isAfter(value, nextRelatedStartDate.value) && !isBefore(value, cloned.value.startDate)))

const isValidStartDate = ({ value }: { value: number | Date }) => props.validDates.some(d => isSameDay(d, value))
const isValidEndDate = ({ value }: { value: number | Date }) => validEndDates.value.some(d => isSameDay(d, value))
</script>

<template>
  <FormKit v-model="cloned" type="form" :actions="false" @submit="$emit('save', cloned)">
    <n-alert v-if="!validDates.length" :show-icon="false" type="warning" class="mb-2">
      {{ $t('offer.rentalPause.noValidStartDate') }}
    </n-alert>
    <div class="flex space-x-2">
      <FormKitDateNaiveUi
        name="startDate"
        :label="$t('offer.rentalPause.startDate.label')"
        :is-date-disabled="(date: number) => !isValidStartDate({ value: date })"
        :validation-rules="{ isValidStartDate }"
        validation-visibility="live"
        validation="isValidStartDate"
      />
      <FormKitDateNaiveUi
        name="endDate"
        :label="$t('offer.rentalPause.endDate.label')"
        :is-date-disabled="(date: number) => !isValidEndDate({ value: date })"
        :validation-rules="{ isValidEndDate }"
        validation-visibility="live"
        validation="isValidEndDate"
      />
    </div>
    <n-button type="primary" attr-type="submit" :disabled="!isValidStartDate({ value: cloned.startDate }) || !isValidEndDate({ value: cloned.endDate })">
      {{ $t('button.save') }}
    </n-button>
  </FormKit>
</template>
