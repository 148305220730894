<script setup lang="ts">
import { startOfDay } from 'date-fns'
import type { ApiLogisticsTaskGetById, CreateLogisticsTask } from '~/types'

const props = defineProps<{
  logisticsTask?: ApiLogisticsTaskGetById | null
  isReadOnly: boolean
  inquiriedDate: Date
}>()

const emits = defineEmits<{ (e: 'save', data: CreateLogisticsTask): void }>()

const inquiriedDateAsString = computed(() => useDateAsString(startOfDay(props.inquiriedDate), 'yyyy-MM-dd\'T\'HH:mm'))

const formkitPlugins = useFormkitPlugins()
</script>

<template>
  <FormKit
    v-slot="{ value }"
    type="form"
    submit-label="Lieferung speichern"
    :disabled="isReadOnly"
    :value="logisticsTask ?? undefined"
    :plugins="[formkitPlugins]"
    @submit="emits('save', $event)"
  >
    <n-alert v-if="isReadOnly" type="warning">
      Diese Logistik ist nicht mehr bearbeitbar.
    </n-alert>
    <FormKit
      id="deliveryFrom"
      type="text"
      validation="required"
      label="Abholadresse"
      placeholder="Rotebachring 39, 74564 Crailsheim"
    />
    <FormKit
      id="deliveryTo"
      type="text"
      validation="required"
      label="Lieferadresse"
      placeholder="Friesenplatz 4, 50676 Köln"
    />
    <FormKitDateTime
      id="plannedDeliveryAt"
      label="Anlieferdatum und Zeit"
      :validation="`required|date_after:${inquiriedDateAsString}`"
      validation-visibility="live"
      :validation-messages="{
        date_after: `Anlieferdatum kann nicht vor Anfragedatum liegen.`,
      }"
    />
    <TheDevOnlyNiceJson :value="value" />
  </FormKit>
</template>
