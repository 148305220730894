<script setup lang="ts">
const { t: $t } = useI18n()

import type { ApiMachineryAccessoryGetById, ApiMachineryGetById, ApiOfferGetById, OfferType } from '~/types'
import { offerTypesToGerman } from '~/translations'

const { openInternalCostPopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const machineryOverview: OneDataColumn<ApiMachineryGetById>[] = [
  {
    title: 'Geräte Nr.',
    key: 'id',
  },
  {
    title: 'Geräte Typ',
    key: 'type',
    render: ({ type }) => type.name,
  },
  {
    title: 'Hersteller',
    key: 'producerCompanyName',
  },
]

const machineryAccessoryOverview: OneDataColumn<ApiMachineryAccessoryGetById>[] = [
  {
    title: $t('internalCost.machineryAccessory.id.name'),
    key: 'id',
  },
  {
    title: $t('internalCost.machineryAccessory.type.name'),
    key: 'typeName',
  },
  {
    title: $t('machinery.field.producerCompanyName.name'),
    key: 'producerCompanyName',
  },
]

const offerOverview: OneDataColumn<ApiOfferGetById>[] = [
  {
    title: 'Auftragsnummer',
    key: 'id',
  },
  {
    title: 'Auftragsart',
    key: 'type',
    render: ({ type }) => offerTypesToGerman[type as OfferType],
  },
  {
    title: 'Kunde',
    key: 'customer',
    render: ({ customer }) => customer.name,

  },
]

const id = computed(() => popupData.value?.id)
const type = computed(() => popupData.value?.type)

const { machinery: machineryQuery, machineryAccessory: machineryAccessoryQuery, offer: offerQuery } = useQuery()

const { data: machinery } = machineryQuery.byId(id, undefined, undefined, undefined, type.value === 'machinery')
const { data: machineryAccessory } = machineryAccessoryQuery.byId(id, type.value === 'machineryAccessory')
const { data: offer } = offerQuery.byId(id, undefined, undefined, type.value === 'offer')
</script>

<template>
  <ThePopup v-if="popupData" :show="Boolean(popupData)" :title="`${$t('internalCost.popup.title', { entity: $t(`${type}.name`) })} ${popupData.id}`" width="1100px" @close="closePopup">
    <TableOneDataColumn v-if="machinery" class="mb-2" :config="{ columns: machineryOverview, data: machinery }" />
    <TableOneDataColumn v-if="machineryAccessory" class="mb-2" :config="{ columns: machineryAccessoryOverview, data: machineryAccessory }" />
    <TableOneDataColumn v-if="offer" class="mb-2" :config="{ columns: offerOverview, data: offer }" />
    <InternalCostPage :id="popupData.id" :type="popupData.type" />
  </ThePopup>
</template>
