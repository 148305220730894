const BIGINT_FOR_EMPTY_NUMBER = 9999999999999

/**
 * Sort two numbers `num1` and `num2` that are potentially `null` or `undefined`.
 */
export default (num1: number | null | undefined, num2: number | null | undefined, type: 'asc' | 'desc' = 'asc') => {
  const sortingResult = (num1 ?? BIGINT_FOR_EMPTY_NUMBER) - (num2 ?? BIGINT_FOR_EMPTY_NUMBER)

  return sortingResult * (type === 'asc' ? 1 : -1)
}
