<script setup lang="ts">
import type { ApiCalendarMachinery, ApiCalendarMachineryAccessory } from '~/types'

defineProps<{ item: ApiCalendarMachinery | ApiCalendarMachineryAccessory }>()

const columns: OneDataColumn<ApiCalendarMachinery | ApiCalendarMachineryAccessory>[] = [
  {
    title: 'Id',
    key: 'id',
  },
  {
    title: 'Nicht verfügbar seit',
    key: 'notAvailabledSince',
    render: ({ notAvailabledSince }) => {
      return notAvailabledSince ? useDateAsString(notAvailabledSince) : ' '
    },
    isVisible: ({ isDefective }) => isDefective,
  },
  {
    title: 'Beschreibung',
    key: 'notAvailableReason',
    render: ({ notAvailableReason }) => notAvailableReason,
  },
]
</script>

<template>
  <n-popover trigger="hover">
    <template #trigger>
      <slot />
    </template>
    <TableOneDataColumn :config="{ columns, data: item }" />
    <span class="text-blue-400 pt-2">
      <Icon name="material-symbols:edit-outline-rounded" />
      <nuxt-link :to="`${'receptionMachinery' in item ? 'machinery' : 'machinery-accessory'}/${item.id}`">
        Gerätedetailseite aufrufen
      </nuxt-link>
    </span>
  </n-popover>
</template>
