<script setup lang="ts">
import { createId } from '@paralleldrive/cuid2'
import { defu } from 'defu'
import type { ApexOptions } from 'apexcharts'
import VueApexCharts from 'vue3-apexcharts'
import { type ReportingChartConfiguration, enrichApexOptions } from './chartAndApexConfig'

const props = withDefaults(defineProps<ReportingChartConfiguration>(), {
  id: () => createId(),
  enrichOptionsWith: () => ({}),
})

const chartOptions = computed((): ApexOptions => {
  const baseConfig: ApexOptions = {
    chart: {
      id: props.id,
      animations: { enabled: false },
      zoom: { enabled: false },
    },
  }

  const mergedWithBaseConfig = defu(props.options, baseConfig)
  return enrichApexOptions(mergedWithBaseConfig, props.enrichOptionsWith)
})
</script>

<template>
  <ClientOnly>
    <VueApexCharts
      width="100%"
      height="400px"
      :type="props.type"
      :series="props.series"
      :options="chartOptions"
      v-bind="$attrs"
    />
    <TheDevOnlyNiceJson v-bind="$attrs" :props="props" />
  </ClientOnly>
</template>
