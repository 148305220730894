<script setup lang="ts">
import type { ApiCalendarMachinery } from '~/types'

type CalendarInternalReservation = ApiCalendarMachinery['internalReservations'][number]

defineProps<{
  internalReservation: CalendarInternalReservation
  dateOfColumn?: Date
}>()

const columns: OneDataColumn<CalendarInternalReservation>[] = [{
  title: 'Beschreibung',
  key: 'reservationDescription',
}, {
  title: 'Startdatum der Reservierung',
  key: 'reservationStartsAt',
  render: reservation => useDateAsString(reservation.reservationStartsAt, 'dd.MM.yyyy'),
}, {
  title: 'Enddatum der Reservierung',
  key: 'reservationEndsAt',
  render: reservation => useDateAsString(reservation.reservationEndsAt, 'dd.MM.yyyy'),
}]
</script>

<template>
  <n-popover trigger="hover" class="w-[460px] lg:w-[512px]">
    <template #trigger>
      <slot />
    </template>
    <TableOneDataColumn :config="{ columns, data: internalReservation }" />
  </n-popover>
</template>
