<script setup lang="ts">
import type { FilePath } from '~/types'

type PositionType = 'machinery' | 'machineryAccessory'

const props = withDefaults(defineProps<{
  id: string
  photos: FilePath[]
  type: PositionType
  howToShowDetails?: 'show-details-popup' | 'show-details-page'
}>(), {
  howToShowDetails: 'show-details-page',
})

const detailsPagePathByType: Record<PositionType, string> = {
  machinery: 'machinery',
  machineryAccessory: 'machinery-accessory',
}

const { openPositionsDetailsPopup } = useGlobalOpeners()

function handleClick() {
  if (props.howToShowDetails === 'show-details-page') {
    return navigateTo(`/${detailsPagePathByType[props.type]}/${props.id}`)
  }

  return openPositionsDetailsPopup.open({ id: props.id, type: props.type })
}
</script>

<template>
  <n-popover
    trigger="hover"
    display-directive="if"
    @click="handleClick"
  >
    <template #trigger>
      <slot />
    </template>
    <div>
      <p v-if="props.type === 'machinery' && props.photos.length === 0">
        Es gibt aktuell noch keine Marketing Fotos für dieses Gerät
      </p>
      <FileView v-else :files="props.photos" class="max-w-md" />
    </div>
  </n-popover>
</template>
