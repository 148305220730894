<script setup lang="ts">
import type { ApiMachineryGetById } from '~/types'

defineProps<{ payload: ApiMachineryGetById }>()
const emit = defineEmits<{ (e: 'save', data: ApiMachineryGetById): void }>()
</script>

<template>
  <FormKit
    type="form"
    :value="payload"
    submit-label="Speichern"
    @submit="(data: ApiMachineryGetById) => emit('save', data)"
  >
    <FormKit
      name="priceRecommendedToSellFor"
      type="number"
      number="float"
      step="0.01"
      min="0"
      label="UVP (EUR)"
    />
    <FormKit
      name="endCustomerSellingPrice"
      type="number"
      number="float"
      step="0.01"
      min="1"
      :label="$t('onlineSales.endCustomerSellingPriceWithCurrency.label', { currency: 'EUR' })"
    />
    <FormKit
      name="dealerSellingPrice"
      type="number"
      number="float"
      step="0.01"
      min="1"
      :label="$t('onlineSales.dealerSellingPriceWithCurrency.label', { currency: 'EUR' })"
    />
  </FormKit>
</template>
