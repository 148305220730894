<script setup lang="ts">
import type { UpdateOrCreateStorageUser } from '~/types'

const { openStorageUserCreateOrUpdatePopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const storageUserEmail = computed(() => popupData.value?.mode === 'update' ? popupData.value.email : undefined)

const { storageCustomer, storageUser: queryStorageUser } = useQuery()

const { data: customers } = storageCustomer.allExternalStorageCustomers()
const { data: storageUser, isFetching: isFetchingStorageUser } = queryStorageUser.byEmail(storageUserEmail)

const payload = computed((): UpdateOrCreateStorageUser | undefined => {
  if (!popupData.value) {
    return undefined
  }

  if (popupData.value.mode === 'create') {
    return {
      mode: 'create',
      data: {
        name: '',
        email: '',
        storageCustomerId: '',
      },
    }
  }

  if (!storageUser.value || isFetchingStorageUser.value) {
    return undefined
  }

  return {
    mode: 'update',
    data: storageUser.value,
  }
})

const customerOptions = computed(() => customers.value?.map(customer => ({ label: customer.name, value: customer.id })) ?? [])

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const create = useMutation({
  mutationFn: $trpc.externalStorage.storageUser.create.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Lagernutzer konnte nicht erstellt werden' }),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['users'] })
    closePopup()
    notification.success({ title: 'Der Lagernutzer wurde erfolgreich erstellt', duration: 4500 })
  },
})

const update = useMutation({
  mutationFn: $trpc.externalStorage.storageUser.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Aktualisierung konnte nicht durchgeführt werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['users'] })
    closePopup()
    notification.success({ title: 'Die Aktualisierung wurde erfolgreich durchgeführt', duration: 4500 })
  },
})

const submit = (payload: UpdateOrCreateStorageUser) => payload.mode === 'create' ? create.mutate(payload.data) : update.mutate(payload.data)
</script>

<template>
  <ThePopup
    v-if="popupData && payload"
    :show="true"
    :title="popupData.mode === 'create' ? 'Neuen Lagernutzer einladen' : 'Lagernutzer bearbeiten'"
    @close="closePopup"
  >
    <ExternalStorageAdministrationStorageUserCreateOrEditForm :payload="payload" :customer-options="customerOptions" @submit="submit" />
  </ThePopup>
</template>
