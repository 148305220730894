export default (query?: string | null) => {
  const baseURL = 'https://www.google.com/maps/search/?api=1&query='
  if (!query) {
    return encodeURIComponent(baseURL)
  }

  if (query.startsWith('Koordinaten:')) {
    const queryWithoutText = query.replace('Koordinaten:', '')
    return `${baseURL}${encodeURIComponent(queryWithoutText)}`
  }

  return `${baseURL}${encodeURIComponent(query)}`
}
