<script setup lang="ts">
import type { UpdateOrCreateSpecialPositions } from '~/types'

const props = defineProps<{ payload: UpdateOrCreateSpecialPositions, disabled?: boolean }>()
const emit = defineEmits<{ (e: 'submit', payload: UpdateOrCreateSpecialPositions): void }>()

const { cloned } = useMightyCloned(props.payload)

const formkitPlugins = useFormkitPlugins()
</script>

<template>
  <FormKit
    v-model="cloned.data"
    type="form"
    submit-label="Speichern"
    :disabled="disabled"
    :plugins="[formkitPlugins]"
    @submit="emit('submit', cloned)"
  >
    <FormKit
      id="positions"
      v-slot="{ items, node, value }"
      type="list"
      dynamic
    >
      <FormKit
        v-for="(item, index) in items"
        :key="item"
        type="group"
        :index="index"
      >
        <div class="group">
          <div class="flex justify-between gap-x-2 items-center">
            <FormKit
              id="title"
              type="text"
              label="Titel"
              validation="required"
            />

            <n-button v-if="items.length > 1" :disabled="disabled" @click="() => node.input(value?.filter((_: unknown, i: number) => i !== index))">
              <Icon name="material-symbols:delete-outline-rounded" class="text-2xl" />
            </n-button>
          </div>

          <FormKitFileDropzone
            id="outboundGeneralPhotos"
            wrap-path-in-object
          />
        </div>
      </FormKit>

      <n-button :disabled="disabled" class="mb-4" @click="() => node.input(value?.concat({}))">
        + Hinzufügen
      </n-button>
    </FormKit>
  </FormKit>
</template>
