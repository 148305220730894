<script setup lang="ts">
import { createReceptionMachineryBattery, createReceptionMachineryMotor, createReceptionMachineryTire, idObjectSchema, receptionMachinerySchema } from '~/server/schemas'
import type { MachineryDrives, UpdateOrCreateReceptionMachinery } from '~/types'

const { openReceptionProtocolHistory: { data: popupData, close: closePopup } } = useGlobalOpeners()

const { receptionMachineryHistory: queryReceptionMachineryHistory, receptionMachinery: queryReceptionMachinery, machineryType: queryMachineryType, machineryDrive: queryMachineryDrives } = useQuery()

const receptionMachineryHistoryId = computed(() => popupData.value?.id)

const { data: receptionMachineryHistory, isLoading: isLoadingReceptionMachineryHistory } = queryReceptionMachineryHistory.byId(receptionMachineryHistoryId)
const { data: specialEquipments } = queryReceptionMachinery.specialEquipments()
const { data: tireTypes } = queryReceptionMachinery.tireTypes()
const { data: machineryTypes } = queryMachineryType.all()
const { data: machineryDrives } = queryMachineryDrives.all()

const payload = computed((): UpdateOrCreateReceptionMachinery | undefined => {
  if (!popupData.value) {
    return undefined
  }

  if (!receptionMachineryHistory.value || isLoadingReceptionMachineryHistory.value) {
    return undefined
  }

  return {
    mode: 'update',
    data: {
      receptionMachinery: receptionMachinerySchema.merge(idObjectSchema).parse(receptionMachineryHistory.value),
      drive: receptionMachineryHistory.value.drive?.name as MachineryDrives ?? 'electric',
      machinerySpecialEquipments: receptionMachineryHistory.value.specialEquipmentRelations?.map(({ specialEquipmentId }) => specialEquipmentId) ?? [],
      machineryTire: createReceptionMachineryTire.parse(receptionMachineryHistory.value.tire),
      machineryTireTypes: receptionMachineryHistory.value.tire?.receptionMachineryTireHistoryTireTypeRelation?.receptionMachineryTireTypes.map(({ id }) => id) ?? [],
      machineryBattery: createReceptionMachineryBattery.parse(receptionMachineryHistory.value.battery),
      machineryMotor: createReceptionMachineryMotor.parse(receptionMachineryHistory.value.motor),
    },
  }
})
</script>

<template>
  <ThePopup v-if="popupData && payload" :show="Boolean(payload)" :title="`Aufnahmeprotokoll ${payload.data.receptionMachinery.machineryId}`" @close="closePopup">
    <MachineryCreationReceptionProtocolForm
      :payload="payload"
      :special-equipments="specialEquipments ?? []"
      :tire-types="tireTypes ?? []"
      :machinery-types="machineryTypes ?? []"
      :machinery-drives="machineryDrives ?? []"
      :disabled="true"
    />
  </ThePopup>
</template>
