import type { OfferPositionGroupType } from '~/types'

export function findFirstGroupOfTypeInOffer<T extends { groupType?: string }>(positionsByGroup: Record<string | number, T[]>, groupType: OfferPositionGroupType) {
  const positionsInGroup = Object.entries(positionsByGroup).find(([_, positions]) => positions.some(p => p.groupType === groupType))
  return positionsInGroup ? Number(positionsInGroup[0][0]) : null
}

/**
 * Generic group by utility to group a list of objects by a specific key.
 *
 * For example:
 * ```ts
 * groupByKey([{ id: 1, type: 'a' }, { id: 2, type: 'b'}, { id: 3, type: 'b'}], 'type') === {
 *    a: [{ id: 1, type: 'a' }],
 *    b: [{ id: 2, type: 'b' }, { id: 3, type: 'b'}],
 * }
 * ```
 */
export function groupByKey<T extends object>(items: T[], key: keyof T) {
  const groupedByKey: Record<string | number, T[]> = {}
  for (const item of items) {
    // Get the key and pretend its a string. This won't matter on the outside but makes it work on the inside
    const keyToUse = item[key] as string | number

    const currentlyGroupedItemsForKey = groupedByKey[keyToUse] ?? []
    groupedByKey[keyToUse] = [...currentlyGroupedItemsForKey, item]
  }

  return groupedByKey
}

/**
 * Generic flat-group by utility to group a list of objects by a specific key.
 *
 * For example:
 * ```ts
 * flatGroupByKey([{ id: 1, type: 'a' }, { id: 2, type: 'b'}, { id: 3, type: 'b'}], 'type') === {
 *    a: { id: 1, type: 'a' },
 *    b: { id: 3, type: 'b'},
 * }
 * ```
 *
 * NOTE HOW:
 * - the values are objects not arrays
 * - the last value of for a given key is preserved
 *
 * Passing in a function to dynamically determine the key per item.
 */
export function flatGroupByKey<T extends object>(items: T[], key: keyof T | ((arg: T) => string | number)) {
  const groupedByKey: Record<string | number, T> = {}
  for (const item of items) {
    // Get the key and pretend its a string. This won't matter on the outside but makes it work on the inside
    let keyToUse: string | number
    if (typeof key === 'function') {
      keyToUse = key(item)
    } else {
      keyToUse = item[key] as string | number
    }

    groupedByKey[keyToUse] = item
  }

  return groupedByKey
}
