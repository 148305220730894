<script setup lang="ts">
import type { ApiOfferGetById } from '~/types'

type OfferPosition = ApiOfferGetById['positions'][number]

defineProps<{ offerPositions: OfferPosition[], offerId: string }>()
const emit = defineEmits<{ (e: 'revertTermination', id: string): void, (e: 'close'): void }>()

function buttonsToShow(offerPosition: OfferPosition) {
  if (offerPosition.isCollected) {
    return []
  }

  return ['revertTermination'] satisfies Action[]
}

const { offerPositionForTermination: { columns } } = useTableColumnConfigs()

const formkitPlugins = useFormkitPlugins()
</script>

<template>
  <FormKit type="form" :plugins="[formkitPlugins]" submit-label="Speichern" @submit="emit('close')">
    <TableView
      class="mt-4"
      :data="offerPositions"
      :is-loading="false"
      :columns="columns"
      :action-buttons="buttonsToShow"
      @revert-termination="(row: OfferPosition) => emit('revertTermination', row.id)"
    />
    <CommentList :id="offerId" type="termination" class="mb-5" hide-list-title />
  </FormKit>
</template>
