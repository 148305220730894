<script setup lang="ts">
const props = defineProps<{ machineryId?: string }>()

const selectedDuration = useRouteQueryAsDateRange('machineryInvoicingDateRange')

const target = ref<HTMLElement | null>(null)
const { isQueryEnabled } = useEnableQueryOnIntersection(target)

const { reporting } = useQuery()
const { data: machineryRevenueChart, isLoading, suspense } = reporting.getChartRevenueMachinery(selectedDuration, props.machineryId, isQueryEnabled)

onServerPrefetch(async () => {
  if (isQueryEnabled.value) {
    await suspense()
  }
})
</script>

<template>
  <ReportingCard ref="target" :header="$t('reporting.machineryRevenue.title')" :is-loading="isLoading">
    <template #controls>
      <TheDurationPicker v-model="selectedDuration" class="mb-2" />
      <p class="mb-2">
        Der hier dargestellte Umsatz wird aus offenen und bezahlten Rechnungen berechnet, nachträglich erstellte Rechnungskorrekturen werden nicht berücksichtigt. Zur Berechnung werden ausschließlich die Maschinen-Positionen berücksichtigt. Die Berechnung basiert auf den jeweils von der Maschine abgerechneten Tagen.
      </p>
      <p class="mb-2 font-semibold">
        Gesamt-Maschinen-Umsatz im Zeitraum: {{ useDisplayNumberAsCurrency(machineryRevenueChart?.additionalData?.totalRevenue ?? 0) }}
      </p>
      <p>Umsatz pro Tag:</p>
    </template>

    <ReportingChart v-if="machineryRevenueChart" v-bind="machineryRevenueChart" />
  </ReportingCard>
</template>
