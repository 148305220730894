<script setup lang="ts">
import type { ApiInternalCostPositionGetAll, InternalCostPositionType } from '~/types'

const props = defineProps<{ id: string, type: InternalCostPositionType }>()

const { internalCostPosition: { columns, fulltextSearch, rowProps } } = useTableColumnConfigs()
const { fulltextSearchValue, where } = useFilterData(fulltextSearch)

const { internalCostPosition } = useQuery()
const internalCostPositionWhere = computed(() => ({
  ...where.value,
  [`${props.type}Id`]: props.id,
}))

const { data: internalCostPositions, isLoading: isLoadingInternalCostPositions } = internalCostPosition.all(internalCostPositionWhere, computed(() => Boolean(props)))

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const deleteOne = useMutation({
  mutationFn: $trpc.internalCostPosition.delete.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die interne Position konnte nicht gelöscht werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['internalCostPosition'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
      queryClient.invalidateQueries({ queryKey: ['accessories'] }),
    ])
    notification.success({ title: 'Die interne Position wurde gelöscht', duration: 4500 })

    popupConfirmDeletion.value = null
  },
})

const sumOfPositions = computed(() => {
  if (!internalCostPositions.value) {
    return 0
  }

  return internalCostPositions.value.reduce((acc, curr) => acc + curr.price, 0).toLocaleString('de-DE')
})

const popupConfirmDeletion = ref<null | ApiInternalCostPositionGetAll>(null)

const { openInternalCostCreateOrEditPopup } = useGlobalOpeners()
</script>

<template>
  <TheConfirmPopup v-if="popupConfirmDeletion" @confirm="deleteOne.mutate(popupConfirmDeletion)" @close="popupConfirmDeletion = null">
    Die Position wird gelöscht.
  </TheConfirmPopup>

  <div class="w-full flex justify-end">
    <n-button class="w-full md:w-auto" @click="openInternalCostCreateOrEditPopup.open({ mode: 'create', type, id })">
      <template #icon>
        <Icon name="material-symbols:add-comment" />
      </template>
      Individuelle Position hinzufügen
    </n-button>
  </div>

  <TableFilters v-model="fulltextSearchValue" :placeholder="fulltextSearch.placeholder">
    <div class="w-full mt-2 px-2 text-gray-500 italic text-right">
      Gesamtbetrag der Positionen: {{ sumOfPositions }} EUR
    </div>

    <TableView
      :data="internalCostPositions"
      :columns="columns"
      :is-loading="isLoadingInternalCostPositions"
      :action-buttons="() => ['update', 'delete']"
      :row-props="rowProps"
      @update="(row: ApiInternalCostPositionGetAll) => openInternalCostCreateOrEditPopup.open({ mode: 'update', type: row.type as InternalCostPositionType, id: row[`${row.type as InternalCostPositionType}Id`] ?? '', internalCostPositionId: row.id })"
      @delete="costPosition => popupConfirmDeletion = costPosition"
    />
  </TableFilters>
</template>
