<script setup lang="ts">
import type { UpdateOrCreateSpecialPositions } from '~/types'

const { openSpecialPositionsCreateOrEditPopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const { offerPosition: queryOfferPosition } = useQuery()

const offerId = computed(() => popupData.value?.offerId)
const logisticsTaskId = computed(() => popupData.value?.logisticsTaskId)

const { data: specialPositions, isLoading: isLoadingSpecialPositions } = queryOfferPosition.allSpecialPositions(offerId, logisticsTaskId, false)

const isSomeLoaded = computed(() => specialPositions.value?.some(position => position.isLoaded))

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const createAll = useMutation({
  mutationFn: $trpc.offerPosition.special.createAll.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Sonderartikel konnten nicht erstellt werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['offerPosition'] }),
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
    ])

    closePopup()

    notification.success({ title: 'Die Sonderartikel wurden erfolgreich erstellt', duration: 4500 })
  },
})

const updateAll = useMutation({
  mutationFn: $trpc.offerPosition.special.updateAll.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Aktualisierung kann nicht durchgeführt werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['offerPosition'] }),
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
    ])

    closePopup()

    notification.success({ title: 'Die Aktualisierung wurde erfolgreich durchgeführt', duration: 4500 })
  },
})

const payload = computed((): UpdateOrCreateSpecialPositions | undefined => {
  if (!popupData.value) {
    return undefined
  }

  const { offerId, logisticsTaskId } = popupData.value

  if (popupData.value.mode === 'create') {
    return {
      mode: 'create',
      data: {
        offerId,
        logisticsTaskId,
        positions: [{ title: '', outboundGeneralPhotos: [] }],
      },
    }
  }

  if (!specialPositions.value) {
    return undefined
  }

  return {
    mode: 'update',
    data: {
      offerId,
      logisticsTaskId,
      positions: specialPositions.value,
    },
  }
})

function submit(payload: UpdateOrCreateSpecialPositions) {
  if (!popupData.value) {
    defaultLogger.error({
      msg: 'No popup data provided in components/SpecialPosition/CreateOrEditContainer.vue',
    })
    return
  }

  if (payload.mode === 'create') {
    createAll.mutate(payload.data)
  } else {
    updateAll.mutate(payload.data)
  }
}
</script>

<template>
  <ThePopup v-if="payload && popupData && !isLoadingSpecialPositions" :show="Boolean(payload)" :title="`${popupData.mode === 'create' ? 'Sonderartikel anlegen' : 'Sonderartikel aktualisieren'}`" width="1100px" @close="closePopup">
    <SpecialPositionCreateOrEditForm
      :payload="payload"
      :disabled="isSomeLoaded"
      @submit="submit"
    />
  </ThePopup>
</template>
