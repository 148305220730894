<script setup lang="ts">
import type { CreateInvoiceExtraDaysPosition } from '~/types'

defineProps<{ modelValue: CreateInvoiceExtraDaysPosition[] }>()
const emits = defineEmits<{ (e: 'delete', index: number): void }>()
</script>

<template>
  <div class="flex flex-col border rounded px-2 divide-y">
    <div v-for="(extraPosition, index) of modelValue" :key="extraPosition.offerPositionToExtendId" class="flex gap-x-3 items-center justify-between py-2">
      <div class="flex gap-2">
        <span class="font-semibold">{{ `${index + 1}. ${extraPosition.title}` }}</span>
        <span>( Tage: {{ extraPosition.quantity }} )</span>
      </div>
      <n-button
        @click="emits('delete', index)"
      >
        <template #icon>
          <Icon name="material-symbols:delete-outline-rounded" />
        </template>
      </n-button>
    </div>
  </div>
</template>
