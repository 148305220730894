<script setup lang="ts">
import { logisticsTaskTypeToGerman } from '~/translations'
import type { LogisticsTaskType } from '~/types'

const { openLogisticsTaskInformationPopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const id = computed(() => popupData.value?.id)

const { logisticsTask: queryLogisticsTask } = useQuery()
const { data: logisticsTask, isLoading: isLoadingLogisticsTask } = queryLogisticsTask.byIdOrThrow(id)

const { openOfferPage } = useGlobalOpeners()

const router = useRouter()

function handleOpenOfferPage() {
  if (!logisticsTask.value?.offer) {
    return
  }

  openOfferPage({ mode: 'edit', id: logisticsTask.value.offer.id, redirectUrl: router.currentRoute.value.fullPath }).then(() => closePopup())
}

function navigateToDeliveryNotePDF() {
  if (!logisticsTask.value?.offer) {
    return
  }

  const customerLocale = logisticsTask.value.offer.customer?.language || 'de'
  window.open(`/api/pdf/offer/details/${logisticsTask.value.offer.cuid}?type=deliveryNote&locale=${customerLocale}`, '_blank')
}
</script>

<template>
  <ThePopup v-if="logisticsTask && popupData" :show="Boolean(logisticsTask)" :title="`${logisticsTaskTypeToGerman[logisticsTask.type as LogisticsTaskType]} ${logisticsTask.offer?.id}`" width="870px" @close="closePopup">
    <div v-if="['inbound', 'outbound', 'a-to-b'].includes(logisticsTask.type) && logisticsTask.offer">
      <div class="my-4 flex gap-4">
        <n-button @click="handleOpenOfferPage">
          Zum Auftrag
        </n-button>
        <n-button
          :disabled="!logisticsTask.deliveryAt"
          :title="logisticsTask.deliveryAt ? undefined : 'Lieferung ist noch nicht geplant'"
          @click="navigateTo(`/logistics-planning?dispatcherSelectedTime=${logisticsTask.deliveryAt?.getTime()}`)"
        >
          Zum Dispo-Planer
        </n-button>
        <n-button @click="navigateToDeliveryNotePDF">
          Lieferschein drucken
        </n-button>
      </div>

      <LogisticsInformationCard :is-loading="isLoadingLogisticsTask" :logistics-task="logisticsTask" />
    </div>
    <div v-else>
      Zu dieser Aufgabe können keine Informationen angezeigt werden ({{ logisticsTask.type }}, {{ logisticsTask.id }})
    </div>
  </ThePopup>
</template>
