<script setup lang="ts">
import { createId } from '@paralleldrive/cuid2'
import type { SpecialPermissions } from '~/types'

const specialPermissions = defineModel<SpecialPermissions>({ required: true })

const expandedNames = ref<string[]>([])
const newSpecialPermissionIds = ref<string[]>([])

function addSpecialPermission() {
  const id = createId()
  specialPermissions.value.push({
    id,
    title: '',
    date: new Date(),
  })
  expandedNames.value.push(id)
  newSpecialPermissionIds.value.push(id)
}

const specialPermissionIndexToDelete = ref<number | undefined>(undefined)
function deleteSpecialPermission(specialPermissionIndex: number) {
  if (specialPermissions.value.length === 0) {
    return
  }
  specialPermissions.value.splice(specialPermissionIndex, 1)
}
</script>

<template>
  <div class="mb-4">
    <div class="flex flex-col md:flex-row md:items-center justify-between gap-2 py-4">
      <h2 class="text-base font-bold">
        Sondergenehmigung
      </h2>

      <div class="flex items-center gap-2 md:flex-row-reverse">
        <n-button type="primary" @click="addSpecialPermission">
          <template #icon>
            <Icon name="material-symbols:add-box-outline-rounded" />
          </template>
          Hinzufügen
        </n-button>
      </div>
    </div>
    <FormKit v-model="specialPermissions" type="list" dynamic>
      <n-collapse v-model:expanded-names="expandedNames" display-directive="show" :trigger-areas="['main', 'arrow']" class="space-y-2">
        <n-collapse-item
          v-for="(item, index) in specialPermissions"
          :key="item.id"
          :name="item.id"
          class="bg-gray-50 p-2 border rounded"
        >
          <template #header>
            {{ item.title }}
          </template>
          <template #header-extra>
            <div class="flex items-center gap-1">
              <n-button
                type="error"
                secondary
                class="size-9"
                @click="() => specialPermissionIndexToDelete = index"
              >
                <template #icon>
                  <Icon name="material-symbols:delete-outline-rounded" class="cursor-pointer" />
                </template>
              </n-button>
            </div>
          </template>

          <n-divider class="!mt-0 !mb-4" />

          <FormKit
            type="group"
            :index="index"
          >
            <div class="flex flex-col gap-3 ml-3 mb-3">
              <div>
                <FormKit
                  name="title"
                  type="text"
                  label="Titel"
                  required
                />
                <FormKitDate
                  name="date"
                  label="Nächste Sondergenehmigung fällig am"
                  required
                />
              </div>

              <CommentList
                v-if="!newSpecialPermissionIds.includes(item.id)"
                :id="item.id"
                type="specialPermission"
                list-title-text="Sondergenehmigung Kommentare"
              />
              <p v-else>
                Kommentare für neue Sondergenehmigung können nach dem Speichern hinzugefügt werden
              </p>
            </div>
          </FormKit>
        </n-collapse-item>
      </n-collapse>
    </FormKit>

    <TheConfirmPopup
      v-if="specialPermissionIndexToDelete !== undefined"
      @confirm="deleteSpecialPermission(specialPermissionIndexToDelete)"
      @close="specialPermissionIndexToDelete = undefined"
    />
  </div>
</template>

<style scoped>
/* Remove extra top padding from collapse item headers */
:deep(.n-collapse-item__header) {
  @apply pt-0 !important
}
</style>
