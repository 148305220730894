<script setup lang="ts">
/**
 * Here we are creating a custom file-dropzone instead of using `naive-ui/upload`.
 * We are not using the naive-ui version due to vue warnings you can check out the issue about this: https://github.com/tusen-ai/naive-ui/issues/4447
 */
import path from 'pathe'
import axios from 'axios'
import { getPathFromFile, isImageFilePath } from './common'
import type { FilePath, FileSchema, UploadStatus, UploadedFile } from '~/types'

type DropzoneValueType = (string | FilePath)[]
const props = defineProps<{
  context: {
    value: DropzoneValueType
    _value: DropzoneValueType
    node: { input: (v: DropzoneValueType, async?: boolean) => unknown }
    attrs: {
      'accept'?: string
      'read-only': boolean
      'wrap-path-in-object': boolean
      'use-public-signed-url-endpoint': boolean
    }
  }
}>()

onMounted(() => {
  if (!props.context.value) {
    props.context.node.input([])
  }
})

const { $trpc } = useMutationHelpers()

// Whether the dropzone content can be edited
// We check if typeof value is string https://github.com/sidestream-tech/hanselmann-os/pull/2465#discussion_r1555885462
const isReadOnly = props.context.attrs['read-only'] || typeof props.context.attrs['read-only'] === 'string'

// Whether the value should be emitted as `[path, path, path, ...]` or `[{ path }, { path }, { path }, ...]`
// We check if typeof value is string https://github.com/sidestream-tech/hanselmann-os/pull/2465#discussion_r1555885462
const doWrapValueInObject = props.context.attrs['wrap-path-in-object'] || typeof props.context.attrs['wrap-path-in-object'] === 'string'

// Whether the signed upload URL should be received from the internal, authenticated endpoint or the public one
// We check if typeof value is string https://github.com/sidestream-tech/hanselmann-os/pull/2465#discussion_r1555885462
const usePublicSignedUrlEndpoint = props.context.attrs['use-public-signed-url-endpoint'] || typeof props.context.attrs['use-public-signed-url-endpoint'] === 'string'

function getInitialUploadedFilesValue() {
  /**
   * https://formkit.com/essentials/custom-inputs#displaying-values
   * _value should be used only to display value
   */
  const _value = props.context._value
  if (!_value) {
    return []
  }

  return _value.map((file) => {
    const filePath = getPathFromFile(file)

    return ({
      filekey: filePath,
      status: 'success' as UploadStatus,
      hasPreview: isImageFilePath(filePath),
    })
  })
}

const uploadedFiles: Ref<UploadedFile[]> = ref(getInitialUploadedFilesValue())

// Reset `uploadedFiles` if `undefined` is passed down from the parent while, this is what happens when formkit `reset`s the node
watch(() => props.context.value, (newVal, oldVal) => {
  if (newVal === undefined && oldVal !== undefined) {
    uploadedFiles.value = getInitialUploadedFilesValue()
  }
})

// Accept logic
const acceptArray = computed(() => props.context.attrs.accept?.split(','))

// Dropzone
const dropZoneRef = ref<HTMLDivElement>()
const hasError = ref(false)
const { files, open } = useFileDialog({
  accept: props.context.attrs.accept,
})

async function updateSelectedFileList(files: File[] | null) {
  if (!files) {
    return
  }

  let filesToUpload = []
  if (props.context.value) {
    filesToUpload = files.map((file) => {
      if (props.context.value.some(selectedFile => path.basename(getPathFromFile(selectedFile)) === file.name)) {
        return new File([file], `${Date.now()}-${path.basename(file.name)}`, { type: file.type })
      }

      return file
    })
  } else {
    filesToUpload = files
  }

  // Filter out accepted data types if needed
  const accepted = acceptArray.value
  if (accepted) {
    filesToUpload = filesToUpload.filter(it => accepted.includes(it.type))
  }

  hasError.value = filesToUpload.length === 0

  await Promise.all(filesToUpload.map(async (file) => {
    const uploadFile: Ref<UploadedFile> = ref({
      filekey: file.name,
      status: 'upload' as UploadStatus,
      hasPreview: false,
      progress: 0,
    })
    const currentFile: FileSchema = { name: file.name, type: file.type }
    try {
      uploadedFiles.value.push(uploadFile.value)
      const clientUrls = usePublicSignedUrlEndpoint
        ? await $trpc.file.publicRestrictedGetSignedUrlToUpload.query(currentFile)
        : await $trpc.file.getSignedUrlToUpload.query(currentFile)

      // TODO: Get rid of axios. We cannot do so right now as we rely on it's status upload tracking, which `unjs/ofetch` does not support yet: https://github.com/unjs/ofetch/issues/45
      await axios({
        method: 'PUT',
        url: clientUrls.uploadUrl,
        data: file,
        onUploadProgress: (stats) => {
          uploadFile.value.progress = (stats.loaded / (stats.total ?? 1)) * 100
        },
      })

      const downloadUrl = clientUrls.downloadUrl
      uploadFile.value.filekey = downloadUrl
      uploadFile.value.status = 'success'
      uploadFile.value.hasPreview = file.type.includes('image')

      if (doWrapValueInObject) {
        props.context.node.input([...props.context._value, { path: downloadUrl }], false)
      } else {
        props.context.node.input([...props.context._value, downloadUrl], false)
      }

      return downloadUrl
    } catch (error: unknown) {
      uploadFile.value.status = 'error'
    }
  }))
}

watch(files, (newFiles) => {
  if (newFiles) {
    updateSelectedFileList([...newFiles])
  }
})

function removeFile(fileToDelete: string) {
  uploadedFiles.value = uploadedFiles.value.filter(file => file.filekey !== fileToDelete)
  props.context.node.input(props.context._value.filter(file => getPathFromFile(file) !== fileToDelete))
}

useDropZone(dropZoneRef, {
  onDrop(files: File[] | null) {
    updateSelectedFileList(files)
  },
})
</script>

<template>
  <div class="mb-6">
    <div
      v-if="!isReadOnly"
      ref="dropZoneRef"
      class="w-full py-6 px-4 flex items-center flex-col border border-dashed cursor-pointer"
      :class="{ DropzoneError: hasError }"
      @click="() => open()"
    >
      <div>
        <Icon name="material-symbols:archive-outline" size="32" />
      </div>
      <n-text class="mt-3 text-base text-center md:text-start">
        Ziehen Sie die Datei(en) zum Hochladen in diesen Bereich
      </n-text>
    </div>
    <div v-if="isReadOnly && !context._value.length">
      <n-empty class="py-5" description="Keine Dateien hochgeladen" />
    </div>

    <FileListWithPreviews
      v-else
      :allow-delete="!isReadOnly"
      :files="uploadedFiles"
      @delete="removeFile"
    />
  </div>
</template>

<style scoped>
.DropzoneError {
  @apply border-red-500
}
</style>
