<script setup lang="ts">
import type { SelectOption } from 'naive-ui'

type CheckboxValueType = (string | number | undefined)[]
const props = defineProps<{
  context: { value: string[], node: { input: (v: CheckboxValueType) => unknown }, update?: (v: CheckboxValueType) => unknown, multiSelectOptions: SelectOption[], selectAllSwitch?: boolean }
}>()

const availableOptions = computed(() => props.context.multiSelectOptions.filter(option => !option.disabled))
const selectAll = computed(() => props.context.value.length === availableOptions.value.length)

function updateSwitch(value: boolean) {
  props.context.node.input(value ? availableOptions.value.map(option => option.value) : [])
}

function updateCheckboxGroup(value: CheckboxValueType) {
  if (props.context.update) {
    props.context.update(value)
  } else {
    props.context.node.input(value)
  }
}
</script>

<template>
  <div>
    <div v-if="context.selectAllSwitch" class="flex items-center gap-x-2 mb-4">
      <n-switch v-model:value="selectAll" @update:value="updateSwitch" />
      <span>Alle auswählen</span>
    </div>

    <n-checkbox-group :value="context.value ?? null" @update:value="updateCheckboxGroup">
      <n-space item-style="display: flex;" vertical>
        <n-checkbox
          v-for="multiSelectOption of context.multiSelectOptions"
          :key="multiSelectOption.value"
          :value="multiSelectOption.value"
          :label="multiSelectOption.label as string"
          :disabled="multiSelectOption.disabled"
        />
      </n-space>
    </n-checkbox-group>
  </div>
</template>
