<script setup lang="ts">
import type { AutoCompleteOption } from 'naive-ui'

type Event = string | null
const props = defineProps<{
  context: {
    value: string
    node: { input: (e: Event) => unknown }
    update?: (e: Event) => unknown
    options: AutoCompleteOption[]
    placeholder?: string
    isOptionCreationAllowed?: boolean
    isClearable?: boolean
    class: string
    showEmpty?: boolean
  }
}>()

/** NOTE: It may be `null` due to a `naive-ui` bug */
const rawInput = ref('')

/** Options manually filtered */
const filteredOptions = computed(() => {
  const opts = props.context.options

  // Protect against a stray `null` from `naive-ui`
  const rawInputValue: string | null = rawInput.value
  if (!opts || rawInputValue === null) {
    return []
  }

  const rawInputLowercase = rawInputValue.toLowerCase()
  const includeIfNoValue = rawInputValue === ''
  const filtered = opts.filter(
    it => it.label
      ? it.label.toLowerCase().includes(rawInputLowercase)
      : includeIfNoValue,
  )

  // Prepend a new option if creation is allowed (and option is not selectable already)
  if (props.context.isOptionCreationAllowed && !includeIfNoValue && !filtered.some(it => it.label === rawInputValue)) {
    filtered.unshift({
      label: rawInputValue,
      value: rawInputValue,
    })
  }

  return filtered
})

// Update the text input when options change
watch(() => props.context.options, updateRawInputFromValue, { immediate: true })

function updateRawInputFromValue() {
  if (!props.context.options) {
    return
  }

  const foundOption = props.context.options.find(it => it.value === props.context.value)
  if (foundOption) {
    rawInput.value = foundOption.label ?? ''
  }
}

/**
 * This handler is invoked when a value is actually selected or cleared
 */
function onSelect(event: Event) {
  if (props.context.update) {
    props.context.update(event)
  } else {
    props.context.node.input(event)
  }
}

/**
 * This is a workaround for naive-ui which doesn't correctly emit `select` event when input is cleared.
 * But it emits `null` on raw input instead, which we can use to reset the value.
 */
function onUpdateRawInput(v: string | null) {
  if (v === null) {
    onSelect(null)
  }
}

function onBlur() {
  const rawInputValue = rawInput.value
  if (rawInputValue === '') {
    // If blurred on an empty input, reset
    onSelect(null)
    return
  }

  // Check exact match against the label - select if found.
  // This covers a case for new creation
  const exactMatch = filteredOptions.value.find(it => it.label === rawInputValue)
  if (exactMatch && exactMatch.value !== undefined) {
    onSelect(exactMatch.value)
  } else {
    // Reset to previous correct value
    updateRawInputFromValue()
  }
}
</script>

<template>
  <div>
    <n-auto-complete
      v-model:value="rawInput"
      :class="context.class"
      :options="filteredOptions"
      :placeholder="context.placeholder ?? 'Wählen Sie aus der Liste'"
      filterable
      :consistent-menu-width="false"
      size="large"
      :clearable="context.isClearable"
      :show-empty="context.showEmpty"
      :get-show="() => true"
      @select="onSelect"
      @blur="onBlur"
      @update:value="onUpdateRawInput"
    />
  </div>
</template>
