<script lang="ts" setup>
import type { ApiLogisticsTaskGetById } from '~/types'

const props = defineProps<{
  context: {
    value: string
    disablePositionsOverview?: boolean
    title?: string
    buttonText?: string
    disableByPass?: boolean
    node: { input: (v: any) => unknown }
    attrs: {
      positions: ApiLogisticsTaskGetById['positionsToShip']
    }
    onSave?: (s: string | null) => undefined
  }
}>()

const { logisticsTaskPositionsOverview } = useOneDataColumnConfigs()

const isPopupOpen = ref(false)
const signaturePad = ref()
const isSignatureSet = ref(false)
const skipSignature = ref(false)

function save() {
  const { data } = signaturePad.value.saveSignature()
  const signature = data || null
  props.context.node.input(signature)
  if (props.context.onSave) {
    props.context.onSave(signature)
  }
  closePopup()
}

function clear() {
  signaturePad.value.clearSignature()
  isSignatureSet.value = false
}

function closePopup() {
  isPopupOpen.value = false
  isSignatureSet.value = false
  skipSignature.value = false
}
</script>

<template>
  <div>
    <n-button @click="() => isPopupOpen = true">
      {{ context.buttonText || 'Unterschrift des Kunden' }}
    </n-button>
    <div v-if="context?.value" class="border-2 w-[300px] mt-3 bg-gray-100">
      <img v-if="context.value" :src="context.value">
    </div>
    <ThePopup :show="isPopupOpen" :title="context.title || 'Ich bestätige die folgende Ware erhalten zu haben'" @close="closePopup">
      <div class="flex flex-col gap-3">
        <div v-if="!context.disablePositionsOverview">
          <h1 class="text-gray-500 font-bold">
            Zusammenfassung
          </h1>
          <TableOneDataColumn :config="{ columns: logisticsTaskPositionsOverview, data: context.attrs.positions }" />
        </div>
        <div class="flex flex-col gap-3">
          <h1 class="text-gray-500 font-bold">
            Ihre Unterschrift
          </h1>
          <div class="relative border-2">
            <VueSignaturePad
              ref="signaturePad"
              height="200px"
              :options="{ onEnd: () => {
                isSignatureSet = true
                skipSignature = false
              } }"
            />
            <button class="absolute bottom-0 right-0 bg-gray-500 p-1 rounded-tl-md" type="button" @click="clear">
              <Icon name="material-symbols:delete-outline" class="text-2xl text-white" />
            </button>
          </div>
          <n-checkbox v-if="!context.disableByPass" v-model:checked="skipSignature" :disabled="isSignatureSet">
            Ohne Unterschrift des Kunden übergeben
          </n-checkbox>
          <n-button type="info" :disabled="!skipSignature && !isSignatureSet" @click="save">
            Ich bestätige
          </n-button>
        </div>
      </div>
    </ThePopup>
  </div>
</template>
