<script setup lang="ts">
import type { UpdateComment, UpdateOrCreateComment } from '~/types'

const { openCommentCreateOrUpdatePopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const { comment: commentQuery } = useQuery()
const { data: comment } = commentQuery.byId(computed(() => popupData.value?.mode === 'update' ? popupData.value.commentId : undefined))

const payload = computed((): UpdateOrCreateComment | undefined => {
  if (!popupData.value) {
    return undefined
  }

  if (popupData.value.mode === 'create') {
    return {
      mode: 'create',
      data: {
        type: popupData.value.type,
        text: '',
        files: [],
        [commentConfigs[popupData.value.type].commentTableRelationFieldName]: popupData.value.id,
      },
    }
  }

  if (comment.value) {
    return {
      mode: 'update',
      data: comment.value,
    } as UpdateComment
  }
})

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const create = useMutation({
  mutationFn: $trpc.comment.create.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Kommentar konnte nicht erstellt werden' }),
  onSuccess: async () => {
    const invalidateQueriesPromises = []

    invalidateQueriesPromises.push(queryClient.invalidateQueries({ queryKey: ['comment'] }))
    invalidateQueriesPromises.push(queryClient.invalidateQueries({
      queryKey: popupData.value?.type ? [commentConfigs[popupData.value.type].entity] : undefined,
    }))

    popupData.value?.refreshQueries?.forEach((query) => {
      invalidateQueriesPromises.push(queryClient.invalidateQueries({ queryKey: [commentConfigs[query].entity] }))
    })

    await Promise.all(invalidateQueriesPromises)

    closePopup()
    notification.success({ title: 'Der Kommentar wurde erfolgreich erstellt', duration: 4500 })
  },
})

const update = useMutation({
  mutationFn: $trpc.comment.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Kommentar konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    const invalidateQueriesPromises = []

    invalidateQueriesPromises.push(queryClient.invalidateQueries({ queryKey: ['comment'] }))
    invalidateQueriesPromises.push(queryClient.invalidateQueries({
      queryKey: popupData.value?.type ? [commentConfigs[popupData.value.type].entity] : undefined,
    }))
    popupData.value?.refreshQueries?.forEach((query) => {
      invalidateQueriesPromises.push(queryClient.invalidateQueries({ queryKey: [commentConfigs[query].entity] }))
    })

    await Promise.all(invalidateQueriesPromises)

    closePopup()
    notification.success({ title: 'Der Kommentar wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

function save(payload: UpdateOrCreateComment) {
  if (payload.mode === 'create') {
    return create.mutateAsync(payload.data)
  } else {
    return update.mutateAsync(payload.data)
  }
}
</script>

<template>
  <ThePopup v-if="payload && popupData" :show="Boolean(payload)" :title="`Kommentar ${payload.mode === 'create' ? 'Erstellung' : 'Bearbeiten'}`" @close="() => popupData?.isClosable ? closePopup() : undefined">
    <CommentCreateOrEditForm v-if="payload" :payload="payload" @save="save" />
  </ThePopup>
</template>
