<script setup lang="ts">
import { dateDeDE, deDE } from 'naive-ui'

const { status } = useAuth()
const doLoadGlobalPopups = computed(() => status.value === 'authenticated' && Object.keys(useRoute().query).length > 0)
</script>

<template>
  <NaiveConfig :locale="deDE" :date-locale="dateDeDE">
    <NNotificationProvider placement="top">
      <!-- See https://nuxt-naiveui.bg.tn/composables/use-naive-notification (docs of solutin) and https://github.com/becem-gharbi/nuxt-naiveui/issues/36 (problem being solved) -->
      <naive-notification />
      <TheAllGlobalPopups v-if="doLoadGlobalPopups" />
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </NNotificationProvider>
  </NaiveConfig>
</template>
