<script setup lang="ts">
const { t: $t } = useI18n()

import countries from 'i18n-iso-countries/langs/de.json'
import { customerLanguages, customerTypes, paymentConditions, taxRates } from '~/server/schemas'
import type { ApiCustomerGetById, CreateCustomerData, UpdateOrCreateCustomer } from '~/types'
import { customerFieldsToGerman } from '~/translations'

const props = defineProps<{
  mode: 'create' | 'update'
  showComments: boolean
  customer?: ApiCustomerGetById | null
  canUpdateCustomerUser: boolean
  inviteErrors: Record<string, string>
  isSaving: boolean
}>()
const emit = defineEmits<{
  (e: 'save', payload: UpdateOrCreateCustomer): void
  (e: 'resetInviteErrors'): void
}>()

const defaultCustomer = {
  type: 'dealer',
  paymentCondition: 'instantNet',
  taxRate: '0',
  language: 'DE',
  country: 'DE',
} satisfies Partial<CreateCustomerData['customer']>

const customerTypeOptions = useTranslatedSelectOptions('customer.type', customerTypes.options)

const taxRateOptions = taxRates.options.map(rate => ({
  label: `${(Number(rate) * 100).toFixed(0)}%`,
  value: rate,
}))

const countryOptions = Object.entries(countries.countries).map((country) => {
  if (typeof country[1] === 'string') {
    return {
      label: country[1],
      value: country[0],
    }
  } else {
    // if there are multiple names exist for one country, we use the first one
    return {
      label: country[1][0],
      value: country[0],
    }
  }
})

const paymentConditionOptions = paymentConditions.options.map(condition => ({
  label: $t(`paymentConditions.${condition}`),
  value: condition,
}))

const languageOptions = customerLanguages.options.map(language => ({
  label: $t(`customerLanguages.${language}`),
  value: language,
}))

const contactPersons = ref<UpdateOrCreateCustomer['data']['contactPersons']>()
watch(() => props.customer, (fetchedCustomer) => {
  if (contactPersons.value) {
    return
  }

  if (fetchedCustomer) {
    contactPersons.value = fetchedCustomer.contactPersons.map((contactPerson) => {
      return {
        ...contactPerson,
        hasHOSAccess: contactPerson.customerUser !== null,
      }
    })
  } else {
    contactPersons.value = []
  }
}, { immediate: true })

// Reset invite errors when contact persons updated
watch(contactPersons, () => {
  emit('resetInviteErrors')
})

const alreadyInvitedContactPersons = computed((): Record<string, boolean> => {
  if (!props.customer) {
    return {}
  }
  return props.customer.contactPersons.reduce((acc, contactPerson) => {
    acc[contactPerson.id] = contactPerson.customerUser !== null
    return acc
  }, {} as Record<string, boolean>)
})

const formData = ref<ApiCustomerGetById | Partial<CreateCustomerData['customer']>>(props.customer ?? defaultCustomer)

watchEffect(() => {
  if (formData.value && formData.value?.type !== customerTypes.enum.dealer) {
    formData.value.isPartnerLiftCustomer = false
    formData.value.isSystemLiftCustomer = false
  }
})
</script>

<template>
  <FormKit
    v-model="formData"
    type="form"
    :actions="false"
    :disabled="isSaving"
    @submit="emit('save', { data: { customer: $event, contactPersons: contactPersons ?? [] }, mode })"
  >
    <CommentList v-if="customer && showComments" :id="customer.id" type="customer" class="mb-5" />
    <TheDevOnlyNiceJson :form-value="formData" />
    <div class="flex space-x-2">
      <FormKit
        name="name"
        type="text"
        validation="required"
        label="Name"
        placeholder="Musterman AG"
      />
      <FormKit
        name="nameTwo"
        type="text"
        label="Name 2"
        placeholder="ACME Inc."
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="nameThree"
        type="text"
        label="Name 3"
        placeholder="Musterfirma GmbH"
      />
      <FormKit
        name="type"
        type="select"
        :options="customerTypeOptions"
        validation="required"
        :label="$t('customer.type')"
        placeholder="Bitte auswählen"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="location"
        type="text"
        :label="customerFieldsToGerman.location"
        placeholder="Spitalseestraße 21, 74564 Crailsheim"
      />
      <FormKit
        name="invoiceAddress"
        type="text"
        :label="customerFieldsToGerman.invoiceAddress"
        placeholder="Haller Str. 195, 74564 Crailsheim"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="email"
        type="email"
        validation="email"
        :label="customerFieldsToGerman.email"
        placeholder="max@musterman.de"
      />
      <FormKit
        name="invoiceEmail"
        type="email"
        :label="customerFieldsToGerman.invoiceEmail"
        placeholder="max@musterman.de"
        validation="required"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="telephone"
        type="tel"
        :label="customerFieldsToGerman.telephone"
        placeholder="+49 123456789"
      />
      <FormKit
        name="telefax"
        type="tel"
        label="Telefax"
        placeholder="+49 123456966966"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="iban"
        type="text"
        :label="customerFieldsToGerman.iban"
        placeholder="DE89370400440532013000"
      />
      <FormKit
        name="taxId"
        type="text"
        :label="customerFieldsToGerman.taxId"
        placeholder="22 00 0 001 00001"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="vatID"
        type="text"
        :label="customerFieldsToGerman.vatID"
        placeholder="DE89370400440532013000"
      />
      <FormKit
        name="taxRate"
        type="select"
        :options="taxRateOptions"
        label="Steuersatz"
      />
      <FormKit
        name="paymentCondition"
        type="select"
        :options="paymentConditionOptions"
        validation="required"
        label="Zahlungskonditionen"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="language"
        type="select"
        :options="languageOptions"
        validation="required"
        label="Sprache"
        placeholder="Bitte auswählen"
      />
      <FormKitMultiSelect
        name="country"
        label="Land"
        :multi-select-options="countryOptions"
        :is-multi-select-allowed="false"
        placeholder="Deutschland"
      />
    </div>

    <h2 class="Heading">
      Zusätzliches
    </h2>
    <div class="flex space-x-2">
      <FormKit
        name="isExternalStorageCustomer"
        type="checkbox"
        label="Lagerkunde"
      />
      <FormKit
        name="isExternalStorageSupplier"
        type="checkbox"
        label="Lieferant"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="isPartnerLiftCustomer"
        type="checkbox"
        :disabled="formData.isSystemLiftCustomer || formData.type !== customerTypes.enum.dealer"
        :label="$t('customer.isPartnerLiftCustomer.label')"
      />
      <FormKit
        name="isSystemLiftCustomer"
        type="checkbox"
        :disabled="formData.isPartnerLiftCustomer || formData.type !== customerTypes.enum.dealer"
        :label="$t('customer.isSystemLiftCustomer.label')"
      />
    </div>

    <CustomerContactPersonInput
      v-model="contactPersons"
      :already-invited-contact-persons
      :can-update-customer-user
      :invite-errors
    />

    <FormKit type="submit">
      Kunden speichern <n-spin v-if="isSaving" size="small" class="ml-2" />
    </FormKit>
  </FormKit>
</template>

<style scoped>
.Heading {
  @apply text-xl font-bold py-4
}
</style>
