<script setup lang="ts">
const { t: $t } = useI18n()

import type { InternalCostPositionType, UpdateInternalCostPosition, UpdateOrCreateInternalCostPosition } from '~/types'

const { openInternalCostCreateOrEditPopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const { internalCostPosition: queryInternalCostPosition } = useQuery()

const internalCostPositionId = computed(() => {
  if (popupData.value?.mode === 'update') {
    return popupData.value.internalCostPositionId
  }
  return undefined
})
const { data: internalCostPosition } = queryInternalCostPosition.byId(internalCostPositionId)

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()
const update = useMutation({
  mutationFn: $trpc.internalCostPosition.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Aktualisierung kann nicht durchgeführt werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['internalCostPosition'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
      queryClient.invalidateQueries({ queryKey: ['accessories'] }),
    ])
    closePopup()
    notification.success({ title: 'Die Aktualisierung wurde erfolgreich durchgeführt', duration: 4500 })
  },
})

const create = useMutation({
  mutationFn: $trpc.internalCostPosition.create.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Interne Kosten konnte nicht erstellt werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['internalCostPosition'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
      queryClient.invalidateQueries({ queryKey: ['accessories'] }),
    ])
    closePopup()
    notification.success({ title: 'Die Interne Kosten wurde erfolgreich erstellt', duration: 4500 })
  },
})

const payload = computed((): UpdateOrCreateInternalCostPosition | undefined => {
  if (!popupData.value) {
    return undefined
  }

  if (popupData.value.mode === 'create') {
    return {
      mode: 'create',
      data: {
        type: popupData.value.type as InternalCostPositionType,
        title: '',
        price: 0,
        [`${popupData.value.type}Id`]: popupData.value.id,
      },
    }
  }

  if (!internalCostPosition.value) {
    return undefined
  }

  return {
    mode: 'update',
    data: internalCostPosition.value as UpdateInternalCostPosition['data'],
  }
})

function submitInternalCostPosition(payload: UpdateOrCreateInternalCostPosition) {
  if (payload.mode === 'create') {
    create.mutate(payload.data)
  } else {
    update.mutate(payload.data)
  }
}

const popupTitle = computed(() => {
  if (!popupData.value) {
    return ''
  }
  if (popupData.value.mode === 'create') {
    return `${$t('internalCost.popup.title', { entity: $t(`${popupData.value.type}.name`) })} ${popupData.value.id}`
  }
  return `Interne Kosten "${internalCostPosition.value?.title}"`
})
</script>

<template>
  <ThePopup v-if="payload && popupData" :show="Boolean(payload)" :title="popupTitle" width="1100px" @close="closePopup">
    <InternalCostEditForm :payload="payload" :internal-cost-position="internalCostPosition" @submit="submitInternalCostPosition" />
  </ThePopup>
</template>
