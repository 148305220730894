<script setup lang="ts">
import path from 'pathe'
import type { Size } from './common'
import { getVideoProvider } from './common'
import type { UploadedFile } from '~/types'
import 'vidstack/bundle'

const props = withDefaults(defineProps<{
  allowDelete?: boolean
  file: UploadedFile
  size?: Size
  disableCookieConset: boolean
}>(), {
  allowDelete: false,
  size: 'Normal',
})

const emit = defineEmits<{
  delete: [src: string]
  showPdf: [src: string]
  showVideo: [src: string]
}>()

const { cookiesEnabledIds } = useCookieControl()

const iconSize = computed(() => props.size === 'Normal' ? '110' : '64')

const ext = computed(() => getFileExtension(props.file.filekey))
const filename = computed(() => path.basename(props.file.filekey))
const isPdf = computed(() => ext.value === '.pdf')

const externalVideoProvider = computed(() => getVideoProvider(props.file.filekey))
const isExternalVideo = computed(() => !!externalVideoProvider.value)
const cookieConsentGiven = computed(() => {
  return props.disableCookieConset
    || !externalVideoProvider.value
    || cookiesEnabledIds.value?.includes(externalVideoProvider.value)
})
const isVideo = computed(() => ext.value === '.mp4' || isExternalVideo.value)

function handleClick() {
  if (isVideo.value) {
    emit('showVideo', props.file.filekey)
    return
  }

  if (isPdf.value) {
    emit('showPdf', props.file.filekey)
  }
}
</script>

<template>
  <div class="ImageContainer w-full m-2" :class="size">
    <div
      v-if="file.status === 'success'"
      class="flex flex-col space-x-1 w-[145px]"
      :class="{ 'cursor-pointer': isVideo || isPdf }"
      @click="handleClick"
    >
      <FilePreviewInner v-if="!isVideo" :file :icon-size :allow-delete :is-pdf :is-video :is-external-video :alt="filename" @delete="$emit('delete', file.filekey)" />
      <n-popover v-else raw style="border-radius: 0.5rem">
        <template #trigger>
          <FilePreviewInner :file :icon-size :allow-delete :is-pdf :is-video :is-external-video :alt="filename" @delete="$emit('delete', file.filekey)" />
        </template>
        <n-card class="hidden md:block w-96 rounded-lg p-2 pointer-events-none select-none" content-style="padding: 0; margin-bottom: -2.5px">
          <media-player v-if="cookieConsentGiven" :src="props.file.filekey" class="!rounded-md">
            <media-provider />
            <media-video-layout />
          </media-player>
          <p v-else class="p-2">
            Um eine Vorschau abzuspielen, bitte akzeptiere die {{ externalVideoProvider === 'youtube' ? 'YouTube' : 'Vimeo' }} Cookie Richtlinien über den Cookie-Consent Manager.
          </p>
        </n-card>
      </n-popover>
      <span
        v-if="size !== 'Small' && !isExternalVideo"
        class="flex-1 truncate text-center"
        :title="filename"
      >{{ filename }}</span>
    </div>

    <div v-else-if="file.status === 'upload'" class="flex flex-col space-x-1">
      <div class="w-14 flex flex-col">
        <Icon name="line-md:uploading-loop" size="144" class="p-10 bg-gray-100 rounded overflow-hidden" />
        <div class="bg-green-200 rounded h-2 mt-[-8px]" :style="{ width: `${file.progress}%` }" />
      </div>
      <span class="flex-1 truncate text-center">{{ filename }}</span>
    </div>

    <div v-else class="flex space-x-1">
      <Icon name="material-symbols:error-outline-rounded" size="56" />
      <span class="flex-1 text-red-500">{{ filename }}</span>
    </div>
  </div>
</template>

<style scoped>
media-player :deep(media-controls) {
  display: none;
}

.ImageContainer.Normal {
 @apply w-[175px]
}

.ImageContainer.Small {
  @apply w-[175px]
}

.ImageContainer.Normal .DocumentIcon {
 @apply p-5
}

.ImageContainer.Small .DocumentIcon {
 @apply p-1
}
</style>
