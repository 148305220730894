<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'
import type { CRUDModes, MachineryCategories, UpdateOrCreateMachineryType } from '~/types'
import { CRUDModeSchema, idSchema, machineryCategories, zodStringAsBoolean } from '~/server/schemas'

const editMachineryTypeMode = useRouteQuery('editMachineryTypeMode')
const machineryCategory = useRouteQuery('machineryCategory')
const machineryTypeId = useRouteQuery('machineryTypeId')
const isCreatedMachineryTypeIdRequired = useRouteQuery('isCreatedMachineryTypeIdRequired')
const machineryCreateOrEditPopup = useRouteQueryAsObject('machineryCreateOrEditPopup')

// TODO: THIS COULD BE DRASTICALLY IMPROVED BY USING THE `useGlobalOpeners` approach
const popupData = computed(() => {
  const parsedId = idSchema.safeParse(machineryTypeId.value)
  const parsedCategory = machineryCategories.safeParse(machineryCategory.value)
  const parsedEditMode = CRUDModeSchema.safeParse(editMachineryTypeMode.value)
  const parsedIsCreatedTypeIdRequired = zodStringAsBoolean.safeParse(isCreatedMachineryTypeIdRequired.value)

  return {
    typeId: parsedId.success ? parsedId.data : undefined,
    category: parsedCategory.success ? parsedCategory.data : undefined,
    mode: parsedEditMode.success ? parsedEditMode.data : undefined,
    isCreatedTypeIdRequired: parsedIsCreatedTypeIdRequired.success ? parsedIsCreatedTypeIdRequired.data : undefined,
  }
})

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const id = computed(() => popupData.value.typeId)
const enabledMachineryType = computed(() => !!popupData.value.typeId)

const { machineryType: queryMachineryType } = useQuery()
const { data: machineryType, isLoading: isLoadingMachineryType } = queryMachineryType.byIdOrThrow(id, enabledMachineryType)

const update = useMutation({
  mutationFn: $trpc.machineryType.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Typ konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['machineryTypes'] })
    closePopup()
    notification.success({ title: 'Die Typ wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const create = useMutation({
  mutationFn: $trpc.machineryType.create.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Typ konnte nicht erstellt werden' }),
  onSuccess: async (type) => {
    await queryClient.invalidateQueries({ queryKey: ['machineryTypes'] })
    if (popupData.value.isCreatedTypeIdRequired) {
      closePopup(type.id)
    } else {
      closePopup()
    }
    notification.success({ title: 'Der Typ wurde erfolgreich erstellt', duration: 4500 })
  },
})

const payload = computed((): UpdateOrCreateMachineryType | undefined => {
  if (!popupData.value.mode) {
    return undefined
  }

  if (popupData.value.mode === 'create') {
    return {
      mode: 'create',
      data: {
        name: '',
        category: popupData.value.category ?? 'forklift',
        documentDataSheetFiles: [],
        documentManualFiles: [],
        documentVideoFiles: [],
        homepageImages: [],
        documentExternalVideoFiles: [],
      },
    }
  }

  if (machineryType.value && !isLoadingMachineryType.value) {
    return {
      mode: 'update',
      data: {
        ...machineryType.value,
        category: machineryType.value.category as MachineryCategories,
      },
    }
  }
})

function saveCreateOrEditForm(payload: UpdateOrCreateMachineryType) {
  if (payload.mode === 'create') {
    return create.mutateAsync(payload.data)
  } else {
    return update.mutateAsync(payload.data)
  }
}

function closePopup(createdTypeId?: string) {
  if (createdTypeId) {
    machineryCreateOrEditPopup.value = {
      ...machineryCreateOrEditPopup.value,
      typeId: createdTypeId,
    }
  }

  editMachineryTypeMode.value = null
  machineryTypeId.value = null
  machineryCategory.value = null
  isCreatedMachineryTypeIdRequired.value = null
}

watch(() => popupData.value.mode, (value: CRUDModes | undefined) => {
  if (!value) {
    return
  }

  if (value === 'create' && !popupData.value.category) {
    makeTrpcErrorToast(notification, { title: 'Typ Fehlgeschlagen', description: 'Bitte geben Sie eine Kategorie an.' })(null)
    closePopup()
  } else if (value === 'update' && !popupData.value.typeId) {
    makeTrpcErrorToast(notification, { title: 'Typ Fehlgeschlagen', description: 'Bitte geben Sie eine gültige ID zum Bearbeiten an.' })(null)
    closePopup()
  }
}, { immediate: true })
</script>

<template>
  <ThePopup v-if="payload" :show="Boolean(payload)" title="Gerätetyp-Anlage" @close="closePopup">
    <MachineryTypeCreationCreateOrEditForm :payload="payload" @save="saveCreateOrEditForm" />
  </ThePopup>
</template>
