<script setup lang="ts">
import type { ApiLogisticsTaskGetById } from '~/types'

defineProps<{ logisticsTask: ApiLogisticsTaskGetById }>()

const { openLogisticsTaskDocumentUploadPopup } = useGlobalOpeners()
</script>

<template>
  <TheDataCard>
    <template #header>
      <div class="CardHeader">
        <h2>Dokumente</h2>
        <n-button @click="openLogisticsTaskDocumentUploadPopup.open({ id: logisticsTask.id })">
          <template #icon>
            <Icon name="material-symbols:edit-outline-rounded" />
          </template>
          Ändern
        </n-button>
      </div>
    </template>
    <FileView wrap-path-in-object :files="logisticsTask.documentFiles" />
  </TheDataCard>
</template>

<style scoped>
.CardHeader {
  @apply  w-full flex justify-between mb-5
}
.CardHeader h2 {
  @apply text-lg font-semibold
}
</style>
