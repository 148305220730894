<script setup lang="ts">
import type { ApiMachineryGetById } from '~/types'

defineProps<{ payload: ApiMachineryGetById }>()
const emit = defineEmits<{ (e: 'save', data: ApiMachineryGetById): void }>()

function submitLicensePlateForm(data: ApiMachineryGetById) {
  emit('save', data)
}
</script>

<template>
  <FormKit
    type="form"
    :value="payload"
    submit-label="Speichern"
    @submit="submitLicensePlateForm"
  >
    <FormKit
      type="text"
      name="licensePlateNumber"
      label="Kennzeichen"
      validation="required"
    />
  </FormKit>
</template>
