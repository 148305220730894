<script setup lang="ts">
const props = defineProps<{ show: boolean, machineryId: string }>()
const emit = defineEmits<{ (e: 'close'): void }>()

const { openReceptionProtocol } = useGlobalOpeners()

function handleClick(isModification?: boolean) {
  openReceptionProtocol.open({ mode: 'update', machineryId: props.machineryId, isModification })
  emit('close')
}
</script>

<template>
  <n-modal
    :show="show"
    preset="dialog"
    title="Aufnahmeinformationen ändern"
    positive-text="Modifizieren"
    negative-text="Korrigieren"
    @positive-click="handleClick(true)"
    @negative-click="handleClick"
    @update-show="emit('close')"
  />
</template>
