<script setup lang="ts">
import type { ApiLogisticsTaskGetById, LogisticsTaskType, LogisticsTourDirections, MachineryCategories, MachineryDrives } from '~/types'

const props = defineProps<{ task: ApiLogisticsTaskGetById }>()

const { isRole } = useAuthorization()

const { openLogisticsIssuanceMachineryList } = useGlobalOpeners()

const type = computed(() => props.task.type as LogisticsTaskType)

const protocolName = computed(() => type.value === 'outbound' ? 'Ausgabeprotokoll' : 'Rücknahmeprotokoll')

const {
  logisticsIssuanceAndMachineryDetails,
  logisticsIssuanceAccessoryDetails,
  logisticsReturnAndMachineryDetails,
  logisticsReturnAccessoryDetails,
} = useOneDataColumnConfigs()
const { logisticsDetailsFieldsByMachineCategory } = useFormOptionalConditions()

const machineryPosition = computed(() => props.task.positionsToShip.find(({ type }) => type === 'machinery'))
const machinery = computed(() => machineryPosition.value?.machinery)

const accessoriesPositions = computed(() => props.task.positionsToShip.filter(({ type }) => type !== 'machinery'))
const isSpecialOffer = computed(() => props.task.offer?.type === 'special')
</script>

<template>
  <TheDataCard>
    <template #header>
      <div class="CardHeader">
        <h2>{{ protocolName }}</h2>
        <n-button
          v-if="task && isRole() && !isSpecialOffer"
          @click="openLogisticsIssuanceMachineryList.open({ id: task.id, taskType: task.type as LogisticsTourDirections })"
        >
          <template #icon>
            <Icon name="material-symbols:edit-outline-rounded" />
          </template>Ändern
        </n-button>
      </div>
    </template>
    <div v-if="!task">
      <n-empty class="py-5" description="Es wurde noch keine Aufnahme für die Logistik durchgeführt." />
    </div>
    <!-- Special offer doesn't have issuance data -->
    <div v-else-if="isSpecialOffer || type === 'a-to-b'">
      <n-empty class="py-5" description="Für diesen Spezial-Auftrag ist keine Aufzeichnung erforderlich." />
    </div>
    <div v-else>
      <div v-if="machineryPosition && machinery">
        <TableOneDataColumn
          :config="{
            columns: type === 'outbound' ? logisticsIssuanceAndMachineryDetails : logisticsReturnAndMachineryDetails,
            data: machineryPosition }"
        />
        <h2 class="Heading">
          Fotos
        </h2>
        <FileView
          v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'generalPhotos', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)"
          label="Allgemeine"
          :files="machineryPosition[`${type}GeneralPhotos`]"
        />
        <FileView
          v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'innerCabinPhotos', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)"
          label="Kabine innen"
          :files="machineryPosition[`${type}InnerCabinPhotos`]"
        />
        <FileView
          v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'outerCabinPhotos', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)"
          label="Kabine außen"
          :files="machineryPosition[`${type}OuterCabinPhotos`]"
        />
        <FileView
          v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'tiresPhotos', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)"
          label="Reifen"
          :files="machineryPosition[`${type}TiresPhotos`]"
        />
        <FileView
          v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'enginePhotos', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)"
          label="Motor"
          :files="machineryPosition[`${type}EnginePhotos`]"
        />
      </div>
      <div v-if="accessoriesPositions.length" class="flex flex-col gap-y-3">
        <h2 class="Heading mt-5">
          Anbaugeräte
        </h2>

        <div v-for="accessory in accessoriesPositions" :key="accessory.id">
          <TableOneDataColumn
            :config="{
              columns: type === 'outbound' ? logisticsIssuanceAccessoryDetails : logisticsReturnAccessoryDetails,
              data: accessory }"
          />
          <FileView
            label="Anbaugerät"
            :files="accessory[`${type}LogisticsAccessoryDetails`]?.photos ?? []"
          />
        </div>
      </div>
    </div>
  </TheDataCard>
</template>

<style scoped>
.CardHeader {
  @apply  w-full flex justify-between mb-5
}
.CardHeader h2 {
  @apply text-lg font-semibold
}
.Heading {
  @apply text-lg font-semibold pb-1;
}
</style>
