<script setup lang="ts">
import { useClipboard, useShare } from '@vueuse/core'
import type { ApiLogisticsTaskGetById } from '~/types'

interface Payload {
  task: ApiLogisticsTaskGetById
}
const props = defineProps<Payload>()

const emit = defineEmits<{ (e: 'save', payload: Payload): void }>()
const customErrorMessage = ref<string | null>(null)

const { cloned: clonedTask } = useCloned(props.task)
const { cloned: clonedPositionsToShip } = useCloned(props.task.positionsToShip)

const googleMapsLink = computed(() => {
  return useGoogleMapsLink(clonedTask.value.deliveryLocation)
})

// Custom error logic to allow for signature to be null or string, but still required
// See https://github.com/sidestream-tech/hanselmann-os/pull/327#issuecomment-1544155393
function submit() {
  const taskToSave = { ...clonedTask.value, positionsToShip: clonedPositionsToShip.value }
  if (isServiceProject.value) {
    emit('save', { task: taskToSave })
    return
  }
  if (clonedTask.value.deliveryCustomerSignature === undefined) {
    customErrorMessage.value = 'Bitte trage eine Kunden Signatur ein.'
    return
  }
  if (!clonedTask.value.deliveryPerson && clonedTask.value.deliveryCustomerSignature !== null) {
    customErrorMessage.value = 'Bitte trage den Namen der eingewiesenen Person ein.'
    return
  }
  emit('save', { task: taskToSave })
}

const formkitPlugins = useFormkitPlugins()

const { coords, resume } = useGeolocation({ immediate: false })
function setDeliveryLocationToCurrentCoordinates() {
  if (!clonedTask.value) {
    return
  }

  // register a watcher, then un-pause geolocation
  watch(coords, (newCoords) => {
    if (clonedTask.value && newCoords) {
      clonedTask.value.deliveryLocation = `Koordinaten: ${newCoords.latitude},${newCoords.longitude}`
    }
  })
  resume()
}

const isServiceProject = computed(() => {
  return props.task.offer?.type === 'service-project'
})

const { share, isSupported: isShareSupported } = useShare()
const { copy, copied, isSupported: isCopySupported } = useClipboard()
</script>

<template>
  <FormKit
    type="form"
    :plugins="[formkitPlugins]"
    submit-label="Speichern"
    @submit="submit"
  >
    <FormKit
      v-model="clonedPositionsToShip"
      type="list"
    >
      <FormKit
        v-for="(position, index) of clonedPositionsToShip"
        :key="index"
        type="group"
      >
        <FormKit
          id="isDelivered"
          type="checkbox"
          :label="useMakeLabelForPosition(position)"
          :disabled="!position.isLoaded"
        />
      </FormKit>
    </FormKit>

    <FormKit v-model="clonedTask" type="group">
      <FormKitFileDropzone
        id="deliveryPhotos"
        wrap-path-in-object
        validation="required|min:3"
        label="Fotos (Min 3)"
      />

      <div class="flex flex-col mb-4">
        <FormKit
          id="deliveryLocation"
          type="text"
          label="Standort der übergebenen Geräte"
          validation="required"
          outer-class="!mb-1"
        >
          <template #suffix>
            <Icon name="material-symbols:my-location" size="20" class="hover:text-blue-500 cursor-pointer mr-2" @click="setDeliveryLocationToCurrentCoordinates" />
          </template>
        </FormKit>
        <div class="flex items-center gap-1">
          <n-button
            v-if="isShareSupported"
            :disabled="!clonedTask.deliveryLocation"
            size="small"
            @click="share({ url: googleMapsLink })"
          >
            <template #icon>
              <Icon
                name="material-symbols:ios-share"
                size="15"
              />
            </template>
            Teilen
          </n-button>
          <n-button
            size="small"
            :disabled="!clonedTask.deliveryLocation"
            @click="() => navigateTo(getMailtoForLogisticsEmail(task, googleMapsLink), { external: true })"
          >
            <template #icon>
              <Icon
                name="material-symbols:mail-outline"
                size="15"
              />
            </template>
            Mail senden
          </n-button>
          <n-button
            v-if-="isCopySupported"
            size="small"
            :disabled="!clonedTask.deliveryLocation"
            @click="copy(googleMapsLink)"
          >
            <template #icon>
              <Icon
                name="material-symbols:content-copy-outline"
                size="15"
              />
            </template>
            <span v-if="copied">
              Kopiert
            </span>
            <span v-else>
              Kopieren
            </span>
          </n-button>
        </div>
      </div>

      <FormKit
        id="deliveryPerson"
        type="text"
        label="Eingewiesene Person"
        :validation="!isServiceProject && clonedTask.deliveryCustomerSignature ? 'required' : ''"
      />

      <FormKit
        id="deliveryNotes"
        type="text"
        label="Bemerkungen"
      />

      <FormKitSignaturePopup
        id="deliveryCustomerSignature"
        label="Kunden Signatur"
        :default="undefined"
        :positions="task.positionsToShip"
      />

      <div v-if="customErrorMessage" class="text-red-500 mb-2">
        {{ customErrorMessage }}
      </div>
    </FormKit>
  </FormKit>
</template>
