/**
 * issue: https://github.com/sidestream-tech/hanselmann-os/issues/2131
 * A composable function that wraps around useRouteQuery, enabling the usage of the query as an object.
 * This is necessary due to type limitations of useRouteQuery, which only accepts `null | string | string[]` as the query value.
 *
 * @param name The name of the query parameter.
 * @param defaultValue An optional default value for the query parameter.
 * @returns return type of useRouteQuery with typed as Record<string, any> or given Object type.
 *
 * It works by invoking useRouteQuery with the provided name and a transform function to handle the query parameter as an object.
 * Additionally, it allows setting a default value separately, since useRouteQuery does not accept a Record type as a default value.
 */

import { useRouteQuery } from '@vueuse/router'

export default function useRouteQueryAsObject<T extends Record<string, any>>(name: string, defaultValue?: T) {
  const query = useRouteQuery(
    name,
    null,
    { transform: (v): T | null => v },
  )

  if (defaultValue) {
    query.value = defaultValue
  }

  return query
}
