<script setup lang="ts">
import type { ApiInvoiceGetById } from '~/types'

const { openInvoiceNextStepsPopup: { data: payload, close: closePopup }, openInvoiceCommentPopup } = useGlobalOpeners()

const { invoice: queryInvoice } = useQuery()

const { data: invoice, isLoading } = queryInvoice.byId(computed(() => payload.value?.invoiceId))

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()
const updateStatus = useMutation({
  mutationFn: $trpc.invoice.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Rechnungs-Status konnte nicht erfolgreich aktualisiert werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['invoice'] })
    closePopup()
    notification.success({ title: 'Der Rechnungs-Status wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

function cancelInvoice() {
  if (!invoice.value) {
    return
  }

  updateStatus.mutate({ id: invoice.value.id, status: 'cancelled' })
}

function openInvoicePdf(invoice: ApiInvoiceGetById) {
  const customerLocale = getLocale(invoice.offer.customer.language)

  window.open(urlWithLocale(`/api/pdf/invoice/details/${invoice.cuid}`, customerLocale), '_blank')
}

const showEmailPopup = ref<boolean>(false)
const customerInvoiceEmail = ref<string | undefined>(invoice.value?.offer?.customer?.invoiceEmail || undefined)
const isSendingMail = ref(false)

watch(invoice, (newInvoice) => {
  const newOffer = newInvoice?.offer
  if (newOffer) {
    const invoiceRecipientEmail = newOffer.otherInvoiceRecipient?.email ?? newOffer.customer.invoiceEmail
    customerInvoiceEmail.value = invoiceRecipientEmail || undefined
  }
})

function closeEmailPopup() {
  showEmailPopup.value = false
  customerInvoiceEmail.value = invoice.value?.offer?.customer?.invoiceEmail || undefined
  isSendingMail.value = false
}

const sendInvoiceMail = useMutation({
  mutationFn: ({ invoiceId, email }: { invoiceId: string, email?: string }) => {
    isSendingMail.value = true
    notification.info({ title: 'Versenden gestartet - bitte haben Sie einen Moment Geduld...', duration: 3500 })
    return $trpc.invoice.emailInvoiceToCustomer.mutate({ id: invoiceId, email })
  },
  onError: makeTrpcErrorToast(notification, { description: 'Die Rechnung konnte nicht versendet werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['invoice'] })
    closeEmailPopup()
    notification.success({ title: 'Die Rechnung wurde erfolgreich versendet', duration: 4500 })
    closePopup()
  },
})
</script>

<template>
  <div>
    <ThePopup v-if="invoice" :show="showEmailPopup" title="Email Versand bestätigen" @close="closeEmailPopup">
      <div class="flex flex-col gap-2">
        <p>
          Wollen Sie die Rechnung an die folgende Email versenden?
        </p>
        <FormKit
          type="form"
          :disabled="isSendingMail"
          @submit="sendInvoiceMail.mutate({ invoiceId: invoice.id, email: customerInvoiceEmail })"
        >
          <FormKit
            v-model="customerInvoiceEmail"
            type="email"
            label="E-mail"
            validation="email|required"
            validation-visibility="live"
            placeholder="Bitte geben Sie eine Email an."
          />

          <template #actions>
            <div class="flex items-center gap-2 justify-end">
              <n-button
                type="primary"
                :disabled="isSendingMail"
                attr-type="submit"
              >
                Email versenden
              </n-button>
              <n-button
                :disabled="isSendingMail"
                @click="closeEmailPopup"
              >
                Abbrechen
              </n-button>
            </div>
          </template>
        </FormKit>
      </div>
    </ThePopup>
    <ThePopup v-if="payload" :show="Boolean(payload)" title="Nächste Schritte einleiten" @close="closePopup">
      <div v-if="isLoading">
        Daten werden für nächste Schritte geladen...
      </div>
      <div v-else-if="invoice" class="w-1/2 mb-4 flex flex-col gap-y-3">
        <div class="flex items-center justify-between">
          <span>PDF drucken</span>
          <n-button
            @click="openInvoicePdf(invoice)"
          >
            <Icon
              name="material-symbols:description-outline-rounded"
              size="20"
              class="text-blue-900 cursor-pointer"
            />
          </n-button>
        </div>
        <div class="flex items-center justify-between">
          <span>Email versenden</span>
          <n-button @click="showEmailPopup = true">
            <Icon
              name="material-symbols:mail-outline-rounded"
              size="20"
              class="text-blue-900 cursor-pointer"
            />
          </n-button>
        </div>
        <div class="flex items-center justify-between">
          <span>Stornieren</span>
          <n-button
            :disabled="!invoice.invoiceId"
            @click="cancelInvoice"
          >
            <Icon
              name="material-symbols:cancel-outline"
              size="20"
              class="text-blue-900"
            />
          </n-button>
        </div>
        <div class="flex items-center justify-between">
          <span>Kommentar</span>
          <n-button
            :disabled="!invoice.invoiceId || !invoice.id"
            @click="openInvoiceCommentPopup.open({ id: invoice.id, invoiceId: invoice.invoiceId })"
          >
            <Icon
              name="material-symbols:comment-outline-rounded"
              size="20"
              class="text-blue-900"
            />
          </n-button>
        </div>
        <n-button type="primary" @click="closePopup">
          Vorgang beenden
        </n-button>
      </div>
    </ThePopup>
  </div>
</template>
