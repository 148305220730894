<script setup lang="ts">
import type { ShoppingTab } from '~/components/Calendar/Page.vue'
import type { ApiItemSetGetAll, ApiMachineryAccessoryGetAll, ApiMachineryGetById, MachineryAccessoryCategory, OfferStatus, ShoppingCartPosition, ShoppingCartPositionAccessory } from '~/types'
import { accessoryFilterConditionsFieldsToGermanWithPrefix, machineryAccessoryCategoryToGerman } from '~/translations'

const props = defineProps<{
  selectedMachineryToAdd?: ApiMachineryGetById | null
  detailsForMachineryAccessoriesInCart: ApiMachineryAccessoryGetAll[]
  isForkEditMode: boolean
  selectedItemSetsToAdd: ApiItemSetGetAll[]
  offerStartDay: Date
  offerEndDay?: Date
  offer: { status: OfferStatus, id?: string }
  disabledAccessoryIds: string[]
  isAccessoryOnlyOffer: boolean
}>()

const emits = defineEmits<{ (e: 'selectTab', value: ShoppingTab): void, (e: 'selectMachineryAccessoryCategory', value: MachineryAccessoryCategory | null): void, (e: 'updateIsForkEditMode', value: boolean): void, (e: 'removeSet', id: string): void }>()

const cartPositions = defineModel<ShoppingCartPosition[]>({ default: () => [] })

const { openPositionsDetailsPopup } = useGlobalOpeners()

const attachedFork = computed(() => props.selectedMachineryToAdd?.attachedMachineryAccessories.find(a => a.category === 'fork'))

function removeMachineryAccessoryFromCart(id: string) {
  const accessoryToDelete = props.detailsForMachineryAccessoriesInCart.find(accessory => accessory.id === id)

  if (!accessoryToDelete) {
    return
  }

  if (accessoryToDelete.machineryAccessoryBundle) {
    const bundleAccessoryIds = accessoryToDelete.machineryAccessoryBundle ? accessoryToDelete.machineryAccessoryBundle.machineryAccessories.map(({ id }) => id) : [id]

    cartPositions.value = cartPositions.value.filter(p => p.type !== 'machineryAccessory' || !bundleAccessoryIds.includes(p.machineryAccessoryId))
  } else {
    cartPositions.value = cartPositions.value.filter(p => p.type !== 'machineryAccessory' || p.machineryAccessoryId !== id)
  }
}

/** At least a value is expected from either index or compatibleMachineryAccessoryId */
function removeMachineryAccessoryCategoryFromCart(machineryAccessoryCategory: MachineryAccessoryCategory, index?: number, compatibleMachineryAccessoryId?: string) {
  let indexToRemove = -1

  if (index) {
    const accessoryInIndex = cartPositions.value[index]
    // check if the accessory in the index is the same as the one we want to remove
    // else we remove the first matching item
    indexToRemove = accessoryInIndex.type === 'machineryAccessoryCategory' && accessoryInIndex.machineryAccessoryCategory === machineryAccessoryCategory
      ? index
      : cartPositions.value.findIndex(p => p.type === 'machineryAccessoryCategory' && p.machineryAccessoryCategory === machineryAccessoryCategory)
  } else if (compatibleMachineryAccessoryId) {
    indexToRemove = cartPositions.value.findIndex(p => p.type === 'machineryAccessoryCategory' && p.machineryAccessoryCategory === machineryAccessoryCategory && p.compatibleMachineryAccessoryId === compatibleMachineryAccessoryId)
  }

  if (indexToRemove === -1) {
    throw new Error('Machinery accessory category not found')
  }

  const copyOfPositions = [...cartPositions.value]
  copyOfPositions.splice(indexToRemove, 1)

  cartPositions.value = copyOfPositions
}

function isCartPositionFork(position: ShoppingCartPosition) {
  if (position.type === 'machineryAccessoryCategory') {
    return position.machineryAccessoryCategory === 'fork'
  }
  return props.detailsForMachineryAccessoriesInCart.find(a => a.id === position.machineryAccessoryId)?.category === 'fork'
}

type SwitchForkInCartProps = { type: 'id', id: string } | { type: 'category', index: number }
function switchForkInCart(forkToSwitch: SwitchForkInCartProps) {
  emits('updateIsForkEditMode', true)
  emits('selectTab', 'attachable')
  emits('selectMachineryAccessoryCategory', 'fork')
  if (forkToSwitch.type === 'id') {
    removeMachineryAccessoryFromCart(forkToSwitch.id)
  } else {
    removeMachineryAccessoryCategoryFromCart('fork', forkToSwitch.index)
  }
}

function cancelForkEditMode() {
  emits('updateIsForkEditMode', false)
  if (!attachedFork.value?.id) {
    return
  }

  cartPositions.value = [
    ...cartPositions.value,
    { type: 'machineryAccessory', machineryAccessoryId: attachedFork.value?.id ?? '' },
  ]
}

const isAttached = (cartPosition: ShoppingCartPositionAccessory) => props.selectedMachineryToAdd?.attachedMachineryAccessories?.find(({ id }) => cartPosition.type === 'machineryAccessory' ? id === cartPosition.machineryAccessoryId : false)

const { payload: accessoryCompatibilityPayload, open: openAccessoryCompatibilityPopup, close: closeAccessoryCompatibilityPopup } = usePopup<{
  machineryAccessoryId: string
  machineryAccessoryCategory: MachineryAccessoryCategory
  selectedCompatiblePositions: ShoppingCartPosition[]
}>()

function getSelectedCompatibleAccessoryPositions(compatibleMachineryAccessoryId: string) {
  return cartPositions.value.filter(p => p.compatibleMachineryAccessoryId === compatibleMachineryAccessoryId)
}

const selectedAccessoryIdsWithoutCompatibleAccessoryId = cartPositions.value.flatMap((p) => {
  if (p.compatibleMachineryAccessoryId || p.type === 'machineryAccessoryCategory') {
    return []
  }

  return p.machineryAccessoryId
})

function updateCompatibleMachineryAccessories(selectedCompatibleAccessoryPositions: ShoppingCartPosition[], compatibleMachineryAccessoryId: string) {
  const positionsToAdd = selectedCompatibleAccessoryPositions
    .map(compatiblePosition => ({
      ...compatiblePosition,
      compatibleMachineryAccessoryId,
    }))

  // Clear current compatible positions in cart and re-add current selected compatible positions
  cartPositions.value = cartPositions.value
    .filter(p => p.compatibleMachineryAccessoryId !== compatibleMachineryAccessoryId)
    .concat(positionsToAdd)

  closeAccessoryCompatibilityPopup()
}
</script>

<template>
  <ShoppingAccessoryCompatibilityPopup
    v-if="accessoryCompatibilityPayload"
    :selected-machinery-id="selectedMachineryToAdd?.id"
    :selected-machinery-category="selectedMachineryToAdd?.category"
    :selected-accessory-ids-without-compatible-accessory-id="selectedAccessoryIdsWithoutCompatibleAccessoryId"
    :payload="accessoryCompatibilityPayload"
    :offer-start-day="props.offerStartDay"
    :offer-end-day="props.offerEndDay"
    :offer="offer"
    :disabled-accessory-ids="disabledAccessoryIds"
    :is-accessory-only-offer="isAccessoryOnlyOffer"
    @close="closeAccessoryCompatibilityPopup"
    @update-compatible-machinery-accessories="(selectedAccessoryPositions, compatibleMachineryAccessoryId) => updateCompatibleMachineryAccessories(selectedAccessoryPositions, compatibleMachineryAccessoryId)"
  />

  <TheDataCard>
    <h3 class="text-xl">
      Gewählte Konfiguration
    </h3>
    <div>
      <!-- Selected Machinery -->
      <div>
        <span class="font-bold">Gerät: </span>
        <span v-if="!selectedMachineryToAdd">
          Nicht gewählt
        </span>
        <span v-else class="underline text-blue-400 hover:cursor-pointer" @click="openPositionsDetailsPopup.open({ type: 'machinery', id: selectedMachineryToAdd.id })">
          {{ selectedMachineryToAdd.id }}, {{ selectedMachineryToAdd.producerCompanyName }}, {{ selectedMachineryToAdd.type.name }}
        </span>
      </div>
      <!-- Selected ItemSet -->
      <div class="mt-2 flex flex-col">
        <p class="font-bold">
          Weitere ausgewählte Sets:
        </p>
        <p v-if="selectedItemSetsToAdd.length === 0">
          Keine
        </p>
        <div v-else>
          <div v-for="(selectedItemSet, idx) in selectedItemSetsToAdd" :key="selectedItemSet.id">
            <span @click="openPositionsDetailsPopup.open({ type: 'itemSet', id: selectedItemSet.id })">
              {{ idx + 1 }}. {{ selectedItemSet.title }} <span class="underline text-blue-400 hover:cursor-pointer">({{ selectedItemSet.id }})</span>
            </span>
            <span
              class="pl-2 underline hover:cursor-pointer"
              @click="emits('removeSet', selectedItemSet.id)"
            >(Löschen)</span>
          </div>
        </div>
      </div>
      <!-- Selected machinery accessories -->
      <div class="mt-2 flex flex-col">
        <p class="font-bold">
          Weitere ausgewählte Anbau-Geräte:
        </p>
        <div v-if="isForkEditMode" class="flex-col">
          <p>
            Bitte die neuen Gabeln auswählen
          </p>
          <n-button size="small" style="width: 250px" @click="cancelForkEditMode">
            Zurück zu verbundenen Gabeln
            <Icon class="ml-2" name="material-symbols:check-circle-outline-rounded" />
          </n-button>
        </div>
        <p v-else-if="cartPositions.length === 0">
          Keine
        </p>
        <div v-else>
          <div v-for="(cartPosition, idx) in cartPositions" :key="idx">
            <div v-if="cartPosition.type === 'machineryAccessory'">
              <div className="flex flex-wrap">
                <div @click="openPositionsDetailsPopup.open({ type: 'machineryAccessory', id: cartPosition.machineryAccessoryId })">
                  <div v-if="cartPosition.isForkReplaceRequired && isCartPositionFork(cartPosition) && attachedFork">
                    {{ idx + 1 }}. Gabeln <span class="underline text-blue-400 hover:cursor-pointer" @click="openPositionsDetailsPopup.open({ type: 'machineryAccessory', id: attachedFork.id })">{{ attachedFork.id }}</span><br>
                    Länge (mm): {{ attachedFork?.lengthInMillimeters?.toLocaleString('de-DE') ?? 'Kein Wert' }}<br>
                    Breite (mm): {{ attachedFork?.widthInMillimeters?.toLocaleString('de-DE') ?? 'Kein Wert' }}<br>
                    Height (mm): {{ attachedFork?.heightInMillimeters?.toLocaleString('de-DE') ?? 'Kein Wert' }}<br>
                    <span className="font-semibold">Wird ersetzt durch:</span>
                  </div>
                  <span v-else>
                    {{ idx + 1 }}.
                  </span>
                  <span>
                    {{ machineryAccessoryCategoryToGerman[detailsForMachineryAccessoriesInCart?.find(a => a.id === cartPosition.machineryAccessoryId)?.category as MachineryAccessoryCategory] ?? 'Lädt...' }} <span class="underline text-blue-400 hover:cursor-pointer">({{ cartPosition.machineryAccessoryId }})</span>
                  </span>
                </div>
                <span
                  v-if="isAttached(cartPosition)"
                  class="pl-2 hover:cursor-disabled"
                >(Verbunden)</span>
                <span
                  v-if="isCartPositionFork(cartPosition) && isAttached(cartPosition) || cartPosition.isForkReplaceRequired"
                  class="pl-2 underline hover:cursor-pointer"
                  @click="switchForkInCart({ type: 'id', id: cartPosition.machineryAccessoryId })"
                >(Gabeln wechseln)</span>
                <span
                  v-else-if="!isAttached(cartPosition)"
                  class="pl-2 underline hover:cursor-pointer"
                  @click="removeMachineryAccessoryFromCart(cartPosition.machineryAccessoryId)"
                >(Löschen)</span>
                <span
                  v-if="!cartPosition.compatibleMachineryAccessoryId"
                  class="pl-2 underline hover:cursor-pointer text-orange-500"
                  @click="openAccessoryCompatibilityPopup({
                    machineryAccessoryId: cartPosition.machineryAccessoryId,
                    machineryAccessoryCategory: detailsForMachineryAccessoriesInCart?.find(a => a.id === cartPosition.machineryAccessoryId)?.category as MachineryAccessoryCategory,
                    selectedCompatiblePositions: getSelectedCompatibleAccessoryPositions(cartPosition.machineryAccessoryId),
                  })"
                >Kompatible Lagertools</span>
                <span
                  v-else
                  class="pl-2"
                >(Kompatibel mit:
                  <span
                    class="underline text-blue-400 hover:cursor-pointer"
                    @click="openPositionsDetailsPopup.open({ type: 'machineryAccessory', id: cartPosition.compatibleMachineryAccessoryId })"
                  >{{ cartPosition.compatibleMachineryAccessoryId }}</span>)
                </span>
              </div>
              <!-- show the width of the fork, if the selected fork is permanently connected one -->
              <p v-if="isCartPositionFork(cartPosition) && isAttached(cartPosition)">
                Länge (mm): {{ attachedFork?.lengthInMillimeters?.toLocaleString('de-DE') ?? 'Kein Wert' }}<br>
                Breite (mm): {{ attachedFork?.widthInMillimeters?.toLocaleString('de-DE') ?? 'Kein Wert' }}<br>
                Höhe (mm): {{ attachedFork?.heightInMillimeters?.toLocaleString('de-DE') ?? 'Kein Wert' }}<br>
              </p>
            </div>
            <div v-else>
              <div className="flex flex-wrap">
                <div>
                  <div v-if="cartPosition.isForkReplaceRequired && isCartPositionFork(cartPosition) && attachedFork">
                    {{ idx + 1 }}. Gabeln <span class="underline text-blue-400 hover:cursor-pointer" @click="openPositionsDetailsPopup.open({ type: 'machineryAccessory', id: attachedFork.id })">{{ attachedFork.id }}</span><br>
                    Länge (mm): {{ attachedFork?.lengthInMillimeters?.toLocaleString('de-DE') ?? 'Kein Wert' }}<br>
                    Breite (mm): {{ attachedFork?.widthInMillimeters?.toLocaleString('de-DE') ?? 'Kein Wert' }}<br>
                    Höhe (mm): {{ attachedFork?.heightInMillimeters?.toLocaleString('de-DE') ?? 'Kein Wert' }}<br>
                    <span className="font-semibold">Wird ersetzt durch:</span>
                  </div>
                  <span v-else>
                    {{ idx + 1 }}.
                  </span>
                  <span>
                    {{ machineryAccessoryCategoryToGerman[cartPosition.machineryAccessoryCategory] }}
                  </span>
                </div>
                <span
                  v-if="isCartPositionFork(cartPosition) && cartPosition.isForkReplaceRequired"
                  class="pl-2 underline hover:cursor-pointer"
                  @click="switchForkInCart({ type: 'category', index: idx })"
                >(Gabeln wechseln)</span>
                <span v-else class="pl-2 underline hover:cursor-pointer" @click="removeMachineryAccessoryCategoryFromCart(cartPosition.machineryAccessoryCategory, idx, cartPosition.compatibleMachineryAccessoryId)">(Löschen)</span>
                <span
                  v-if="cartPosition.compatibleMachineryAccessoryId"
                  class="pl-2"
                >(Kompatibel mit:
                  <span
                    class="underline text-blue-400 hover:cursor-pointer"
                    @click="openPositionsDetailsPopup.open({ type: 'machineryAccessory', id: cartPosition.compatibleMachineryAccessoryId })"
                  >{{ cartPosition.compatibleMachineryAccessoryId }}</span>)
                </span>
              </div>
              <div class="mx-3 text-xs text-gray-500 flex gap-2 flex-wrap">
                <div v-for="(filter, key) in cartPosition.filters" :key="key" :class="filter ? 'w-[calc(50%-0.5rem)]' : 'hidden'">
                  <p v-if="filter">
                    <span>{{ accessoryFilterConditionsFieldsToGermanWithPrefix(cartPosition.machineryAccessoryCategory, key) }}: </span><span>{{ typeof filter === 'number' ? filter.toLocaleString('de-DE') : filter }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </TheDataCard>
</template>
