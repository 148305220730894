import path from 'pathe'

/**
 * Get the lower-cased file extension of a file (including its dot!), given the path to the file.
 * @param {string} fileSourcePath - Path to the file, for example: `/my/secret/file.pdf`.
 *
 * @example
 * // returns `.pdf`
 * getFileExtension('/my/secret/file.pDf')
 */
export default (fileSourcePath: string) => path.extname(fileSourcePath).toLocaleLowerCase()
