<script setup lang="ts">
import type { LogisticsTaskGetByCuidPositionToShip, MachineryCategories, MachineryDrives, OfferPositionsToShip } from '~/types'

const props = defineProps<{
  cuid: string
  type: 'issued' | 'collected'
  defaultOpenedPositions?: string[]
}>()

const { logisticsTask: queryLogisticsTask } = useQuery()
const { data: logisticsTask, isLoading, error } = queryLogisticsTask.byCuidOrThrow(computed(() => props.cuid))

const positionsToShow = computed(() => logisticsTask.value?.positionsToShip.filter((position) => {
  if (props.type === 'issued') {
    return position.isIssued
  } else {
    return position.isCollected
  }
}) ?? [])

const itemSetDetails: OneDataColumn<LogisticsTaskGetByCuidPositionToShip>[] = [
  {
    title: 'Set Nr.',
    key: 'itemSetId',
    minWidth: 200,
  },
  {
    title: 'Titel',
    key: 'title',
    minWidth: 200,
  },
  {
    title: 'Geräte Nr.',
    key: 'itemSet',
    minWidth: 200,
    render: ({ itemSet }) => itemSet?.machineryAccessories.map(({ id }) => id).join(', '),
  },
]

const specialPositionDetails: OneDataColumn<LogisticsTaskGetByCuidPositionToShip>[] = [{
  title: 'Titel',
  key: 'title',
  minWidth: 200,
}]

const {
  logisticsIssuanceAndMachineryDetails,
  logisticsIssuanceAccessoryDetails,
  logisticsReturnAndMachineryDetails,
  logisticsReturnAccessoryDetails,
} = useOneDataColumnConfigs()

const currentColumns = computed(() => {
  if (props.type === 'issued') {
    return {
      machinery: logisticsIssuanceAndMachineryDetails,
      machineryAccessory: logisticsIssuanceAccessoryDetails,
      machineryAccessoryCategory: logisticsIssuanceAccessoryDetails,
      itemSet: itemSetDetails,
      special: specialPositionDetails,
    }
  } else {
    return {
      machinery: logisticsReturnAndMachineryDetails,
      machineryAccessory: logisticsReturnAccessoryDetails,
      machineryAccessoryCategory: logisticsReturnAccessoryDetails,
      itemSet: itemSetDetails,
      special: specialPositionDetails,
    }
  }
})

const { logisticsDetailsFieldsByMachineCategory } = useFormOptionalConditions()

const logisticsType = computed(() => {
  if (props.type === 'issued') {
    return 'outbound'
  } else {
    return 'inbound'
  }
})

const { status } = useAuth()
const showLogo = computed(() => status.value === 'unauthenticated')
</script>

<template>
  <div>
    <TheDataCard v-if="showLogo" class="mb-2">
      <TheLogo />
    </TheDataCard>
    <TheDataCard
      :is-loading="isLoading"
      :title="`${type === 'issued' ? 'Ausgabeprotokoll' : 'Rücknahmeprotokoll'} ${logisticsTask?.id ?? cuid}`"
      :error="error"
    >
      <n-collapse :default-expanded-names="defaultOpenedPositions">
        <n-collapse-item
          v-for="position of positionsToShow"
          :key="position.id"
          :name="position.id"
          :title="position.title"
        >
          <TableOneDataColumn
            :config="{
              columns: currentColumns[position.type as OfferPositionsToShip],
              data: position,
            }"
          />
          <div v-if="position.type === 'itemSet'" class="mt-4">
            <n-collapse>
              <n-collapse-item
                v-for="itemSetPosition of position.itemSetMember"
                :key="itemSetPosition.id"
                :name="itemSetPosition.id"
                :title="itemSetPosition.title"
              >
                <TableOneDataColumn
                  :config="{
                    columns: currentColumns.machineryAccessory,
                    data: itemSetPosition,
                  }"
                />
                <div v-if="itemSetPosition.isNotReturnable" class="mt-4 flex flex-col gap-2">
                  <p class="font-semibold">
                    Das Lagertool ist als nicht rückgabefähig gekennzeichnet
                  </p>
                  <p>Erklärung: {{ itemSetPosition.notReturnableReason }}</p>
                </div>
                <p class="Label">
                  Fotos
                </p>
                <FileView
                  :files="itemSetPosition[`${logisticsType}LogisticsAccessoryDetails`]?.photos ?? []"
                />
              </n-collapse-item>
            </n-collapse>
          </div>
          <div v-else>
            <div v-if="position.isNotReturnable" class="mt-4 flex flex-col gap-2">
              <p class="font-semibold">
                {{ position.type === 'machinery' ? 'Das Gerät' : 'Das Lagertool' }} ist als nicht rückgabefähig gekennzeichnet
              </p>
              <p>Erklärung: {{ position.notReturnableReason }}</p>
            </div>
            <div v-else-if="position.type === 'machinery' && position.machinery">
              <p class="Heading">
                Fotos
              </p>
              <FileView
                v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'generalPhotos', position.machinery.category as MachineryCategories, position.machinery.drive.name as MachineryDrives)"
                label="Allgemeine"
                :files="position[`${logisticsType}GeneralPhotos`]"
              />
              <FileView
                v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'innerCabinPhotos', position.machinery.category as MachineryCategories, position.machinery.drive.name as MachineryDrives)"
                label="Kabine innen"
                :files="position[`${logisticsType}InnerCabinPhotos`]"
              />
              <FileView
                v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'outerCabinPhotos', position.machinery.category as MachineryCategories, position.machinery.drive.name as MachineryDrives)"
                label="Kabine außen"
                :files="position[`${logisticsType}OuterCabinPhotos`]"
              />
              <FileView
                v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'tiresPhotos', position.machinery.category as MachineryCategories, position.machinery.drive.name as MachineryDrives)"
                label="Reifen"
                :files="position[`${logisticsType}TiresPhotos`]"
              />
              <FileView
                v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'enginePhotos', position.machinery.category as MachineryCategories, position.machinery.drive.name as MachineryDrives)"
                label="Motor"
                :files="position[`${logisticsType}EnginePhotos`]"
              />
            </div>
            <div v-else-if="position.type === 'special' && position.isIssued">
              <p class="Label">
                Fotos
              </p>
              <FileView
                :files="position[`${logisticsType}GeneralPhotos`]"
              />
            </div>
            <div v-else>
              <p class="Label">
                Fotos
              </p>
              <FileView
                :files="position[`${logisticsType}LogisticsAccessoryDetails`]?.photos ?? []"
              />
            </div>
          </div>
        </n-collapse-item>
      </n-collapse>
    </TheDataCard>
    <n-alert type="info" class="mt-2" :show-icon="false">
      <template #header>
        <n-collapse>
          <n-collapse-item>
            <template #header>
              <h1 class="text-base">
                Mietbedingungen/Hinweise
              </h1>
            </template>
            <div class="flex flex-col gap-2 ml-1 font-normal">
              <p>
                Die eingewiesenen und mit der Funktion des Mietgegenstandes vertraut gemachten Personen bestätigen durch ihre Unterschrift, dass sie insbesondere auf folgendes hingewiesen wurden:
              </p>
              <ul class="list-disc list-inside">
                <li>
                  Nachstehende Personen wurden in die Handhabung des Mietgegenstandes, die Sicherheits- und Unfallverhütungsvorschriften sowie den im Notfall zugehhörigen Sicherheitsvorkehrungen vertraut gemacht.
                </li>
                <li>
                  Die Bedienungsanleitung und UVV-Vorschriften sind vor der Inbetriebnahme sorgfältig durchzulesen und müssen inhaltlich verstanden werden.
                </li>
                <li>
                  Der Bediener muss gemäß DGUV das 18. Lebensjahr vollendet haben, geistig und körperlich geeignet und unterwiesen sein.
                </li>
                <li>
                  Der Bediener muss vom Unternehmer schriftlich beauftragt sein.
                </li>
                <li>
                  Täglich ist eine Funktions- und Sichtprüfung vorzunehmen, der Motorölstand ist zu kontrollieren. Ebenfalls muss wöchentlich der Batteriewasserstand bei Elektro-
                  Maschinen überprüft werden. Achtung: Gelbatterien und verschlossene Batterien niemals öffnen!
                </li>
                <li>
                  Nicht fachgerechte Arbeitsweise kann zu Personenschäden oder Schäden am Mietgerät führen.
                </li>
                <li>
                  Spritz- und Sandstrahlarbeiten sind verboten.
                </li>
                <li>
                  Bei starker Verschmutzung des Gerätes entstehen Reinigungskosten.
                </li>
                <li>
                  Das Gerät darf nicht zweckentfremdet werden.
                </li>
                <li>
                  Die max. Tragkraft darf nicht überschritten werden.
                </li>
                <li>
                  Bodenverhältnisse am Einsatzort sind zu prüfen (Schächte, Kanäle, Untergrund, Belastbarkeit...).
                </li>
                <li>
                  Bei Eigentransport ist vom Mieter auf genügend Anhängelast des Zugfahrzeugs und auf korrekte Ladesicherung zu achten.
                </li>
                <li>
                  Kommt es zu Störungen, benachrichtigen Sie bitte umgehend den Vermieter. Bei Schäden, die eine Umweltgefährdung zur Folge haben könnten (z.B. auf Grund eines defekten Hydraulikschlauches o. a.) sind Sie verpflichtet, alle notwendigen Sofortmaßnahmen zur Schadensminderung einzuleiten.
                </li>
                <li>
                  Bitte tragen Sie Sorge, dass über ihre Betriebshaftpflicht das Führen von angemieteten Geräten versichert ist und somit Fremdschäden gedeckt sind.
                </li>
              </ul>
              <p>
                Es gelten unsere Miet- und Geschäftsbedingungen, einzusehen unter <nuxt-link to="https://www.hanselmann.de/impressum" target="_blank" class="underline text-primary">
                  https://www.hanselmann.de/impressum.
                </nuxt-link>
              </p>
              <p>
                ACHTUNG: Wir weisen ausdrücklich darauf hin, dass unser Fahrer bei Abholung von Geräten und Zubehör NUR die Übernahme bestätigt. Die Geräteendkontrolle auf evtl. Schäden (auch äußerlich erkennbar sowie Folgeschäden) kann nur in der Haus eigenen Werkstatt vorgenommen werden.
              </p>
            </div>
          </n-collapse-item>
        </n-collapse>
      </template>
    </n-alert>
  </div>
</template>

<style scoped>
.Label {
  @apply font-bold py-2
}
.Heading {
  @apply text-base font-bold py-4
}
</style>
