<script lang="ts" setup>
import { useRouteQuery } from '@vueuse/router'
import currency from 'currency.js'
import { calculateTotals as calculateInvoiceTotals } from '~/server/pdf-generation/sections/invoice/total'

const { openInvoicePartialPaymentPopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const amountToPay = ref(0)
watch(popupData, () => {
  if (!popupData.value) {
    return
  }
  amountToPay.value = 0
})

const { invoice: queryInvoice } = useQuery()
const { data: invoice } = queryInvoice.byId(computed(() => popupData.value?.id))

const totalPaidAmount = computed(() => {
  if (!invoice.value) {
    return 0
  }

  return invoice.value.payments.reduce((acc, payment) => {
    return acc.add(payment.amount)
  }, currency(0)).value
})

const bruttoTotalAmount = computed(() => {
  if (!invoice.value) {
    return 0
  }

  return calculateInvoiceTotals(invoice.value).brutto.value
})

const leftAmountToPay = computed(() => {
  if (!invoice.value) {
    return 0
  }

  const amountToPay = currency(bruttoTotalAmount.value).subtract(totalPaidAmount.value)
  return amountToPay.value
})

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const invoiceConfirmPaymentToClosePopup = useRouteQuery('popupConfirmInvoicePayment')
const updatePartialPayment = useMutation({
  mutationFn: $trpc.invoice.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Rechnungs-Status konnte nicht erfolgreich aktualisiert werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['invoice'] })
    invoiceConfirmPaymentToClosePopup.value = null

    closePopup()

    notification.success({ title: 'Die Rechnung wurde als bezahlt markiert', duration: 4500 })
  },
})

function updateInvoice() {
  if (!invoice.value) {
    return
  }

  updatePartialPayment.mutate({
    id: invoice.value.id,
    status: 'partiallyPaid',
    paidAmount: amountToPay.value,
  })
}

const formkitPlugins = useFormkitPlugins()
</script>

<template>
  <ThePopup v-if="popupData && invoice" :show="Boolean(popupData && invoice)" :title="`Teilweise Bezahlung von Rechnung ${invoice.invoiceId}`" @close="closePopup">
    <div class="mb-4">
      <p>
        <span class="font-bold inline-block w-[16rem]">Vollständiger Rechnungsbetrag:</span> {{ formatNumberToString(bruttoTotalAmount) }} EUR
      </p>
      <p v-if="totalPaidAmount">
        <span class="font-bold inline-block w-[16rem]">Bezahlter Rechnungsbetrag:</span> {{ formatNumberToString(totalPaidAmount) }} EUR
      </p>
    </div>
    <FormKit
      type="form"
      :plugins="[formkitPlugins]"
      submit-label="Teilweise Zahlung bestätigen"
      @submit="updateInvoice"
    >
      <FormKit
        v-model="amountToPay"
        type="number"
        number
        step="0.1"
        label="Höhe des Zahlungseingangs angeben (EUR)"
        validation-visibility="live"
        :validation="`required|min:0|max:${leftAmountToPay - 0.01}`"
        :validation-messages="{
          required: 'Bitte geben Sie die Höhe des Zahlungseingangs an',
          min: 'Der Zahlungseingang muss mindestens 0 EUR betragen',
          max: 'Da der Zahlungseingang den vollständigen Rechnungsbetrag deckt, bitte entsprechend die vollständige Zahlung der Rechnung bestätigen.',
        }"
      />
    </FormKit>
  </ThePopup>
</template>
