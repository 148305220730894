import type { SelectOption } from 'naive-ui'
import type { MachineryAccessoryCategory, MachineryAccessoryCategoryFilter } from '~/types'
import type { ShoppingTab } from '~/components/Calendar/Page.vue'
import { machineryFemsAndFive } from '~/server/schemas/machineryAndMachineryAccessoryCommon'

// Add StringTypeFields/NumberTypeFields to use v-model for filter inputs in Shopping/AccessoryOverview.vue correctly
export type StringTypeFields = 'producerCompanyName' | 'typeName' | 'storageLocation' | 'volt' | 'wheelSize' | 'fem'
export type NumberTypeFields = 'liftingWeight' | 'lengthInMillimeters' | 'widthInMillimeters' | 'heightInMillimeters' | 'retractionLugsWidth' | 'retractionLugsHeight' | 'retractionLugsDistanceInnerEdgeToInnerEdge' | 'retractionLugsDistanceOuterEdgeToOuterEdge' | 'weightInKilograms' | 'cubicMeters' | 'distanceInnerEdgeToInnerEdge' | 'distanceOuterEdgeToOuterEdge'

export interface AccessoryFilterCondition {
  type: 'select' | 'text' | 'number'
  compare: 'equal' | 'min' | 'includes'
  options?: SelectOption[]
  upsupportedTab?: ShoppingTab
  optionsFromDBDataConfigs?: { getValue: (parm: string | number) => string | number }
}
export type AccessoryFilter = Partial <Record<keyof MachineryAccessoryCategoryFilter, AccessoryFilterCondition>>
export type AccessoryFilterConditions = Partial<Record<MachineryAccessoryCategory, AccessoryFilter>>
const filters: Readonly<AccessoryFilterConditions> = {
  hookLong: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'select',
      compare: 'min',
      options: [4000, 5000, 6000, 8000, 9000, 13000, 18000].map(val => ({ label: String(val), value: val })),
    },
  },
  hookShort: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'select',
      compare: 'min',
      options: [4000, 5000, 6000, 8000, 9000, 13000, 18000].map(val => ({ label: String(val), value: val })),
    },
  },
  clipOnHook: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    retractionLugsWidth: {
      type: 'number',
      compare: 'min',
    },
    retractionLugsHeight: {
      type: 'number',
      compare: 'min',
    },
    retractionLugsDistanceInnerEdgeToInnerEdge: {
      type: 'number',
      compare: 'min',
    },
    retractionLugsDistanceOuterEdgeToOuterEdge: {
      type: 'number',
      compare: 'min',
    },
    liftingWeight: {
      type: 'select',
      compare: 'min',
      options: [5000].map(val => ({ label: String(val), value: val })),
    },
  },
  latticeMastWinch: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'select',
      compare: 'min',
      options: [800, 1500, 2000, 4000, 5000, 6000].map(val => ({ label: String(val), value: val })),
    },
  },
  winch: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'select',
      compare: 'min',
      options: [3000, 4000, 5000, 6000, 7200, 23000].map(val => ({ label: String(val), value: val })),
    },
  },
  wheels: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    wheelSize: {
      type: 'number',
      compare: 'equal',
    },
  },
  workBasket: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'select',
      compare: 'min',
      options: [365, 500, 920, 1000].map(val => ({ label: String(val), value: val })),
    },
  },
  clipOnBasket: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'number',
      compare: 'min',
    },
    retractionLugsWidth: {
      type: 'number',
      compare: 'min',
    },
    retractionLugsHeight: {
      type: 'number',
      compare: 'min',
    },
    retractionLugsDistanceInnerEdgeToInnerEdge: {
      type: 'number',
      compare: 'min',
    },
    retractionLugsDistanceOuterEdgeToOuterEdge: {
      type: 'number',
      compare: 'min',
    },
  },
  charger: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    volt: {
      type: 'text',
      compare: 'equal',
    },
  },
  weights: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    weightInKilograms: {
      type: 'number',
      compare: 'equal',
    },
  },
  rollers: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
  },
  stands: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'number',
      compare: 'min',
    },
  },
  forkAdjuster: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'number',
      compare: 'min',
    },
    distanceInnerEdgeToInnerEdge: {
      type: 'number',
      compare: 'equal',
    },
    distanceOuterEdgeToOuterEdge: {
      type: 'number',
      compare: 'equal',
    },
  },
  sideShifter: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'number',
      compare: 'min',
    },
    distanceInnerEdgeToInnerEdge: {
      type: 'number',
      compare: 'equal',
    },
    distanceOuterEdgeToOuterEdge: {
      type: 'number',
      compare: 'equal',
    },
  },
  forkCarriage: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'number',
      compare: 'min',
    },
  },
  ramps: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'number',
      compare: 'min',
    },
  },
  jib: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'number',
      compare: 'min',
    },
  },
  heavyCasters: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    drive: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'select',
      compare: 'equal',
      optionsFromDBDataConfigs: { getValue: Number },
    },
    heightInMillimeters: {
      type: 'number',
      compare: 'min',
    },
    description: {
      type: 'text',
      compare: 'equal',
    },
    comment: {
      type: 'text',
      compare: 'equal',
    },
  },
  shovel: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'select',
      compare: 'min',
      options: [300, 600, 730, 1000, 1500, 2000, 2500].map(val => ({ label: String(val), value: val })),
    },
    cubicMeters: {
      type: 'number',
      compare: 'equal',
    },
  },
  loadDistributionPlates: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    lengthInMillimeters: {
      type: 'select',
      compare: 'equal',
      optionsFromDBDataConfigs: { getValue: Number },
    },
    comment: {
      type: 'text',
      compare: 'equal',
    },
  },
  ballast: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    weightInKilograms: {
      type: 'number',
      compare: 'equal',
    },
  },
  forkExtension: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'number',
      compare: 'min',
    },
    lengthInMillimeters: {
      type: 'number',
      compare: 'min',
    },
    retractionLugsWidth: {
      type: 'number',
      compare: 'min',
    },
    retractionLugsHeight: {
      type: 'number',
      compare: 'min',
    },
  },
  fork: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'number',
      compare: 'min',
      upsupportedTab: 'attachable',
    },
    lengthInMillimeters: {
      type: 'select',
      compare: 'equal',
      optionsFromDBDataConfigs: { getValue: Number },
    },
    fem: {
      type: 'select',
      compare: 'equal',
      options: machineryFemsAndFive.options.map(val => ({ label: val, value: val })),
    },
    widthInMillimeters: {
      type: 'number',
      compare: 'equal',
    },
    heightInMillimeters: {
      type: 'number',
      compare: 'equal',
    },
  },
  craneArm: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'select',
      compare: 'min',
      options: [2000, 4000, 6000, 18000].map(val => ({ label: String(val), value: val })),
    },
  },
  rotaryDevice: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'number',
      compare: 'min',
    },
  },
  coilDorn: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'number',
      compare: 'min',
    },
    lengthInMillimeters: {
      type: 'number',
      compare: 'min',
    },
  },
  miscellaneous: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'number',
      compare: 'min',
    },
    lengthInMillimeters: {
      type: 'number',
      compare: 'min',
    },
    weightInKilograms: {
      type: 'number',
      compare: 'equal',
    },
    volt: {
      type: 'text',
      compare: 'equal',
    },
    description: {
      type: 'text',
      compare: 'equal',
    },
    comment: {
      type: 'text',
      compare: 'equal',
    },
  },
  abusBogie: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'number',
      compare: 'min',
    },
  },
  heavyDutyLifter: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'select',
      compare: 'equal',
      optionsFromDBDataConfigs: { getValue: Number },
    },
    drive: {
      type: 'text',
      compare: 'equal',
    },
    comment: {
      type: 'text',
      compare: 'equal',
    },
  },
  basePlate: {
    producerCompanyName: {
      type: 'text',
      compare: 'equal',
    },
    typeName: {
      type: 'text',
      compare: 'equal',
    },
    storageLocation: {
      type: 'text',
      compare: 'equal',
    },
    liftingWeight: {
      type: 'number',
      compare: 'min',
    },
  },
}

const sanitizeString = (str: string) => str.trim().toLocaleLowerCase('de')
function checkShoppingAccessoryIncludes(accessoryValue: string | number, valueToCompare: string | number) {
  if (typeof valueToCompare !== 'string' || typeof accessoryValue !== 'string') {
    return false
  }

  return sanitizeString(accessoryValue).includes(sanitizeString(valueToCompare))
}
function checkShoppingAccessoryEqual(accessoryValue: string | number, valueToCompare: string | number) {
  if (typeof valueToCompare !== 'string' || typeof accessoryValue !== 'string') {
    return Number(accessoryValue) !== Number(valueToCompare)
  }

  return sanitizeString(accessoryValue) !== sanitizeString(valueToCompare)
}

export function checkShoppingAccessoryFilter(filters: AccessoryFilter, filterKey: keyof MachineryAccessoryCategoryFilter, accessoryValue: string | number, valueToCompare: string | number) {
  if (filters[filterKey]?.compare === 'equal') {
    return !checkShoppingAccessoryEqual(accessoryValue, valueToCompare)
  } else if (filters[filterKey]?.compare === 'includes') {
    return !checkShoppingAccessoryIncludes(accessoryValue, valueToCompare)
  } else if (filters[filterKey]?.compare === 'min' && accessoryValue < valueToCompare) {
    return false
  }
  return true
}

export default filters
