<script lang="ts" setup>
import type { ApiOfferGetAll, OfferView } from '~/types'

const props = defineProps<{ offer: ApiOfferGetAll, view?: OfferView }>()

const { useremail, isRole } = useAuthorization()
const { openOfferPage } = useGlobalOpeners()
const notification = useNotification()

function handleClick() {
  const { id, type, userMemberships } = props.offer
  const isServiceProject = type === 'service-project'
  const isLeadRole = isRole(['department-lead', 'bookkeeping'])
  const isServiceProjectMember = userMemberships.some(({ userEmail }) => userEmail === useremail.value)

  if (isServiceProject && !isLeadRole && !isServiceProjectMember) {
    notification.error({ title: 'Sie sind kein Mitglied dieser Dienstleistung', duration: 4500 })
    return
  }
  const selectedTab = isServiceProject && props.view === 'order' ? 'timeTracking' : undefined

  openOfferPage({ mode: 'edit', id, selectedTab })
}
</script>

<template>
  <OfferStatusTimelinePopover :offer-id="offer.id" :disabled="offer.status !== 'order'" placement="top-start">
    <div
      class="bg-gray-100 hover:bg-gray-200 p-3 rounded my-2 flex flex-col cursor-pointer text-gray-400"
      @click="handleClick"
    >
      <h1 class="font-bold text-gray-500">
        {{ offer.id }}
      </h1>
      <div class="mt-2">
        <p>Titel: {{ offer.title || 'N/A' }}</p>
        <p>
          Einsatzdatum:
          <span v-if="offer.type === 'service-project'">{{ useDateAsString(offer.obligationStartsAt, 'dd.MM.yyyy') }}</span>
          <span v-else>{{ useDateAsString(offer.obligationStartsAt) }}</span>
        </p>
        <p>Einsatzort:  {{ offer.deliveryLocation || 'N/A' }}</p>
        <p>Kundenname:  {{ offer.customer.name }}</p>
      </div>
    </div>
  </OfferStatusTimelinePopover>
</template>
