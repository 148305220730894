import { useRouteQuery } from '@vueuse/router'

export default (name: string, defaultRange?: [number, number] | [string, string]) => {
  let _defaultRange: [string, string] | null = null
  if (defaultRange) {
    _defaultRange = [String(defaultRange[0]), String(defaultRange[1])]
  }

  return useRouteQuery<[string, string] | null, [number, number] | null>(
    name,
    _defaultRange,
    { transform: v => v === null ? null : [Number(v[0]), Number(v[1])] },
  )
}
